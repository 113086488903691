import { useCallback } from "react";

import {
  TrackAnalyticsEventOptions,
  TrackAnalyticsEventProperties,
  trackAnalyticsEvent,
} from "shared/Analytics";

import { AnalyticsPageBlueprints, pagesBlueprints } from "./pages/blueprints";
import { AnalyticsPageNotifications, pagesNotifications } from "./pages/notifications";
import { AnalyticsPageContext, pagesContext } from "./pages/context";
import { AnalyticsPageOrganization, pagesOrganization } from "./pages/organization";
import { AnalyticsPageStack, pagesStack } from "./pages/stack";
import { AnalyticsPageModule, pagesModule } from "./pages/module";
import { AnalyticsPageWorkerPool, pagesWorkerPool } from "./pages/workerPool";
import { AnalyticsPageSpaces, pagesSpaces } from "./pages/spaces";
import { AnalyticsPagePolicy, pagesPolicy } from "./pages/policy";
import { AnalyticsPageVCS, pagesVCS } from "./pages/vcs";
import { AnalyticsPageCloudIntegrations, pagesCloudIntegration } from "./pages/cloudIntegration";
import { AnalyticsPagePersonal, pagesPersonal } from "./pages/personal";
import { AnalyticsPageLogin, pagesLogin } from "./pages/login";
import { AnalyticsPageResources, pagesResources } from "./pages/resources";
import { AnalyticsPageRuns, pagesRuns } from "./pages/runs";
import { AnalyticsPageLaunchpad, pagesLaunchpad } from "./pages/launchpad";
import { AnalyticsPageWebhook, pagesWebhook } from "./pages/webhooks";
import { AnalyticsContent, Pages } from "./pages/types";
import { AnalyticsPageDashboard, pagesDashboard } from "./pages/dashboard";
import { AnalyticsPageGlobal, pagesGlobal } from "./pages/global";

export type AnalyticsCommonProps = {
  analyticsPage?: AnalyticsPage;
  analyticsTitle?: string;
  analyticsProps?: TrackAnalyticsEventProperties;
};

const pages: Pages<AnalyticsPage> = {
  ...pagesBlueprints,
  ...pagesCloudIntegration,
  ...pagesContext,
  ...pagesLaunchpad,
  ...pagesLogin,
  ...pagesModule,
  ...pagesNotifications,
  ...pagesOrganization,
  ...pagesPersonal,
  ...pagesPolicy,
  ...pagesResources,
  ...pagesRuns,
  ...pagesLaunchpad,
  ...pagesDashboard,
  ...pagesSpaces,
  ...pagesStack,
  ...pagesVCS,
  ...pagesWebhook,
  ...pagesWorkerPool,
  ...pagesGlobal,
};

export type AnalyticsPage =
  | AnalyticsPageBlueprints
  | AnalyticsPageCloudIntegrations
  | AnalyticsPageContext
  | AnalyticsPageLaunchpad
  | AnalyticsPageLogin
  | AnalyticsPageModule
  | AnalyticsPageNotifications
  | AnalyticsPageOrganization
  | AnalyticsPagePersonal
  | AnalyticsPagePolicy
  | AnalyticsPageResources
  | AnalyticsPageRuns
  | AnalyticsPageLaunchpad
  | AnalyticsPageDashboard
  | AnalyticsPageSpaces
  | AnalyticsPageStack
  | AnalyticsPageVCS
  | AnalyticsPageWebhook
  | AnalyticsPageWorkerPool
  | AnalyticsPageGlobal;

export type CallbackTrack = (title: string, properties?: TrackAnalyticsEventProperties) => void;

/**
 * This hook is used to send all of analytics in our app.
 * We can use it to send events to segement and hubspot
 *
 * @param props.page - the analytics page from which the event is triggered
 * @param props.pageArguments - arguments for analytics page config which can be a function, we need to pass it only for special pages
 * @param props.callbackTrackProviders - it forces this hook to return callback function with segment and/or hubspod provider
 * @param props.callbackTrackProviders.segment - when true then callback returned from this hook will send segment event
 * @param props.callbackTrackProviders.hubspot - when true then callback returned from this hook will send hubspot event
 * @param props.defaultCallbackTrackProperties - default properties for track providers callback, it should be an object with string keys
 * @returns undefined for no callbackTrackProviders or function which is calling track providers when callbackTrackProviders provided
 */
function useAnalytics<T>(props: {
  page: AnalyticsPage;
  pageArguments?: T;
  callbackTrackProviders: TrackAnalyticsEventOptions;
  defaultCallbackTrackProperties?: TrackAnalyticsEventProperties;
}): CallbackTrack;
function useAnalytics<T>(props: {
  page?: undefined | AnalyticsPage;
  pageArguments?: T;
  callbackTrackProviders: TrackAnalyticsEventOptions;
  defaultCallbackTrackProperties?: TrackAnalyticsEventProperties;
}): CallbackTrack;
function useAnalytics<T>(props: {
  page: AnalyticsPage;
  pageArguments?: T;
  callbackTrackProviders?: never;
  defaultCallbackTrackProperties?: never;
}): undefined;
function useAnalytics<T>({
  page,
  callbackTrackProviders,
  defaultCallbackTrackProperties,
  pageArguments,
}: {
  page?: AnalyticsPage;
  pageArguments?: T;
  callbackTrackProviders?: TrackAnalyticsEventOptions | never;
  defaultCallbackTrackProperties?: TrackAnalyticsEventProperties;
}) {
  const pageValue = page && pages[page];
  let contentGroups: AnalyticsContent[];

  if (typeof pageValue === "function") {
    contentGroups = pageValue(pageArguments);
  } else {
    contentGroups = pageValue ?? [];
  }

  const trackCallback = useCallback<CallbackTrack>(
    (title, properties) => {
      try {
        if (!contentGroups) {
          return;
        }

        const locationMessage = contentGroups.reduce(
          (acc, next) => (acc ? `${acc} - ${next}` : next),
          ""
        );

        const [feature, page, ...rest] = contentGroups;
        const trackAnalyticsEventProperties = {
          eventName: title,
          feature,
          page,
          sections: rest,
        };

        trackAnalyticsEvent(
          locationMessage ? `${locationMessage} - ${title}` : title,
          {
            ...trackAnalyticsEventProperties,
            ...(defaultCallbackTrackProperties && defaultCallbackTrackProperties),
            ...(properties && properties),
          },
          callbackTrackProviders
        );
      } catch (e) {
        console.warn("Error while tracking analytics event", e);
      }
    },
    [contentGroups, defaultCallbackTrackProperties, callbackTrackProviders]
  );

  return callbackTrackProviders ? trackCallback : undefined;
}

export default useAnalytics;
