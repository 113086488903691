import { StackContextAttachment } from "types/generated";
import { SourceCodeSettingsField } from "components/Forms/SourceCode/types";

export enum ModuleCreationWizardStep {
  Vcs = "Vcs",
  Details = "Details",
  Share = "Share",
  Behavior = "Behavior",
  Cloud = "Cloud",
  Policies = "Policies",
  Contexts = "Contexts",
  Summary = "Summary",
}

export enum ModuleCreationCloud {
  AWS = "AWS",
  Azure = "Azure",
  GCP = "GCP",
}

export type ModuleCreationWizardFormStep = ModuleCreationWizardStep;

export type ModuleDetailsFormFields = {
  name?: string;
  terraformProvider?: string;
  labels: Record<"value", string>[];
  description?: string;
};

export type ModuleBehaviorFormFields = {
  administrative: boolean;
  protectFromDeletion: boolean;
  localPreviewEnabled: boolean;
  workerPool: string;
  workflowTool: string;
};

export type ModuleVcsFormFields = {
  space: string;
} & SourceCodeSettingsField;

export type ModuleShareFormFields = {
  sharedAccounts?: Record<"value", string>[];
};

type ModuleAttachContextFormFields = null;

type ModuleCloudFormFields = null;

type ModulePoliciesFormFields = null;

type ModuleSummaryFormFields = null;

export type ModuleFormFields = {
  [ModuleCreationWizardStep.Vcs]: ModuleVcsFormFields;
  [ModuleCreationWizardStep.Details]: ModuleDetailsFormFields;
  [ModuleCreationWizardStep.Share]: ModuleShareFormFields;
  [ModuleCreationWizardStep.Behavior]: ModuleBehaviorFormFields;
  [ModuleCreationWizardStep.Cloud]: ModuleCloudFormFields;
  [ModuleCreationWizardStep.Contexts]: ModuleAttachContextFormFields;
  [ModuleCreationWizardStep.Policies]: ModulePoliciesFormFields;
  [ModuleCreationWizardStep.Summary]: ModuleSummaryFormFields;
};

export type InternalFormData = {
  workerPoolLabel?: string;
  spaceLabel?: string;
  vcsIntegrationLabel?: string;
};

export type AttachedContexts<IsAutoAttached extends boolean> = Array<
  Omit<StackContextAttachment, "isAutoattached" | "contextHooks"> & {
    isAutoattached: IsAutoAttached;
  }
>;

export type ModuleCreationWizardNavStep = {
  name: string;
  summaryName: string;
  step: ModuleCreationWizardFormStep;
  isOptional?: boolean;
};
