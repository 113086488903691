import React, { useCallback, useState, useRef } from "react";
import { FetchMoreOptions, useLazyQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import WebhookDeliveriesList from "components/WebhookDeliveries";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { GET_AUDIT_TRAIL_DELIVERIES } from "./gql";
import { AuditTrailWebhookGql } from "./types";

const AuditTrailWebhookDeliveries = () => {
  const { onError } = useTypedContext(FlashContext);
  const [token, setToken] = useState<string | null | undefined>(null);
  const [active, setActive] = useState(false);
  const deliveriesRef = useRef<HTMLDivElement>(null);
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAuditTrail,
    callbackTrackProviders: { segment: true },
  });
  const onCompleted = (data: AuditTrailWebhookGql) => {
    if (!data) return;
    const { auditTrailWebhook } = data;

    if (!token && deliveriesRef) {
      const elTop = deliveriesRef?.current?.offsetTop;

      if (elTop) {
        window.scrollTo({ top: elTop - 75, behavior: "smooth" });
      }
    }

    if (auditTrailWebhook?.deliveries?.nextToken !== token) {
      setToken(auditTrailWebhook?.deliveries?.nextToken);
    } else {
      setToken(null);
    }
  };

  const [getDeliveries, { loading, data, fetchMore }] = useLazyQuery<AuditTrailWebhookGql>(
    GET_AUDIT_TRAIL_DELIVERIES,
    {
      onError,
      onCompleted,
      variables: { nextToken: token },
      nextFetchPolicy: "cache-first",
    }
  );

  const handleClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();
      trackSegmentAnalyticsEvent("Recent Deliveries click");

      if (!active && token === null) {
        getDeliveries({ variables: { nextToken: token } });
      }

      setActive(!active);
    },
    [active, token, getDeliveries]
  );

  const updateQuery: FetchMoreOptions<AuditTrailWebhookGql>["updateQuery"] = (
    prev,
    { fetchMoreResult }
  ) => {
    if (!fetchMoreResult) return prev;

    if (
      fetchMoreResult &&
      prev?.auditTrailWebhook?.deliveries?.nodes &&
      fetchMoreResult?.auditTrailWebhook?.deliveries?.nodes
    ) {
      if (fetchMoreResult.auditTrailWebhook?.deliveries?.nextToken) {
        setToken(fetchMoreResult.auditTrailWebhook?.deliveries?.nextToken);
      }

      return {
        auditTrailWebhook: {
          ...fetchMoreResult.auditTrailWebhook,
          deliveries: {
            ...fetchMoreResult.auditTrailWebhook.deliveries,
            nextToken: fetchMoreResult.auditTrailWebhook.deliveries.nextToken,
            nodes: [
              ...(prev?.auditTrailWebhook?.deliveries.nodes || []),
              ...(fetchMoreResult?.auditTrailWebhook?.deliveries.nodes || []),
            ],
          },
        },
      };
    }

    return { auditTrailWebhook: { ...prev.auditTrailWebhook } };
  };

  const loadMore = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      const asyncFn = async (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        try {
          if (fetchMore) {
            await fetchMore({ variables: { nextToken: token }, updateQuery });
          }
        } catch (error) {
          onError(error);
        }
      };

      void asyncFn(evt);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, updateQuery]
  );

  return (
    <WebhookDeliveriesList
      innerRef={deliveriesRef}
      items={data?.auditTrailWebhook?.deliveries?.nodes}
      collapsed={active}
      loading={loading}
      handleClick={handleClick}
      token={token}
      loadMore={loadMore}
      openDeliveryCallback={() => trackSegmentAnalyticsEvent("Recent Deliveries Expand Delivery")}
    />
  );
};

export default AuditTrailWebhookDeliveries;
