import { AnsibleTaskStatus } from "types/generated";

export enum Components {
  Text = "Text",
  Group = "Group",
}

export type ConfigNode = {
  id: string;
  name: string;
  count?: number;
  order?: number;
  last?: boolean;
  lastUnderRole?: boolean;
  hasChildrenToLoad?: boolean;
  description?: string;
  status?: AnsibleTaskStatus;
  parent?: string;
  limitMargin?: boolean;
  hasParentTask?: boolean;
  parentTaskId?: string;
  timestamp?: number;
  checkMode?: boolean;
  group?: Array<ConfigNode>;
  link?: {
    path: string;
    queryKey?: string;
    queryValue?: string;
  };
  roleName?: string;
  isTask?: boolean;
  isHost?: boolean;
  isPlaybook?: boolean;
  type?: Components;
  logs?: string;
};
