import { Link, NavLink } from "react-router-dom-v5-compat";

import Box from "components/box/Box";
import { AwsIntegration, SpaceAccessLevel } from "types/generated";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import { pluralize } from "shared/Pluralize";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { getDuration } from "utils/time";

import styles from "./styles.module.css";
import { showDeleteConfirmation } from "../DeleteConfirmation";

type ListItemProps = {
  integration: AwsIntegration;
};

const ListItem = (props: ListItemProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const { integration } = props;

  const canManageItem =
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Admin ||
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Write;

  const attachedStackCount = integration.attachedStacks.length;

  const onDeleteClick = (clb: () => void) => {
    clb();
    showDeleteConfirmation({ integration });
  };

  const hasAttachedEntities = attachedStackCount > 0;
  const hasManageAccess = viewer.admin || canManageItem;

  return (
    <Box className={styles.item}>
      <div className={styles.itemDetails}>
        <div className={styles.itemContent}>
          <NavLink to={integration.id} className={styles.itemName}>
            <span>{integration.name}</span>
          </NavLink>

          <div className={styles.itemInfo}>
            <div>Role ARN: {integration.roleArn}</div>
            <div>Duration: {getDuration(integration.durationSeconds)}</div>
            {integration.externalId && <div>External ID: {integration.externalId}</div>}
            {integration.spaceDetails && (
              <div>
                Space:{` `}
                <Link to={`/spaces/${integration.spaceDetails.id}`} className={styles.itemLink}>
                  {integration.spaceDetails.name}
                </Link>
              </div>
            )}
            <div>
              Used by {attachedStackCount} {pluralize("stack", attachedStackCount)}
            </div>
          </div>
        </div>

        <div className={styles.itemButtons}>
          <DropdownEllipsis>
            {({ close }) => (
              <DropdownSection>
                <DropdownSectionItem to={integration.id} v5Compat>
                  Go to details
                </DropdownSectionItem>
                <CopyFieldDropdownItem title="Copy ID" value={integration.id} callback={close} />
                {hasManageAccess && (
                  <>
                    <DropdownSectionItem to={`${integration.id}/edit`} v5Compat>
                      Edit
                    </DropdownSectionItem>
                    <DropdownSectionItem
                      tooltip="Cannot delete this integration because it is attached to a stack"
                      isTooltipActive={hasAttachedEntities}
                      onClick={() => onDeleteClick(close)}
                      danger
                      disabled={hasAttachedEntities}
                    >
                      Delete
                    </DropdownSectionItem>
                  </>
                )}
              </DropdownSection>
            )}
          </DropdownEllipsis>
        </div>
      </div>
    </Box>
  );
};

export default ListItem;
