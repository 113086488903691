import { forwardRef, memo, ReactElement, Ref, useCallback, useMemo, useRef } from "react";
import {
  ResizableTableContainer,
  Table as AriaTable,
  TableBody,
  TableHeader,
  TableBodyProps,
} from "react-aria-components";

import useTypedContext from "hooks/useTypedContext";

import TableColumnSelectAll from "./ColumnSelectAll";
import { TableContext } from "./Context";
import styles from "./styles.module.css";
import TableColumn from "./Column";

type TableProps<ItemType extends object & { id: string }, SelectAllType = string> = {
  ariaLabel?: string;
  children: TableBodyProps<ItemType>["children"];
  items: ItemType[];
  selectAllOptions?: Array<{ id: SelectAllType; title: string }>;
};

const Table = <ItemType extends object & { id: string }>(
  { children, ariaLabel, items, selectAllOptions }: TableProps<ItemType>,
  ref?: Ref<HTMLDivElement>
) => {
  const headerRef = useRef<HTMLTableSectionElement>(null);

  const {
    visibleColumns,
    rowHeaderId,
    selectable,
    onResizeEnd,
    columnSizesConfig,
    sortDescriptor,
    setSortDescriptor,
    setSavedColumnConfig,
    savedColumnConfig,
    configurableColumnIds,
  } = useTypedContext(TableContext);

  const hideColumn = useCallback(
    (id: string) => {
      const method = "column dropdown";
      if (savedColumnConfig) {
        setSavedColumnConfig(
          {
            visible: savedColumnConfig.visible.filter((value) => value !== id),
            hidden: [...savedColumnConfig.hidden, id],
          },
          method
        );
      } else {
        setSavedColumnConfig(
          {
            visible: configurableColumnIds.filter((value) => value !== id),
            hidden: [id],
          },
          method
        );
      }
    },
    [configurableColumnIds, setSavedColumnConfig, savedColumnConfig]
  );

  const allIds = useMemo(() => items.map(({ id }) => id), [items]);

  return (
    <ResizableTableContainer className={styles.tableContainer} ref={ref} onResizeEnd={onResizeEnd}>
      <AriaTable
        aria-label={ariaLabel}
        className={styles.table}
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        selectionMode="none"
      >
        <TableHeader ref={headerRef} className={styles.tableHeader}>
          {selectable && <TableColumnSelectAll allIds={allIds} options={selectAllOptions} />}
          {visibleColumns.map(({ id, resizable = true, ...columnProps }, i) => {
            return (
              <TableColumn
                key={id}
                value={{
                  id,
                  resizable: resizable && i < visibleColumns.length - 1,
                  ...columnProps,
                }}
                size={columnSizesConfig ? columnSizesConfig?.[id] : undefined}
                isRowHeader={id === rowHeaderId}
                hideColumn={hideColumn}
              />
            );
          })}
        </TableHeader>
        <TableBody className={styles.tableBody} items={items}>
          {children}
        </TableBody>
      </AriaTable>
    </ResizableTableContainer>
  );
};

export default memo(forwardRef(Table)) as <
  ItemType extends object & { id: string },
  SelectAllType = string,
>(
  p: TableProps<ItemType, SelectAllType> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
