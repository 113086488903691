import { forwardRef, MouseEvent } from "react";
import cx from "classnames";

import { IconComponent } from "types/Icon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import BaseAction, { BaseActionProps } from "../BaseAction";
import styles from "./styles.module.css";
import Icon, { IconProps } from "../Icon";

export type ButtonProps = BaseActionProps & {
  variant:
    | "primary"
    | "secondary"
    | "contrast"
    | "dangerPrimary"
    | "dangerSecondary"
    | "ghost"
    | "outlineOnDark";
  size?: "small" | "medium";
  startIcon?: IconComponent;
  startIconRotate?: IconProps["rotate"];
  endIcon?: IconComponent;
  endIconRotate?: IconProps["rotate"];
  active?: boolean;
  disabled?: boolean;
  children: string;
} & AnalyticsCommonProps;

const Button = forwardRef(function Button(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const {
    size = "medium",
    variant,
    onClick,
    children,
    className,
    startIcon,
    startIconRotate,
    endIcon,
    endIconRotate,
    active,
    ...restProps
  } = props;

  const handleOnClick = (e: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>) => {
    if (restProps.type !== "submit") {
      e.preventDefault();
    }
    onClick?.(e);
  };

  return (
    <BaseAction
      ref={ref}
      className={cx(
        styles.button,
        styles[size],
        styles[variant],
        {
          [styles.active]: active,
          [styles.disabled]: restProps.disabled,
        },
        className
      )}
      {...(onClick ? { onClick: handleOnClick } : {})}
      {...restProps}
    >
      {startIcon && <Icon src={startIcon} aria-hidden rotate={startIconRotate} />}
      {children}
      {endIcon && <Icon src={endIcon} aria-hidden rotate={endIconRotate} />}
    </BaseAction>
  );
});

export default Button;
