import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool, WorkerPoolUser } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getSortOptionFromURI } from "components/Filters/helpers";
import { uniqByKey } from "utils/uniq";
import {
  ITEMS_LIMIT,
  POLL_INTERVAL,
  INITIAL_SORT_OPTION,
  INITIAL_SORT_DIRECTION,
} from "views/account/WorkerPool/UsedByView/constants";

import { SEARCH_PUBLIC_WORKER_POOL_USED_BY } from "./gql";

const useSearchPublicWorkerPoolUsedBy = () => {
  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<WorkerPoolUser[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { error, loading, data, stopPolling, fetchMore, networkStatus, previousData } = useQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(SEARCH_PUBLIC_WORKER_POOL_USED_BY, {
    variables: {
      input: {
        predicates: null,
        fullTextSearch: searchInput,
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [publicWorkerPool, rawSearchUsers] = useMemo(() => {
    if (data?.publicWorkerPool) {
      const { searchUsers, ...publicWorkerPool } = data.publicWorkerPool;

      return [publicWorkerPool, searchUsers];
    }

    return [undefined, undefined];
  }, [data?.publicWorkerPool]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchUsers?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchUsers?.edges, loading]);

  const loadMoreItems = async () => {
    try {
      if (rawSearchUsers?.pageInfo.endCursor && rawSearchUsers?.pageInfo.hasNextPage) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult?.publicWorkerPool.searchUsers &&
              fetchMoreResult.publicWorkerPool.searchUsers.edges.length > 0
            ) {
              return {
                publicWorkerPool: {
                  ...prev.publicWorkerPool,
                  ...fetchMoreResult.publicWorkerPool,
                  searchUsers: {
                    ...fetchMoreResult.publicWorkerPool.searchUsers,
                    edges: uniqByKey(
                      [
                        ...(prev.publicWorkerPool.searchUsers?.edges || []),
                        ...fetchMoreResult.publicWorkerPool.searchUsers.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: rawSearchUsers.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates: null,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  return {
    publicWorkerPool: publicWorkerPool || previousData?.publicWorkerPool,
    entities: memoizedEntities,
    error,
    stopPolling,
    isPageRefetching: loading && networkStatus !== NetworkStatus.loading,
    isPageEmpty: !!data && !memoizedEntities.length && !searchInput,
    hasNoFilteringResults: !!data && !memoizedEntities.length && !!searchInput,
    loadMoreItems,
  };
};

export default useSearchPublicWorkerPoolUsedBy;
