import { useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Input from "ds/components/Input";
import FormArrayField from "ds/components/Form/ArrayField";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import { StackVcsFormFields } from "../../types";

const EMPTY_VALUE = { value: "" };

const ProjetGlobsField = () => {
  const {
    formState: { errors },
    getValues,
    register,
  } = useFormContext<StackVcsFormFields>();

  const handleOnKeyDown = (callback?: () => void) => (e: React.KeyboardEvent) => {
    if (callback && e.key === "Enter") {
      callback();
    }
  };

  const getUniqueGlobValidator = (index: number) => (currentValue: string) => {
    const { additionalProjectGlobs } = getValues();

    if (
      additionalProjectGlobs &&
      additionalProjectGlobs.findIndex(
        ({ value }) => value.toLowerCase() === currentValue.toLowerCase()
      ) !== index
    ) {
      return "Glob has to be unique";
    }

    return undefined;
  };

  return (
    <FormArrayField<StackVcsFormFields, "additionalProjectGlobs">
      name="additionalProjectGlobs"
      addButtonLabel="Add another glob"
      emptyValue={EMPTY_VALUE}
    >
      {({ field, index, addEmptyItem }) => (
        <FormField
          key={field.id}
          error={errors?.additionalProjectGlobs?.[index]?.value?.message}
          label={index === 0 ? "Additional project globs" : undefined}
          isOptional
          tooltipInfoVariant="modal"
          tooltipInfo={
            <>
              <TooltipModalTitle>Project globs</TooltipModalTitle>
              <TooltipModalBody align="start">
                Project globs allow you to specify files and directories outside of the project root
                that the stack cares about. In the absence of a push policy, any changes made to the
                project root and any paths specified by project globs will trigger Spacelift runs.
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl("/concepts/stack/stack-settings#project-globs")}
                />
              </TooltipModalBody>
            </>
          }
          fullWidth
          noMargin
        >
          {({ ariaInputProps }) => (
            <Input
              onKeyDown={handleOnKeyDown(addEmptyItem)}
              placeholder="Type glob path here"
              error={!!errors?.additionalProjectGlobs?.[index]?.value?.message}
              {...register(`additionalProjectGlobs.${index}.value`, {
                validate: {
                  unique: getUniqueGlobValidator(index),
                },
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>
      )}
    </FormArrayField>
  );
};

export default ProjetGlobsField;
