import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import { RunDependency } from "types/generated";
import { TreeBranch, TreeBranchLink } from "ds/components/Tree";

import RunStateBadge from "../../components/StateBadge";
import HistoryEntryWrapperSeparator from "../../components/HistoryEntryWrapper/Separator";
import HistoryEntryDate from "../../components/HistoryEntryDate";

type DependenciesEntryRunDependencyProps = {
  item: RunDependency;
};

const DependenciesEntryRunDependency = ({ item }: DependenciesEntryRunDependencyProps) => {
  return (
    <TreeBranch>
      <TreeBranchLink gap="medium" padding="small 0">
        <Typography variant="p-body3" tag="span" color="secondary">
          Run
        </Typography>
        <Link to={`/stack/${item.stackId}/run/${item.runId}`} size="small">
          {item.runId}
        </Link>
        <RunStateBadge state={item.state} />
        <HistoryEntryWrapperSeparator margin="0" />
        <HistoryEntryDate timestamp={item.runUpdatedAt} />
      </TreeBranchLink>
    </TreeBranch>
  );
};

export default DependenciesEntryRunDependency;
