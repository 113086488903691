import { ReactNode } from "react";

import Box from "ds/components/Box";
import Link from "ds/components/Link";
import StaticSpaceliftLogo from "ds/components/StaticSpaceliftLogo";
import Typography from "ds/components/Typography";
import { IconComponent } from "types/Icon";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";

type SystemMessageProps = {
  title: string;
  text: string;
  icon?: IconComponent;
  children?: ReactNode;
};

const SystemMessage = ({ title, text, children, icon: Icon }: SystemMessageProps) => {
  return (
    <Box align="center" direction="column">
      <StaticSpaceliftLogo className={styles.logo} />

      <Box align="center" direction="column" className={styles.card} surfaceColor="primary">
        {Icon ? (
          <Icon className={styles.icon} />
        ) : (
          <StaticSpaceliftLogo className={styles.defaultIcon} short />
        )}
        <Typography variant="p-t2" tag="h1" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="p-body2" tag="p" className={styles.text}>
          {text}
        </Typography>
        {children}
      </Box>
      <Box gap="large" padding="0 small" margin="x-large 0 0">
        <Link
          href={getDocsUrl("/legal/terms")}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Terms
        </Link>
        <Link
          href={getDocsUrl("/legal/privacy")}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Privacy
        </Link>
        <Link
          href={getDocsUrl("/product/security")}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Security
        </Link>
        <Link
          href="https://spacelift.io/contact"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          Contact
        </Link>
      </Box>
    </Box>
  );
};

export default SystemMessage;
