import { dropdownEllipsisWidth } from "ds/components/DropdownEllipsis/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";

export const COLUMN_GAP: Spacing = "large";
export const COLUMN_ORDER = `minmax(5rem, 4fr) minmax(3rem, 2fr) minmax(3rem, 2fr) minmax(3rem, 2fr) minmax(3rem, 2fr) ${dropdownEllipsisWidth}`;

export const FILTERS_TYPE = "policies";
export const FILTERS_ORDER_SETTINGS_KEY = "policiesFiltersOrder";

export enum FilterItemKeys {
  NAME = "name",
  TYPE = "type",
  SPACE = "space",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.NAME]: "name",
  [FilterItemKeys.TYPE]: "type",
  [FilterItemKeys.SPACE]: "space",
  [FilterItemKeys.CREATED_AT]: "Created at",
  [FilterItemKeys.UPDATED_AT]: "Last update",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Asc;

export const ENTITIES_PER_PAGE = 50;

// TODO: remove when backend apply login policy removal on their side
export const EXCLUDE_LOGIN_PREDICATE = {
  field: "type",
  exclude: true,
  constraint: {
    booleanEquals: null,
    enumEquals: ["LOGIN"],
    stringMatches: null,
    hierarchyNodeValueEquals: null,
    timeInRange: null,
    timeInLast: null,
  },
};

export const REFETCH_QUERIES_ON_DELETE = [
  "GetPolicyNames",
  "SearchPolicies",
  "SearchPoliciesSuggestions",
];

export const POLICIES_LIST_ITEM_TEST_ID = "policies-list-item";
export const POLICIES_DISABLED_INFO_KEY = "policies-trial-info";
