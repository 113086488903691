import { IconComponent } from "types/Icon";
import { MenuCloudIntegrations, MenuIntegrations, MenuStacks, Profile } from "components/icons";

import { Feature } from "../types";

type GetStartedFeatureMetadata = Record<
  Feature,
  {
    title: string;
    url: string;
    icon: IconComponent;
    analyticsTitle: string;
  }
>;

export const GET_STARTED_FEATURES: GetStartedFeatureMetadata = {
  [Feature.IntegrateVCS]: {
    title: "Integrate Source code",
    icon: MenuIntegrations,
    url: "/vcs/integrations",
    analyticsTitle: "Integrate VCS Click",
  },
  [Feature.IntegrateCloud]: {
    title: "Integrate your cloud account",
    icon: MenuCloudIntegrations,
    url: "/cloud-integrations/aws",
    analyticsTitle: "Integrate Cloud Click",
  },
  [Feature.CreateStack]: {
    title: "Create first stack",
    icon: MenuStacks,
    url: "/new/stack",
    analyticsTitle: "Create Stack Click",
  },
  [Feature.InviteTeammates]: {
    title: "Invite teammates",
    icon: Profile,
    url: "/settings/users",
    analyticsTitle: "Invite User Click",
  },
};

export const GET_STARTED_FEATURES_COUNT = Object.keys(GET_STARTED_FEATURES).length;
