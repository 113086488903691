import { useMemo } from "react";
import { fromUnixTime } from "date-fns";

import useTypedContext from "hooks/useTypedContext";
import { BillingTierFeature, Stack } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import {
  LockNew,
  Disabled,
  LeftRight,
  DeleteScheduled,
  Administrative,
  Unlink,
} from "components/icons";
import Box from "ds/components/Box";
import Timestamp from "components/time/Timestamp";
import Tooltip from "ds/components/Tooltip";
import { truncate } from "utils/strings";
import Icon from "ds/components/Icon";
import LinkNew from "ds/components/LinkNew";
import { SubscriptionContext } from "views/account/SubscriptionWrapper";

import useStackListAnalytics from "../useStackListAnalytics";

type StackListItemNameCellProps = { stack: Stack };

const StackListItemNameCell = ({ stack }: StackListItemNameCellProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  const trackAnalytics = useStackListAnalytics();

  const stackUrl = `/stack/${stack.id}`;

  const nextDeleteSchedule = useMemo(
    () =>
      stack.scheduledDeletes.reduce(
        (acc, next) => {
          if (!acc && next.nextSchedule) {
            return next.nextSchedule;
          }

          if (next.nextSchedule && acc) {
            return Math.min(acc, next.nextSchedule);
          }

          return acc;
        },
        undefined as number | undefined
      ),
    [stack.scheduledDeletes]
  );

  const hasActiveStatus =
    stack.vcsDetached ||
    stack.isDisabled ||
    !!stack.lockedBy ||
    nextDeleteSchedule ||
    stack.integrations?.driftDetection?.nextSchedule;

  return (
    <Box align="start" direction="column" gap="large" justify="center">
      <Box fullWidth>
        <LinkNew
          size="small"
          variant="secondary"
          href={stackUrl}
          onPress={() => trackAnalytics("Name link clicked")}
        >
          {stack.name}
        </LinkNew>
      </Box>

      {hasActiveStatus && (
        <Box gap="medium">
          {stack.vcsDetached && (
            <Tooltip
              widthMode="maxWidthXSm"
              textAlign="center"
              on={(props) => <Icon {...props} src={Unlink} />}
            >
              Source code integration has been detached
            </Tooltip>
          )}

          {stack.administrative && (
            <Tooltip on={(props) => <Icon {...props} src={Administrative} />}>
              Administrative
            </Tooltip>
          )}

          {tierFeatures.includes(BillingTierFeature.DriftDetection) &&
            stack.integrations?.driftDetection?.nextSchedule && (
              <Tooltip on={(props) => <Icon {...props} src={LeftRight} />}>
                Drift detection on. Next schedule:{" "}
                {fromUnixTime(stack.integrations?.driftDetection?.nextSchedule).toLocaleDateString(
                  undefined,
                  {
                    hour: "numeric",
                    minute: "numeric",
                  }
                )}
              </Tooltip>
            )}
          {nextDeleteSchedule && (
            <Tooltip on={(props) => <Icon {...props} src={DeleteScheduled} />}>
              Stack scheduled for deletion on:{" "}
              {fromUnixTime(nextDeleteSchedule).toLocaleDateString(undefined, {
                hour: "numeric",
                minute: "numeric",
              })}
            </Tooltip>
          )}
          {stack.isDisabled && (
            <Tooltip on={(props) => <Icon {...props} src={Disabled} />}>Stack is disabled</Tooltip>
          )}
          {!!stack.lockedBy && (
            <Tooltip
              on={(props) => (
                <Icon {...props} src={LockNew} aria-label={`Stack ${stack.name} is locked`} />
              )}
              active={!!stack.lockedBy}
            >
              {stack.lockedBy === viewer?.id ? "You" : stack.lockedBy} locked this stack
              {stack.lockedAt && (
                <>
                  {" "}
                  <Timestamp timestamp={stack.lockedAt} />
                </>
              )}
              {stack.lockNote && <>: {truncate(stack.lockNote, 150)}</>}
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StackListItemNameCell;
