import { CrossNew } from "components/icons";

import IconAction from "../IconAction";

type DrawerCloseIconProps = {
  handleCloseDrawer: () => void;
};

const DrawerCloseIcon = ({ handleCloseDrawer }: DrawerCloseIconProps) => (
  <IconAction icon={CrossNew} onClick={handleCloseDrawer} tooltip="Close" />
);

export default DrawerCloseIcon;
