import { useMemo, useRef } from "react";
import { useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { PublicWorkerPool, RunWithPosition } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import {
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  POLL_INTERVAL,
} from "views/account/WorkerPool/QueuedRunsView/constants";

import { SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS } from "./gql";

const useSearchPublicQueuedRuns = () => {
  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<RunWithPosition[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { error, loading, data, stopPolling, refetch, previousData } = useQuery<{
    publicWorkerPool: PublicWorkerPool;
  }>(SEARCH_PUBLIC_WORKER_POOL_SCHEDULABLE_RUNS, {
    variables: {
      input: {
        predicates,
        fullTextSearch: searchInput,
        // "first" & "after" arguments are ignored by the server, no pagination is needed here
        first: null,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const rawSearchRuns = useMemo(() => {
    const dataWorkerPool = data?.publicWorkerPool || previousData?.publicWorkerPool;
    if (dataWorkerPool) {
      const { searchSchedulableRuns } = dataWorkerPool;

      return searchSchedulableRuns;
    }

    return undefined;
  }, [data?.publicWorkerPool, previousData?.publicWorkerPool]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchRuns?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchRuns?.edges, loading]);

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) {
      onError(error);
    }
  };

  return {
    entities: memoizedEntities,
    error,
    stopPolling,
    loading,
    isPageEmpty: !!(data && !memoizedEntities.length && !searchInput && predicates.length === 0),
    hasNoFilteringResults:
      !!data && !memoizedEntities.length && (!!searchInput || predicates.length > 0),
    refetch: handleRefetch,
  };
};

export default useSearchPublicQueuedRuns;
