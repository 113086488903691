import {
  GithubEnterpriseIntegration,
  MutationGithubEnterpriseIntegrationCreateArgs,
  MutationGithubEnterpriseIntegrationUpdateArgs,
  VcsCheck,
} from "types/generated";
import { generateWebhookSecret } from "utils/webhook";
import { VCS_INTEGRATION_TYPES } from "views/account/VCS/constants";
import { ROOT_SPACE_ID } from "constants/space";

import { ManageGitHubEnterpriseManualFormFields } from "./types";

export const getFormDefaultValues = (
  integration?: GithubEnterpriseIntegration
): ManageGitHubEnterpriseManualFormFields => ({
  name: integration?.name || "",
  space: integration?.space?.id || "",
  labels: integration?.labels?.map((label) => ({ value: label })) || [],
  appID: integration?.appID || "",
  apiHost: integration?.apiHost || "",
  userFacingHost: integration?.userFacingHost || "",
  webhookSecret: integration?.webhookSecret || generateWebhookSecret(),
  privateKey: "",
  description: integration?.description || "",
  integrationType: integration?.isDefault
    ? VCS_INTEGRATION_TYPES.DEFAULT
    : VCS_INTEGRATION_TYPES.MULTIPLE,
  vcsChecks: integration?.vcsChecks || VcsCheck.Individual,
});

export const mapCreateGitHubEnterpriseIntegrationInputValues = (
  values: ManageGitHubEnterpriseManualFormFields
): MutationGithubEnterpriseIntegrationCreateArgs => {
  const isDefault = values.integrationType === VCS_INTEGRATION_TYPES.DEFAULT;

  return {
    apiHost: values.apiHost.trim(),
    appID: values.appID.trim(),
    customInput: {
      isDefault,
      name: values.name.trim(),
      labels: values.labels.map((label) => label.value) || [],
      // FYI: set the root to be able to convert to the multiple integration from the default one
      spaceID: isDefault ? ROOT_SPACE_ID : values.space,
      description: values.description?.trim() || null,
      vcsChecks: values.vcsChecks,
    },
    privateKey: values.privateKey.trim(),
    userFacingHost: values.userFacingHost.trim(),
    webhookSecret: values.webhookSecret.trim(),
  };
};

export const mapUpdateGitHubEnterpriseIntegrationInputValues = (
  values: ManageGitHubEnterpriseManualFormFields,
  integrationId: string
): MutationGithubEnterpriseIntegrationUpdateArgs => ({
  apiHost: values.apiHost.trim(),
  customInput: {
    id: integrationId,
    labels: values.labels.map((label) => label.value) || [],
    description: values.description?.trim() || null,
    space: values.space,
    vcsChecks: values.vcsChecks,
  },
  privateKey: values.privateKey?.trim() || null,
  userFacingHost: values.userFacingHost.trim(),
  webhookSecret: values.webhookSecret.trim(),
});
