import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { BitbucketCloudIntegration, Maybe } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { DELETE_BITBUCKET_CLOUD_INTEGRATION } from "./gql";

const useDeleteBitbucketCloudIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const [bitbucketCloudIntegrationDelete] = useMutation<{
    bitbucketCloudIntegrationDelete: Maybe<BitbucketCloudIntegration>;
  }>(DELETE_BITBUCKET_CLOUD_INTEGRATION, {
    refetchQueries: ["SearchVCSIntegrations"],
    onError,
  });

  const deleteBitbucketCloudIntegration = useCallback(
    (id: string, attachedEntitiesQty: number, successCallback?: () => void) => {
      bitbucketCloudIntegrationDelete({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data) {
            reportSuccess({
              message: `${
                data?.bitbucketCloudIntegrationDelete?.name || "Bitbucket Cloud"
              } integration successfully deleted`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration - Unlink Confirm", {
              attachedEntitiesQty,
              integration: "Bitbucket Cloud",
            });
          }
        })
        .catch(onError);
    },
    [bitbucketCloudIntegrationDelete, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return { deleteBitbucketCloudIntegration };
};

export default useDeleteBitbucketCloudIntegration;
