import { useCallback, useRef, useState } from "react";
import cx from "classnames";

import Portal from "components/Portal";
import { withEnterKeyPress } from "utils/browser";
import useOutsideClick from "hooks/useOutsideClick";
import useEscapeKeypress from "hooks/useEscapeKeyPress";

import { ModalProps } from "./types";
import styles from "./styles.module.css";

/**
 * @deprecated Use `ModalNew` from `ds/components/ModalNew` instead.
 */
const Modal = ({
  children,
  triggerComponent,
  renderTriggerComponent,
  triggerClassName,
  forceIsVisible,
  setForceModalVisibility,
  preventClosing,
  size = "regular",
  withAnimation = true,
  classNameInner,
  closeCallback,
  testMode,
}: ModalProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(
    (closeMethod?: string) => {
      setIsVisible(false);
      setForceModalVisibility?.(false);
      closeCallback?.(closeMethod);
    },
    [setIsVisible, setForceModalVisibility, closeCallback]
  );

  const openModal = () => {
    setIsVisible(true);
    setForceModalVisibility?.(true);
  };

  const handleOutsideClick = useCallback(() => {
    closeModal("outside");
  }, [closeModal]);

  const handleEscape = useCallback(() => {
    closeModal("escape");
  }, [closeModal]);

  const isModalVisible = forceIsVisible !== undefined ? forceIsVisible : isVisible;
  const WrapperComponent = testMode ? "div" : Portal;

  useOutsideClick(modalRef, handleOutsideClick, "mousedown", !preventClosing);

  useEscapeKeypress(isModalVisible && !preventClosing, handleEscape);

  return (
    <>
      {triggerComponent && !renderTriggerComponent && (
        <div
          className={triggerClassName}
          onClick={openModal}
          onKeyDown={withEnterKeyPress(openModal)}
          role="button"
          tabIndex={0}
        >
          {triggerComponent}
        </div>
      )}
      {!triggerComponent && renderTriggerComponent && renderTriggerComponent({ openModal })}
      {isModalVisible && (
        <WrapperComponent>
          <div
            className={cx(styles.modal, {
              [styles.modalAnimated]: withAnimation && !testMode,
              [styles.testMode]: testMode,
            })}
          >
            <div className={cx(styles.modalInner, styles[size], classNameInner)} ref={modalRef}>
              {typeof children === "function" ? children({ closeModal }) : children}
            </div>
          </div>
        </WrapperComponent>
      )}
    </>
  );
};

export default Modal;
