import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

import useDeleteOutputReference from "./useDeleteOutputReference";
import { showOutputReferencesDeleteConfirmationModal } from "./DeleteConfirmationModal";

type OutputReferencesDrawerDeleteActionProps = {
  name: string;
  referenceId: string;
};

const OutputReferencesDrawerDeleteAction = ({
  name,
  referenceId,
}: OutputReferencesDrawerDeleteActionProps) => {
  const { deleteOutputReference } = useDeleteOutputReference();

  const handleDelete = () => {
    deleteOutputReference(referenceId);
  };

  const onModalShow = () => {
    showOutputReferencesDeleteConfirmationModal({ name, onConfirm: handleDelete });
  };

  return (
    <DropdownSectionItem onClick={onModalShow} danger>
      Delete reference
    </DropdownSectionItem>
  );
};

export default OutputReferencesDrawerDeleteAction;
