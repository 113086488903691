import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { ChartColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/account/SpacesProvider";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import { Widget } from "../../types";

type DashboardWidgetsStackFailuresEmptyProps = {
  notEnoughData?: boolean;
};

const DashboardWidgetsStackFailuresEmpty = ({
  notEnoughData,
}: DashboardWidgetsStackFailuresEmptyProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <TileWrapper>
      <Box padding="small" fullWidth justify="center">
        <EmptyState
          title="There's no data to show yet"
          icon={ChartColored}
          caption={
            notEnoughData
              ? "Create more stacks so we can display statistics over stack failures here."
              : "Start creating stacks so we can display statistics over stack failures here."
          }
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/concepts/stack")}
              label="Documentation"
              analyticsPage={AnalyticsPageDashboard.Dashboard}
              analyticsTitle="Widget - Documentation Clicked"
              analyticsProps={{ widget: Widget.StacksFailures }}
            />
            {hasEntityCreateAccess && (
              <Button variant="primary" to="/new/stack">
                Create stack
              </Button>
            )}
          </Box>
        </EmptyState>
      </Box>
    </TileWrapper>
  );
};

export default DashboardWidgetsStackFailuresEmpty;
