import { useCallback } from "react";

import ModalConfirmation, { ModalConfirmationProps } from ".";
import useNavigationBlocker from "./useNavigationBlocker";

type ModalConfirmationBlockNavigationProps = {
  isNavigationBlocked: boolean;
} & Omit<Partial<ModalConfirmationProps>, "forceIsVisible">;

/**
 * @deprecated Use `ds/components/ModalNew` or `src/ds/components/ConfirmationModal` instead
 */
const ModalConfirmationBlockNavigation = ({
  isNavigationBlocked,
  children,
  confirmCallback,
  closeCallback,
  rejectCallback,
  title = "Are you sure you want to leave?",
  confirmVariant = "dangerPrimary",
  size = "regular",
  confirmText = "Leave",
  ...modalConfirmationProps
}: ModalConfirmationBlockNavigationProps) => {
  const { showConfirmation, onNavigationConfirm, onNavigationCancel } = useNavigationBlocker({
    shouldBlock: isNavigationBlocked,
  });

  const handleConfirm = useCallback(() => {
    confirmCallback?.();

    onNavigationConfirm?.();
  }, [onNavigationConfirm, confirmCallback]);

  const handleClose = useCallback(() => {
    closeCallback?.();
    onNavigationCancel?.();
  }, [closeCallback, onNavigationCancel]);

  const handleReject = useCallback(() => {
    rejectCallback?.();
    onNavigationCancel?.();
  }, [rejectCallback, onNavigationCancel]);

  return (
    <ModalConfirmation
      {...modalConfirmationProps}
      title={title}
      confirmCallback={handleConfirm}
      confirmVariant={confirmVariant}
      closeCallback={handleClose}
      rejectCallback={handleReject}
      size={size}
      confirmText={confirmText}
      forceIsVisible={showConfirmation}
    >
      {children}
    </ModalConfirmation>
  );
};

export default ModalConfirmationBlockNavigation;
