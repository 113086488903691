import { useModal, create, show } from "@ebay/nice-modal-react";

import DeleteConfirmationModal from "ds/components/DeleteConfirmationModal";

import useDeletePolicy from "./useDeletePolicy";

type DeleteConfirmationProps = {
  id: string;
  name: string;
};

const DeleteConfirmation = create(function DeleteConfirmation({
  id,
  name,
}: DeleteConfirmationProps) {
  const modal = useModal();

  const { onDelete, loading: deleteLoading } = useDeletePolicy();

  const onConfirm = () => {
    onDelete(id).then(() => {
      modal.resolve(true);
      modal.hide();
    });
  };

  const onClose = () => {
    modal.resolve(false);
    modal.hide();
  };

  return (
    <DeleteConfirmationModal
      title="Delete policy"
      name={name}
      onConfirm={onConfirm}
      onClose={onClose}
      isLoading={deleteLoading}
      isDismissable={!deleteLoading}
    />
  );
});

export const showDeleteConfirmation = (props: DeleteConfirmationProps) =>
  show(DeleteConfirmation, props);
