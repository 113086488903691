import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Base64 } from "js-base64";
import { FileTrigger } from "react-aria-components";

import CodeEditor from "components/CodeEditor";
import Button from "components/button/Button";
import InputText from "components/input/InputText";
import { File, Upload, Trash } from "components/icons";
import Col from "components/column/Col";
import Row from "components/row/Row";
import IconButton from "components/button/IconButton";
import { ConfigInput, ConfigType } from "types/generated";
import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import { fileToBase64 } from "utils/file";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import styles from "../styles.module.css";

type NewFileMountProps = {
  loading: boolean;
  onSave: (value: ConfigInput, reset: () => void) => void;
};

const NewFileMount = ({ loading, onSave }: NewFileMountProps) => {
  const pathRef = useRef<HTMLInputElement>(null);
  const { onError } = useTypedContext(FlashContext);

  const [disabled, setDisabled] = useState(true);
  const [fileName, setFileName] = useState("");
  const [value, setValue] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [fileBody, setFileBody] = useState("");

  const handleEditorChange = (value?: string) => {
    setFileBody(value || "");
  };

  useEffect(() => {
    if (disabled) {
      if (fileName && (value || fileBody)) setDisabled(false);
    } else {
      if (!fileName || (!value && !fileBody)) setDisabled(true);
    }
  }, [disabled, fileName, value, fileBody]);

  const handleFiles = async (files: FileList | null) => {
    if (!files) return;

    const file = files[0];

    const fileName = file.name;

    try {
      const base64 = await fileToBase64(file);

      if (base64) {
        // If the user didn't (yet?) explicitly set the path of the file, we can try
        // to be helpful and propose one based on the name of the uploaded file.
        // At least some of the time we'll be correct, and if not then the user can
        // trivially change it afterwards.
        setFileName(fileName);
        setValue(base64.toString().split(",")[1]);
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleSetFileName = (e: ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const onDiscard = () => {
    setFileName("");
    setValue("");
    setIsCreating(false);
    setFileBody("");
  };

  const reset = () => {
    onDiscard();
    pathRef.current?.focus();
  };

  const save = (writeOnly: boolean) => (evt: MouseEvent) => {
    evt.preventDefault();

    const fileValue = value || Base64.encode(fileBody);

    onSave(
      {
        id: fileName,
        value: fileValue,
        writeOnly,
        type: ConfigType.FileMount,
        description: "",
        fileMode: null,
      },
      reset
    );
  };

  const toggleIsCreating = () => setIsCreating((value) => !value);

  const wantToAddFile = !value && !fileBody && !isCreating && (
    <>
      <IconButton
        icon={File}
        text="Create file"
        onPress={toggleIsCreating}
        className={styles.mr5}
      />
      <FileTrigger onSelect={handleFiles}>
        <IconButton icon={Upload} text="Upload file" />
      </FileTrigger>
    </>
  );

  const isFileCreating = isCreating && (
    <Box direction="row" align="center" justify="end" fullWidth>
      <IconAction icon={Trash} onClick={onDiscard} color="danger" />
    </Box>
  );

  const isFileUploaded = value && !isCreating && !fileBody && (
    <Box direction="row" align="center" justify="end" fullWidth>
      <IconButton className={styles.mr10} icon={File} text={fileName} info />
      <IconAction icon={Trash} onClick={onDiscard} color="danger" />
    </Box>
  );

  return (
    <>
      <Col className={styles.newConfigWrapper}>
        <Row align className={styles.newConfigFile}>
          <Col className={styles.newConfigFileWrapper} auto>
            <Row align>
              <span className={styles.newConfigPrefix}>/mnt/workspace/</span>
              <InputText
                ref={pathRef}
                className={styles.newConfigInput}
                placeholder="your_path"
                value={fileName}
                onChange={handleSetFileName}
              />
            </Row>
          </Col>
          <Col className={cx(styles.mr10, styles.newConfigFileButtons)} auto>
            <Row className={styles.newConfigFileRow}>
              {wantToAddFile}
              {isFileCreating}
              {isFileUploaded}
            </Row>
          </Col>
        </Row>
      </Col>
      <div>
        {loading ? (
          <Button disabled loading sm2 pill>
            Saving
          </Button>
        ) : (
          <>
            <Button onClick={save(false)} disabled={disabled || !fileName} sm left pill>
              Plain
            </Button>
            <Button onClick={save(true)} disabled={disabled || !fileName} sm right pill>
              Secret
            </Button>
          </>
        )}
      </div>

      {isCreating && (
        <CodeEditor
          className={styles.variablePreviewEditor}
          body={fileBody}
          onChange={handleEditorChange}
          language="rego"
        />
      )}
    </>
  );
};

export default NewFileMount;
