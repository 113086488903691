import React, { useEffect, useMemo, useRef } from "react";
import { ApolloQueryResult, FetchMoreOptions, useQuery } from "@apollo/client";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";

import useTypedContext from "hooks/useTypedContext";
import { EntityChangePhase, EntityChangeType, Stack } from "types/generated";
import { uniqByKey } from "utils/uniq";
import FlashContext from "components/FlashMessages/FlashContext";
import PageLoading from "components/loading/PageLoading";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { RUN_CHANGES_POLL_INTERVAL } from "../constants";
import { RunContext } from "../Context";
import { RunChangesItem } from "./types";
import { isRunApplied, makeChangesList } from "./helpers";
import VirtualizedLineElement from "./VirtualizedLineElement";
import { GET_CHANGES } from "./gql";
import styles from "./styles.module.css";

type RunChangesProps = {
  type: EntityChangeType;
};

type TData = { stack: Stack };

const CHANGES_LIMIT = 50;

type RunChangesWrapperProps = {
  children: React.ReactNode;
};

const RunChangesWrapper = ({ children }: RunChangesWrapperProps) => {
  return <div className={styles.runChangesWrapper}>{children}</div>;
};

const RunChanges = ({ type: changeType }: RunChangesProps) => {
  const {
    stack: { id: stackId },
    run: { id: runId, history },
  } = useTypedContext(RunContext);
  const { onError } = useTypedContext(FlashContext);

  const {
    data: dataPlanned,
    fetchMore: fetchMorePlanned,
    loading: loadingPlanned,
  } = useQuery<TData>(GET_CHANGES, {
    variables: {
      stackId: stackId,
      runId,
      changeType,
      phaseType: EntityChangePhase.Plan,
      limit: CHANGES_LIMIT,
    },
    onError,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const {
    data: dataActual,
    fetchMore: fetchMoreActual,
    loading: loadingActual,
    stopPolling,
  } = useQuery<TData>(GET_CHANGES, {
    variables: {
      stackId: stackId,
      runId,
      changeType,
      phaseType: EntityChangePhase.Apply,
      limit: CHANGES_LIMIT,
    },
    onError,
    pollInterval: RUN_CHANGES_POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [planned, actual] = useMemo(
    () => [
      dataPlanned?.stack.run?.changesV2?.edges.map((edge) => edge.node) || [],
      dataActual?.stack.run?.changesV2?.edges.map((edge) => edge.node) || [],
    ],
    [dataPlanned, dataActual]
  );

  const isApplied = useMemo(() => isRunApplied(history), [history]);

  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<Record<string, number>>({});

  const changesList = useMemo<[string, RunChangesItem][]>(
    () => makeChangesList(planned, actual, isApplied),
    [planned, actual, isApplied]
  );

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] + 6 || 28;
  };

  const updateQuery: FetchMoreOptions<TData>["updateQuery"] = (previous, { fetchMoreResult }) => {
    if (fetchMoreResult?.stack?.run?.changesV2) {
      return {
        stack: {
          ...fetchMoreResult.stack,
          run: {
            ...fetchMoreResult.stack.run,
            changesV2: {
              ...fetchMoreResult.stack.run.changesV2,
              ...(fetchMoreResult.stack.run.changesV2?.edges && {
                edges: uniqByKey(
                  [
                    ...fetchMoreResult.stack.run.changesV2.edges,
                    ...(previous.stack.run?.changesV2?.edges || []),
                  ],
                  "cursor"
                ),
              }),
            },
          },
        },
      };
    }

    return { stack: { ...previous.stack } };
  };

  const loadMoreItems = async () => {
    const promises: Promise<ApolloQueryResult<TData | undefined>>[] = [];

    if (dataPlanned?.stack.run?.changesV2?.pageInfo?.endCursor) {
      promises.push(
        fetchMorePlanned({
          updateQuery,
          variables: {
            offset: dataPlanned.stack.run.changesV2.pageInfo.endCursor,
          },
        })
      );
    }

    if (dataActual?.stack.run?.changesV2?.pageInfo?.endCursor) {
      promises.push(
        fetchMoreActual({
          updateQuery,
          variables: {
            offset: dataActual.stack.run.changesV2.pageInfo.endCursor,
          },
        })
      );
    }

    try {
      await Promise.all(promises);
    } catch (error) {
      onError(error);
    }
  };

  const isItemLoaded = (value: number) => {
    return value < changesList.length;
  };

  useAnalytics<EntityChangeType>({
    page: AnalyticsPageStack.StacksRunChanges,
    pageArguments: changeType,
  });

  useEffect(() => {
    if (isApplied) {
      stopPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplied]);

  if ((loadingPlanned && !dataPlanned?.stack.run) || (loadingActual && !dataActual?.stack.run)) {
    return <PageLoading />;
  }

  return (
    <RunChangesWrapper>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={changesList.length + CHANGES_LIMIT}
        loadMoreItems={loadMoreItems}
      >
        {({ onItemsRendered }) => (
          <AutoSizer disableWidth>
            {({ height }) => (
              <VariableSizeList
                itemKey={(index) => changesList[index][0]}
                itemData={{
                  changesList,
                  setRowHeight,
                }}
                itemCount={changesList.length}
                itemSize={getRowHeight}
                ref={listRef}
                width="100%"
                height={height}
                onItemsRendered={onItemsRendered}
              >
                {VirtualizedLineElement}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </RunChangesWrapper>
  );
};

export default RunChanges;
