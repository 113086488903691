import { ReactNode, memo } from "react";
import cx from "classnames";
import { Cell, Row } from "react-aria-components";

import styles from "./styles.module.css";
import TableRowCheckbox from "./RowCheckbox";
import useSelectionStore from "./useSelectionStore";

type TableRowProps = {
  children: ReactNode;
  id: string;
  name: string;
};

const TableRow = ({ children, id, name }: TableRowProps) => {
  const [isActive, isSelectionOn] = useSelectionStore(({ activeId, selected }) => [
    activeId === id,
    selected.size > 0,
  ]);

  return (
    <Row key={id} id={id} className={cx(styles.row, { [styles.active]: isActive })}>
      <Cell
        className={cx(
          styles.tableCell,
          styles.checkboxCell,
          isSelectionOn && styles.checkboxCellSticky
        )}
      >
        <TableRowCheckbox name={name} id={id} />
      </Cell>
      {children}
    </Row>
  );
};

export default memo(TableRow);
