import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { GithubIntegration, MutationGithubIntegrationUpdateArgs } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { UPDATE_GITHUB_INTEGRATION } from "./gql";

const useUpdateGitHubIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const [githubIntegrationUpdate, { loading }] = useMutation<{
    githubIntegrationUpdate: GithubIntegration;
  }>(UPDATE_GITHUB_INTEGRATION, {
    onError,
  });

  const updateGitHubIntegration = useCallback(
    (input: MutationGithubIntegrationUpdateArgs, successCallback?: () => void) => {
      githubIntegrationUpdate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.githubIntegrationUpdate) {
            reportSuccess({
              message: `Built-in GitHub integration was successfully updated`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration Edit - Finish", {
              integration: "GitHub",
            });
          }
        })
        .catch(onError);
    },
    [githubIntegrationUpdate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    updateGitHubIntegration: updateGitHubIntegration,
    loading,
  };
};

export default useUpdateGitHubIntegration;
