import { useMutation } from "@apollo/client";

import { Stack } from "types/generated";
import { Star, StarEmpty } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import IconActionButton from "ds/components/IconAction/Button";

import { STAR_SET } from "../gql";
import styles from "./styles.module.css";
import { ANALYTICS } from "../constants";

type StackListItemFavoriteCellProps = { stack: Stack };

const StackListItemFavoriteCell = ({ stack }: StackListItemFavoriteCellProps) => {
  const { onError } = useTypedContext(FlashContext);

  const [starSet] = useMutation<{ starSet: Stack }>(STAR_SET, {
    refetchQueries: ["SearchStacksNew", "SearchStacksSuggestionsNew"],
  });

  const handleStarStack = (clb?: () => void) => () => {
    starSet({ variables: { id: stack.id, star: !stack.starred } }).catch(onError);
    clb?.();
  };

  return (
    <IconActionButton
      {...ANALYTICS}
      analyticsTitle={"Star clicked"}
      analyticsProps={{
        ...ANALYTICS.analyticsProps,
        value: stack.starred ? "remove" : "add",
      }}
      className={styles.favoriteButtonIcon}
      icon={stack.starred ? Star : StarEmpty}
      onPress={handleStarStack()}
      tooltip={stack.starred ? "Remove from favorites" : "Add to favorites"}
    />
  );
};

export default StackListItemFavoriteCell;
