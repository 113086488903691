import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import useAnalytics from "hooks/useAnalytics";
import Link from "ds/components/Link";
import IconAction from "ds/components/IconAction";
import Box from "ds/components/Box";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import { Info } from "components/icons";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";

import { MANAGE_API_KEYS_INFO_KEY } from "../../constants";

type PersonalSettingsApiKeysPageLayoutProps = {
  children: ReactNode;
  onOpenCreateApiKeyDrawer?: () => void;
  onOpenInfoDrawer?: () => void;
};

const PersonalSettingsApiKeysPageLayout = ({
  children,
  onOpenCreateApiKeyDrawer,
  onOpenInfoDrawer,
}: PersonalSettingsApiKeysPageLayoutProps) => {
  const { accountName, viewer } = useTypedContext(AccountContext);

  useTitle(`Personal Settings · API keys · ${accountName}`);

  const trackAnalyticsSegmentEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalApiKeys,
    callbackTrackProviders: { segment: true },
  });

  return (
    <>
      {viewer.admin && (
        <Callout
          variant="info"
          title="Manage API keys in your organization"
          storageKey={MANAGE_API_KEYS_INFO_KEY}
          closeCallback={() => trackAnalyticsSegmentEvent("Organization Settings Callout Close")}
        >
          To manage all API keys created in your organization go to your Organization settings.
          <FeedbackActions>
            <Link
              analyticsPage={AnalyticsPagePersonal.PersonalApiKeys}
              analyticsTitle="Organization Settings Callout Click"
              to="/settings/api-keys"
              size="small"
            >
              Organization settings
            </Link>
          </FeedbackActions>
        </Callout>
      )}
      <ViewHeader firstLevel>
        <Box fullWidth justify="between">
          <Box direction="row" align="center" gap="medium">
            <ViewHeaderTitle tag="h2">API Keys</ViewHeaderTitle>
            {onOpenInfoDrawer && (
              <IconAction onClick={onOpenInfoDrawer} icon={Info} tooltip="Open info drawer" />
            )}
          </Box>
          {onOpenCreateApiKeyDrawer && (
            <ViewHeaderWrapper direction="row">
              <Button onClick={onOpenCreateApiKeyDrawer} variant="primary">
                Create API key
              </Button>
            </ViewHeaderWrapper>
          )}
        </Box>
      </ViewHeader>
      {children}
    </>
  );
};

export default PersonalSettingsApiKeysPageLayout;
