import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom-v5-compat";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import Typography from "ds/components/Typography";
import Input from "ds/components/Input";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import FormField from "ds/components/Form/Field";
import Feedback from "ds/components/Feedback";
import FeedbackActions from "ds/components/Feedback/Actions";
import Link from "ds/components/Link";
import useAnalytics from "hooks/useAnalytics";

import { DELETE_MODULE } from "../gql";
import ModuleSettingsPageLayout from "../components/PageLayout";
import { ModuleSettingsContext } from "../Context";
import ModuleSettingsFormWrapper from "../components/FormWrapper";

type FormValues = {
  name: string;
};

const ModuleSettingsDeletion = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const navigate = useNavigate();
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const trackSegmentAnalyticsEvent = useAnalytics({
    callbackTrackProviders: { segment: true },
  });

  const [deleteModule, { loading }] = useMutation(DELETE_MODULE, {
    variables: { moduleId: module.id },
    onError,
  });

  const form = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
    mode: "onBlur",
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = form;

  const onSubmit = async () => {
    return deleteModule().then(({ data }) => {
      if (data?.moduleDelete?.id) {
        trackSegmentAnalyticsEvent("Module successfully deleted");
        reportSuccess({ message: "Module was successfully deleted" });
        navigate("/modules");
      }
    });
  };

  return (
    <ModuleSettingsPageLayout
      title="Module deletion"
      description="You can delete this module, but be aware that this action is irreversible."
    >
      {module.protectFromDeletion && (
        <Feedback type="callout" variant="danger">
          Module is protected from deletion. Go to behavior tab and switch “Protect from deletion”
          toggle off to be able to remove the module.
          <FeedbackActions>
            <Link size="small" to="../behavior" v5Compat>
              Go to Behavior tab
            </Link>
          </FeedbackActions>
        </Feedback>
      )}
      <ModuleSettingsFormWrapper>
        <FormProvider {...form}>
          <Typography
            tag="p"
            variant="p-body3"
            margin="0 0 medium"
            color={module.protectFromDeletion ? "tertiary" : "primary"}
          >
            Please type <strong>{module.name}</strong> to confirm.
          </Typography>
          <Controller
            name="name"
            rules={{
              required: true,
              validate: (value) =>
                value === module.name ? undefined : "Module name does not match",
            }}
            render={({ field, fieldState }) => (
              <FormField error={fieldState.error?.message} noMargin>
                {({ ariaInputProps }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Enter module name here..."
                    disabled={module.protectFromDeletion}
                    error={!!fieldState.error?.message}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            )}
          />
        </FormProvider>
        <Box justify="end" margin="large 0 0">
          <Button
            variant="dangerPrimary"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || !isDirty || loading}
            loading={loading}
          >
            Delete
          </Button>
        </Box>
      </ModuleSettingsFormWrapper>
    </ModuleSettingsPageLayout>
  );
};

export default ModuleSettingsDeletion;
