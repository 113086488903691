import { UNSAFE_NavigationContext, useNavigate } from "react-router-dom-v5-compat";
import { Location, History, UnregisterCallback } from "history";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

type UseNavigationBlockerProps = {
  shouldBlock: boolean;
};

/**
 * This hook will need to be refactored once we update to react-router v6 with the data routers (https://reactrouter.com/en/6.26.1/routers/picking-a-router) use the `useBlocker` hook instead + useBeforeUnload.
 * For any react-router-dom updates verify if the NavigationContext still returns the history object.
 * The casting here is necessary as react-router-dom team cut out the types on purpose (https://github.com/remix-run/react-router/issues/8139#issuecomment-954431589)
 *
 * @deprecated Use `ds/components/ModalNew`
 */
const useNavigationBlocker = ({ shouldBlock }: UseNavigationBlockerProps) => {
  const { block } = useContext(UNSAFE_NavigationContext).navigator as unknown as History;
  const navigate = useNavigate();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const locationRef = useRef<Location<unknown>>();
  const unregisterCallbackRef = useRef<UnregisterCallback>();

  useEffect(() => {
    unregisterCallbackRef.current = block((location) => {
      if (shouldBlock) {
        locationRef.current = location;
        setShowConfirmation(true);
        return false;
      }

      return undefined;
    });

    return () => unregisterCallbackRef.current?.();
  }, [shouldBlock, block]);

  const onNavigationConfirm = useCallback(() => {
    setShowConfirmation(false);
    unregisterCallbackRef.current?.();
    if (locationRef.current) {
      navigate(locationRef.current);
    }
  }, [navigate]);

  const onNavigationCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  return {
    showConfirmation,
    onNavigationConfirm,
    onNavigationCancel,
  };
};

export default useNavigationBlocker;
