import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Tooltip from "ds/components/Tooltip";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type ContextListItemDropdownDeleteProps = {
  hasAttachedStacks: boolean;
  name: string;
  id: string;
  onSuccess?: () => void;
  showConfirmationCallback?: () => void;
};

const ContextListItemDropdownDelete = ({
  hasAttachedStacks,
  name,
  id,
  onSuccess,
  showConfirmationCallback,
}: ContextListItemDropdownDeleteProps) => {
  const showConfirmation = () => {
    showConfirmationCallback?.();
    showDeleteConfirmation({ id, name }).then(onSuccess);
  };

  return (
    <Tooltip
      active={hasAttachedStacks}
      widthMode="maxWidthSm"
      placement="left"
      delay={0}
      on={({ ref, ...props }) => (
        <DropdownSectionItem
          {...props}
          innerRef={ref}
          disabled={hasAttachedStacks}
          onClick={showConfirmation}
          danger
        >
          Delete
        </DropdownSectionItem>
      )}
    >
      Before deleting context assure it is not attached to any stacks or modules.
    </Tooltip>
  );
};

export default ContextListItemDropdownDelete;
