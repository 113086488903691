export const flags = {
  bananaMode: false,
  mfa: false,
  "billing-usage": false,
  "diff-view-for-json-encoded-values": false,
  "multiple-vcs-with-redesign": false,
  "enable-api-key-creation-for-non-root-admins-frontend": false,
  "dashboard-metrics": false,
  // Do not remove until SH support
  "policy-templates-frontend": false,
  "trigger-run-with-custom-config-frontend": false,
  "use-slack-member-id-from-idp-for-user-profile": false,
  "aggregated-vcs-checks": false,
  "dashboard-refresh-interval-seconds": 10,
  "use-billing-v-4": false,
  "scheduled-runs-frontend": false,
  "use-new-topological-sort-in-runs-view": false,
  "ansible-configuration-management-frontend": false,
  "mfa-only-behind-sso": false,
  "sticky-view-header": false,
  "hidden-beta-tags-ansible": false,
  "stack-list-redesign-frontend": false,
  "show-leaking-sensitive-outputs-through-dependencies": false,
  "vcs-events-show-ignored-runs": false,
  "audit-trail-logs-frontend": false,
  "number-of-deployments-widget-frontend": false,
  "approval-policy-evaluations-widget-frontend": false,
};

type KebabToCamelCase<S extends string> = S extends `${infer P1}-${infer P2}${infer P3}`
  ? `${P1}${Uppercase<P2>}${KebabToCamelCase<P3>}`
  : S;

type ObjectKeysFromKebabToCamelCase<ObjectType> = {
  [K in keyof ObjectType as K extends string ? KebabToCamelCase<K> : K]: ObjectType[K];
};

export type Flags = ObjectKeysFromKebabToCamelCase<typeof flags>;
