import { useCallback } from "react";

import { VcsProvider } from "types/generated";

import useDeleteGitHubIntegration from "./Integrations/ManageGitHubEnterprise/useDeleteGitHubEnterpriseIntegration";
import useDeleteGitLabIntegration from "./Integrations/ManageGitLab/useDeleteGitLabIntegration";
import useDeleteAzureDevOpsIntegration from "./Integrations/ManageAzureDevOps/useDeleteAzuredevOpsIntegration";
import useDeleteBitbucketDataCenterIntegration from "./Integrations/ManageBitbucketDataCenter/useDeleteBitbucketDataCenterIntegration";
import useDeleteBitbucketCloudIntegration from "./Integrations/ManageBitbucketCloud/useDeleteBitbucketCloudIntegration";

const useDeleteVcsIntegration = () => {
  const { deleteGitHubEnterpriseIntegration } = useDeleteGitHubIntegration();
  const { deleteGitLabIntegration } = useDeleteGitLabIntegration();
  const { deleteAzureDevOpsIntegration } = useDeleteAzureDevOpsIntegration();
  const { deleteBitbucketDataCenterIntegration } = useDeleteBitbucketDataCenterIntegration();
  const { deleteBitbucketCloudIntegration } = useDeleteBitbucketCloudIntegration();

  const handleDeleteVcsIntegration = useCallback(
    (
      id: string,
      provider: VcsProvider,
      totalUsedByEntitiesQty: number,
      successCallback?: () => void
    ) => {
      if (provider === VcsProvider.GithubEnterprise) {
        deleteGitHubEnterpriseIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.Gitlab) {
        deleteGitLabIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.AzureDevops) {
        deleteAzureDevOpsIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.BitbucketDatacenter) {
        deleteBitbucketDataCenterIntegration(id, totalUsedByEntitiesQty, successCallback);
      }

      if (provider === VcsProvider.BitbucketCloud) {
        deleteBitbucketCloudIntegration(id, totalUsedByEntitiesQty, successCallback);
      }
    },
    [
      deleteAzureDevOpsIntegration,
      deleteBitbucketCloudIntegration,
      deleteBitbucketDataCenterIntegration,
      deleteGitHubEnterpriseIntegration,
      deleteGitLabIntegration,
    ]
  );

  return {
    deleteVcsIntegration: handleDeleteVcsIntegration,
  };
};

export default useDeleteVcsIntegration;
