import { memo } from "react";

import useTypedContext from "hooks/useTypedContext";
import Drawer from "ds/components/Drawer";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackSchedulingContextApi, StackSchedulingContextData } from "../Context";
import StackManageScheduledTask from "../ManageScheduledTask";
import StackManageDriftDetection from "../ManageDriftDetection";
import StackManageScheduledDelete from "../ManageScheduledDelete";
import StackManageScheduledRun from "../ManageScheduledRun";

const StackSchedulingDrawer = () => {
  const { focusedItem, drawerEntityType } = useTypedContext(StackSchedulingContextData);
  const { onClose } = useTypedContext(StackSchedulingContextApi);

  const visible = !!drawerEntityType;

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const handleCancelAction = () => {
    onClose();

    if (focusedItem) {
      trackSegmentEvent("Schedule Edit Cancelled", { type: drawerEntityType });
    } else {
      trackSegmentEvent("Create Schedule Canceled", { type: drawerEntityType });
    }
  };

  return (
    <Drawer visible={visible}>
      {drawerEntityType === SCHEDULING_TYPE.DRIFT_DETECTION && (
        <StackManageDriftDetection
          integration={
            (focusedItem?.__typename === "DriftDetectionIntegration" && focusedItem) || undefined
          }
          onCancel={handleCancelAction}
        />
      )}
      {drawerEntityType === SCHEDULING_TYPE.DELETE_STACK && (
        <StackManageScheduledDelete
          integration={(focusedItem?.__typename === "ScheduledDelete" && focusedItem) || undefined}
          onCancel={handleCancelAction}
        />
      )}
      {drawerEntityType === SCHEDULING_TYPE.TASK && (
        <StackManageScheduledTask
          integration={(focusedItem?.__typename === "ScheduledTask" && focusedItem) || undefined}
          onCancel={handleCancelAction}
        />
      )}
      {drawerEntityType === SCHEDULING_TYPE.RUN && (
        <StackManageScheduledRun
          integration={(focusedItem?.__typename === "ScheduledRun" && focusedItem) || undefined}
          onCancel={handleCancelAction}
        />
      )}
    </Drawer>
  );
};

export default memo(StackSchedulingDrawer);
