import FlashContext from "components/FlashMessages/FlashContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import useEnableStack from "shared/Stack/useEnableStack";

type EnableButtonProps = {
  stackId: Stack["id"];
};

const EnableButton = ({ stackId }: EnableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [enableStack, { loading }] = useEnableStack({
    refetchQueries: ["GetStack"],
  });

  const handleClick = () =>
    enableStack({ id: stackId }, (data) => {
      if (data?.stackEnable.id) {
        reportSuccess({ message: "Stack successfully enabled" });
      }
    });

  return (
    <DropdownSectionItem onClick={handleClick} disabled={loading} loading={loading}>
      Enable
    </DropdownSectionItem>
  );
};

export default EnableButton;
