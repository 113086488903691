import { DiffEditor } from "@monaco-editor/react";
import { create, show } from "@ebay/nice-modal-react";

import ModalContent from "ds/components/ModalNew/Content";
import ModalHeader from "ds/components/ModalNew/Header";
import ModalSimple from "ds/components/ModalSimple";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import styles from "./styles.module.css";

type PolicyDiffCalloutModalProps = {
  body: string;
  evaluationBody: string;
};

const PolicyDiffCalloutModal = create(function SettingModal({
  body,
  evaluationBody,
}: PolicyDiffCalloutModalProps) {
  const { isDarkMode, currentDarkModeSyntaxTheme, currentLightModeSyntaxTheme } =
    useTypedContext(ThemeContext);

  return (
    <ModalSimple size="x-large">
      <ModalHeader title="Policy body changes" />
      <ModalContent>
        <Box className={styles.diffHeader}>
          <Typography className={styles.diffLabel} tag="span" variant="p-t7">
            Current body
          </Typography>
          <Typography tag="span" variant="p-t7">
            Evaluation record's body
          </Typography>
        </Box>
        <div className={styles.diffEditorWrapper}>
          <DiffEditor
            original={body}
            modified={evaluationBody}
            language="rego"
            theme={isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme}
          />
        </div>
      </ModalContent>
    </ModalSimple>
  );
});

export const showPolicyDiffCalloutModal = (props: PolicyDiffCalloutModalProps) =>
  show(PolicyDiffCalloutModal, props);
