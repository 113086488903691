import { WarningSolid } from "components/icons";
import Box from "ds/components/Box";
import Icon from "ds/components/Icon";
import IconAction from "ds/components/IconAction";
import Tooltip from "ds/components/Tooltip";
import FloatingTooltipModal from "ds/components/TooltipModal";
import Typography from "ds/components/Typography";

type DashboardWidgetsResourcesHealthDriftDisabledCentralInfoProps = {
  hasSmallContainer?: boolean;
};

const DashboardWidgetsResourcesHealthDriftDisabledCentralInfo = ({
  hasSmallContainer,
}: DashboardWidgetsResourcesHealthDriftDisabledCentralInfoProps) => {
  return (
    <Box gap="small" justify="center" align="center">
      {hasSmallContainer ? (
        <FloatingTooltipModal
          variant="colorful"
          on={(props) => <IconAction {...props} icon={WarningSolid} color="warning" />}
        >
          <Box direction="column" gap="medium">
            <Typography tag="span" variant="p-t6">
              Drift Detection not enabled
            </Typography>
            <Typography tag="span" variant="p-body3">
              Enable drift detection on your stacks to be able to preview your resources health.
            </Typography>

            {/* TODO: [dashboard] userflow trigger */}
            {/* <Box justify="end">
              <Button variant="outlineOnDark">Show me how</Button>
            </Box> */}
          </Box>
        </FloatingTooltipModal>
      ) : (
        <Tooltip
          on={(props) => <Icon {...props} src={WarningSolid} color="warning" />}
          widthMode="maxWidthSm"
        >
          Enable drift detection on your stacks to be able to preview your resources health.
        </Tooltip>
      )}

      <Typography color="secondary" tag="span" variant="p-t7">
        RESOURCES
      </Typography>
    </Box>
  );
};

export default DashboardWidgetsResourcesHealthDriftDisabledCentralInfo;
