import { useMemo } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import MetaInfoList from "components/MetaInfoList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { ClockNew, MenuSpaces } from "components/icons";
import Timestamp from "components/time/Timestamp";
import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import Button from "ds/components/Button";
import { ConfigType } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import DropdownSection from "ds/components/Dropdown/Section";
import TabWithCounter from "ds/components/Tab/WithCounter";
import TabWithBadge from "ds/components/Tab/WithBadge";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import ContextListItemDropdownDelete from "views/account/Contexts/ListItemDropdown/Delete";
import ViewHeaderScrollCollapse from "components/ViewHeader/ScrollCollapse";

import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import { ContextHooks } from "../types";
import { ContextContext } from "../Context";

const ContextHeader = () => {
  const {
    context,
    contextUrl,
    isDetailsDrawerVisible,
    handleOpenDetailsDrawer,
    handleOpenEditDrawer,
    canManageContext,
  } = useTypedContext(ContextContext);
  const navigate = useNavigate();

  const {
    attachedStacks,
    hasAttachedStacks,
    id,
    name,
    createdAt,
    spaceDetails,
    config,
    hooks,
    labels,
  } = context;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.Context,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const variablesCount = config.filter(
    (item) => item.type === ConfigType.EnvironmentVariable
  ).length;

  const mountedFilesCount = config.filter((item) => item.type === ConfigType.FileMount).length;
  const hasHooksDefined = useMemo(() => {
    return Object.keys(hooks).some((item) => {
      const key = item as keyof ContextHooks;
      if (key === "__typename") return;
      const value = hooks[key];

      return value && value.length > 0;
    });
  }, [hooks]);

  const onDeleteSuccess = () => {
    trackSegmentAnalyticsEvent("Delete", {
      labels: labels,
      space: spaceDetails.name,
      name: name,
    });

    navigate("/contexts");
  };

  const onEditClick = (clb?: () => unknown) => () => {
    handleOpenEditDrawer();

    clb?.();
  };

  return (
    <ViewHeader>
      <ViewHeaderWrapper direction="row" justify="between" fullWidth>
        <ViewHeaderWrapper direction="row" align="center">
          <ViewHeaderTitle>{name}</ViewHeaderTitle>
        </ViewHeaderWrapper>

        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          <Button
            variant="secondary"
            onClick={handleOpenDetailsDrawer}
            active={isDetailsDrawerVisible}
          >
            Details
          </Button>

          <DropdownEllipsis>
            {({ close }) => (
              <DropdownSection>
                <CopyFieldDropdownItem title="Copy context ID" value={id} callback={close} />
                {canManageContext && (
                  <>
                    <DropdownSectionItem onClick={onEditClick(close)}>Edit</DropdownSectionItem>
                    <ContextListItemDropdownDelete
                      hasAttachedStacks={hasAttachedStacks}
                      name={name}
                      id={id}
                      onSuccess={onDeleteSuccess}
                      showConfirmationCallback={close}
                    />
                  </>
                )}
              </DropdownSection>
            )}
          </DropdownEllipsis>
        </ViewHeaderWrapper>
      </ViewHeaderWrapper>
      <ViewHeaderScrollCollapse>
        <MetaInfoList>
          {spaceDetails && (
            <MetaInfoListItem
              icon={MenuSpaces}
              linkText={spaceDetails.name}
              href={`/spaces/${spaceDetails.id}`}
            />
          )}
          {createdAt && (
            <MetaInfoListItem icon={ClockNew}>
              <Timestamp timestamp={createdAt} />
            </MetaInfoListItem>
          )}
        </MetaInfoList>
      </ViewHeaderScrollCollapse>

      <ViewHeaderNavigation>
        <TabWithCounter exact to={contextUrl} count={variablesCount} label="Variables" />
        <TabWithCounter
          to={`${contextUrl}/files`}
          count={mountedFilesCount}
          label="Mounted files"
        />
        <TabWithBadge
          badgeValue={hasHooksDefined ? "Defined" : "None"}
          to={`${contextUrl}/hooks`}
          label="Hooks"
        />
        <TabWithCounter
          to={`${contextUrl}/used-by`}
          count={attachedStacks.length}
          label="Used by"
        />
      </ViewHeaderNavigation>
    </ViewHeader>
  );
};

export default ContextHeader;
