import { memo, useCallback, useEffect, useRef } from "react";

import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { ClockNew } from "components/icons";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import MetaInfoCommit from "components/MetaInfoList/Commit";
import MetaInfoListItem from "components/MetaInfoList/Item";
import ModuleVersionStateBadge from "components/ModuleVersionStateBadge";
import Timestamp from "components/time/Timestamp";
import { ViewCustomizationContext } from "components/ViewCustomization/Context";
import ViewCustomizationItem from "components/ViewCustomization/Item";
import Box from "ds/components/Box";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import useTypedContext from "hooks/useTypedContext";
import { Module, Version, VersionState } from "types/generated";
import { hasSpaceManageAccess } from "utils/user";
import UserLinkNew from "views/account/Module/components/UserLinkNew";
import DeleteVersionButton from "views/account/Module/Version/components/DeleteButton";
import MarkAsBadDropdownItem from "views/account/Module/Version/components/MarkAsBad/DropdownItem";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import TriggerAgainDropdownItem from "views/account/Module/Version/components/TriggerAgainDropdownItem";

import { EMPTY_COMMIT_MESSAGE_VERSION_NAME } from "../../constants";
import {
  canOpenInstructionsDrawer,
  COLUMN_GAP,
  columnTemplate,
  versionListViewItemId,
} from "../../constants";
import YankedBadge from "../YankedBadge";

export type VersionsListItemProps = {
  item: Version;
  setRowHeight: (size: number) => void;
  module: Module;
  openInstructionsDrawer: (version: Version) => void;
  openEditDescriptionDrawer: (version: Version) => void;
  openViewDescriptionDrawer: (version: Version) => void;
};

const VersionsListItem = ({
  item,
  module,
  openEditDescriptionDrawer,
  openViewDescriptionDrawer,
  openInstructionsDrawer,
  setRowHeight,
}: VersionsListItemProps): JSX.Element => {
  const rowRef = useRef<HTMLDivElement>(null);
  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // Update the row height on every render
  useEffect(handleRowHeight);

  const displayInstructions = useCallback(
    (close: () => void): void => {
      openInstructionsDrawer(item);
      close();
    },
    [openInstructionsDrawer, item]
  );

  const editDescription = useCallback(
    (close: () => void): void => {
      openEditDescriptionDrawer(item);
      close();
    },
    [openEditDescriptionDrawer, item]
  );

  const handleShowFullDescription = useCallback(() => {
    openViewDescriptionDrawer(item);
  }, [openViewDescriptionDrawer, item]);

  const canManageModule =
    module.spaceDetails?.accessLevel && hasSpaceManageAccess(module.spaceDetails.accessLevel);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={columnTemplate(itemsVisibility)}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <ViewCustomizationItem
        id={versionListViewItemId.VERSION}
        onVisibilityChange={handleRowHeight}
      >
        <Box align="center">
          <ModuleVersionStateBadge state={item.state} number={item.number} />
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem id={versionListViewItemId.NAME} onVisibilityChange={handleRowHeight}>
        <Box direction="column" align="start" justify="center" zeroMinWidth>
          <Box align="center" gap="medium" fullWidth>
            <ListEntitiesItemLink
              to={`./${module.id}/version/${item.id}`}
              title={item.commit.message || EMPTY_COMMIT_MESSAGE_VERSION_NAME}
              titleColor="primary"
              titleVariant="p-t5"
            />

            {item.yanked && <YankedBadge notes={item.yankNote} />}
          </Box>

          {!!item.notes && (
            <Box align="center" gap={COLUMN_GAP} fullWidth>
              <ListEntitiesItemDescription
                description={item.notes!}
                onShowFullDescription={handleShowFullDescription}
              />
            </Box>
          )}
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem id={versionListViewItemId.TESTS} onVisibilityChange={handleRowHeight}>
        <Box align="center">
          <MetaInfoListItem>{item.versionCount}</MetaInfoListItem>
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem
        id={versionListViewItemId.COMMITTED_BY}
        onVisibilityChange={handleRowHeight}
      >
        <Box align="center">
          <UserLinkNew
            login={item.commit.authorLogin}
            name={item.commit.authorName}
            provider={module.provider}
          />
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem
        id={versionListViewItemId.TRIGGERED_BY}
        onVisibilityChange={handleRowHeight}
      >
        <Box align="center">
          <TextEllipsis tooltip={item.triggeredBy}>
            {(props) => (
              <Typography {...props} tag="span" variant="p-body2">
                {item.triggeredBy ?? "-"}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem id={versionListViewItemId.COMMIT} onVisibilityChange={handleRowHeight}>
        <Box align="center">
          <MetaInfoCommit commit={item.commit} provider={module.provider} />
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem
        id={versionListViewItemId.COMMITTED}
        onVisibilityChange={handleRowHeight}
      >
        <Box align="center">
          <MetaInfoListItem icon={ClockNew}>
            <Timestamp timestamp={item.commit.timestamp} />
          </MetaInfoListItem>
        </Box>
      </ViewCustomizationItem>

      <ViewCustomizationItem
        id={versionListViewItemId.CREATED_AT}
        onVisibilityChange={handleRowHeight}
      >
        <Box align="center">
          <MetaInfoListItem icon={ClockNew}>
            <Timestamp timestamp={item.createdAt} />
          </MetaInfoListItem>
        </Box>
      </ViewCustomizationItem>

      <Box align="center">
        <DropdownEllipsis dotsSize="small">
          {({ close }) => (
            <DropdownSection>
              {canManageModule && item.state === VersionState.Failed &&
                <TriggerAgainDropdownItem id={item.id} moduleId={module.id} onClickCb={close} />
              }
              <CopyFieldDropdownItem title="Copy ID" value={item.id} callback={close} />
              {canOpenInstructionsDrawer(item) && (
                <DropdownSectionItem onClick={() => displayInstructions(close)}>
                  Instructions
                </DropdownSectionItem>
              )}
              {canManageModule && (
                <DropdownSectionItem onClick={() => editDescription(close)}>
                  Edit description
                </DropdownSectionItem>
              )}
              {canManageModule && !item.yanked && (
                <MarkAsBadDropdownItem
                  id={item.id}
                  moduleId={module.id}
                  versionState={item.state}
                  versionNumber={item.number}
                  onClickCb={close}
                />
              )}
              {canManageModule && (
                <DeleteVersionButton
                  id={item.id}
                  moduleId={module.id}
                  number={item.number}
                  onClickCb={close}
                />
              )}
            </DropdownSection>
          )}
        </DropdownEllipsis>
      </Box>
    </ListEntitiesItem>
  );
};

export default memo(VersionsListItem);
