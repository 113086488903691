import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";

import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import SearchInput from "components/SearchInput";
import EmptyState from "ds/components/EmptyState";
import { KeyColored, NoResultsColored } from "components/icons";
import Drawer from "ds/components/Drawer";
import FlashContext from "components/FlashMessages/FlashContext";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import { GpgKey, SearchQueryOrderDirection } from "types/generated";
import Box from "ds/components/Box";
import { fuzzySearch } from "utils/fuzzySearch";
import useURLParams from "hooks/useURLParams";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import DocumentationButton from "components/DocumentationButton";
import SortableTable, { SortableTableColumn } from "components/SortableTable";
import ListEntitiesItem from "components/ListEntitiesItem";
import PageInfo from "components/PageWrapper/Info";
import { getDocsUrl } from "utils/getDocsUrl";

import TerraformRegistryViewHeader from "./ViewHeader";
import CreateGpgKeyDrawer from "./CreateGpgKeyDrawer";
import { GET_GPG_KEYS } from "./gql";
import UpdateGpgKeyDrawer from "./UpdateGpgKeyDrawer";
import GpgKeysListSection from "./GpgKeysListSection";
import GpgKeyListItem from "./GpgKeyListItem";
import { COLUMN_ORDER } from "./constants";
import { GetGpgKeysGql } from "./types";

const activeGpgKeysColumns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "id",
    label: "ID",
  },
  {
    value: "createdBy",
    label: "Created by",
  },
  {
    value: "createdAt",
    label: "Created",
  },
];

const revokedGpgKeysColumns: SortableTableColumn[] = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "id",
    label: "ID",
  },
  {
    value: "revokedBy",
    label: "Revoked by",
  },
  {
    value: "revokedAt",
    label: "Revoked",
  },
];

function GpgKeys() {
  const { onError } = useTypedContext(FlashContext);
  const { accountName, viewer } = useTypedContext(AccountContext);

  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [isCreateDrawerVisible, setCreateDrawerVisibility] = useState(false);
  const [isUpdateDrawerVisible, setUpdateDrawerVisibility] = useState(false);
  const [focusedGpgKey, setFocusedGpgKey] = useState<GpgKey | undefined>(undefined);

  const handleCloseCreateDrawer = () => setCreateDrawerVisibility(false);
  const handleOpenCreateDrawer = () => setCreateDrawerVisibility(true);

  const handleCloseUpdateDrawer = () => {
    setUpdateDrawerVisibility(false);
    setFocusedGpgKey(undefined);
  };

  const handleOpenUpdateDrawer = (gpgKey: GpgKey) => {
    setFocusedGpgKey(gpgKey);
    setUpdateDrawerVisibility(true);
  };

  const handleOpenFullDescriptionDrawer = (gpgKey: GpgKey) => {
    setFocusedGpgKey(gpgKey);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedGpgKey(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const { error, loading, data } = useQuery<GetGpgKeysGql>(GET_GPG_KEYS, { onError });
  const urlParams = useURLParams();
  const searchQuery = urlParams.get(URL_SEARCH_KEY);

  const [activeGpgKeys, revokedGpgKeys] = useMemo(() => {
    if (!data?.gpgKeys.length) {
      return [[], []];
    }

    let filteredGpgKeys = data.gpgKeys;

    if (searchQuery) {
      filteredGpgKeys = fuzzySearch(filteredGpgKeys, searchQuery.trim(), {
        keys: ["description", "name", "id"],
        scoreThreshold: -1000,
      });
    }

    return [
      filteredGpgKeys.filter((gpgKey) => !gpgKey.revokedAt),
      filteredGpgKeys.filter((gpgKey) => gpgKey.revokedAt),
    ];
  }, [data, searchQuery]);

  useTitle(`GPG Keys · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  const isLoading = loading;
  const isPageEmpty = !isLoading && !error && data?.gpgKeys.length === 0;
  const hasNoQueryResults =
    activeGpgKeys.length === 0 && revokedGpgKeys.length === 0 && searchQuery;

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="GPG Keys">
        <SearchInput
          placeholder="Search by ID, name and description..."
          filtersOrderSettingsKey="gpgKeysFiltersOrder"
          disabled={isLoading}
        />
        {viewer.admin && (
          <Button
            onClick={handleOpenCreateDrawer}
            variant="primary"
            disabled={isLoading || isCreateDrawerVisible}
          >
            Register GPG key
          </Button>
        )}
      </PageInfo>
      {isLoading && <PageLoading />}
      <Drawer
        position="fixedRight"
        visible={isCreateDrawerVisible}
        onOutsideClick={handleCloseCreateDrawer}
      >
        <CreateGpgKeyDrawer handleCloseDrawer={handleCloseCreateDrawer} />
      </Drawer>
      <Drawer
        position="fixedRight"
        visible={isUpdateDrawerVisible}
        onOutsideClick={handleCloseUpdateDrawer}
      >
        {focusedGpgKey && (
          <UpdateGpgKeyDrawer gpgKey={focusedGpgKey} handleCloseDrawer={handleCloseUpdateDrawer} />
        )}
      </Drawer>
      <FullDescriptionDrawer
        position="fixedRight"
        visible={isFullDescriptionDrawerVisible}
        description={focusedGpgKey?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      {!isLoading && isPageEmpty && !hasNoQueryResults && (
        <EmptyState
          title="Register your first GPG key"
          icon={KeyColored}
          caption="GPG keys are used to sign provider archives."
        >
          <Box gap="medium">
            <DocumentationButton
              to={getDocsUrl("/vendors/terraform/provider-registry#register-a-gpg-key")}
              label="Documentation"
            />
            {viewer.admin && (
              <Button
                variant="primary"
                onClick={handleOpenCreateDrawer}
                disabled={isCreateDrawerVisible}
              >
                Register GPG key
              </Button>
            )}
          </Box>
        </EmptyState>
      )}
      {!isLoading && hasNoQueryResults && <EmptyState title="No results" icon={NoResultsColored} />}
      {!isLoading && !isPageEmpty && (
        <Box __deprecatedGap="1rem" direction="column">
          {activeGpgKeys.length > 0 && (
            <GpgKeysListSection title="Active" count={activeGpgKeys.length}>
              <SortableTable
                items={activeGpgKeys}
                renderItems={(sorted) =>
                  sorted.map((item) => (
                    <ListEntitiesItem
                      grid
                      gridTemplate={COLUMN_ORDER}
                      gap="0 large"
                      key={item.id}
                      isActive={focusedGpgKey?.id === item.id}
                    >
                      <GpgKeyListItem
                        item={item}
                        onEdit={handleOpenUpdateDrawer}
                        onShowFullDescription={handleOpenFullDescriptionDrawer}
                      />
                    </ListEntitiesItem>
                  ))
                }
                columnOrder={COLUMN_ORDER}
                columns={activeGpgKeysColumns}
                initialSortBy="createdAt"
                initialDirection={SearchQueryOrderDirection.Desc}
              />
            </GpgKeysListSection>
          )}

          {revokedGpgKeys.length > 0 && (
            <GpgKeysListSection title="Revoked" count={revokedGpgKeys.length}>
              <SortableTable
                items={revokedGpgKeys}
                renderItems={(sorted) =>
                  sorted.map((item) => (
                    <ListEntitiesItem
                      grid
                      gridTemplate={COLUMN_ORDER}
                      gap="0 large"
                      key={item.id}
                      isActive={focusedGpgKey?.id === item.id}
                    >
                      <GpgKeyListItem
                        item={item}
                        onEdit={handleOpenUpdateDrawer}
                        onShowFullDescription={handleOpenFullDescriptionDrawer}
                      />
                    </ListEntitiesItem>
                  ))
                }
                columnOrder={COLUMN_ORDER}
                columns={revokedGpgKeysColumns}
                initialSortBy="revokedAt"
                initialDirection={SearchQueryOrderDirection.Desc}
              />
            </GpgKeysListSection>
          )}
        </Box>
      )}
    </>
  );
}

export default GpgKeys;
