import { MouseEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import {
  ChevronNew,
  LogoAzureDevOps,
  LogoBitbucket,
  LogoGitHub,
  LogoGitLab,
} from "components/icons";
import Button from "ds/components/Button";
import Dropdown from "ds/components/Dropdown";
import Icon from "ds/components/Icon";
import { VcsProvider } from "types/generated";
import { getManageIntegrationURL } from "views/account/VCS/helpers";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownSection from "ds/components/Dropdown/Section";
import useTypedContext from "hooks/useTypedContext";

import VCSIntegrationsSetupDropdownOption from "./Option";
import { VCSIntegrationsListContext } from "../Context";

type VCSIntegrationsSetupDropdownProps = {
  analyticsLocation?: "on-page-empty-state" | "on-page-header";
};

const VCSIntegrationsSetupDropdown = ({
  analyticsLocation = "on-page-header",
}: VCSIntegrationsSetupDropdownProps) => {
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { location: analyticsLocation },
  });

  const triggerDropdownClickHandler = useCallback(
    (onClick: (e: MouseEvent) => void) => (e: MouseEvent) => {
      onClick(e);
      trackSegmentAnalyticsEvent("Setup Start Click");
    },
    [trackSegmentAnalyticsEvent]
  );

  const chooseProviderClickHandler = useCallback(
    (provider: VcsProvider, closeDropdown: () => void) => () => {
      navigate(getManageIntegrationURL(provider));
      closeDropdown();
      trackSegmentAnalyticsEvent("Setup Integration Select", { integration: provider });
    },
    [navigate, trackSegmentAnalyticsEvent]
  );

  const { isDefaultIntegrationsLoading, isSetupDisabled } = useTypedContext(
    VCSIntegrationsListContext
  );

  return (
    <Dropdown
      renderTriggerComponent={({ onClick, ariaProps }) => (
        <Button
          variant="primary"
          onClick={triggerDropdownClickHandler(onClick)}
          loading={isDefaultIntegrationsLoading}
          disabled={isDefaultIntegrationsLoading}
          endIcon={ChevronNew}
          endIconRotate="90"
          {...ariaProps}
        >
          Set up integration
        </Button>
      )}
    >
      {({ closeDropdown }) => (
        <DropdownSection>
          <VCSIntegrationsSetupDropdownOption
            onClick={chooseProviderClickHandler(VcsProvider.AzureDevops, closeDropdown)}
            isDisabled={isSetupDisabled(VcsProvider.AzureDevops)}
          >
            <Icon src={LogoAzureDevOps} />
            Azure DevOps
          </VCSIntegrationsSetupDropdownOption>

          <VCSIntegrationsSetupDropdownOption
            onClick={chooseProviderClickHandler(VcsProvider.BitbucketCloud, closeDropdown)}
            isDisabled={isSetupDisabled(VcsProvider.BitbucketCloud)}
          >
            <Icon src={LogoBitbucket} />
            Bitbucket Cloud
          </VCSIntegrationsSetupDropdownOption>

          <VCSIntegrationsSetupDropdownOption
            onClick={chooseProviderClickHandler(VcsProvider.BitbucketDatacenter, closeDropdown)}
            isDisabled={isSetupDisabled(VcsProvider.BitbucketDatacenter)}
          >
            <Icon src={LogoBitbucket} />
            Bitbucket Data Center
          </VCSIntegrationsSetupDropdownOption>

          <VCSIntegrationsSetupDropdownOption
            onClick={chooseProviderClickHandler(VcsProvider.GithubEnterprise, closeDropdown)}
            isDisabled={isSetupDisabled(VcsProvider.GithubEnterprise)}
          >
            <Icon src={LogoGitHub} />
            GitHub
          </VCSIntegrationsSetupDropdownOption>

          <VCSIntegrationsSetupDropdownOption
            onClick={chooseProviderClickHandler(VcsProvider.Gitlab, closeDropdown)}
            isDisabled={isSetupDisabled(VcsProvider.Gitlab)}
          >
            <Icon src={LogoGitLab} />
            GitLab
          </VCSIntegrationsSetupDropdownOption>
        </DropdownSection>
      )}
    </Dropdown>
  );
};

export default VCSIntegrationsSetupDropdown;
