import { useEffect, useRef } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { Policy } from "types/generated";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItem from "components/ListEntitiesItem";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { MenuSpaces } from "components/icons";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import useApplyFilter from "components/Filters/useApplyFilter";
import { PolicySuggestions, searchPolicySuggestionsDictionary } from "constants/policy";
import TagsListFilterable from "components/TagsList/Filterable";
import PolicyTypeBadgeDropdown from "components/PolicyTypeBadge/Dropdown";
import { withTestId } from "utils/withTestId";

import { COLUMN_GAP, COLUMN_ORDER, POLICIES_LIST_ITEM_TEST_ID } from "./constants";
import { showDeleteConfirmation } from "../DeleteConfirmation";

type PoliciesListItemProps = {
  setRowHeight?: (size: number) => void;
  isActive?: boolean;
  onEdit: () => void;
  canManagePolicies: boolean;
  hasAttachedStacks: boolean;
  onShowFullDescription: () => void;
} & Pick<
  Policy,
  "id" | "name" | "type" | "labels" | "createdAt" | "updatedAt" | "description" | "spaceDetails"
>;

const PoliciesListItem = ({
  id,
  name,
  type,
  labels,
  createdAt,
  updatedAt,
  spaceDetails,
  setRowHeight,
  onEdit,
  onShowFullDescription,
  isActive = false,
  canManagePolicies,
  description,
  hasAttachedStacks,
}: PoliciesListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const { applyFilter, applySpaceFilter, applyLabelFilter } = useApplyFilter<PolicySuggestions>({
    searchSuggestionsDictionary: searchPolicySuggestionsDictionary,
  });

  // on every render, update the row height
  useEffect(handleRowHeight);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePolicy.PoliciesList,
    callbackTrackProviders: { segment: true },
  });

  const handleDelete = (closeDropdown: () => void) => {
    closeDropdown();
    trackSegmentAnalyticsEvent("Start Delete");
    showDeleteConfirmation({
      id,
      name,
    }).then((finished) =>
      trackSegmentAnalyticsEvent(finished ? "Confirm Delete" : "Cancel Delete")
    );
  };

  const handleEdit = (callback: () => void) => {
    onEdit();
    callback();
  };

  return (
    <ListEntitiesItem
      ref={rowRef}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
      {...withTestId(POLICIES_LIST_ITEM_TEST_ID)}
    >
      <Box direction="column" justify="center">
        <Box align="center">
          <ListEntitiesItemLink title={name} titleTag="h3" to={`/policy/${id}`} />
        </Box>

        {description && (
          <Box direction="row" align="start" fullWidth>
            <ListEntitiesItemDescription
              description={description}
              onShowFullDescription={onShowFullDescription}
            />
          </Box>
        )}

        <TagsListFilterable
          applyLabelFilter={applyLabelFilter(PolicySuggestions.Label)}
          applyFolderFilter={applyFilter(PolicySuggestions.Folder)}
          tags={labels}
          onExpand={handleRowHeight}
        />
      </Box>

      <Box>
        <PolicyTypeBadgeDropdown type={type} applyFilter={applyFilter(PolicySuggestions.Type)} />
      </Box>

      <MetaInfoListItem
        icon={MenuSpaces}
        linkText={spaceDetails.name}
        href={`/spaces/${spaceDetails.id}`}
        applyFilter={() => applySpaceFilter(PolicySuggestions.Space)(spaceDetails.id)}
        type={PolicySuggestions.Space}
      />

      <Box direction="row" align="center" shrink="0">
        <Typography tag="span" variant="p-body2">
          <Timestamp timestamp={createdAt} />
        </Typography>
      </Box>
      <Box direction="row" align="center" shrink="0">
        <Typography tag="span" variant="p-body2">
          <Timestamp timestamp={updatedAt} />
        </Typography>
      </Box>
      <DropdownEllipsis dotsSize="small">
        {({ close }) => (
          <DropdownSection>
            {canManagePolicies && (
              <DropdownSectionItem onClick={() => handleEdit(close)}>
                Edit details
              </DropdownSectionItem>
            )}
            <CopyFieldDropdownItem title="Copy policy ID" value={id} callback={close} />
            {canManagePolicies && (
              <DropdownSectionItem
                tooltip="Before deleting policy assure it is not attached to any stacks or modules."
                isTooltipActive={hasAttachedStacks}
                tooltipWidthMode="maxWidthSm"
                tooltipPlacement="left"
                disabled={hasAttachedStacks}
                onClick={() => handleDelete(close)}
                danger
              >
                Delete
              </DropdownSectionItem>
            )}
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </ListEntitiesItem>
  );
};

export default PoliciesListItem;
