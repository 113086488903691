import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import { VcsIntegration } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import useTestVCSIntegrationConnection from "../useTestVCSIntegrationConnection";
import useDeleteVcsIntegration from "../useDeleteVcsIntegration";
import { VCS_INTEGRATIONS_PATH } from "../constants";
import { isTestIntegrationConnectionAvailable } from "../helpers";
import { showVCSDeleteIntegrationModal } from "../components/DeleteIntegrationModal";

type VCSIntegrationDropdownActionsProps = {
  integration: VcsIntegration;
  onEdit: () => void;
  totalEntitiesCount: number;
};

const VCSIntegrationDropdownActions = ({
  integration,
  onEdit,
  totalEntitiesCount,
}: VCSIntegrationDropdownActionsProps) => {
  const navigate = useNavigate();

  const { testIntegrationConnection, loading } = useTestVCSIntegrationConnection({
    id: integration.id,
    name: integration.name || `Default ${HUMANIZE_PROVIDER[integration.provider]}`,
    provider: integration.provider,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const { deleteVcsIntegration } = useDeleteVcsIntegration();

  const isTestConnectionAvailable = isTestIntegrationConnectionAvailable(integration.details);

  const goToVcsIntegrations = useCallback(() => {
    navigate(VCS_INTEGRATIONS_PATH);
  }, [navigate]);

  const handleDeleteVcsIntegration = useCallback(() => {
    // FYI: the ID is not available only for the built-in GitHub integration & the delete option is not available for it
    if (integration.id) {
      deleteVcsIntegration(
        integration.id,
        integration.provider,
        totalEntitiesCount,
        goToVcsIntegrations
      );
    }
  }, [
    deleteVcsIntegration,
    goToVcsIntegrations,
    integration.id,
    integration.provider,
    totalEntitiesCount,
  ]);

  const onDeleteConfirmationModalShow = useCallback(() => {
    showVCSDeleteIntegrationModal({
      attachedEntitiesQty: totalEntitiesCount,
      integrationName: integration.name,
      onConfirm: handleDeleteVcsIntegration,
      onClose: () => trackSegmentAnalyticsEvent("Integration - Unlink Exit"),
    });

    trackSegmentAnalyticsEvent("Integration - Unlink Start");
  }, [
    handleDeleteVcsIntegration,
    integration.name,
    totalEntitiesCount,
    trackSegmentAnalyticsEvent,
  ]);

  return (
    <DropdownEllipsis tooltip="Integration actions">
      {({ closeDropdown }) => (
        <DropdownSection>
          <CopyFieldDropdownItem title="Copy ID" value={integration.id} callback={closeDropdown} />
          {isTestConnectionAvailable && (
            <DropdownSectionItem onClick={testIntegrationConnection} loading={loading}>
              Test
            </DropdownSectionItem>
          )}
          <DropdownSectionItem onClick={onEdit}>Edit</DropdownSectionItem>
          <DropdownSectionItem onClick={onDeleteConfirmationModalShow} danger>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default VCSIntegrationDropdownActions;
