import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type WorkerPoolDeleteActionProps = {
  onClick: () => void;
  hasUsers: boolean;
  hasBusyWorkers: boolean;
};

const hasUsersTooltip = (
  <>
    Before you delete the worker pool, you must make sure it is not used by any stacks or modules.
  </>
);

const hasBusyWorkersTooltip = (
  <>
    Please drain all workers first to make sure that no work is being performed by this worker pool.
  </>
);

const WorkerPoolDeleteAction = ({
  onClick,
  hasUsers,
  hasBusyWorkers,
}: WorkerPoolDeleteActionProps) => (
  <DropdownSectionItem
    tooltip={hasUsers && !hasBusyWorkers ? hasUsersTooltip : hasBusyWorkersTooltip}
    isTooltipActive={hasBusyWorkers || hasUsers}
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    onClick={onClick}
    danger
    disabled={hasBusyWorkers || hasUsers}
  >
    Delete
  </DropdownSectionItem>
);

export default WorkerPoolDeleteAction;
