import useLocalStorage from "@rehooks/local-storage";

import { VERSION_STACK_STORAGE_KEY } from "constants/stack";
import Box from "ds/components/Box";
import ToggleField from "ds/components/Form/ToggleField";
import useTypedFlags from "hooks/useTypedFlags";

const STACKS_LIST_SWITCH_ID = "stacks-list-switch";

const StacksSwitch = () => {
  const { stackListRedesignFrontend } = useTypedFlags();
  const [stacksListVersion, setStacksListVersion] = useLocalStorage(VERSION_STACK_STORAGE_KEY);

  if (!stackListRedesignFrontend) {
    return null;
  }

  return (
    <Box align="center" padding="0 large 0 0">
      <ToggleField
        id={STACKS_LIST_SWITCH_ID}
        variant="switch"
        checked={stacksListVersion === "new"}
        onChange={(e) => setStacksListVersion(e.target.checked ? "new" : "old")}
        title="New stack list"
      />
    </Box>
  );
};

export default StacksSwitch;
