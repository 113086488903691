import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SpaceAccessLevel } from "types/generated";
import { AccountContext } from "views/AccountWrapper";
import DropdownSection from "ds/components/Dropdown/Section";

import { ProviderListItemDropdownProps } from "./types";
import { showProviderDeleteConfirmation } from "../ProviderDeleteConfirmation";
import useDeleteProvider from "../useDeleteProvider";

const ProviderListItemDropdown = ({ item, onEdit }: ProviderListItemDropdownProps) => {
  const { reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [deleteProvider] = useDeleteProvider({
    refetchQueries: ["GetProviderWithVersions", "GetProviders"],
  });

  const handleDeleteProvider = (callback?: () => void) => () => {
    deleteProvider({ id: item.id }, (data) => {
      if (data?.terraformProviderDelete?.deleted) {
        reportSuccess({
          message: `Terraform provider "${data.terraformProviderDelete.id}" was successfully deleted`,
        });
      } else {
        reportError({
          message: "Something went wrong while deleting Terraform provider, please try again.",
        });
      }

      callback?.();
    });
  };

  const handleEditProvider = (callback?: () => void) => () => {
    onEdit(item);
    callback?.();
  };

  const handleDeleteProviderConfirmation = (callback?: () => void) => () => {
    showProviderDeleteConfirmation({
      providerId: item.id,
      onConfirm: handleDeleteProvider(callback),
    });
  };

  const canManageProvider =
    viewer.admin || item.spaceDetails.accessLevel === SpaceAccessLevel.Admin;

  if (!canManageProvider) {
    return null;
  }

  return (
    <DropdownEllipsis tooltip="Provider actions" dotsSize="small">
      {({ closeDropdown }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={handleEditProvider(closeDropdown)}>
            Edit
          </DropdownSectionItem>

          <DropdownSectionItem onClick={handleDeleteProviderConfirmation(closeDropdown)} danger>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default ProviderListItemDropdown;
