import { useMemo, useState } from "react";

import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { ApiKey } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import ApiKeysDetailsDrawer from "components/ApiKeys/DetailsDrawer";
import ApiKeysSortHeader from "components/ApiKeys/SortHeader";
import ApiKeysEmptyList from "components/ApiKeys/EmptyList";
import ApiKeysList from "components/ApiKeys/List";
import ApiKeysCreateDrawer from "components/ApiKeys/CreateDrawer";
import UsersInfoDrawer from "components/ApiKeys/InfoDrawer";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import FullDescriptionDrawer from "components/FullDescription/Drawer";

import ApiKeysPageLayout from "./PageLayout";
import { getManagementStrategy } from "../helpers";
import { SettingsContext } from "../Context";

const byMostRecentUsage = (x: ApiKey, y: ApiKey) => (y.lastUsedAt || 0) - (x.lastUsedAt || 0);

type ApiKeysProps = {
  apiKeys: ApiKey[];
};

const REFETCH_QUERIES = ["GetAccountSettings"];

const ApiKeys = ({ apiKeys }: ApiKeysProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);
  const [isCreateApiKeyDrawerVisible, setIsCreateApiKeyDrawerVisible] = useState(false);

  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · API Keys · ${accountName}`);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationApiKeys,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const memoizedApiKeys = useMemo<ApiKey[]>(() => {
    return [...apiKeys].sort(byMostRecentUsage);
  }, [apiKeys]);

  const [apiKeyForDrawer, setApiKeyForDrawer] = useState<ApiKey | undefined>(undefined);
  const [fullDescription, setFullDescription] = useState<string | undefined>(undefined);

  const handleApiKeyOpenDetails = (apiKey: ApiKey) => {
    trackSegmentAnalyticsEvent("Edit Details");
    setApiKeyForDrawer(apiKey);
  };

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    setIsInfoDrawerVisible(true);
  };

  const openCreateApiKeyDrawer = () => {
    trackSegmentAnalyticsEvent("Create Key");
    setIsCreateApiKeyDrawerVisible(true);
  };

  const openFullDescriptionDrawer = (description: string) => {
    setFullDescription(description);
    setIsInfoDrawerVisible(false);
    setApiKeyForDrawer(undefined);
  };

  return (
    <ApiKeysPageLayout
      openCreateApiKeyDrawer={openCreateApiKeyDrawer}
      openInfoDrawer={openInfoDrawer}
    >
      <ApiKeysCreateDrawer
        isDrawerVisible={isCreateApiKeyDrawerVisible}
        setDrawerVisibility={setIsCreateApiKeyDrawerVisible}
        activationStatus={activationStatus}
        refetchQueries={REFETCH_QUERIES}
        analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
      />
      <ApiKeysDetailsDrawer
        apiKey={apiKeyForDrawer}
        handleCloseDrawer={() => setApiKeyForDrawer(undefined)}
        activationStatus={activationStatus}
        openFullDescription={openFullDescriptionDrawer}
      />
      <UsersInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={() => setIsInfoDrawerVisible(false)}
        analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
      />
      <FullDescriptionDrawer
        position="fixedRight"
        visible={!!fullDescription}
        description={fullDescription}
        onCloseDrawer={() => setFullDescription(undefined)}
      />
      {memoizedApiKeys.length > 0 && (
        <>
          <ApiKeysSortHeader />
          <ApiKeysList
            apiKeys={memoizedApiKeys}
            handleApiKeyOpenDetails={handleApiKeyOpenDetails}
            refetchQueriesOnDelete={REFETCH_QUERIES}
          />
        </>
      )}
      {memoizedApiKeys.length === 0 && <ApiKeysEmptyList />}
    </ApiKeysPageLayout>
  );
};

export default ApiKeys;
