import FlashContext from "components/FlashMessages/FlashContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import useDisableStack from "shared/Stack/useDisableStack";
import { Stack } from "types/generated";

type DisableButtonProps = {
  stackId: Stack["id"];
};

const DisableButton = ({ stackId }: DisableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [disableStack, { loading }] = useDisableStack({
    refetchQueries: ["GetStack"],
  });

  const handleClick = () =>
    disableStack({ id: stackId }, (data) => {
      if (data?.stackDisable.id) {
        reportSuccess({ message: "Stack successfully disabled" });
      }
    });

  return (
    <DropdownSectionItem onClick={handleClick} disabled={loading} loading={loading}>
      Disable
    </DropdownSectionItem>
  );
};

export default DisableButton;
