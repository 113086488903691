import { AUTO_ATTACHMENT_KEY } from "constants/labels";

/**
 * Just return the first auto attached label
 *
 * @param targetLabels - labels that are attached to the stack, module, blueprint, etc.
 * @param sourceLabels - labels that are attached to the context, policy, etc.
 */
export const getAttachedLabel = (targetLabels: string[], sourceLabels: string[]) => {
  for (const label of sourceLabels) {
    if (!label.startsWith(AUTO_ATTACHMENT_KEY)) continue;

    const [, rest] = label.split(":");

    // If it's a wildcard label, break here and return it
    if (rest === "*") return label;

    const stackLabel = targetLabels.find((label) => label === rest);

    // If it's a specific label that also was attached to the stack, return it
    if (stackLabel) return label;
  }

  // This should never happen, backend should return contexts that have a matching label
  return "";
};
