import { useCallback, useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import useTypedFlags from "hooks/useTypedFlags";
import FlashContext from "components/FlashMessages/FlashContext";
import FormButton from "components/FormDefault/Button";
import FormFooter from "components/FormDefault/Footer";
import Button from "components/button/Button";
import WarningBar from "components/warning/WarningBar";
import { BillingTierFeature } from "types/generated";
import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import Drawer from "ds/components/Drawer";
import TierInfo from "components/TierInfo";
import useErrorHandle from "hooks/useErrorHandle";
import PageLoading from "components/loading/PageLoading";
import PageInfo from "components/PageWrapper/Info";

import { DELETE_AUDIT_TRAIL_WEBHOOK, GET_AUDIT_TRAIL_WEBHOOK } from "./gql";
import View from "./View";
import EditDrawer from "./EditDrawer";
import styles from "./styles.module.css";

const AuditTrailConfig = () => {
  const { accountName } = useContext(AccountContext);
  const { onError, reportSuccess } = useContext(FlashContext);
  const [createMode, setCreateMode] = useState(false);
  const [isAskedToDelete, askToDelete] = useState(false);
  const { auditTrailLogsFrontend } = useTypedFlags();

  const { loading, data, error } = useQuery(GET_AUDIT_TRAIL_WEBHOOK, {
    onError,
  });

  const [deleteWebhook, { loading: isDeleting }] = useMutation(DELETE_AUDIT_TRAIL_WEBHOOK, {
    refetchQueries: [{ query: GET_AUDIT_TRAIL_WEBHOOK }],
  });

  useTitle(
    auditTrailLogsFrontend
      ? `Audit Trail · Configuration · ${accountName}`
      : `Organization Settings · Audit trail · ${accountName}`
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationAuditTrail,

    callbackTrackProviders: { segment: true },
  });

  const onFormClose = () => setCreateMode(false);
  const onEditMode = () => {
    trackSegmentAnalyticsEvent("Edit Click");
    setCreateMode(true);
  };
  const onRemove = useCallback(async () => {
    if (!isAskedToDelete) {
      return askToDelete(true);
    }

    if (!isDeleting && isAskedToDelete) {
      try {
        await deleteWebhook();

        askToDelete(false);

        reportSuccess({ message: "Audit trail integration successfully disabled" });
      } catch (e) {
        onError(e);
      }
    }
  }, [isAskedToDelete, isDeleting, deleteWebhook, reportSuccess, onError]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  const integration = data?.auditTrailWebhook;

  const isViewMode = !createMode && integration;

  const openCreateForm = () => {
    trackSegmentAnalyticsEvent("Set Up Click");
    setCreateMode(true);
  };

  return (
    <>
      {auditTrailLogsFrontend && <PageInfo title="Configuration" />}
      {!auditTrailLogsFrontend && (
        <ViewHeader firstLevel>
          <ViewHeaderTitle tag="h2">Audit Trail</ViewHeaderTitle>
        </ViewHeader>
      )}
      <TierInfo
        variant="promo"
        feature={BillingTierFeature.AuditWebhooks}
        title="Audit trail is not supported by your plan"
      >
        You may still manage your audit trails configuration, but events will not be dispatched
        until you upgrade to a plan that supports Audit.
      </TierInfo>
      <div className={styles.auditTrailWrapper}>
        <div className={styles.integrationHeaderWrapper}>
          <h3 className={styles.integrationHeaderTitle}>Audit trail configuration</h3>

          {isViewMode && integration && (
            <div className={styles.integrationHeaderWrapperButtons}>
              <Button type="button" onClick={onEditMode} pill>
                Edit
              </Button>

              <Button type="button" danger loading={isDeleting} onClick={onRemove} pill>
                {isAskedToDelete ? "Confirm" : "Delete"}
              </Button>
            </div>
          )}
        </div>

        {isAskedToDelete && (
          <WarningBar>
            <p>
              Note that by disabling the Audit trail you will stop receiving audit event webhooks.
            </p>
          </WarningBar>
        )}

        {integration && <View {...integration} />}

        <Drawer position="absoluteRight" visible={createMode}>
          <EditDrawer integration={integration} onFormClose={onFormClose} />
        </Drawer>

        {!integration && !createMode && (
          <FormFooter top>
            <FormButton type="button" onClick={openCreateForm} full pill>
              Set up
            </FormButton>
          </FormFooter>
        )}
      </div>
    </>
  );
};

export default AuditTrailConfig;
