import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type ApiKeysListItemDropdownProps = {
  handleApiKeyDetailsClick: (callback: () => void) => unknown;
  name: string;
  id: string;
  refetchQueriesOnDelete?: string[];
};

const ApiKeysListItemDropdown = ({
  handleApiKeyDetailsClick,
  refetchQueriesOnDelete,
  name,
  id,
}: ApiKeysListItemDropdownProps) => {
  const onDeleteClick = (callback: () => void) => {
    callback();
    showDeleteConfirmation({ name, id, refetchQueriesOnDelete });
  };

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={() => handleApiKeyDetailsClick(close)}>
            Access details
          </DropdownSectionItem>
          <DropdownSectionItem onClick={() => onDeleteClick(close)} danger>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default ApiKeysListItemDropdown;
