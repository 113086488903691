import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import Tooltip from "ds/components/Tooltip";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useConfirmRun from "shared/Run/useConfirmRun";

import { useRunShortcut } from "../../hooks/useRunShortcut";

type ConfirmRunButtonProps = {
  stackId: string;
  runId: string;
  isStackDisabled: boolean;
  analyticsPage?: AnalyticsPage;
};

const ConfirmRunButton = ({
  stackId,
  runId,
  isStackDisabled,
  analyticsPage,
}: ConfirmRunButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const [runConfirm, { loading, data }] = useConfirmRun({
    refetchQueries: ["GetRun"],
  });

  const onClick = useCallback(() => {
    return runConfirm({ stack: stackId, run: runId, note: null }, (data) => {
      if (data?.runConfirm?.id) {
        if (analyticsPage) {
          trackSegmentAnalyticsEvent?.("Run confirmed", {
            runType: data?.runConfirm?.type,
          });
        }
        reportSuccess({ message: `Run ${data.runConfirm.id} successfully confirmed` });
      }
    });
  }, [analyticsPage, reportSuccess, runConfirm, runId, stackId, trackSegmentAnalyticsEvent]);

  const isLoading = loading || !!data?.runConfirm?.id;

  useRunShortcut({
    label: "Confirm",
    code: "alt+c",
    callback: onClick,
    loading: isLoading,
  });

  return (
    <Tooltip
      on={(props) => (
        <Button
          {...props}
          disabled={isLoading || isStackDisabled}
          loading={isLoading}
          onClick={onClick}
          id="confirm-button"
          variant="primary"
          size="small"
        >
          Confirm
        </Button>
      )}
      active={isStackDisabled}
    >
      Stack is disabled
    </Tooltip>
  );
};

export default ConfirmRunButton;
