import { Group } from "@visx/group";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import isEqual from "lodash-es/isEqual";
import cx from "classnames";
import { Point } from "d3-dag/dist/dag";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { withEnterKeyPress } from "utils/browser";

import styles from "./styles.module.css";
import { Position } from "../types";
import { createEndId, createStartId } from "../utils";

const ITEM_ORDER_SPACE = 8;
const ITEM_ORDER_WIDTH = 20;
const ITEM_ORDER_PADDING = 6;
const ITEM_STATUS_SPACE = 8;
const ITEM_STATUS_WIDTH = 8;
const ITEM_CONNECTION_SPACE_X = 10;
const ITEM_CONNECTION_CENTER_Y = 5;

type TreeChartTextNodeProps = {
  id: string;
  isParent: boolean;
  isOpened?: boolean;
  item: {
    name: string;
    order?: number;
    position: Position;
    status?: string;
    link?: {
      path: string;
      queryKey?: string;
      queryValue?: string;
    };
  };
  columnWidth: number;
  onToggle?: () => void;
  connectionPoints: Record<string, Position>;
  setConnectionPoints: React.Dispatch<React.SetStateAction<Record<string, Position>>>;
  onMouseEnter: (text: string, point: Point | null) => void;
  onMouseLeave: () => void;
};

const TreeChartTextNode = ({
  id,
  item,
  isParent,
  isOpened,
  onToggle,
  setConnectionPoints,
  connectionPoints,
  columnWidth,
  onMouseEnter,
  onMouseLeave,
}: TreeChartTextNodeProps) => {
  const textRef = useRef<SVGTextElement>(null);
  const orderTextRef = useRef<SVGTextElement>(null);
  const [tooltipActive, setTooltipActive] = useState(false);
  const [orderWidth, setOrderWidth] = useState(ITEM_ORDER_WIDTH);
  const navigate = useNavigate();

  const location = useLocation();
  const newUrlParams = new URLSearchParams(location.search);
  if (item.link?.queryKey && item.link?.queryValue) {
    newUrlParams.set(item.link?.queryKey, item.link?.queryValue);
  }

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<SVGTextElement | SVGRectElement>) => {
      const position = (event.target as SVGTextElement | SVGRectElement).getBBox();
      const x = position.x + item.position.x + position.width / 2;
      const y = position.y + item.position.y;

      if ("ariaLabel" in event.target && typeof event.target.ariaLabel === "string") {
        onMouseEnter(event.target.ariaLabel, { x, y });
      }
    },
    [onMouseEnter, item.position.x, item.position.y]
  );

  const orderElemSpace = item.order ? orderWidth + ITEM_ORDER_SPACE : 0;
  const statusElemSpace = item.status && !item.order ? ITEM_STATUS_WIDTH + ITEM_STATUS_SPACE : 0;

  useLayoutEffect(() => {
    // Set width for counter
    if (orderTextRef.current) {
      const newWidth = Math.ceil(orderTextRef.current.getBBox().width) + ITEM_ORDER_PADDING * 2;

      setOrderWidth(newWidth);
    }

    // Text ellipsis
    if (textRef.current) {
      let newText = item.name;
      let textLength = textRef.current.getComputedTextLength();
      let activateTooltip = false;

      while (textLength > 0 && textLength > columnWidth - orderElemSpace - statusElemSpace) {
        activateTooltip = true;
        newText = newText.slice(0, -1).trim();
        textRef.current.textContent = `${newText}...`;
        textLength = textRef.current.getComputedTextLength();
      }

      setTooltipActive((isActive) => isActive || activateTooltip);
    }
  }, [columnWidth, item.name, orderElemSpace, statusElemSpace]);

  useLayoutEffect(() => {
    // Set connection points
    const startPointID = createStartId(id);
    const startPointPosition = {
      x: item.position.x - ITEM_CONNECTION_SPACE_X,
      y: item.position.y + ITEM_CONNECTION_CENTER_Y,
    };

    const endPointID = createEndId(id);
    const endPointPosition = {
      x:
        item.position.x +
        ITEM_CONNECTION_SPACE_X +
        (textRef?.current?.getBBox().width || 0) +
        statusElemSpace +
        orderElemSpace,
      y: item.position.y + ITEM_CONNECTION_CENTER_Y,
    };

    if (
      !isEqual(startPointPosition, connectionPoints[startPointID]) ||
      !isEqual(endPointPosition, connectionPoints[endPointID])
    ) {
      setConnectionPoints(
        (connectionPoints) =>
          ({
            ...connectionPoints,
            [startPointID]: startPointPosition,
            [endPointID]: endPointPosition,
          }) as Record<string, Position>
      );
    }
  }, [
    connectionPoints,
    item.position.x,
    item.position.y,
    id,
    isParent,
    setConnectionPoints,
    columnWidth,
    item.name,
    statusElemSpace,
    orderElemSpace,
    tooltipActive,
  ]);

  const statusSize = item.status ? ITEM_STATUS_WIDTH + ITEM_STATUS_SPACE : 0;

  const linkAction = item.link
    ? () => {
        navigate(`${item.link?.path}?${newUrlParams}`, { replace: true });
      }
    : undefined;

  const actionProps = isParent
    ? {
        onClick: () => onToggle?.(),
        "aria-label": isOpened ? `Uncollapse ${item.name}` : `Collapse ${item.name}`,
        tabIndex: 0,
        onKeyDown: withEnterKeyPress<SVGGElement>(() => onToggle?.()),
        role: "button",
      }
    : {
        "aria-label": linkAction ? `Navigate to ${item.name}` : undefined,
        role: "button",
        tabIndex: linkAction ? 0 : -1,
        onClick: linkAction,
        onKeyDown: withEnterKeyPress<SVGGElement>(() => linkAction?.()),
      };

  const status = item.status?.toLowerCase() || "";

  return (
    <Group className={styles.textNode} left={item.position.x} top={item.position.y} id={id}>
      <Group
        className={cx(styles.textNode, (isParent || item.link) && styles.textNodeInteractive)}
        {...actionProps}
      >
        {!item.order && item.status && (
          <rect
            className={cx(styles.orderRect, item.status && styles[status])}
            y={1}
            ry={4}
            rx={4}
            width={ITEM_STATUS_WIDTH}
            height={ITEM_STATUS_WIDTH}
            onMouseLeave={onMouseLeave}
            role="figure"
            aria-label={status}
            onMouseEnter={handleMouseEnter}
          />
        )}
        {item.order && (
          <>
            <rect
              className={cx(styles.orderRect, item.status && styles[status])}
              y={-5}
              ry={10}
              rx={10}
              width={orderWidth}
              height={20}
              onMouseLeave={onMouseLeave}
              aria-label={status}
              onMouseEnter={handleMouseEnter}
            />
            <text
              y={-1}
              textAnchor="middle"
              dominantBaseline="hanging"
              x={orderWidth / 2}
              ref={orderTextRef}
              className={cx(styles.orderText, item.status && styles[status])}
            >
              {item.order}
            </text>
          </>
        )}
        <text
          dominantBaseline="hanging"
          textAnchor="start"
          dy={-1}
          dx={item.order ? orderWidth + ITEM_ORDER_SPACE : statusSize}
          className={styles.text}
          ref={textRef}
          aria-label={item.name}
          onMouseLeave={tooltipActive ? onMouseLeave : undefined}
          onMouseEnter={tooltipActive ? handleMouseEnter : undefined}
        >
          {item.name}
        </text>
      </Group>
    </Group>
  );
};

export default TreeChartTextNode;
