import { FiltersProps } from "components/Filters";
import { dropdownEllipsisWidth } from "ds/components/DropdownEllipsis/constants";
import { SearchQueryOrderDirection, Version, VersionState } from "types/generated";
import { Spacing } from "types/Spacing";

export const COLUMN_GAP: Spacing = "x-large";

export const ITEMS_LIMIT = 50;

export const columnTemplate = (columns: Record<string, boolean>) => {
  const columnWidthConfig: Record<string, string> = {
    [versionListViewItemId.VERSION]: "minmax(10rem, 10rem)",
    [versionListViewItemId.NAME]: "minmax(28rem, 1fr)",
    [versionListViewItemId.TESTS]: "6rem",
    [versionListViewItemId.COMMITTED_BY]: "minmax(10rem, 16rem)",
    [versionListViewItemId.TRIGGERED_BY]: "minmax(10rem, 16rem)",
    [versionListViewItemId.COMMIT]: "minmax(10rem, 16rem)",
    [versionListViewItemId.COMMITTED]: "minmax(12rem, 16rem)",
    [versionListViewItemId.CREATED_AT]: "minmax(12rem, 16rem)",
    [versionListViewItemId.CONFIG]: dropdownEllipsisWidth,
  };

  return Object.entries(columns).reduce((acc, [key, value]) => {
    return value ? `${acc} ${columnWidthConfig[key]}` : acc;
  }, "");
};

export const VERSIONS_LIST_VIEW_ITEMS_SETTINGS_KEY = "versionsListViewItemsSettings";
export const FILTERS_ORDER_SETTINGS_KEY = "versionsListFiltersOrder";
export const FILTERS_TYPE = "versions";

export enum versionListViewItemId {
  VERSION = "number",
  NAME = "name",
  TESTS = "tests",
  COMMITTED_BY = "createdBy",
  TRIGGERED_BY = "triggeredBy",
  COMMIT = "commit",
  COMMITTED = "committedAt",
  CONFIG = "config",
  CREATED_AT = "createdAt",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<versionListViewItemId, string>> = {
  [versionListViewItemId.VERSION]: "Version",
  [versionListViewItemId.NAME]: "Name",
  [versionListViewItemId.TESTS]: "Tests",
  [versionListViewItemId.COMMITTED_BY]: "Committed by",
  [versionListViewItemId.TRIGGERED_BY]: "Triggered by",
  [versionListViewItemId.COMMIT]: "Commit",
  [versionListViewItemId.COMMITTED]: "Committed",
  [versionListViewItemId.CREATED_AT]: "Created at",
  [versionListViewItemId.CONFIG]: "Config",
};

export const INITIAL_SORT_OPTION = versionListViewItemId.CREATED_AT;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const INITIAL_VERSIONS_LIST_VIEW_ITEMS = [
  {
    id: versionListViewItemId.VERSION,
    name: "Version",
    selected: true,
    configurable: false,
  },
  {
    id: versionListViewItemId.NAME,
    name: "Name",
    selected: true,
    configurable: false,
  },
  {
    id: versionListViewItemId.TESTS,
    name: "Tests",
    selected: true,
  },
  {
    id: versionListViewItemId.COMMITTED_BY,
    name: "Committed by",
    selected: true,
  },
  {
    id: versionListViewItemId.TRIGGERED_BY,
    name: "Triggered by",
    selected: false,
  },
  {
    id: versionListViewItemId.COMMIT,
    name: "Commit",
    selected: true,
  },
  {
    id: versionListViewItemId.COMMITTED,
    name: "Committed",
    selected: true,
  },
  {
    id: versionListViewItemId.CREATED_AT,
    name: "Created at",
    selected: true,
  },
  {
    id: versionListViewItemId.CONFIG,
    name: "Config",
    selected: true,
    configurable: false,
  },
];

type SortOnlyFilterDefaults = {
  filters: FiltersProps["filters"];
  filtersLoading: FiltersProps["filtersLoading"];
  sortOptions: FiltersProps["sortOptions"];
  filtersItemsOptionsMap: FiltersProps["filtersItemsOptionsMap"];
  setCurrentSavedView: FiltersProps["setCurrentSavedView"];
  pollActiveSections: FiltersProps["pollActiveSections"];
};

export const sortOnlyFilters: SortOnlyFilterDefaults = {
  filters: [],
  filtersLoading: false,
  sortOptions: [],
  filtersItemsOptionsMap: new Map(),
  setCurrentSavedView: () => undefined,
  pollActiveSections: () => undefined,
};

export const canOpenInstructionsDrawer = (version?: Version): version is Version =>
  !!(
    version !== undefined &&
    !version.yanked &&
    version.state === VersionState.Active &&
    version.metadata?.root.inputs
  );

export const EMPTY_COMMIT_MESSAGE_VERSION_NAME = "Empty commit message";
