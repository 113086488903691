import cx from "classnames";
import { Column } from "react-aria-components";
import { useState } from "react";

import Toggle from "ds/components/ToggleNew";
import DropdownNew from "ds/components/Dropdown/New";
import IconActionButton from "ds/components/IconAction/Button";
import { ChevronNew } from "components/icons";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import Box from "ds/components/Box";

import styles from "./styles.module.css";
import useSelectionStore from "./useSelectionStore";

const COLUMN_WIDTH = 70;

const getTooltip = (isSelected: boolean) =>
  isSelected ? "Unselect all items" : "Select all items on this page";

type TableColumnSelectAllProps<SelectAllType = string> = {
  allIds: string[];
  options?: Array<{ id: SelectAllType; title: string }>;
};
const TableColumnSelectAll = ({ allIds, options }: TableColumnSelectAllProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectAll, resetAllSelected, selected, isSelectionOn, setSelectedAllOption] =
    useSelectionStore(({ unselectIds, selected, setSelectedAllOption, addSelectedIds }) => [
      () => addSelectedIds(allIds),
      () => unselectIds(allIds),
      allIds.every((id) => selected.has(id)),
      selected.size > 0 && allIds.some((id) => selected.has(id)),
      setSelectedAllOption,
    ]);

  const isIndeterminate = isSelectionOn && !selected;

  return (
    <Column
      textValue="Select all items"
      id="selection"
      className={cx(styles.checkboxColumn, isSelectionOn && styles.checkboxColumnSticky)}
      maxWidth={COLUMN_WIDTH}
      minWidth={COLUMN_WIDTH}
      width={COLUMN_WIDTH}
    >
      <Box align="center" gap="small">
        <Toggle
          isIndeterminate={isIndeterminate}
          tooltip={getTooltip}
          slot={null}
          variant="checkbox"
          aria-label="Select all items on this page"
          isSelected={selected}
          onChange={() => (selected || isIndeterminate ? resetAllSelected() : selectAll())}
        />
        {options?.length && (
          <DropdownNew
            onOpenChange={setDropdownOpen}
            isOpen={isDropdownOpen}
            triggerComponent={
              <IconActionButton
                aria-label="Open select all menu"
                rotate={isDropdownOpen ? "270" : "90"}
                icon={ChevronNew}
              />
            }
          >
            {({ close }) => (
              <DropdownSection>
                {options.map(({ id, title }) => (
                  <DropdownSectionItem
                    key={id}
                    onClick={() => {
                      close();
                      setSelectedAllOption?.(id);
                    }}
                  >
                    {title}
                  </DropdownSectionItem>
                ))}
              </DropdownSection>
            )}
          </DropdownNew>
        )}
      </Box>
    </Column>
  );
};

export default TableColumnSelectAll;
