import { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { decodeTime } from "ulid";
import kebabCase from "lodash-es/kebabCase";
import cx from "classnames";
import DOMPurify from "dompurify";

import Tooltip from "ds/components/Tooltip";
import { RunCommit, RunCircle, RunUser } from "components/icons";
import FullScreenToggleButton from "views/account/Resources/Chart/FullScreenToggleButton";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import useTypedFlags from "hooks/useTypedFlags";

import {
  bidirectionalDfs,
  getEntitySidebarDetails,
  getIdText,
  getRectPosition,
  groupBy,
  legacyTopologicalSort,
} from "./helpers";
import topologicalSort from "./topologicalSort";
import populateLogicalTimestampsForTimeline from "./populateLogicalTimestampsForTimeline";
import { getKeyValue, getVisibleEntities } from "../helpers";

import "views/account/Resources/Chart/styles.css";
import "./styles.css";

const Chart = (props) => {
  const {
    data,
    filters: parentFilters,
    fullScreen,
    groupByKey: parentGroupByKey,
    handleFilterAdd,
    isAccountWide,
    setEntityDetails,
    setMenuVisible,
    zoomTarget,
    setZoomTarget,
    toggleFullScreen,
    transferVisibleItems,
  } = props;
  const d3Container = useRef(null);
  const d3Wrapper = useRef(null);
  const triggerByLabelsWrapper = useRef(null);
  const [height, setHeight] = useState(500);
  const [width, setWidth] = useState(1000);
  const [texts, setTexts] = useState([]);
  const { useNewTopologicalSortInRunsView } = useTypedFlags();

  const createChart = useCallback(() => {
    const node = d3Container.current;
    let allEntities = data;
    const filters = parentFilters;

    const t = d3
      .select(node)
      .attr("width", width)
      .attr("height", height)
      .transition()
      .duration(1000);

    const lineWidth = 200;
    const rectWidth = 20;
    const rectHeight = 20;
    const rectsInLine = lineWidth / rectWidth;
    let groupByKey = parentGroupByKey;

    // Create the tooltip if it doesn't exist yet. It's invisible initially.
    // The [0] is a placeholder so we create a single one.
    d3.select("body")
      .selectAll(".d3-tooltip")
      .data([0])
      .enter()
      .append("div")
      .attr("class", "d3-tooltip")
      .style("opacity", 0);

    // If we're grouping by the resource parents, then we want to present it in the timeline view.
    let timeline = groupByKey === "trigger";

    let visibleEntities = getVisibleEntities(allEntities, filters);
    let visibleGroupedByKey = groupBy(visibleEntities, groupByKey);

    // *** universal variables ***

    // Position for each resource's rectangle.
    let rectTransforms = {};

    // *** flat layout variables ***

    // Texts on the left side of the canvas, describing group names.
    let flatTexts = [];

    // *** timeline layout variables ***

    // Edges visible on the graph between objects.
    let visibleEdges = [];

    // Visible objects: runs, commits, manual triggers.
    let objects = [];
    let objectsByID = {};

    let maxLogicalTimestamp = 0;

    if (!timeline) {
      // This branch of the if sets up positioning for a flat view, with group names one the left,
      // and the groups being displayed line by line.

      // Each group occupies a number of lines based on the number of rectangles in a single line,
      // and the number of rectangles in the group.
      // This allows us to calculate at which line each of the groups should start at.
      let groupOffset = {};
      let curLine = 1;
      for (let key of Object.keys(visibleGroupedByKey).sort()) {
        groupOffset[key] = curLine * rectHeight;
        curLine += Math.ceil(visibleGroupedByKey[key].length / rectsInLine);
        // We leave a one-line gap between each group.
        curLine++;
      }

      setHeight(curLine * rectHeight < 500 ? 500 : curLine * rectHeight);
      setWidth(750);

      // Each entity will have the index of itself in its group stored here.
      let positionInGroup = {};
      for (let entity of visibleEntities) {
        let keyValue = getKeyValue(groupByKey, entity);
        if ((groupByKey === "stack.id" || groupByKey === "module.id") && keyValue === "none")
          continue;

        for (let i = 0; i < visibleGroupedByKey[keyValue].length; i++) {
          if (visibleGroupedByKey[keyValue][i].id === entity.id) {
            positionInGroup[entity.id] = i;
            break;
          }
        }
      }

      // We could think about fine-tuning the text-width.
      let textWidth = 500;
      for (let entity of visibleEntities) {
        // The transform of a rectangle is:
        //  1. Translation to its offset inside of its group. <- specific to this rectangle
        //  2. Translation to the line its group starts at. <- same for all rectangles in a group
        //  3. Translation to the right, so we leave space for the text. <- same for all rectangles
        let [x1, y1] = getRectPosition(
          positionInGroup[entity.id],
          rectWidth,
          rectHeight,
          lineWidth
        );
        let [x2, y2] = [0, groupOffset[getKeyValue(groupByKey, entity)]];
        let [x3, y3] = [textWidth, 8];

        rectTransforms[entity.id] = { x: x1 + x2 + x3, y: y1 + y2 + y3 };
      }

      // For each group we set the text label for it.
      for (let key of Object.keys(visibleGroupedByKey).sort()) {
        // We use a composite key, so ID's change when changing the grouping.
        // Otherwise update bugs start to appear.
        flatTexts.push({ key: groupByKey + "_" + key, offset: groupOffset[key], text: key });
      }

      // Create one run object for each visible entity.
      for (let run of visibleEntities) {
        if (
          (run.isModule && groupByKey === "stack.id") ||
          (!run.isModule && groupByKey === "module.id")
        )
          continue;
        objects.push({
          id: run.id,
          type: "run",
          run: run,
          name: run.id,
          pathid: run.isModule ? run.module.id : run.stack.id,
        });
      }
      for (let object of objects) {
        objectsByID[object.id] = object;
      }
    } else {
      // Dependency edges, a superset of the visible edges.
      // If a -> b, then that means the logical timestamp of a has to be smaller than the one of b.
      let edges = [];
      let edgesMap = {};

      // We'll have one commit object for each hash.
      let commits = {};

      // And each manual user trigger. Users can be here multiple times,
      // but the strings will be unique, with the triggered run id appended.
      let users = [];

      // For each run we now create an edge and object for its cause.
      for (let run of visibleEntities) {
        let from;
        let to = run.id;
        if (run.triggeredBy === null) {
          // This run was triggered by a pushed commit, so we have to make sure the commit exists,
          // as well as create an edge from the commit to this run.
          commits[run.commit.hash] = run.commit;
          from = run.commit.hash;
        } else if (run.triggeredBy.startsWith("policy/")) {
          // This run was triggered by a trigger policy, so it was actually triggered by a different run finishing.
          // We get the id of this predecessor run and create an edge from that run to this run.
          from = run.triggeredBy.split("/")[2];
        } else if (run.triggeredBy.startsWith("reconciliation/")) {
          // This run was triggered by a drift detection run.
          // We get the id of this predecessor run and create an edge from that run to this run.
          from = run.triggeredBy.split("/")[1];
        } else if (run.triggeredBy.startsWith("dependencies/")) {
          // Ignore it, we handle that a bit later below.
          continue;
        } else {
          // This run was triggered manually by a user. We create an object for this manual trigger and an edge
          // from that trigger to this run.
          users.push(`${run.id}/${run.triggeredBy}`);
          from = `${run.id}/${run.triggeredBy}`;
        }
        edges.push([from, to]);
        if (edgesMap[from]) {
          edgesMap[from].push(to);
        } else {
          edgesMap[from] = [to];
        }
        // Trigger edges are visible.
        visibleEdges.push([from, to]);
      }

      for (let run of visibleEntities.filter((x) => !!x.dependsOn)) {
        for (let dependency of run.dependsOn) {
          edges.push([dependency.runId, run.id]);
          if (edgesMap[dependency.runId]) {
            edgesMap[dependency.runId].push(run.id);
          } else {
            edgesMap[dependency.runId] = [run.id];
          }
          visibleEdges.push([dependency.runId, run.id]);
        }
      }

      if (zoomTarget) {
        // If we have an object marked then we want to filter to only those objects,
        // which are connected by a series of arrows (no matter the direction) to this marked object.
        // That's why we walk the graph of edges using a bidirectional depth-first search,
        // starting at the marked object.
        let visibleObjectIds = new Set();
        bidirectionalDfs(visibleEdges, zoomTarget, (nodeId) => {
          visibleObjectIds.add(nodeId);
        });
        visibleEntities = visibleEntities.filter((run) => visibleObjectIds.has(run.id));
        users = users.filter((user) => visibleObjectIds.has(user));
        commits = Object.keys(commits)
          .filter((commitHash) => visibleObjectIds.has(commitHash))
          .reduce((obj, key) => {
            obj[key] = commits[key];
            return obj;
          }, {});
        edges = edges.filter(
          (edge) => visibleObjectIds.has(edge[0]) && visibleObjectIds.has(edge[1])
        );
        visibleEdges = visibleEdges.filter(
          (edge) => visibleObjectIds.has(edge[0]) && visibleObjectIds.has(edge[1])
        );
      }

      // We now create groups with descriptions.
      // We want one group for commits (if any is visible), one for manual triggers (if any is visible),
      // and one for each stack with visible runs.
      let curOffset = 45;
      let groupOffset = {};
      flatTexts = [];
      if (Object.keys(commits).length > 0) {
        flatTexts.push({ text: "Commits", offset: curOffset });
        groupOffset["Commits"] = curOffset;
        curOffset += 50;
      }
      if (users.length > 0) {
        flatTexts.push({ text: "Users", offset: curOffset });
        groupOffset["Users"] = curOffset;
        curOffset += 50;
      }
      let uniqueStacks = new Set();
      for (let run of visibleEntities) {
        const runId = run.isModule ? run.module.id : run.stack.id;
        uniqueStacks.add(runId);
      }
      for (let stack of Array.from(uniqueStacks).sort()) {
        flatTexts.push({ text: stack, offset: curOffset });
        groupOffset[stack] = curOffset;
        curOffset += 50;
      }

      setTexts(flatTexts);

      let sortedCommits = Object.keys(commits).sort((a, b) =>
        commits[a].timestamp > commits[b].timestamp ? 1 : -1
      );
      let sortedUsers = users.sort();
      // Sorted commits and manual user triggers are "external world events" so to say.
      let sortedCommitsAndUsers = [];
      for (let commit of sortedCommits) {
        sortedCommitsAndUsers.push({ id: commit, t: commits[commit].timestamp });
      }
      for (let user of sortedUsers) {
        sortedCommitsAndUsers.push({ id: user, t: decodeTime(user.split("/")[0]) / 1000 });
      }
      // We sort all external world events by their timestamp.
      sortedCommitsAndUsers.sort((a, b) => (a.t > b.t ? 1 : -1));
      // We create one invisible edge between each two consecutive external world events.
      // This way no manual user trigger and/or commit happen simultaneously on the graph,
      // and get different logical timestamps.
      for (let i = 0; i < sortedCommitsAndUsers.length - 1; i++) {
        const from = sortedCommitsAndUsers[i].id;
        const to = sortedCommitsAndUsers[i + 1].id;
        edges.push([from, to]);
        if (edgesMap[from]) {
          edgesMap[from].push(to);
        } else {
          edgesMap[from] = [to];
        }
      }
      let sortedRunsPerStack = {};
      for (let stack of uniqueStacks) {
        sortedRunsPerStack[stack] = [];
      }
      for (let run of visibleEntities) {
        if (run.stack) {
          sortedRunsPerStack[run.stack.id].push(run.id);
        }
      }
      for (let stack of uniqueStacks) {
        // We also want runs on each stack to be sorted,
        // so we have to add invisible edges between consecutive runs on a single stack.
        const runs = sortedRunsPerStack[stack].sort(); // ULIDs sort as we want them to, by time.
        for (let i = 0; i < runs.length - 1; i++) {
          const from = runs[i];
          const to = runs[i + 1];
          edges.push([from, to]);
          if (edgesMap[from]) {
            edgesMap[from].push(to);
          } else {
            edgesMap[from] = [to];
          }
        }
      }

      // We do a topological sort of the objects, so each object is placed after all it's predecessors from the graph.
      const sortedEdges = useNewTopologicalSortInRunsView
        ? topologicalSort(edges)
        : legacyTopologicalSort(edges);

      const initialLogicalTimestamps = {};

      for (const commit of sortedCommits) {
        initialLogicalTimestamps[commit] = 0;
      }
      for (const user of sortedUsers) {
        initialLogicalTimestamps[user] = 0;
      }
      for (const run of visibleEntities) {
        initialLogicalTimestamps[run.id] = 0;
      }

      // Now we iterate over the sorted list of nodes
      // (this way, whenever we look at an object, all its predecessors have already been looked at)
      // and for each successor we set their logical timestamp to "my own logical timestamp" + 1.
      // This way we get logical timestamps where a successor always has a larger one than its predecessor.
      const logicalTimestamps = populateLogicalTimestampsForTimeline(
        initialLogicalTimestamps,
        sortedEdges,
        edgesMap
      );

      // Save the max logical timestamp
      maxLogicalTimestamp = Math.max(...Object.values(logicalTimestamps));

      // We now store all commits, manual user triggers and runs as self-contained objects,
      // with all information needed to display them and show their details on the infobar.
      for (let commit of sortedCommits) {
        objects.push({
          id: commit,
          type: "commit",
          timestamp: logicalTimestamps[commit],
          commit: commits[commit],
          name: DOMPurify.sanitize(commits[commit].message),
          pathid: "Commits", // Timeline ID.
        });
      }
      for (let user of sortedUsers) {
        objects.push({
          id: user,
          type: "user",
          timestamp: logicalTimestamps[user],
          name: user.split("/").slice(1).join("/"),
          pathid: "Users", // Timeline ID.
        });
      }
      for (let run of visibleEntities) {
        objects.push({
          id: run.id,
          type: "run",
          timestamp: logicalTimestamps[run.id],
          run: run,
          name: run.id,
          pathid: run.isModule ? run.module.id : run.stack.id, // Timeline ID.
        });
      }
      for (let object of objects) {
        objectsByID[object.id] = object;
      }

      // Set the global transform for each rectangle.
      for (let object of objects) {
        rectTransforms[object.id] = {
          // The timelines will be (maxLogicalTimestamp + 2) * 100 long.
          x: 300 + (maxLogicalTimestamp - logicalTimestamps[object.id]) * 100 + 100,
          y: groupOffset[object.pathid] + 15,
        };
      }

      // Adjust the viewport size. It needs to be able to contain all the timelines and all groups.
      setHeight(((h) => (h < 500 ? 500 : h))(curOffset));
      setWidth(((w) => (w < 1000 ? 1000 : w))(300 + (maxLogicalTimestamp + 2) * 100));
    }

    // All the rendering has to happen regardless of layout, otherwise objects aren't deleted properly.
    // (i.e. tree links would stay when changing grouping, so you have to try to render tree links,
    // which sees there are none in the data, and deletes any existing ones)

    // *** universal
    transferVisibleItems(visibleEntities);

    // Render the group names.
    d3.select(node)
      .selectAll("text.d3-label")
      .data(flatTexts, (d) => d.text)
      .join(
        (enter) => {
          return (
            enter
              .append("text")
              .attr("class", "d3-label")
              .attr("font-size", "0.8em")
              // Only show 45 character long group names.
              .html((d) => (d.text.length <= 45 ? d.text : d.text.substring(0, 44) + "…"))
              // Move the text label to the proper line.
              .attr("transform", (d) => `translate(0 ${d.offset})`)
              .attr("x", 0)
              .attr("y", rectHeight * 0.8)
              .attr("opacity", 0)
              .on("dblclick", (event, entity) => {
                event.preventDefault();
                handleFilterAdd(groupByKey, entity.text);
              })
              .on("mouseover", function (event) {
                let tooltip = d3.select("body").selectAll(".d3-tooltip");

                // Show the tooltip when an entity is hovered
                tooltip.transition().duration(200).style("opacity", 0.9);
                tooltip
                  .html(`Double click to set as filter`)
                  .style("left", event.pageX + "px")
                  .style("top", event.pageY + "px");
              })
              .on("mouseout", function () {
                let tooltip = d3.select("body").selectAll(".d3-tooltip");

                tooltip.transition().duration(100).style("opacity", 0);
              })
          );
        },
        (update) =>
          update.on("dblclick", (event, entity) => {
            event.preventDefault();
            handleFilterAdd(groupByKey, entity.text);
          }),
        (exit) => {
          exit.transition().duration(500).attr("opacity", 0).remove();
        }
      )
      .transition(t)
      .attr("transform", (d) => `translate(0 ${d.offset})`)
      .attr("opacity", 1);

    // Create container for timelines.
    d3.select(node)
      .selectAll("g.timeline-paths")
      .data(["timeline-paths"])
      .enter()
      .append("g")
      .attr("id", "timeline-paths")
      .attr("class", "timeline-paths");

    const timelineLegend = d3
      .select("#timeline-paths")
      .selectAll("g.run-timeline-helper")
      .data(timeline ? ["timeline-helper"] : [])
      .enter()
      .append("g")
      .attr("id", "timeline-helper")
      .attr("class", "run-timeline-helper")
      .attr("transform", "translate(315 18)");

    timelineLegend
      .append("text")
      .html("Most recent")
      .attr("class", "run-timeline-helper-text")
      .attr("transform", "translate(40 -5)");

    timelineLegend
      .append("path")
      .attr("d", `M0,0 H150`)
      .attr("fill", "none")
      .attr("stroke", "var(--color-default-primary)")
      .attr("stroke-opacity", 0.25)
      .attr("stroke-width", 1.5);

    timelineLegend
      .append("path")
      .attr("d", `M0,0 L5,2.5`)
      .attr("fill", "none")
      .attr("stroke", "var(--color-default-primary)")
      .attr("stroke-opacity", 0.25)
      .attr("stroke-width", 1.1);

    timelineLegend
      .append("path")
      .attr("d", `M0,0 L5,-2.5`)
      .attr("fill", "none")
      .attr("stroke", "var(--color-default-primary)")
      .attr("stroke-opacity", 0.25)
      .attr("stroke-width", 1.1);

    // Create timelines.
    const timelinePaths = d3
      .select("#timeline-paths")
      .selectAll("g.d3-run-timeline")
      .data(timeline ? flatTexts : [], (d) => d.text)
      .join(
        (enter) => {
          let g = enter
            .append("g")
            .attr("class", "d3-run-timeline")
            .attr("id", (d) => d.text)
            .attr("opacity", 0)
            .attr("transform", (d) => `translate(315 ${d.offset + 15})`);

          g.append("path")
            .attr("id", "main")
            .attr("d", `M0,0 H${(maxLogicalTimestamp + 2) * 100}`)
            .attr("fill", "none")
            .attr("stroke", "var(--color-default-primary)")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 1.5);
          g.append("path")
            .attr("d", `M0,0 L5,2.5`)
            .attr("fill", "none")
            .attr("stroke", "var(--color-default-primary)")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 1.1);
          g.append("path")
            .attr("d", `M0,0 L5,-2.5`)
            .attr("fill", "none")
            .attr("stroke", "var(--color-default-primary)")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 1.1);

          return g;
        },
        (update) => {
          return update;
        },
        (exit) => {
          exit.transition().duration(500).attr("opacity", 0).remove();
        }
      );

    // We have to adjust the vertical position of each timeline on each render.
    timelinePaths
      .transition(t)
      .attr("opacity", 1)
      .attr("transform", (d) => `translate(315 ${d.offset + 15})`);
    // We have to adjust the length of each timeline on each render.
    timelinePaths
      .select("#main")
      .transition(t)
      .attr("d", `M0,0 H${(maxLogicalTimestamp + 2) * 100}`);

    // Create container for rectangles.
    d3.select(node)
      .selectAll("g.rectangles")
      .data(["rectangles"])
      .enter()
      .append("g")
      .attr("id", "rectangles")
      .attr("class", "rectangles");

    // Create the rectangles (which are actually circles visually).
    d3.select("#rectangles")
      .selectAll("g")
      .data(objects, (d) => {
        if (!d) {
          return undefined;
        }

        return d.id;
      })
      .join(
        (enter) => {
          let out = enter
            .append("g")
            .attr("opacity", 0)
            .attr("transform", (d) => {
              return `translate(${rectTransforms[d.id].x - rectWidth / 2},${
                rectTransforms[d.id].y - rectHeight / 2
              })`;
            })
            .on("click", function (event, entity) {
              setZoomTarget(entity.id);
              setEntityDetails(getEntitySidebarDetails(entity));
              setMenuVisible(true);
              d3.selectAll(`.d3-run-entity-selected`).classed("d3-run-entity-selected", false);
              d3.select(`[id="${getIdText(entity.id)}"]`).classed("d3-run-entity-selected", true);
            })
            .on("mouseover", function (event, entity) {
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              // Show the tooltip when an entity is hovered
              tooltip.transition().duration(200).style("opacity", 0.9);
              tooltip
                .html(`${entity.name}${entity.type === "run" ? ` - ${entity.run.state}` : ""}`)
                .style("left", event.pageX + "px")
                .style("top", event.pageY + "px");
            })
            .on("mouseout", function () {
              let tooltip = d3.select("body").selectAll(".d3-tooltip");

              tooltip.transition().duration(100).style("opacity", 0);
            });

          out
            .append("rect")
            .attr("id", (d) => getIdText(d.id))
            .attr("stroke-width", 2)
            .attr("x", 0)
            .attr("y", 0)
            .attr("height", rectHeight)
            .attr("width", rectWidth)
            .attr(
              "class",
              (d) =>
                `d3-run-entity-${d.type} ${
                  d.type === "run" ? `d3-run-entity-run-state--${kebabCase(d.run.state)}` : ""
                }`
            )
            .attr("rx", 25)
            .attr("ry", 25);

          out
            .append("path")
            .attr("class", "d3-run-entity-commit-icon")
            .attr("opacity", (d) => (d.type === "commit" ? 1 : 0))
            .attr("transform", "scale(1) translate(-2.25, -2.5) rotate(45 12.5 12.51)")
            .attr("stroke-width", 1)
            .attr(
              "d",
              "M13,9.03544443 C14.6961471,9.27805926 16,10.736764 16,12.5 C16,14.263236 14.6961471,15.7219407 13,15.9645556 L13,21.5207973 C13,21.7969397 12.7761424,22.0207973 12.5,22.0207973 C12.2238576,22.0207973 12,21.7969397 12,21.5207973 L12,15.9645556 C10.3038529,15.7219407 9,14.263236 9,12.5 C9,10.736764 10.3038529,9.27805926 12,9.03544443 L12,3.5 C12,3.22385763 12.2238576,3 12.5,3 C12.7761424,3 13,3.22385763 13,3.5 L13,9.03544443 L13,9.03544443 Z M12.5,15 C13.8807119,15 15,13.8807119 15,12.5 C15,11.1192881 13.8807119,10 12.5,10 C11.1192881,10 10,11.1192881 10,12.5 C10,13.8807119 11.1192881,15 12.5,15 Z"
            );

          out
            .append("path")
            .attr("class", "d3-run-entity-commit-icon")
            .attr("opacity", (d) => (d.type === "user" ? 1 : 0))
            .attr("transform", "scale(0.75) translate(-2.75, -2.5)")
            .attr("stroke-width", 0.5)
            .attr("d", "M16,17a5,5,0,1,1,5-5A5,5,0,0,1,16,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,16,9Z");

          out
            .append("path")
            .attr("class", "d3-run-entity-commit-icon")
            .attr("opacity", (d) => (d.type === "user" ? 1 : 0))
            .attr("transform", "scale(0.75) translate(-2.75, -2.5)")
            .attr("stroke-width", 0.5)
            .attr(
              "d",
              "m 24.612069,24.882758 c -0.280642,0.0019 -0.549165,-0.114219 -0.74,-0.32 C 21.946793,22.111648 19.635583,20.004541 16.46,20 h -0.92 c -2.927794,-0.0063 -5.467271,2.671817 -7.3534483,4.870345 -0.9547769,0.787842 -2.2077402,-0.519597 -1.38,-1.44 C 8.8962225,20.871772 12.096654,17.992591 15.54,18 h 0.92 c 3.732357,0.0084 6.740088,2.457066 8.882069,5.202758 0.592522,0.638879 0.141344,1.677205 -0.73,1.68 z"
            );

          // if we ever try to have icons again
          // out.append("use")
          //   .attr("xlink:xlink:href",d => d.type === "run" ? `#${kebabCase(d.run.state)}` : "")
          //   .attr("opacity", d => d.type === "run" ? 1 : 0)
          //   .attr("transform", "scale(0.8) translate(6, 5)")

          return out;
        },
        (update) => {
          return update;
        },
        (exit) => {
          exit.transition().duration(500).attr("opacity", 0).remove();
        }
      )
      .transition(t)
      .attr("opacity", 1)
      .attr("transform", (d) => {
        return `translate(${rectTransforms[d.id].x - rectWidth / 2},${
          rectTransforms[d.id].y - rectHeight / 2
        })`;
      });

    // This is a link path creator. It basically creates the curves between objects.
    let treeLink = d3
      .linkHorizontal()
      .x((d) => rectTransforms[d.id].x)
      .y((d) => rectTransforms[d.id].y);

    // Create container for links.
    d3.select(node)
      .selectAll("g.links")
      .data(["links"])
      .enter()
      .append("g")
      .attr("id", "links")
      .attr("class", "links");

    // Create the links between objects.
    let link = d3
      .select("#links")
      .selectAll("g.d3-run-timeline-link")
      .data(
        visibleEdges
          .map((d) => ({ source: objectsByID[d[0]], target: objectsByID[d[1]] }))
          .filter((d) => d.target.type === "run")
          .filter((d) => d.source && d.target),
        (d) => {
          if (!d) {
            return undefined;
          }

          return d.source.id + "$" + d.target.id;
        }
      )
      .join(
        (enter) => {
          let g = enter.append("g").attr("class", "d3-run-timeline-link").attr("opacity", 0);

          // This is an alternative approach to arrow heads.
          // const arrowPoints = [[0, 0], [0, 20], [20, 10]];
          // g.append('defs')
          //   .append('marker')
          //   .attr('id', 'arrow')
          //   .attr('viewBox', [0, 0, 20, 20])
          //   .attr('refX', 20)
          //   .attr('refY', 10)
          //   .attr('markerWidth', 3.5)
          //   .attr('markerHeight', 3.5)
          //   .attr('orient', 'auto')
          //   .append('path')
          //   .attr('d', d3.line()(arrowPoints))
          //   .attr("stroke-opacity", d => d.source.type === "run" ? 0.8 : 0.3)
          //   .attr("opacity", d => d.source.type === "run" ? 0.8 : 0.3)
          //   .attr("class", d => `d3-hierarchical-link-${d.source.type}`);

          g.append("path")
            .attr("id", "link")
            .attr("fill", "none")
            .attr("stroke-opacity", (d) => (d.source.type === "run" ? 0.8 : 0.3))
            .attr("stroke-width", 1.5)
            .attr("d", treeLink)
            // .attr("transform", `${hierarchicalGlobalTransform}`)
            .attr("marker-end", "url(#arrow)")
            .attr("class", (d) => `d3-run-hierarchical-link-${d.source.type}`);

          g.append("path")
            .attr("id", "arrow1")
            .attr("fill", "none")
            .attr("stroke-opacity", 0.7)
            .attr("stroke-width", 1.5)
            .attr(
              "d",
              (d) =>
                `M${rectTransforms[d.target.id].x},${rectTransforms[d.target.id].y} L${
                  rectTransforms[d.target.id].x + 5
                },${rectTransforms[d.target.id].y + 2.5}`
            )
            .attr("class", (d) => `d3-run-hierarchical-link-${d.source.type}`);

          g.append("path")
            .attr("id", "arrow2")
            .attr("fill", "none")
            .attr("stroke-opacity", 0.7)
            .attr("stroke-width", 1.5)
            .attr(
              "d",
              (d) =>
                `M${rectTransforms[d.target.id].x},${rectTransforms[d.target.id].y} L${
                  rectTransforms[d.target.id].x + 5
                },${rectTransforms[d.target.id].y - 2.5}`
            )
            .attr("class", (d) => `d3-run-hierarchical-link-${d.source.type}`);

          return g;
        },
        (update) => {
          return update;
        },
        (exit) => {
          exit.transition().duration(500).attr("opacity", 0).remove();
        }
      );

    link.transition(t).attr("opacity", 1);

    // We always have to adjust the positioning of links, as the rectangles could have moved.
    link.select("#link").transition(t).attr("d", treeLink);
    link
      .select("#arrow1")
      .transition(t)
      .attr(
        "d",
        (d) =>
          `M${rectTransforms[d.target.id].x},${rectTransforms[d.target.id].y} L${
            rectTransforms[d.target.id].x + 5
          },${rectTransforms[d.target.id].y + 2.5}`
      );
    link
      .select("#arrow2")
      .transition(t)
      .attr(
        "d",
        (d) =>
          `M${rectTransforms[d.target.id].x},${rectTransforms[d.target.id].y} L${
            rectTransforms[d.target.id].x + 5
          },${rectTransforms[d.target.id].y - 2.5}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    handleFilterAdd,
    height,
    parentFilters,
    parentGroupByKey,
    setEntityDetails,
    setMenuVisible,
    zoomTarget,
    setZoomTarget,
    width,
  ]);

  useEffect(() => {
    createChart();
  }, [createChart]);

  useEffect(() => {
    if (parentGroupByKey !== "parent") {
      const wrapper = d3Wrapper.current;
      wrapper.scrollTop = 0;
    } else {
      const container = d3Container.current;
      const wrapper = d3Wrapper.current;
      const rootNode = container.querySelector(".node--root");
      if (rootNode) {
        const { top } = rootNode.getBoundingClientRect();

        wrapper.scrollTop = top - 400;
      }
    }
  }, [height, parentGroupByKey]);

  useEffect(() => {
    const wrapper = d3Wrapper.current;
    const labelsWrapper = triggerByLabelsWrapper.current;
    if (!labelsWrapper) return;

    const eventListener = () => {
      labelsWrapper.scrollTop = wrapper.scrollTop;
    };

    wrapper.addEventListener("scroll", eventListener);

    return () => {
      wrapper.removeEventListener("scroll", eventListener);
    };
  });

  useEscapeKeypress(fullScreen, toggleFullScreen);

  const isGroupedByTrigger = parentGroupByKey === "trigger";

  const d3ContainerClass = cx("d3-container", {
    "d3-container--account": isAccountWide,
    "d3-container--full": fullScreen,
    "d3-container--run": isGroupedByTrigger,
  });

  const triggerByTextClass = cx("run-resources-names", {
    "run-resources-names--full": fullScreen,
  });

  return (
    <div className={"d3-chart-container"}>
      <div className="resources-legend">
        <RunCommit className="resources-legend__icon" />
        Commit
        <RunUser className="resources-legend__icon" />
        User
        <RunCircle className="resources-legend__icon" />
        Run
      </div>
      <FullScreenToggleButton fullScreen={fullScreen} toggleFullScreen={toggleFullScreen} />
      {isGroupedByTrigger && (
        <div ref={triggerByLabelsWrapper} className={triggerByTextClass}>
          <div className="run-resources-names__container">
            {texts.map((text) => (
              <div key={`${text.text}-${text.offset}`}>
                {text.text.length > 39 ? (
                  <Tooltip
                    on={(props) => (
                      <span {...props} className="run-resources-name__tooltip-wrapper">
                        <span className="run-resources-names__item">{text.text}</span>
                      </span>
                    )}
                  >
                    {text.text}
                  </Tooltip>
                ) : (
                  <span className="run-resources-names__item">{text.text}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div ref={d3Wrapper} className={d3ContainerClass}>
        <svg ref={d3Container} className={"d3-run-svg"}></svg>
      </div>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array,
  groupByKey: PropTypes.string.isRequired,
  handleFilterAdd: PropTypes.func,
  setEntityDetails: PropTypes.func.isRequired,
  setMenuVisible: PropTypes.func.isRequired,
  transferVisibleItems: PropTypes.func.isRequired,
  isAccountWide: PropTypes.bool,
  zoomTarget: PropTypes.string,
  setZoomTarget: PropTypes.func,
  fullScreen: PropTypes.bool,
  toggleFullScreen: PropTypes.func,
};

export default Chart;
