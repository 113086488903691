import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type WorkerPoolResetActionProps = {
  onClick: () => void;
  hasBusyWorkers: boolean;
};

const hasBusyWorkersTooltip = (
  <>
    Please drain all workers first to make sure that no work is being performed by this worker pool.
  </>
);

const WorkerPoolResetAction = ({ onClick, hasBusyWorkers }: WorkerPoolResetActionProps) => (
  <DropdownSectionItem
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    tooltip={
      <>
        {hasBusyWorkers && (
          <>
            {hasBusyWorkersTooltip}
            <br />
            <br />
          </>
        )}
        Resetting this worker pool means that existing workers can no longer be used and will need
        to be restarted with the new configuration.
      </>
    }
    onClick={onClick}
    disabled={hasBusyWorkers}
  >
    Reset
  </DropdownSectionItem>
);

export default WorkerPoolResetAction;
