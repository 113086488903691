import cx from "classnames";
import { forwardRef, ReactNode } from "react";

import { IconComponent } from "types/Icon";

import BaseActionButton, { BaseActionButtonProps } from "../BaseAction/Button";
import Icon, { type IconProps } from "../Icon";
import styles from "./styles.module.css";
import { ICON_ACTION_TEST_ID } from "./constants";
import Tooltip from "../Tooltip";

export type IconActionButtonProps = BaseActionButtonProps & {
  icon: IconComponent;
  disableTooltipPortal?: boolean;
  isTooltipActive?: boolean;
  isActive?: boolean;
  tooltipDelay?: number;
  tooltip?: ReactNode;
} & Pick<IconProps, "color" | "rotate">;

const IconActionButton = forwardRef(function IconAction(
  props: IconActionButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    icon,
    className,
    rotate,
    color,
    tooltip,
    isTooltipActive = true,
    disableTooltipPortal,
    tooltipDelay,
    isActive,
    ...rest
  } = props;

  const commonProps = {
    "data-testid": ICON_ACTION_TEST_ID,
    className: cx(styles.iconAction, { [styles.active]: isActive }, className),
    ...rest,
  };

  const iconComponent = <Icon src={icon} rotate={rotate} color={color} />;

  if (tooltip) {
    return (
      <Tooltip
        disablePortal={disableTooltipPortal}
        ref={ref}
        delay={tooltipDelay}
        on={(tooltipProps) => (
          <BaseActionButton
            aria-label={typeof tooltip === "string" ? tooltip : undefined}
            {...tooltipProps}
            {...commonProps}
          >
            {iconComponent}
          </BaseActionButton>
        )}
        active={isTooltipActive}
        disableAriaLabel={!!rest["aria-label"]}
      >
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <BaseActionButton {...commonProps} ref={ref}>
      {iconComponent}
    </BaseActionButton>
  );
});

export default IconActionButton;
