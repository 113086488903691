import { ReactNode, useCallback, useState } from "react";
import { PopoverProps } from "react-aria-components";

import { Dots } from "components/icons";

import IconActionButton from "../IconAction/Button";
import DropdownNew from "../Dropdown/New";

type DropdownEllipsisIconProps = {
  className?: string;
  triggerClassName?: string;
  children: ({ close }: { close: () => void }) => ReactNode;
  placement?: PopoverProps["placement"];
  disabled?: boolean;
  tooltip?: string;
  onOpenChange?: (isOpen: boolean) => void;
};

const DropdownEllipsisIcon = ({
  className,
  triggerClassName,
  children,
  placement,
  disabled,
  tooltip = "Show more actions",
  onOpenChange,
}: DropdownEllipsisIconProps) => {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsButtonActive(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  return (
    <DropdownNew
      className={className}
      onOpenChange={handleOpenChange}
      placement={placement}
      triggerComponent={
        <IconActionButton
          className={triggerClassName}
          tooltip={tooltip}
          disabled={disabled}
          icon={Dots}
          isActive={isButtonActive}
        />
      }
    >
      {children}
    </DropdownNew>
  );
};

export default DropdownEllipsisIcon;
