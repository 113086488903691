import { useQuery } from "@apollo/client";
import useLocalStorage from "@rehooks/local-storage";
import { useMemo } from "react";

import ConfigManagementTreeGrid from "views/account/ConfigManagement/TreeGrid";
import PageInfo from "components/PageWrapper/Info";
import { AnsibleHost } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import PageLoading from "components/loading/PageLoading";
import { LayoutMode } from "views/account/ConfigManagement/TreeGrid/types";
import { NoResultsColored } from "components/icons";
import SegmentedControl from "ds/components/SegmentedControl";
import FlashContext from "components/FlashMessages/FlashContext";
import useTitle from "hooks/useTitle";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import useErrorHandle from "hooks/useErrorHandle";
import ConfigManagementEmptyState from "views/account/ConfigManagement/EmptyState";
import { URL_SEARCH_KEY } from "constants/url_query_keys";
import useURLParams from "hooks/useURLParams";
import { decodeURIParam } from "utils/urls";
import EmptyState from "ds/components/EmptyState";
import { LAYOUT_MODE_OPTIONS } from "views/account/ConfigManagement/constants";
import SearchInput from "components/SearchInput";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import {
  DEFAULT_LAYOUT_MODE,
  FILTERS_ORDER_SETTINGS_KEY,
  LAYOUT_MODE_LOCAL_STORAGE_KEY,
} from "./constants";
import { getStacksBackUrl } from "../helpers";
import styles from "./styles.module.css";
import StackHeader from "../components/Header";
import { GET_STACK_ANSIBLE_HOSTS } from "./gql";
import { StackContext } from "../Context";

const StackConfigManagement = () => {
  const urlParams = useURLParams();
  const searchQuery = decodeURIParam(urlParams.get(URL_SEARCH_KEY));
  const [layoutMode, setLayoutMode] = useLocalStorage<LayoutMode>(
    LAYOUT_MODE_LOCAL_STORAGE_KEY,
    DEFAULT_LAYOUT_MODE
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageStack.StacksConfigurationManagement,
    callbackTrackProviders: { segment: true },
  });

  const { onError } = useTypedContext(FlashContext);
  const { stack } = useTypedContext(StackContext);
  const { data, loading, error } = useQuery<{ ansibleHosts: AnsibleHost[] }>(
    GET_STACK_ANSIBLE_HOSTS,
    {
      onError,
      variables: { input: { stack: stack.id } },
    }
  );

  const filteredAnsibleHosts = useMemo(() => {
    const ansibleHosts = data?.ansibleHosts || [];
    const searchValue = searchQuery?.trim();
    const filteredAnsibleHosts = searchValue
      ? ansibleHosts.filter((host) => host.name.includes(searchValue))
      : ansibleHosts;
    return filteredAnsibleHosts;
  }, [data?.ansibleHosts, searchQuery]);

  useTitle(`Configuration Management · ${stack.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stack.name,
    },
  ]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // TODO: skeleton
  if (loading && !data?.ansibleHosts) {
    return (
      <>
        <StackHeader />
        <PageInfo title="Configuration Management" />
        <PageLoading />
      </>
    );
  }

  const hasNoFilteringResults = searchQuery && !filteredAnsibleHosts.length;
  const isPageEmpty = !searchQuery && !filteredAnsibleHosts.length;
  const hasAnsibleHosts = !!filteredAnsibleHosts?.length;

  return (
    <>
      <StackHeader />
      <PageInfo title="Configuration Management">
        <SearchInput
          placeholder="Search by host name"
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
        <SegmentedControl
          optionClassName={styles.layoutSwitchOption}
          onChange={(e) => {
            trackSegmentAnalyticsEvent?.("View Changed", { type: e.target.value });
            setLayoutMode(e.target.value as LayoutMode);
          }}
          value={layoutMode}
          options={LAYOUT_MODE_OPTIONS}
        />
      </PageInfo>

      {hasNoFilteringResults && (
        <EmptyState title="No results" caption="Try different filters." icon={NoResultsColored} />
      )}

      {isPageEmpty && <ConfigManagementEmptyState />}

      {hasAnsibleHosts && (
        <ConfigManagementTreeGrid
          chartClassName={styles.chart}
          layoutMode={layoutMode}
          ansibleHosts={filteredAnsibleHosts}
          analyticsPage={AnalyticsPageStack.StacksConfigurationManagement}
        />
      )}
    </>
  );
};

export default StackConfigManagement;
