import { EntityAttributeChangeForPhase, EntityChangeType } from "types/generated";

import { EncodedJsonValue, JsonLocation, RunChangesValueV3, RunChangeValueV3 } from "./types";

type ChangeBaseValue = string | number | boolean | undefined;

type ChangeItem = {
  name: string;
  value: ChangeType;
};

type ChangeType = ChangeBaseValue | AfterBeforeChange;

type ResourceItem = {
  [key: string]: ChangeType | ResourceItem;
};

type AfterBeforeChange = {
  after: ChangeBaseValue;
  before: ChangeBaseValue;
};

const getChangeValue = (change: RunChangesValueV3) => {
  switch (change.__typename) {
    case RunChangeValueV3.ChangeValueFloat:
      return change.floatVal;

    case RunChangeValueV3.ChangeValueBoolean:
      return change.boolVal;

    case RunChangeValueV3.ChangeValueString:
      return change.stringVal;

    default:
      return change.stringVal;
  }
};

export const generateEntityChangeValue = (
  changeType: EntityChangeType,
  change: EntityAttributeChangeForPhase
) => {
  switch (changeType) {
    case EntityChangeType.Add:
      return getChangeValue(change.after as RunChangesValueV3);

    case EntityChangeType.Change:
      return {
        before: getChangeValue(change.before as RunChangesValueV3),
        after: getChangeValue(change.after as RunChangesValueV3),
      };

    case EntityChangeType.Forget:
    case EntityChangeType.Delete:
      return getChangeValue(change.before as RunChangesValueV3);

    default:
      return getChangeValue(change.after as RunChangesValueV3);
  }
};

export const splitPathByDot = (path: string) => {
  const parts = path.split(".");
  const result = [];

  // We start with regular loop over split parts
  for (let i = 0; i < parts.length; i++) {
    let part = parts[i];

    // If part ends with a backslash, we know there was an escaped dot between this and the next part
    // Repeat until we iterate over all parts that were part of the same path
    while (part && part.endsWith("\\")) {
      // We move to the next part that was part of the same path...
      i++;

      // ... and append it to the current part with a dot between them
      part += "." + parts[i];
    }

    // We push the part to the result array and replace all escaped dots with regular ones
    result.push(part.replace(/\\./g, "."));
  }

  return result;
};

export const generateNestedObjectBasedOnJsonPath = (changes: ChangeItem[]) => {
  const resourcesObject: ResourceItem = {};
  for (const { name, value } of changes) {
    let currentResource = resourcesObject;

    const path = splitPathByDot(name);
    const lastKey = path.pop()!;
    for (const k of path) {
      if (k.length) {
        if (!(k in currentResource)) currentResource[k] = {};
        currentResource = currentResource[k] as ResourceItem;
      }
    }
    currentResource[lastKey] = value;
  }

  return resourcesObject;
};

const generateChangePrefix = (changeType: EntityChangeType) => {
  switch (changeType) {
    case EntityChangeType.Change:
      return "~";
    case EntityChangeType.Delete:
    case EntityChangeType.Forget:
      return "-";
    case EntityChangeType.Add:
    default:
      return "+";
  }
};

type Item = {
  json: boolean;
  value: ChangeBaseValue;
};

export const processValue = (value: ChangeBaseValue): Item => {
  if (typeof value === "string") {
    const nonJsonValue = { json: false, value: `"${value}"` };

    try {
      const parsedValue = JSON.parse(value);

      if (typeof parsedValue === "object" && parsedValue !== null) {
        return { json: true, value };
      }

      return nonJsonValue;
    } catch {
      return nonJsonValue;
    }
  }

  return { json: false, value };
};

export const prettyPrintResourcesObjectV2 = (
  resources: ResourceItem,
  changeType: EntityChangeType,
  jsonLocations: JsonLocation[],
  indentation = " ",
  row = 1
) => {
  let result = "";
  let maxKeyLength = 0;
  const changePrefix = generateChangePrefix(changeType);

  for (const key in resources) {
    if (key.length > maxKeyLength) {
      maxKeyLength = key.length;
    }
  }

  for (const key in resources) {
    if (
      typeof resources[key] === "object" &&
      !Object.prototype.hasOwnProperty.call(resources[key], "after")
    ) {
      const prefix = `${indentation}${changePrefix} ${key}: {\n`;
      const suffix = `${indentation}}\n`;

      const value = prettyPrintResourcesObjectV2(
        resources[key] as ResourceItem,
        changeType,
        jsonLocations,
        indentation + "  ",
        row + 1
      );

      const combined = `${prefix}${value}${suffix}`;

      row += combined.split("\n").length - 1;
      result += combined;
    } else {
      const prefix = `${indentation}${changePrefix} ${key ? `${key}: ` : ""}${" ".repeat(
        maxKeyLength - key.length
      )}`;

      const value = resources[key] as ChangeType;

      let processedValue;
      if (typeof value === "object") {
        const before = processValue(value.before);
        const after = processValue(value.after);

        processedValue = `${before.value} -> ${after.value}`;

        if (before.json || after.json) {
          jsonLocations.push({
            row,
            value: [before.value, after.value],
          });
        }
      } else {
        const result = processValue(value);

        if (result.json) {
          jsonLocations.push({
            row,
            value: result.value,
          });
        }

        processedValue = result.value;
      }

      result += `${prefix}${processedValue}\n`;
      row += 1;
    }
  }

  return result;
};

const renderItem = (value: ChangeBaseValue) => {
  if (typeof value === "string") {
    return `"${value}"`;
  }

  return value;
};

const printValue = (item: ChangeType) => {
  if (typeof item === "object") {
    return `${renderItem(item.before)} -> ${renderItem(item.after)}`;
  }
  return renderItem(item);
};

export const prettyPrintResourcesObject = (
  resources: ResourceItem,
  prefix: string,
  changeType: EntityChangeType
) => {
  let result = "";
  let maxKeyLength = 0;
  const changePrefix = generateChangePrefix(changeType);

  for (const key in resources) {
    if (key.length > maxKeyLength) {
      maxKeyLength = key.length;
    }
  }
  for (const key in resources) {
    if (
      typeof resources[key] === "object" &&
      !Object.prototype.hasOwnProperty.call(resources[key], "after")
    ) {
      result +=
        prefix +
        `${changePrefix} ${key}` +
        ": {" +
        "\n" +
        prettyPrintResourcesObject(
          resources[key] as unknown as ResourceItem,
          prefix + "  ",
          changeType
        ) +
        prefix +
        "}\n";
    } else {
      result +=
        prefix +
        `${changePrefix} ${key}` +
        ": " +
        " ".repeat(maxKeyLength - key.length) +
        `${printValue(resources[key] as ChangeType)}` +
        "\n";
    }
  }
  return result;
};

export const getPrettyPrintedResources = (
  resources: ResourceItem,
  changeType: EntityChangeType,
  isV2 = false
) => {
  const jsonLocations: JsonLocation[] = [];

  const result = isV2
    ? prettyPrintResourcesObjectV2(resources, changeType, jsonLocations)
    : prettyPrintResourcesObject(resources, " ", changeType);

  return {
    result,
    jsonLocations,
  };
};

export function prettify(value: EncodedJsonValue) {
  if (typeof value !== "string") {
    return String(value);
  }

  try {
    return JSON.stringify(JSON.parse(value), null, 2);
  } catch {
    return value;
  }
}
