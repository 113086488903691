import { ReactNode } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import Box from "ds/components/Box";
import DraggableItemHandle from "ds/components/DraggableItem/Handle";
import Typography from "ds/components/Typography";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import TooltipInfo from "ds/components/TooltipInfo";
import DropdownEllipsisIcon from "ds/components/DropdownEllipsis/Icon";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

type DragDropListItemProps = {
  text: string;
  children: ReactNode;
  onHide: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  moreActions?: Array<{ title: string; link: string } & AnalyticsCommonProps>;
  infoTooltip?: string;
  dragging: boolean;
};

const DragDropListItem = ({
  text,
  children,
  onHide,
  dragHandleProps,
  moreActions,
  infoTooltip,
  dragging,
}: DragDropListItemProps) => {
  return (
    <Box justify="between" fullWidth direction="column">
      <DraggableItemHandle
        dragging={dragging}
        dragHandleProps={dragHandleProps}
        grow="1"
        padding="small medium medium"
        variant="p-t6"
      >
        <Box justify="between" align="center" grow="1">
          <Box align="center" gap="large">
            <Typography tag="span" variant="p-t5">
              {text}
            </Typography>
            {infoTooltip && <TooltipInfo>{infoTooltip}</TooltipInfo>}
          </Box>
          <DropdownEllipsisIcon tooltip="Settings">
            {() => (
              <DropdownSection>
                <DropdownSectionItem onClick={onHide}>Hide</DropdownSectionItem>
                {moreActions?.map(
                  ({ link, title, analyticsPage, analyticsTitle, analyticsProps }, i) => (
                    <DropdownSectionItem
                      analyticsPage={analyticsPage}
                      analyticsTitle={analyticsTitle}
                      analyticsProps={analyticsProps}
                      key={i}
                      to={link}
                    >
                      {title}
                    </DropdownSectionItem>
                  )
                )}
              </DropdownSection>
            )}
          </DropdownEllipsisIcon>
        </Box>
      </DraggableItemHandle>
      <Box padding="small large large large" fullWidth>
        {children}
      </Box>
    </Box>
  );
};

export default DragDropListItem;
