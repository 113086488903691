import { useCallback } from "react";

import { makeSortableHeaderOption } from "components/Filters/SortHeader/helpers";
import FiltersSortHeaderSortableColumn from "components/Filters/SortHeader/SortableColumn";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import useFiltersSortHeader from "components/Filters/SortHeader/useFiltersSortHeader";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import { ViewCustomizationContext } from "components/ViewCustomization/Context";
import ViewCustomizationDropdown from "components/ViewCustomization/Dropdown";
import ViewCustomizationItem from "components/ViewCustomization/Item";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";

import {
  COLUMN_GAP,
  columnTemplate,
  FILTER_ITEMS_DICTIONARY,
  versionListViewItemId,
} from "../../constants";

const VersionsFilterHeader = (): JSX.Element => {
  const { querySortOption, querySortDirection, onItemClick } = useFiltersSortHeader();
  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    callbackTrackProviders: { segment: true },
  });

  const handleColumnClick = useCallback(
    (value: string) => () => {
      trackSegmentAnalyticsEvent("Module Registry - Sort by Selected", {
        direction: querySortDirection,
        column: value,
      });
      onItemClick(value)();
    },
    [onItemClick, trackSegmentAnalyticsEvent, querySortDirection]
  );

  return (
    <FiltersSortHeaderWrapper columnOrder={columnTemplate(itemsVisibility)} columnGap={COLUMN_GAP}>
      <ViewCustomizationItem id={versionListViewItemId.VERSION}>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            {FILTER_ITEMS_DICTIONARY[versionListViewItemId.VERSION]}
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.NAME}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(versionListViewItemId.NAME, FILTER_ITEMS_DICTIONARY)}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={handleColumnClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.TESTS}>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            {FILTER_ITEMS_DICTIONARY[versionListViewItemId.TESTS]}
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.COMMITTED_BY}>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            {FILTER_ITEMS_DICTIONARY[versionListViewItemId.COMMITTED_BY]}
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.TRIGGERED_BY}>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            {FILTER_ITEMS_DICTIONARY[versionListViewItemId.TRIGGERED_BY]}
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.COMMIT}>
        <FiltersSortHeaderStaticColumn>
          <Typography tag="span" variant="p-t6">
            {FILTER_ITEMS_DICTIONARY[versionListViewItemId.COMMIT]}
          </Typography>
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.COMMITTED}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(
            versionListViewItemId.COMMITTED,
            FILTER_ITEMS_DICTIONARY
          )}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={handleColumnClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.CREATED_AT}>
        <FiltersSortHeaderSortableColumn
          option={makeSortableHeaderOption(
            versionListViewItemId.CREATED_AT,
            FILTER_ITEMS_DICTIONARY
          )}
          querySortOption={querySortOption}
          querySortDirection={querySortDirection}
          onClick={handleColumnClick}
        />
      </ViewCustomizationItem>
      <ViewCustomizationItem id={versionListViewItemId.CONFIG}>
        <FiltersSortHeaderStaticColumn justify="center">
          <ViewCustomizationDropdown analyticsPage={AnalyticsPageModule.ModuleVersions} />
        </FiltersSortHeaderStaticColumn>
      </ViewCustomizationItem>
    </FiltersSortHeaderWrapper>
  );
};

export default VersionsFilterHeader;
