import { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import InfiniteLoader from "react-window-infinite-loader";

import { SavedFilterView } from "components/Filters/types";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import { Context, SearchContextsOutput, SearchSuggestionsOutput } from "types/generated";
import ListEntitiesNew from "components/ListEntitiesNew";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { MenuContexts, NoResultsColored } from "components/icons";
import { AccountContext } from "views/AccountWrapper";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import EmptyState from "ds/components/EmptyState";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import DocumentationIconButton from "components/DocumentationIconButton";
import Button from "ds/components/Button";
import SearchInput from "components/SearchInput";
import { getSearchQuery } from "components/SearchInput/helpers";
import PageLoading from "components/loading/PageLoading";
import FullDescriptionDrawer from "components/FullDescription/Drawer";
import Drawer from "ds/components/Drawer";
import { uniqByKey } from "utils/uniq";
import useURLParams from "hooks/useURLParams";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import Box from "ds/components/Box";
import DocumentationButton from "components/DocumentationButton";
import { getDocsUrl } from "utils/getDocsUrl";

import ContextVirtualizedListItem from "./ListItem/Virtualized";
import { GET_CONTEXT_SUGGESTIONS, SEARCH_CONTEXTS } from "./gql";
import ContextsFiltersLayoutHeader from "./FiltersLayout/Header";
import ContextsFiltersLayout from "./FiltersLayout";
import ContextUpdateDrawer from "./UpdateDrawer";
import {
  CONTEXTS_PER_PAGE,
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  POLL_INTERVAL,
} from "./constants";
import { SpacesContext } from "../SpacesProvider";

function Contexts() {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>(undefined);
  const { onError } = useTypedContext(FlashContext);
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);
  const [isFullDescriptionDrawerVisible, setFullDescriptionDrawerVisible] = useState(false);
  const [focusedContext, setFocusedContext] = useState<Context | undefined>(undefined);
  const [isUpdateDrawerVisible, setUpdateDrawerVisibility] = useState(false);
  const urlParams = useURLParams();

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const searchInput = getSearchQuery(urlParams);

  const { data, error, loading, fetchMore } = useQuery<{ searchContexts: SearchContextsOutput }>(
    SEARCH_CONTEXTS,
    {
      onError,
      nextFetchPolicy: "cache-first",
      variables: {
        input: {
          first: CONTEXTS_PER_PAGE,
          after: null,
          fullTextSearch: searchInput,
          predicates,
          ...(sortOptionFields && { orderBy: sortOptionFields }),
        },
      },
    }
  );

  const cachedContextEdges = useRef<Context[]>([]);

  const contexts = useMemo(() => {
    const sourceEdges = data?.searchContexts?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedContextEdges.current : sourceEdges;

    if (!loading) {
      cachedContextEdges.current = sourceEdges;
    }

    return edges;
  }, [data?.searchContexts?.edges, loading]);

  const {
    data: suggestionsData,
    loading: filteringLoading,
    refetch: refetchSuggestions,
    called,
    stopPolling,
  } = useQuery<{
    searchContextsSuggestions: SearchSuggestionsOutput;
  }>(GET_CONTEXT_SUGGESTIONS, {
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "cache-and-network",
    onError,
    variables: {
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields: null,
      },
    },
  });

  // This updates filter suggestions when user opens one of filter sections
  const refreshSuggestions = useCallback(
    (fields?: string[]) => {
      if (!called) {
        refetchSuggestions?.({
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields,
          },
        });
      }
    },
    [searchInput, predicates, called, refetchSuggestions]
  );

  useTitle(`Contexts · ${accountName}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    stopPolling();
    return ErrorContent;
  }

  const isPageEmpty = !loading && !error && contexts.length === 0;
  const hasNoResultsWithFiltering = searchInput.length > 0 || predicates.length > 0;

  const handleOpenFullDescriptionDrawer = (context: Context) => {
    setFocusedContext(context);
    setFullDescriptionDrawerVisible(true);
  };

  const handleCloseFullDescriptionDrawer = () => {
    setFocusedContext(undefined);
    setFullDescriptionDrawerVisible(false);
  };

  const handleCloseUpdateDrawer = () => {
    setUpdateDrawerVisibility(false);
    setFocusedContext(undefined);
  };

  const handleOpenUpdateDrawer = (context: Context) => {
    setFocusedContext(context);
    setUpdateDrawerVisibility(true);
  };

  const isItemLoaded = (value: number) => value < contexts.length;

  const loadMoreItems = async () => {
    try {
      if (data?.searchContexts?.pageInfo.endCursor && data?.searchContexts?.pageInfo.hasNextPage) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            const previousData = prev?.searchContexts ? prev : data;

            if (!fetchMoreResult || !fetchMoreResult?.searchContexts) return previousData;

            const result = fetchMoreResult?.searchContexts;

            if (!result || !result?.edges?.length) return previousData;

            const edges = uniqByKey(
              [...(previousData?.searchContexts?.edges || []), ...result.edges],
              "cursor"
            );

            return {
              searchContexts: {
                ...result,
                edges,
              },
            };
          },
          variables: {
            input: {
              first: CONTEXTS_PER_PAGE,
              after: data.searchContexts.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle titleSize="p-t3">Contexts</ViewHeaderTitle>
        <ViewHeaderWrapper direction="row" align="end" shrink="0">
          <SearchInput placeholder="Search..." filtersOrderSettingsKey="TBA" />
          <DocumentationIconButton
            href={getDocsUrl("/concepts/configuration/context")}
            tooltipText="Go to contexts documentation"
          />

          {hasEntityCreateAccess && (
            <Button to="/new/context" variant="primary">
              Create context
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <FullDescriptionDrawer
        visible={isFullDescriptionDrawerVisible}
        description={focusedContext?.description}
        onCloseDrawer={handleCloseFullDescriptionDrawer}
      />
      <Drawer
        position="absoluteRight"
        visible={isUpdateDrawerVisible}
        onOutsideClick={handleCloseUpdateDrawer}
      >
        {focusedContext && (
          <ContextUpdateDrawer
            handleCloseDrawer={handleCloseUpdateDrawer}
            context={focusedContext}
            refetchQuery="SearchContexts"
          />
        )}
      </Drawer>
      <ContextsFiltersLayout
        currentSavedView={currentSavedView}
        setCurrentSavedView={setCurrentSavedView}
        suggestions={suggestionsData?.searchContextsSuggestions}
        loading={filteringLoading}
        handlePollingActiveSections={refreshSuggestions}
      >
        {contexts.length > 0 && <ContextsFiltersLayoutHeader />}

        {loading && contexts.length === 0 && <PageLoading />}

        {isPageEmpty && hasNoResultsWithFiltering && (
          <EmptyState title="No results" caption="Try different filters." icon={NoResultsColored} />
        )}

        {isPageEmpty && !hasNoResultsWithFiltering && (
          <EmptyState
            title="You don’t have any contexts yet"
            icon={MenuContexts}
            caption="Contexts are collections of reusable configuration elements that can be shared between multiple Stacks. They make your life significantly easier when dealing with repetitive settings and/or credentials."
          >
            <Box gap="medium">
              <DocumentationButton
                to={getDocsUrl("/concepts/configuration/context")}
                label="Documentation"
              />
              {hasEntityCreateAccess && (
                <Button variant="primary" to="/new/context">
                  Create context
                </Button>
              )}
            </Box>
          </EmptyState>
        )}

        {!isPageEmpty && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={contexts.length + CONTEXTS_PER_PAGE}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <ListEntitiesNew
                itemCount={contexts.length}
                itemProps={{
                  items: contexts,
                  onShowFullDescription: handleOpenFullDescriptionDrawer,
                  onEdit: handleOpenUpdateDrawer,
                  focusedContextId: focusedContext?.id,
                  canManageContexts: hasEntityCreateAccess,
                }}
                virtualizedItem={ContextVirtualizedListItem}
                itemKey={(index) => contexts[index].id}
                onItemsRendered={onItemsRendered}
              />
            )}
          </InfiniteLoader>
        )}
      </ContextsFiltersLayout>
    </>
  );
}

export default Contexts;
