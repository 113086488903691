export enum Feature {
  IntegrateVCS,
  IntegrateCloud,
  CreateStack,
  InviteTeammates,
}

export type FeatureProgress = Record<
  Feature,
  {
    isCompleted: boolean;
  }
>;
