import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { GpgKey } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";

import { UPDATE_GPG_KEY } from "../gql";
import { GpgKeyDrawerProps, UpdateGpgKeyFormFields, UpdateGpgKeyGql } from "./types";

const UpdateGpgKeyDrawer = ({ handleCloseDrawer, gpgKey }: GpgKeyDrawerProps) => {
  const builderForm = useForm<UpdateGpgKeyFormFields>({
    defaultValues: {
      description: gpgKey.description || "",
    },
    mode: "onChange",
  });

  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = builderForm;

  const [updateGpgKey] = useMutation<UpdateGpgKeyGql>(UPDATE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const onUpdateSubmit = (formData: UpdateGpgKeyFormFields, gpgKeyItem: GpgKey) => {
    updateGpgKey({
      variables: {
        id: gpgKeyItem.id,
        description: formData.description,
      },
    })
      .then(({ data }) => {
        if (data?.gpgKeyUpdate?.name) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyUpdate.name}" was successfully saved`,
          });
        } else {
          reportError({
            message: "Something went wrong while saving GPG key, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const onSubmit: SubmitHandler<UpdateGpgKeyFormFields> = (formData) => {
    onUpdateSubmit(formData, gpgKey);
  };

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Edit GPG key" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <FormFieldViewText label="ID" value={gpgKey.id} withCopy />

        <FormField label="Status">
          <Box>
            <BadgeNext
              variant={gpgKey.revokedAt ? "red" : "green"}
              type="strong"
              text={gpgKey.revokedAt ? "REVOKED" : "ACTIVE"}
            />
          </Box>
        </FormField>

        <FormFieldTimestamp label="Created at" timestamp={gpgKey.createdAt} />

        {gpgKey.createdBy && <FormFieldViewText label="Created by" value={gpgKey.createdBy} />}

        {gpgKey.revokedAt && <FormFieldTimestamp label="Revoked at" timestamp={gpgKey.revokedAt} />}

        {gpgKey.revokedBy && <FormFieldViewText label="Revoked by" value={gpgKey.revokedBy} />}

        <FormFieldViewText label="Name" value={gpgKey.name} />

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here..."
              maxRows={15}
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default UpdateGpgKeyDrawer;
