import Box from "ds/components/Box";
import { MenuSpaces, Trash } from "components/icons";
import IconAction from "ds/components/IconAction";
import ListEntitiesItem from "components/ListEntitiesItem";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import ProjectTypeBadge, { ProjectType } from "components/ProjectTypeBadge";

import { COLUMN_ORDER_USED_BY } from "../../constants";
import { ContextUsedByAttachedItemProps } from "./types";
import { ContextContext } from "../../Context";
import { showDetachContextConfirmation } from "./DetachContextConfirmation";

const ContextUsedByAttachedItem = ({ className, item }: ContextUsedByAttachedItemProps) => {
  const { canManageContext } = useTypedContext(ContextContext);
  const isModule = item.isModule;

  const handleDetachClick = () =>
    showDetachContextConfirmation({
      id: item.id,
      name: item.stackName,
      isModule,
    });

  return (
    <ListEntitiesItem
      className={className}
      grid
      gridTemplate={COLUMN_ORDER_USED_BY}
      gap="0 large"
      key={item.id}
    >
      <Box align="center" gap="medium" fullWidth grow="1">
        <ProjectTypeBadge projectType={isModule ? ProjectType.Module : ProjectType.Stack} />
      </Box>
      <Box align="center" gap="medium" fullWidth grow="1">
        <ListEntitiesItemLink
          to={`/${isModule ? "module" : "stack"}/${item.stackId}`}
          title={item.stackName}
        />
      </Box>

      <MetaInfoListItem
        icon={MenuSpaces}
        linkText={item.stackSpaceDetails.name}
        href={`/spaces/${item.stackSpaceDetails.id}`}
      />

      <Box padding="0 0 0 medium">
        {!item.isAutoattached && item.createdAt && (
          <Typography variant="p-body2" tag="p">
            <Timestamp timestamp={item.createdAt} />
          </Typography>
        )}
      </Box>
      <Box>
        {!item.isAutoattached && canManageContext && (
          <IconAction icon={Trash} color="danger" tooltip="Detach" onClick={handleDetachClick} />
        )}
      </Box>
    </ListEntitiesItem>
  );
};

export default ContextUsedByAttachedItem;
