import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

import useTypedContext from "hooks/useTypedContext";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FormField from "ds/components/Form/Field";
import { validateDateIsAfterNow } from "utils/formValidators";
import Datepicker from "ds/components/Datepicker";
import { getNowDateTime } from "utils/date";
import { ScheduledDelete } from "types/generated";
import Tile from "ds/components/Tile";
import Box from "ds/components/Box";
import Feedback from "ds/components/Feedback";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useAnalytics from "hooks/useAnalytics";
import { SCHEDULING_TYPE } from "shared/Stack/Scheduling/types";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { StackSchedulingContextApi } from "../Context";
import { StackManageScheduledDeleteFormFields } from "./types";
import useCreateScheduledDelete from "./useCreateScheduledDelete";
import { getFormDefaultValues, mapCreateFormData } from "./helpers";
import useUpdateScheduledDelete from "./useUpdateScheduledDelete";

type StackManageScheduledDeleteProps = {
  integration?: ScheduledDelete;
  onCancel: () => void;
};

const StackManageScheduledDelete = ({ integration, onCancel }: StackManageScheduledDeleteProps) => {
  const isEditMode = !!integration;

  const { onClose } = useTypedContext(StackSchedulingContextApi);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const minDate = getNowDateTime({ timezone: "UTC" });
  const defaultDateTime = getNowDateTime({ timezone: "UTC", timeShiftMinutes: 15 });

  const scheduleStackDeletionForm = useForm<StackManageScheduledDeleteFormFields>({
    defaultValues: getFormDefaultValues(defaultDateTime, integration),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    trigger,
  } = scheduleStackDeletionForm;

  const { createScheduledDelete, loading: creationLoading } = useCreateScheduledDelete();
  const { updateScheduledDelete, loading: updateLoading } = useUpdateScheduledDelete();

  const handlePrimaryAction = (formData: StackManageScheduledDeleteFormFields) => {
    if (isEditMode) {
      updateScheduledDelete(integration.id, mapCreateFormData(formData), () => {
        onClose();
        trackSegmentEvent("Schedule Edit Saved", { type: SCHEDULING_TYPE.DELETE_STACK });
      });
    } else {
      createScheduledDelete(mapCreateFormData(formData), () => {
        onClose();
        trackSegmentEvent("Schedule Created", { type: SCHEDULING_TYPE.DELETE_STACK });
      });
    }
  };

  useEffect(() => {
    if (isEditMode) {
      trigger();
    }
  }, [isEditMode, trigger]);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle
          title={isEditMode ? "Edit stack deletion schedule" : "Create stack deletion schedule"}
        />
        <DrawerCloseIcon handleCloseDrawer={onCancel} />
      </DrawerHeader>
      <DrawerBody gap="x-large" fullHeight>
        <Feedback type="banner" variant="info">
          Stack deletion schedule only works on private workers.
        </Feedback>

        <Controller
          name="dateTime"
          control={control}
          rules={{
            required: true,
            validate: validateDateIsAfterNow,
          }}
          render={({ field, fieldState }) => (
            <FormField label="Date and time UTC:" error={fieldState.error?.message} noMargin>
              <Datepicker
                withTime
                renderTimezone="UTC"
                date={field.value}
                onChange={field.onChange}
                minDate={minDate}
                hasError={!!fieldState.error?.message}
                fullWidth
              />
            </FormField>
          )}
        />

        <Controller
          name="shouldDeleteResources"
          control={control}
          render={({ field }) => (
            <Box gap="x-large">
              <Tile
                title="Delete resources"
                indicator="radio"
                description="All resources that belong to this stack will be removed with it."
                selected={field.value}
                onClick={() => field.onChange(true)}
              />

              <Tile
                title="Keep resources"
                indicator="radio"
                description="Resources that belong to this stack will remain available when the stack is removed."
                selected={!field.value}
                onClick={() => field.onChange(false)}
              />
            </Box>
          )}
        />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(handlePrimaryAction)}
              loading={creationLoading || updateLoading}
              disabled={!isValid || (isEditMode && !isDirty)}
            >
              {isEditMode ? "Save" : "Create"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </>
  );
};

export default StackManageScheduledDelete;
