import { FetchMoreOptions, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";

import PageLoading from "components/loading/PageLoading";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { RunsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import FlashContext from "components/FlashMessages/FlashContext";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import { uniqBy } from "utils/uniq";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import { RunIgnoredTrigger } from "types/generated";

import { StackContext } from "../Context";
import { FetchListIgnoredRunsData, LIST_IGNORED_RUNS } from "./gql";
import { groupPerCommitOnTimeline } from "../helpers";
import StackIgnoredRunsGroup from "./Group";
import StackIgnoredRunsElement from "./Element";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";
import { COLUMN_GAP, COLUMN_ORDER } from "./constants";

const StackIgnoredRuns = () => {
  const { onError } = useTypedContext(FlashContext);
  const { stack: stackCtx } = useTypedContext(StackContext);

  useTitle(`Ignored runs · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const { loading, error, data, fetchMore } = useQuery(LIST_IGNORED_RUNS, {
    onError,
    variables: { stackId: stackCtx.id },
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
  });

  const updateQuery = useCallback<
    Exclude<FetchMoreOptions<FetchListIgnoredRunsData>["updateQuery"], undefined>
  >((previous, { fetchMoreResult }) => {
    if (
      fetchMoreResult &&
      fetchMoreResult.stack.searchRunIgnoredTriggers &&
      (fetchMoreResult.stack.searchRunIgnoredTriggers.edges?.length || 0) > 0
    ) {
      return {
        stack: {
          ...fetchMoreResult.stack,
          searchRunIgnoredTriggers: {
            pageInfo: fetchMoreResult.stack.searchRunIgnoredTriggers.pageInfo,
            edges: uniqBy(
              [
                ...(previous.stack.searchRunIgnoredTriggers?.edges || []),
                ...(fetchMoreResult.stack.searchRunIgnoredTriggers?.edges || []),
              ],
              (edge) => edge.node.id
            ),
          },
        },
      };
    }

    return { stack: { ...previous.stack } };
  }, []);

  const stack = useMemo(() => {
    if (data?.stack) {
      return {
        ...stackCtx,
        ...(data?.stack ? data.stack : {}),
      };
    }

    return undefined;
  }, [data?.stack, stackCtx]);

  const runsListPerCommit = useMemo(
    () =>
      groupPerCommitOnTimeline<RunIgnoredTrigger>(
        stack?.searchRunIgnoredTriggers?.edges.map((edge) => edge.node) || []
      ),
    [stack?.searchRunIgnoredTriggers]
  );

  const handleScrollEnd = useCallback(async () => {
    const lastRun = stack?.searchRunIgnoredTriggers?.pageInfo.endCursor;

    if (lastRun) {
      try {
        await fetchMore({
          variables: { after: lastRun },
          updateQuery,
        });
      } catch (err) {
        onError(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, stack]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack || !stack) {
    return <NotFoundPage />;
  }

  return (
    <InfiniteScroll
      onScrollEnd={handleScrollEnd}
      hasMore={data.stack.searchRunIgnoredTriggers?.pageInfo.hasNextPage}
    >
      <StackHeader />

      <PageInfo title="Ignored runs" />

      <Callout variant="warning">
        If you want to force the run, sync and trigger it manually.
        <FeedbackActions>
          <Button variant="secondary" size="small" to={`/stack/${stack.id}`}>
            Go to tracked runs
          </Button>
        </FeedbackActions>
      </Callout>

      <PageWrapper>
        {runsListPerCommit.length > 0 && (
          <Box gap="large" direction="column">
            {runsListPerCommit.map(([hash, group], i) => {
              return (
                <StackIgnoredRunsGroup
                  key={`${hash}_${i}`}
                  run={group[0]}
                  provider={stack.provider}
                  count={group.length}
                  isOpen
                >
                  <FiltersSortHeaderWrapper columnOrder={COLUMN_ORDER} columnGap={COLUMN_GAP}>
                    <FiltersSortHeaderStaticColumn>
                      <Typography tag="span" variant="p-t6">
                        Status
                      </Typography>
                    </FiltersSortHeaderStaticColumn>
                    <FiltersSortHeaderStaticColumn>
                      <Typography tag="span" variant="p-t6">
                        Data
                      </Typography>
                    </FiltersSortHeaderStaticColumn>
                  </FiltersSortHeaderWrapper>
                  {group.map((run) => (
                    <StackIgnoredRunsElement key={run.id} stack={stack} run={run} />
                  ))}
                </StackIgnoredRunsGroup>
              );
            })}
          </Box>
        )}

        {(stack.searchRunIgnoredTriggers?.edges.length || 0) === 0 && (
          <EmptyState
            icon={RunsColored}
            title="No ignored runs yet"
            caption={<>No runs were ignored for last 14 days</>}
          />
        )}
      </PageWrapper>
    </InfiniteScroll>
  );
};

export default StackIgnoredRuns;
