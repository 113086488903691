import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { ConfigType } from "types/generated";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import ContextConfigFormPathField from "components/ContextConfigForm/PathField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { ContextVariablesFormFields, ContextMountedFilesFormProps } from "./types";

const ContextMountedFilesForm = ({
  handleCloseDrawer,
  handleFileAdd,
  item,
}: ContextMountedFilesFormProps) => {
  const fileForm = useForm<ContextVariablesFormFields>({
    defaultValues: {
      id: item?.id || "",
      description: item?.description || "",
      writeOnly: item?.writeOnly ? item.writeOnly : true,
      type: ConfigType.FileMount,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = fileForm;

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  const onCreateSubmit = (formData: ContextVariablesFormFields) => {
    const fileData = {
      id: formData.id,
      description: formData.description || "",
      writeOnly: formData.writeOnly,
      type: formData.type,
      isDraft: true,
      createdAt: null,
      value: null,
    };
    handleFileAdd(fileData);
    reset();
    handleCloseDrawer();
  };

  const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
    onCreateSubmit(formData);
  };

  return (
    <FormProvider {...fileForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Add file" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Box margin="0 0 large">
          <ContextConfigFormPathField name="id" />
        </Box>
        <ContextConfigFormIsSecretField name="writeOnly" />
        <ContextConfigFormDescriptionField name="description" />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              {item ? "Save" : "Continue"}
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default ContextMountedFilesForm;
