import Skeleton from "react-loading-skeleton";

import Box from "ds/components/Box";
import TileContent from "ds/components/Tile/Content";
import TileIndicator from "ds/components/Tile/Indicator";
import TileWrapper from "ds/components/Tile/Wrapper";

import { GET_STARTED_FEATURES } from "./features";

const LaunchPadGetStartedChecklistSkeletonTile = () => (
  <TileWrapper>
    <Box align="center" justify="between" grow="1">
      <TileContent direction="row" align="center">
        <Skeleton count={1} height={36} width={36} borderRadius={8} />
        <Skeleton count={1} height={20} width={300} borderRadius={8} />
      </TileContent>
      <TileIndicator indicator={<Skeleton count={1} height={16} width={16} borderRadius={4} />} />
    </Box>
  </TileWrapper>
);

const LaunchPadGetStartedChecklistSkeleton = () => (
  <>
    {Object.entries(GET_STARTED_FEATURES).map(([feature]) => (
      <LaunchPadGetStartedChecklistSkeletonTile key={feature} />
    ))}
  </>
);

export default LaunchPadGetStartedChecklistSkeleton;
