import { Group } from "@visx/group";
import { Line } from "@visx/shape";

import Typography from "ds/components/Typography";
import { TypographyColors } from "types/Colors";

type PercentileLineProps = {
  name: string;
  xMax: number;
  y: number;
  textColor: TypographyColors;
  lineColor: string;
  backgroundColor: string;
};

const PercentileLine = ({
  xMax,
  y,
  name,
  textColor,
  lineColor,
  backgroundColor,
}: PercentileLineProps) => (
  <Group key={name}>
    <Group transform="translate(-13,3)">
      <rect x={-15} y={y - 13} width={28} height={19} rx={4} ry={4} fill={backgroundColor} />
      <text fill="currentColor" textAnchor="middle" x={-1} y={y}>
        <Typography tag="tspan" variant="p-t8" color={textColor}>
          {name}
        </Typography>
      </text>
    </Group>
    <Line from={{ x: 0, y }} to={{ x: xMax, y }} stroke={lineColor} strokeWidth={1} />
  </Group>
);

export default PercentileLine;
