import { memo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";

import FormFieldTags from "components/FormFields/Tags";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import { CreatePolicyFields } from "./types";
import { ADD_LOGIN_POLICY_DRAWER_TEST_ID } from "./constants";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";

type LoginPoliciesEditDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
};

const LoginPoliciesEditDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
}: LoginPoliciesEditDrawerProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationLoginPoliciesList,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const createPolicyForm = useForm<CreatePolicyFields>({
    defaultValues: {
      name: "",
      labels: [],
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = createPolicyForm;

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const cancelHandler = () => {
    trackSegmentAnalyticsEvent("Create Cancel");
    handleCloseDrawer();
  };

  const redirectToCreateView: SubmitHandler<CreatePolicyFields> = (formData) => {
    trackSegmentAnalyticsEvent("Create Continue");

    const labelsQuery =
      formData.labels && `&labels=${btoa(encodeURIComponent(JSON.stringify(formData.labels)))}`;

    navigate(`/new/login-policy?name=${btoa(encodeURIComponent(formData.name))}${labelsQuery}`);
  };

  return (
    <Drawer
      visible={isDrawerVisible}
      onOutsideClick={handleCloseDrawer}
      dataTestId={ADD_LOGIN_POLICY_DRAWER_TEST_ID}
    >
      <FormProvider {...createPolicyForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Create policy" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Name of your new policy"
                error={!!errors?.name}
                {...register("name", {
                  required: "Name field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldTags name="labels" tagName="label" label="Labels" isOptional />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={cancelHandler}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(redirectToCreateView)}
                disabled={!isValid}
              >
                Continue
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(LoginPoliciesEditDrawer);
