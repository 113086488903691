import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { Context } from "types/generated";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";

import ContextListItemDropdownDelete from "./Delete";

type ContextListItemDropdownProps = {
  context: Context;
  canManageContexts: boolean;
  onEdit: (context: Context) => void;
};

function ContextListItemDropdown({
  context,
  canManageContexts,
  onEdit,
}: ContextListItemDropdownProps) {
  const hasAttachedStacks = context.hasAttachedStacks;

  const handleOnEdit = (closeDropdown: () => void) => () => {
    onEdit(context);
    closeDropdown();
  };

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          {canManageContexts && (
            <DropdownSectionItem onClick={handleOnEdit(close)}>Edit</DropdownSectionItem>
          )}
          <CopyFieldDropdownItem title="Copy ID" value={context.id} callback={close} />
          {canManageContexts && (
            <ContextListItemDropdownDelete
              hasAttachedStacks={hasAttachedStacks}
              name={context.name}
              id={context.id}
            />
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
}

export default ContextListItemDropdown;
