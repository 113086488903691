import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import { ConfigElement, ConfigType } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import useTypedContext from "hooks/useTypedContext";
import ContextConfigFormNameField from "components/ContextConfigForm/NameField";
import ContextConfigFormValueField from "components/ContextConfigForm/ValueField";
import ContextConfigFormIsSecretField from "components/ContextConfigForm/IsSecretField";
import ContextConfigFormDescriptionField from "components/ContextConfigForm/DescriptionField";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { ADD_CONTEXT_CONFIG, UPDATE_CONTEXT_CONFIG } from "./gql";
import { ContextVariablesFormFields, ContextVariablesFormProps } from "./types";
import { ContextContext } from "../../Context";

const ContextVariablesForm = ({ handleCloseDrawer, variable }: ContextVariablesFormProps) => {
  const { context } = useTypedContext(ContextContext);
  const isEditMode = !!variable;

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const variableForm = useForm<ContextVariablesFormFields>({
    defaultValues: {
      id: variable?.id || "",
      description: variable?.description || "",
      value: variable?.value || "",
      writeOnly: variable ? variable.writeOnly : true,
      type: ConfigType.EnvironmentVariable,
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
    watch,
  } = variableForm;

  const [addContextConfig] = useMutation<{ contextConfigAdd: ConfigElement }>(ADD_CONTEXT_CONFIG, {
    refetchQueries: ["GetContext"],
    variables: { contextId: context.id },
  });

  const [updateContextConfig] = useMutation<{ contextConfigAdd: ConfigElement }>(
    UPDATE_CONTEXT_CONFIG,
    {
      onError,
      refetchQueries: ["GetContext"],
      variables: { contextId: context.id },
    }
  );

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  const onCreateSubmit = (formData: ContextVariablesFormFields) => {
    addContextConfig({
      variables: {
        input: {
          id: formData.id,
          description: formData.description,
          value: formData.value,
          writeOnly: formData.writeOnly,
          type: formData.type,
        },
      },
    })
      .then(({ data }) => {
        if (data?.contextConfigAdd) {
          reportSuccess({
            message: `Context variable is successfully created`,
          });

          reset();
          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onEditSubmit = (formData: ContextVariablesFormFields) => {
    updateContextConfig({
      variables: {
        input: {
          id: formData.id,
          description: formData.description,
          value: formData.value,
          writeOnly: formData.writeOnly,
          type: formData.type,
        },
      },
    })
      .then(({ data }) => {
        if (data?.contextConfigAdd) {
          reportSuccess({
            message: `Context variable is successfully saved`,
          });

          reset();
          handleCloseDrawer();
        }
      })
      .catch(onError);
  };

  const onSubmit: SubmitHandler<ContextVariablesFormFields> = (formData) => {
    if (isEditMode) {
      onEditSubmit(formData);
    } else {
      onCreateSubmit(formData);
    }
  };

  return (
    <FormProvider {...variableForm}>
      <DrawerHeader justify="between">
        {!isEditMode && <DrawerHeaderTitle title="Add variable" />}
        {isEditMode && <DrawerHeaderTitle title="Edit variable" />}
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <Box margin="0 0 large">
          {isEditMode ? (
            <FormFieldViewText label="Name" value={variable.id} />
          ) : (
            <ContextConfigFormNameField name="id" />
          )}
        </Box>
        <ContextConfigFormValueField name="value" isSecret={watch("writeOnly")} />
        <Box margin="large 0 large">
          <ContextConfigFormIsSecretField name="writeOnly" />
        </Box>
        <ContextConfigFormDescriptionField name="description" />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default ContextVariablesForm;
