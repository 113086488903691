import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import NewConfigElement from "components/config/edit/NewConfigElement";
import { trackHubspotEvent, trackSegmentEvent } from "shared/Analytics";
import useTypedContext from "hooks/useTypedContext";
import { ConfigInput } from "types/generated";

import { ENVIRONMENT_QUERY } from "../constants";
import {
  AddStackConfigGqlResponse,
  AddStackConfigGqlVariables,
  EnvironmentNewConfigProps,
} from "./types";

const ADD_STACK_CONFIG = gql`
  mutation AddStackConfig($stackId: ID!, $input: ConfigInput!) {
    stackConfigAdd(stack: $stackId, config: $input) {
      id
      type
    }
  }
`;

const EnvironmentNewConfig = ({ stackId, type }: EnvironmentNewConfigProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [addConfig, { loading }] = useMutation<
    AddStackConfigGqlResponse,
    Partial<AddStackConfigGqlVariables>
  >(ADD_STACK_CONFIG, {
    refetchQueries: [ENVIRONMENT_QUERY[type]],
    variables: { stackId },
  });

  const onSave = (input: ConfigInput, reset: () => unknown) => {
    addConfig({ variables: { input } })
      .then(({ data }) => {
        trackHubspotEvent("AppEvent - created environment variable");
        trackSegmentEvent("Environment Variable Created");
        reportSuccess({ message: `Config ${data?.stackConfigAdd.id} successfully added` });
        reset();
      })
      .catch(onError);
  };

  return <NewConfigElement loading={loading} onSave={onSave} />;
};

export default EnvironmentNewConfig;
