import cx from "classnames";

import Button from "ds/components/Button";

import styles from "./styles.module.css";
import Box from "../Box";
import { ModalFooterProps } from "./types";
import Link from "../Link";

/**
 * @deprecated Use `ModalNew` from `ds/components/ModalNew` instead.
 */
const ModalFooter = ({
  children,
  mainActionCallback,
  mainActionText = "Apply",
  mainActionVariant = "primary",
  mainActionIsDisabled = false,
  mainActionLoading,
  secondaryActionCallback,
  secondaryActionText = "Cancel",
  linkText,
  linkAction,
}: ModalFooterProps) => {
  const hasLinkComponent = linkText && linkAction;

  const content = children || (
    <>
      {hasLinkComponent && (
        <Link variant="danger" onClick={linkAction}>
          {linkText}
        </Link>
      )}
      <Box className={styles.buttonsWrapper}>
        {secondaryActionCallback && (
          <Button variant="secondary" onClick={secondaryActionCallback}>
            {secondaryActionText}
          </Button>
        )}
        <Button
          loading={mainActionLoading}
          variant={mainActionVariant}
          onClick={mainActionCallback}
          disabled={mainActionIsDisabled}
        >
          {mainActionText}
        </Button>
      </Box>
    </>
  );

  return (
    <div className={cx(styles.modalFooter, { [styles.hasLinkComponent]: hasLinkComponent })}>
      {content}
    </div>
  );
};

export default ModalFooter;
