import { useRef } from "react";
import { useMutation } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import { Stack } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import { canLockStack } from "shared/Stack/useLockStack/accessValidation";
import { canUnlockStack } from "shared/Stack/useUnlockStack/accessValidation";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import { getDisabledTriggerMessage } from "shared/Stack/utils";
import { AccountContext } from "views/AccountWrapper";
import useLockStack from "shared/Stack/useLockStack";
import useUnlockStack from "shared/Stack/useUnlockStack";
import useTriggerStack from "shared/Stack/useTriggerStack";

import { STAR_SET } from "../gql";
import { ANALYTICS } from "../constants";
const REFETCH_QUERIES = ["SearchStacksNew", "SearchStacksSuggestionsNew"];

type StackListItemActionsDropdownCellProps = {
  stack: Stack;
  setTriggerDropdownVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const StackListItemActionsDropdownCell = ({
  stack,
  setTriggerDropdownVisibility,
}: StackListItemActionsDropdownCellProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const closeDropdownRef = useRef<(() => void) | null>(null);

  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [starSet] = useMutation<{ starSet: Stack }>(STAR_SET, {
    refetchQueries: REFETCH_QUERIES,
  });

  const handleStarStack = (clb?: () => void) => () => {
    starSet({ variables: { id: stack.id, star: !stack.starred } }).catch(onError);
    clb?.();
  };

  const [lockStack] = useLockStack({
    refetchQueries: ["SearchStacksSuggestionsNew"],
  });
  const [stackUnlock] = useUnlockStack({
    refetchQueries: ["SearchStacksSuggestionsNew"],
  });
  const [runTrigger] = useTriggerStack();

  const handleLockStack = (clb?: () => void) => () => {
    lockStack({ id: stack.id, note: null }, (data) => {
      if (data?.stackLock.id) {
        reportSuccess({ message: "Stack successfully locked" });
      }
    });

    clb?.();
  };

  const handleUnlockStack = (clb?: () => void) => () => {
    stackUnlock({ id: stack.id }, (data) => {
      if (data?.stackUnlock.id) {
        reportSuccess({ message: "Stack successfully unlocked" });
      }
    });

    clb?.();
  };

  const handleTriggerStack = (clb?: () => void) => () => {
    runTrigger({ stack: stack.id, customRuntimeConfig: null }, (data) => {
      if (data?.runTrigger.id) {
        reportSuccess({ message: "Run successfully triggered" });
      }
    });

    clb?.();
  };

  const assignCloseDropdownRef = (clb: () => void) => {
    closeDropdownRef.current = clb;
    return null;
  };

  const canLock = canLockStack(stack);
  const canUnlock = canUnlockStack(stack, viewer);
  const disableTriggerButtonMessage = getDisabledTriggerMessage(stack);
  const stackUrl = `/stack/${stack.id}`;

  return (
    <DropdownEllipsis dotsSize="small" onOpenChange={setTriggerDropdownVisibility}>
      {({ close }) => (
        <DropdownSection>
          {assignCloseDropdownRef(close)}
          <DropdownSectionItem
            {...ANALYTICS}
            analyticsTitle="Trigger run clicked"
            onClick={handleTriggerStack(close)}
            tooltip={disableTriggerButtonMessage}
            disabled={!!disableTriggerButtonMessage}
          >
            Trigger run
          </DropdownSectionItem>
          {canLock && (
            <DropdownSectionItem
              {...ANALYTICS}
              analyticsTitle="Lock stack clicked"
              onClick={handleLockStack(close)}
            >
              Lock stack
            </DropdownSectionItem>
          )}
          {canUnlock && (
            <DropdownSectionItem
              {...ANALYTICS}
              analyticsTitle="Unlock stack clicked"
              onClick={handleUnlockStack(close)}
            >
              Unlock stack
            </DropdownSectionItem>
          )}
          <CopyFieldDropdownItem
            {...ANALYTICS}
            analyticsTitle="Copy ID clicked"
            title="Copy ID"
            value={stack.id}
            callback={close}
          />
          <DropdownSectionItem
            {...ANALYTICS}
            analyticsTitle={"Star clicked"}
            analyticsProps={{
              ...ANALYTICS.analyticsProps,
              value: stack.starred ? "remove" : "add",
            }}
            onClick={handleStarStack(close)}
          >
            {stack.starred ? "Remove from favorites" : "Add to favorites"}
          </DropdownSectionItem>
          <DropdownSectionItem
            {...ANALYTICS}
            analyticsTitle="Settings clicked"
            to={`${stackUrl}/settings`}
          >
            Settings
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default StackListItemActionsDropdownCell;
