import {
  useFieldArray,
  useFormContext,
  Control,
  FieldValues,
  FieldValue,
  ArrayPath,
  Validate,
  FieldArray,
} from "react-hook-form";
import { ReactNode, useCallback, useEffect } from "react";
import isEqual from "lodash-es/isEqual";
import cx from "classnames";

import IconAction from "ds/components/IconAction";
import { Trash } from "components/icons";
import Box from "ds/components/Box";
import LinkAdd from "ds/components/Link/LinkAdd";

import styles from "./styles.module.css";

type FormArrayFieldProps<T extends FieldValues, K extends ArrayPath<T>> = {
  name: K;
  addButtonLabel: string;
  emptyValue: FieldValue<T>;
  disabled?: boolean;
  validate?: Validate<FieldArray<T, K>[], T> | Record<string, Validate<FieldArray<T, K>[], T>>;
  children: (props: {
    value: T[K][number];
    control: Control<T>;
    field: Record<"id", string>;
    index: number;
    addEmptyItem?: () => void;
  }) => ReactNode;
  onAdd?: () => void;
  onRemove?: (index: number) => void;
  allRowsWithLabels?: boolean;
  isAddDisabled?: boolean;
};

const FormArrayField = <T extends FieldValues, K extends ArrayPath<T>>({
  name,
  children,
  addButtonLabel,
  emptyValue,
  disabled,
  onAdd,
  onRemove,
  validate,
  allRowsWithLabels = false,
  isAddDisabled = false,
}: FormArrayFieldProps<T, K>) => {
  const { control, getValues } = useFormContext<T>();
  const values = getValues();
  const { fields, append, remove, replace } = useFieldArray<T, K>({
    name,
    rules: {
      validate,
    },
  });

  const handleRemoveRow = (index: number) => () => {
    if (fields.length === 1) {
      replace([emptyValue]);
    } else {
      remove(index);
    }

    onRemove?.(index);
  };

  const addEmptyItem = useCallback(() => {
    append(emptyValue);
    onAdd?.();
  }, [append, emptyValue, onAdd]);

  const isRemoveDisabled = (value: FieldValue<T>) => hasOneField && isEqual(value, emptyValue);

  useEffect(() => {
    if (!fields.length) {
      replace([emptyValue]);
    }
  }, [emptyValue, fields.length, replace]);

  const hasOneField = fields.length === 1;

  return (
    <Box direction="column" gap="large" align="start" fullWidth>
      {fields.map((field, index) => {
        const value = values[name][index];

        return (
          <Box align="start" key={field.id} fullWidth>
            {children({
              value,
              control,
              field,
              index,
              addEmptyItem: index === fields.length - 1 && value.value ? addEmptyItem : undefined,
            })}

            <IconAction
              className={cx(
                styles.removeButton,
                (index === 0 || allRowsWithLabels) && styles.removeButtonWithLabel
              )}
              icon={Trash}
              disabled={disabled || isRemoveDisabled(value)}
              onClick={handleRemoveRow(index)}
              color={disabled || isRemoveDisabled(value) ? "disabled" : "danger"}
              tooltip="Delete"
            />
          </Box>
        );
      })}
      <LinkAdd text={addButtonLabel} onClick={addEmptyItem} disabled={disabled || isAddDisabled} />
    </Box>
  );
};

export default FormArrayField;
