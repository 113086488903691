import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import DropdownSection from "ds/components/Dropdown/Section";

import { ContextContext } from "../../Context";
import { ContextVariablesListItemDropdownProps } from "./types";
import { showDeleteVariableConfirmation } from "./DeleteVariableConfirmation";

const ContextVariablesListItemDropdown = ({
  item,
  onEdit,
}: ContextVariablesListItemDropdownProps) => {
  const { context, canManageContext } = useTypedContext(ContextContext);

  const handleEditConfig = (callback: () => void) => () => {
    onEdit(item);
    callback();
  };

  if (!canManageContext) {
    return null;
  }

  const handleDeleteVariable = (callback: () => void) => {
    showDeleteVariableConfirmation({ id: item.id, contextId: context.id });
    callback();
  };

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={handleEditConfig(close)}>Edit</DropdownSectionItem>

          <DropdownSectionItem danger onClick={() => handleDeleteVariable(close)}>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default ContextVariablesListItemDropdown;
