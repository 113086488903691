import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useCallback } from "react";

import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Tile from "ds/components/Tile";
import { Organization, Profile } from "components/icons";
import { AccountType, VcsProvider } from "types/generated";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { stringIsRequired } from "utils/formValidators";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import TileTitle from "ds/components/Tile/Title";

import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import VCSIntegrationsBackButton from "../../components/BackButton";
import VCSIntegrationsContinueButton from "../../components/ContinueButton";
import { ManageGitHubEnterpriseWizardFormFields } from "./types";
import { isOwnerStepValid } from "./helpers";

type VCSIntegrationsManageGitHubEnterpriseWizardStepOwnerProps = {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  loading?: boolean;
};

const VCSIntegrationsManageGitHubEnterpriseWizardStepOwner = ({
  goToNextStep,
  goToPreviousStep,
  loading,
}: VCSIntegrationsManageGitHubEnterpriseWizardStepOwnerProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const { control, register, formState } = useFormContext<ManageGitHubEnterpriseWizardFormFields>();

  const [ownerChoice, integrationChoice] = useWatch({
    name: ["ownerType", "integration"],
    control,
  });

  const isStepValid = isOwnerStepValid(ownerChoice, formState);

  const trackWizardOwnershipContinueClick = useCallback(() => {
    trackSegmentAnalyticsEvent("GitHub Wizard - Ownership Continue Click", {
      ownership: ownerChoice,
      type: integrationChoice,
    });
  }, [integrationChoice, ownerChoice, trackSegmentAnalyticsEvent]);

  const handleGoToNextStep = useCallback(() => {
    goToNextStep();
    trackWizardOwnershipContinueClick();
  }, [goToNextStep, trackWizardOwnershipContinueClick]);

  return (
    <>
      <FullScreenModalBody gap="x-large">
        <Typography variant="p-t4" tag="h3" align="center">
          Do you want the app to be owned by your personal GitHub account or by an organization?
        </Typography>

        <Box fullWidth gap="large">
          <Controller
            name="ownerType"
            control={control}
            render={({ field }) => (
              <>
                <Tile
                  icon={Profile}
                  title={<TileTitle variant="p-t6">Personal</TileTitle>}
                  indicator="radio"
                  selected={field.value === AccountType.User}
                  onClick={() => field.onChange(AccountType.User)}
                />

                <Tile
                  icon={Organization}
                  title={<TileTitle variant="p-t6">Organization</TileTitle>}
                  indicator="radio"
                  selected={field.value === AccountType.Org}
                  onClick={() => field.onChange(AccountType.Org)}
                />
              </>
            )}
          />
        </Box>

        {ownerChoice === AccountType.Org && (
          <FormField
            label="GitHub Organization name"
            error={formState.errors?.ownerName?.message}
            helperText="The name is case-sensitive"
            noMargin
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter Organization name here"
                error={!!formState.errors?.ownerName}
                {...register("ownerName", {
                  validate: stringIsRequired("Organization name field is required."),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}
      </FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.GithubEnterprise} />

        <Box gap="medium">
          <VCSIntegrationsBackButton onClick={goToPreviousStep} />

          <VCSIntegrationsContinueButton
            onClick={handleGoToNextStep}
            disabled={!isStepValid}
            loading={loading}
          />
        </Box>
      </FullScreenModalFooter>
    </>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizardStepOwner;
