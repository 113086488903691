import { ReactNode, useCallback, useMemo, useState } from "react";
import { cloneDeep, isEqual } from "lodash-es";
import { useNavigate } from "react-router-dom-v5-compat";
import { nanoid } from "nanoid";

import { ColumnMode } from "components/DragDropList";
import useUIConfig from "hooks/useUIConfig";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import NotFoundPage from "components/error/NotFoundPage";
import useTypedFlags from "hooks/useTypedFlags";

import { DashboardColumns, DashboardConfig, DashboardOverviewStaticColumn } from "./types";
import { DashboardContext } from "./context";
import {
  createInitialConfig,
  getAllDashboardWidgets,
  getInitialConfig,
  splitColumnsConfigBaseOnMode,
} from "./helpers";
import { VIEWS_LIMIT, DASHBOARD_STORAGE_KEY, DefaultDashboardTabs } from "./constants";
import useCurrentTab from "./useCurrentTab";
import { validateConfig } from "./validateConfig";

type DashboardContextProviderProps = {
  children: ReactNode;
};

// TODO: consider to split for drawer and data
const DashboardContextProvider = ({ children }: DashboardContextProviderProps) => {
  const { numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend } =
    useTypedFlags();
  const initialConfig = getInitialConfig({
    numberOfDeploymentsWidgetFrontend,
    approvalPolicyEvaluationsWidgetFrontend,
  });

  const allWidgets = useMemo(
    () =>
      getAllDashboardWidgets({
        numberOfDeploymentsWidgetFrontend,
        approvalPolicyEvaluationsWidgetFrontend,
      }),
    [numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend]
  );

  const { config, updateConfig, configLoading, noData } = useUIConfig<DashboardConfig>(
    DASHBOARD_STORAGE_KEY,
    initialConfig,
    validateConfig({ numberOfDeploymentsWidgetFrontend, approvalPolicyEvaluationsWidgetFrontend })
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
    callbackTrackProviders: { segment: true },
  });

  const [shouldFocusOnInput, setShouldFocusOnInput] = useState(false);
  const [managementDrawerConfig, setManagementDrawerConfig] = useState(config);
  const tabs = Object.entries(config).map(([id, value]) => ({
    id,
    title: value.title,
  }));
  const managementDrawerTabs = Object.entries(managementDrawerConfig).map(([id, value]) => ({
    id,
    title: value.title,
  }));

  const trackStateChange = useCallback(
    (tabName: string, config: DashboardConfig) => {
      trackSegmentAnalyticsEvent("Dashboard - View changed", {
        tabName,
        ...config[tabName],
      });
    },
    [trackSegmentAnalyticsEvent]
  );

  const updateManagementDrawerTabColumnsConfig = useCallback(
    (tab: string, columns: DashboardColumns) => {
      const newConfig = {
        ...managementDrawerConfig,
        [tab]: {
          ...managementDrawerConfig[tab],
          ...columns,
        },
      };

      setManagementDrawerConfig(newConfig);
    },
    [managementDrawerConfig]
  );

  const updateManagementDrawerTabColumnModeConfig = useCallback(
    (tab: string, columnMode: ColumnMode) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        [tab]: {
          ...managementDrawerConfig[tab],
          ...splitColumnsConfigBaseOnMode(
            [...managementDrawerConfig[tab].left, ...managementDrawerConfig[tab].right],
            columnMode
          ),
        },
      });
    },
    [managementDrawerConfig]
  );

  const updateManagementDrawerTabOverviewColumnConfig = useCallback(
    (column: DashboardOverviewStaticColumn) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        overview: {
          ...managementDrawerConfig["overview"],
          overviewColumn: column,
        },
      });
    },
    [managementDrawerConfig]
  );

  const updateTabColumnsConfig = useCallback(
    (tab: string, columns: DashboardColumns) => {
      const newConfig = {
        ...config,
        [tab]: {
          ...config[tab],
          ...columns,
        },
      };

      updateConfig(newConfig);
      trackStateChange(tab, newConfig);
    },
    [trackStateChange, updateConfig, config]
  );

  const updateTabOverviewColumnConfig = useCallback(
    (column: DashboardOverviewStaticColumn) => {
      const newConfig = {
        ...config,
        overview: {
          ...config["overview"],
          overviewColumn: column,
        },
      };

      updateConfig(newConfig);
      trackStateChange("overview", newConfig);
    },
    [updateConfig, config, trackStateChange]
  );

  const resetManagementDrawerConfig = useCallback(
    () => setManagementDrawerConfig(config),
    [config]
  );

  const navigate = useNavigate();
  const currentTab = useCurrentTab();
  const goToFirstTab = useCallback(
    () => navigate(`/dashboard?tab=${DefaultDashboardTabs.Overview}`),
    [navigate]
  );

  const saveManagementDrawerChangesToStorage = useCallback(() => {
    updateConfig(managementDrawerConfig);
    trackSegmentAnalyticsEvent("Dashboard - Configuration changed", managementDrawerConfig);

    if (!Object.keys(managementDrawerConfig).includes(currentTab)) {
      goToFirstTab();
    }
  }, [updateConfig, managementDrawerConfig, currentTab, goToFirstTab, trackSegmentAnalyticsEvent]);

  const [currentManageDrawerTab, setCurrentManageDrawerTab] = useState(currentTab);
  const [isManageDrawerVisible, setIsManageDrawerVisible] = useState(false);

  const setCurrentManageDrawerTabHandler = (tab: string) => {
    setCurrentManageDrawerTab(tab);
    setShouldFocusOnInput(false);
  };

  const openManageDrawer = useCallback(
    (tab?: string, analyticsProps?: AnalyticsCommonProps["analyticsProps"]) => {
      resetManagementDrawerConfig();
      setIsManageDrawerVisible(true);
      trackSegmentAnalyticsEvent("Dashboard - Manage view Clicked", analyticsProps);

      if (tab) {
        setCurrentManageDrawerTab(tab);
      } else {
        setCurrentManageDrawerTab(currentTab);
      }
    },
    [resetManagementDrawerConfig, currentTab, trackSegmentAnalyticsEvent]
  );

  const closeManageDrawer = useCallback(
    (shouldReset = true) => {
      setIsManageDrawerVisible(false);
      setShouldFocusOnInput(false);

      if (shouldReset) {
        resetManagementDrawerConfig();
      }
    },
    [resetManagementDrawerConfig]
  );

  const addNewTabToManagementDrawerConfig = useCallback(
    (prevConfig: DashboardConfig) => {
      const newKey = nanoid();
      const newConfig = {
        ...prevConfig,
        [newKey]: {
          ...createInitialConfig(allWidgets, ColumnMode.Double),
          title: "New tab",
        },
      };

      setManagementDrawerConfig(newConfig);

      if (Object.keys(newConfig).length === VIEWS_LIMIT) {
        trackSegmentAnalyticsEvent("Dashboard - Custom View Limit Exceeded");
      }

      setCurrentManageDrawerTab(newKey);
    },
    [trackSegmentAnalyticsEvent]
  );

  const addNewTab = useCallback(() => {
    trackSegmentAnalyticsEvent("Dashboard - Create New View", {
      method: "dashboard header tab click",
    });
    addNewTabToManagementDrawerConfig(config);
    setIsManageDrawerVisible(true);
    setShouldFocusOnInput(true);
  }, [config, addNewTabToManagementDrawerConfig, trackSegmentAnalyticsEvent]);

  const addNewTabInDrawer = useCallback(() => {
    trackSegmentAnalyticsEvent("Dashboard - Create New View", {
      method: "manage dashboard drawer tab click",
    });
    addNewTabToManagementDrawerConfig(managementDrawerConfig);
    setShouldFocusOnInput(true);
  }, [managementDrawerConfig, addNewTabToManagementDrawerConfig, trackSegmentAnalyticsEvent]);

  const changeTabName = useCallback(
    (id: string, value: string) => {
      setManagementDrawerConfig({
        ...managementDrawerConfig,
        [id]: {
          ...managementDrawerConfig[id],
          title: value,
        },
      });
    },
    [managementDrawerConfig]
  );

  const removeTabInDrawer = useCallback(
    (id: string) => {
      const newmanagementDrawerConfig = cloneDeep(managementDrawerConfig);
      if (newmanagementDrawerConfig[id]) {
        delete newmanagementDrawerConfig[id];
        setManagementDrawerConfig(newmanagementDrawerConfig);

        if (id === currentManageDrawerTab) {
          setCurrentManageDrawerTab(DefaultDashboardTabs.Overview);
        }
      }
    },
    [managementDrawerConfig, currentManageDrawerTab]
  );

  const removeTab = useCallback(
    (id: string) => {
      const newConfig = cloneDeep(config);
      if (newConfig[id]) {
        delete newConfig[id];
        updateConfig(newConfig);
        trackStateChange(id, newConfig);
        setManagementDrawerConfig(newConfig);

        if (id === currentTab) {
          goToFirstTab();
        }
      }
    },
    [config, goToFirstTab, currentTab, updateConfig, trackStateChange]
  );

  if (noData) {
    return <NotFoundPage />;
  }

  return (
    <DashboardContext.Provider
      value={{
        updateManagementDrawerTabColumnsConfig,
        updateManagementDrawerTabOverviewColumnConfig,
        updateTabOverviewColumnConfig,
        updateTabColumnsConfig,
        updateManagementDrawerTabColumnModeConfig,
        saveManagementDrawerChangesToStorage,
        managementDrawerConfig,
        config,
        isManagementDrawerConfigModified: !isEqual(managementDrawerConfig, config),
        isManageDrawerVisible,
        closeManageDrawer,
        openManageDrawer,
        tabs,
        managementDrawerTabs,
        addNewTab,
        addNewTabInDrawer,
        removeTab,
        removeTabInDrawer,
        changeTabName,
        setCurrentManageDrawerTab: setCurrentManageDrawerTabHandler,
        currentManageDrawerTab,
        canAddMoreManagementDrawerViews: Object.keys(managementDrawerConfig).length < VIEWS_LIMIT,
        canAddMoreViews: Object.keys(config).length < VIEWS_LIMIT,
        shouldFocusOnInput,
        configLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
