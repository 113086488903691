import CodeEditor from "components/CodeEditor";
import CollapsibleList from "components/CollapsibleList";
import PolicyTypeBadge from "components/PolicyTypeBadge";
import { Dots, Expand } from "components/icons";
import { StackPolicyAttachment } from "types/generated";
import DropdownIconAction from "ds/components/DropdownIconAction";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import IconAction from "ds/components/IconAction";
import Box from "ds/components/Box";
import useDetachPolicy from "shared/Policy/useDetachPolicy";
import useTypedContext from "hooks/useTypedContext";
import Tag from "ds/components/Tag";
import CollapsibleListTitle from "components/CollapsibleList/Title";
import { getAttachedLabel } from "utils/labels";

import styles from "./styles.module.css";
import { GET_POLICIES_REFETCH_QUERIES } from "./constants";
import { StackContext } from "../Context";
import { showPoliciesDetachConfirmationModal } from "./DetachConfirmationModal";

type StackPoliciesListItemProps = {
  policy: StackPolicyAttachment;
  stackLabels?: string[];
};

const StackPoliciesListItem = ({ policy, stackLabels }: StackPoliciesListItemProps) => {
  const isAutoAttached = policy.isAutoattached;
  const policyLink = `/policy/${policy.policyId}/`;

  const { canManageStackAndRuns } = useTypedContext(StackContext);

  const { detachPolicy, loading } = useDetachPolicy("stack", GET_POLICIES_REFETCH_QUERIES);

  const onDelete = () => {
    detachPolicy(policy.id);
  };

  const handleDelete = () => {
    showPoliciesDetachConfirmationModal({
      policyName: policy.policyName,
      onConfirm: onDelete,
    });
  };

  return (
    <CollapsibleList
      ariaLevel={4}
      title={<CollapsibleListTitle variant="p-t6">{policy.policyName}</CollapsibleListTitle>}
      action={
        <Box justify="between" align="center" gap="medium" grow="1">
          <IconAction icon={Expand} to={policyLink} tooltip="Go to policy editor" />

          <Box align="center" gap="medium">
            {/* FYI: show only the first auto attached label from the list */}
            {isAutoAttached && stackLabels && (
              <Tag tag={getAttachedLabel(stackLabels, policy.policyLabels)} />
            )}

            <PolicyTypeBadge type={policy.policyType} />

            <DropdownIconAction icon={Dots} tooltip="Choose action">
              {() => (
                <DropdownSection>
                  <DropdownSectionItem to={policyLink}>Go to policy editor</DropdownSectionItem>

                  {!isAutoAttached && canManageStackAndRuns && (
                    <DropdownSectionItem loading={loading} danger onClick={handleDelete}>
                      Detach policy
                    </DropdownSectionItem>
                  )}
                </DropdownSection>
              )}
            </DropdownIconAction>
          </Box>
        </Box>
      }
    >
      <CodeEditor
        className={styles.codeEditor}
        body={policy.policyBody}
        language="rego"
        autoHeight
        maxAutoHeight={460}
        readOnly
      />
    </CollapsibleList>
  );
};

export default StackPoliciesListItem;
