import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import useUpdateStack from "shared/Stack/useUpdateStack";
import { getVendorName } from "utils/vendor";
import { VENDOR_KEY, VENDOR_KEY_BY_TYPENAME } from "constants/vendor";
import Feedback from "ds/components/Feedback";
import { StackConfigVendorInput } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import StackSettingsFormWrapper from "../components/FormWrapper";
import StackSettingsPageLayout from "../components/PageLayout";
import { StackSettingsContextData } from "../Context";
import StackSettingsVendorTerraform from "./Terraform";
import StackSettingsVendorAnsible from "./Ansible";
import StackSettingsVendorTerragrunt from "./Terragrunt";
import StackSettingsVendorCloudFormation from "./CloudFormation";
import StackSettingsVendorKubernetes from "./Kubernetes";
import StackSettingsVendorPulumi from "./Pulumi";
import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../constants";

const StackSettingsVendor = () => {
  const { stackSettings } = useTypedContext(StackSettingsContextData);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsVendor,
    callbackTrackProviders: { segment: true },
  });

  const vendorKey = stackSettings.vendorConfig?.__typename
    ? VENDOR_KEY_BY_TYPENAME[stackSettings.vendorConfig.__typename]
    : undefined;
  const vendorName = getVendorName(stackSettings.vendorConfig?.__typename);

  const { stackUpdate, loading } = useUpdateStack({
    stack: stackSettings,
    refetchQueries: REFETCH_STACK_AND_STACK_SETTINGS_QUERIES,
  });

  const onSubmit = useCallback(
    (vendorConfig: StackConfigVendorInput) => {
      stackUpdate(
        {
          vendorConfig,
        },
        () => {
          trackSegmentEvent("Saved", { vendor: vendorKey });
        }
      );
    },
    [stackUpdate, trackSegmentEvent, vendorKey]
  );

  if (!vendorKey || !vendorName) {
    return (
      <Feedback type="callout" variant="danger">
        The Stack doesn't contain the proper vendor config. Please contact us so that we can
        investigate.
      </Feedback>
    );
  }

  return (
    <StackSettingsPageLayout title="Vendor" description="Your infrastructure tool and its options.">
      <StackSettingsFormWrapper>
        {vendorKey === VENDOR_KEY.ANSIBLE && (
          <StackSettingsVendorAnsible onStackUpdate={onSubmit} updateLoading={loading} />
        )}
        {vendorKey === VENDOR_KEY.CLOUD_FORMATION && (
          <StackSettingsVendorCloudFormation onStackUpdate={onSubmit} updateLoading={loading} />
        )}
        {vendorKey === VENDOR_KEY.KUBERNETES && (
          <StackSettingsVendorKubernetes onStackUpdate={onSubmit} updateLoading={loading} />
        )}
        {vendorKey === VENDOR_KEY.PULUMI && (
          <StackSettingsVendorPulumi onStackUpdate={onSubmit} updateLoading={loading} />
        )}
        {vendorKey === VENDOR_KEY.TERRAFORM && (
          <StackSettingsVendorTerraform onStackUpdate={onSubmit} updateLoading={loading} />
        )}
        {vendorKey === VENDOR_KEY.TERRAGRUNT && (
          <StackSettingsVendorTerragrunt onStackUpdate={onSubmit} updateLoading={loading} />
        )}
      </StackSettingsFormWrapper>
    </StackSettingsPageLayout>
  );
};

export default StackSettingsVendor;
