import { isTrackedCommitOutdated } from "shared/Stack/useSyncCommit/accessValidation";
import { RunIgnoredTrigger, RunIgnoredTriggerReasonType, Stack } from "types/generated";

export const getLastIgnoredRunFromStack = (stack: Stack) =>
  stack.searchRunIgnoredTriggers?.edges[0]?.node;

export const shouldShowIgnoredRunCallout = (
  stack: Stack,
  lastIgnoredRun: RunIgnoredTrigger | undefined,
  mostRecentCommit?: string
): lastIgnoredRun is RunIgnoredTrigger => {
  if (!lastIgnoredRun) {
    return false;
  }

  if (isTrackedCommitOutdated(stack)) {
    return true;
  }

  if (!mostRecentCommit) {
    return true;
  }

  if (
    lastIgnoredRun.commit.hash === mostRecentCommit &&
    lastIgnoredRun.reasonType === RunIgnoredTriggerReasonType.TagsIgnoredByDefault
  ) {
    return true;
  }

  return lastIgnoredRun.commit.hash !== mostRecentCommit;
};
