import { ReactNode, useState } from "react";

import PageWrapper from "components/PageWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import IconAction from "ds/components/IconAction";
import { Info } from "components/icons";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import IntegrationsInfoDrawer from "./InfoDrawer";
import styles from "./styles.module.css";

type GroupsPageLayoutProps = {
  children: ReactNode;
};

const IntegrationsLayout = ({ children }: GroupsPageLayoutProps) => {
  const [isInfoDrawerVisible, setIsInfoDrawerVisible] = useState(false);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const openInfoDrawer = () => {
    trackSegmentAnalyticsEvent("Info Click");
    setIsInfoDrawerVisible(true);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="center" fullWidth>
          <ViewHeaderTitle tag="h2">Integrations</ViewHeaderTitle>
          <IconAction onClick={openInfoDrawer} icon={Info} tooltip="Open info drawer" />
        </ViewHeaderWrapper>
      </ViewHeader>

      <IntegrationsInfoDrawer
        isDrawerVisible={isInfoDrawerVisible}
        handleCloseDrawer={() => setIsInfoDrawerVisible(false)}
      />
      <PageWrapper className={styles.container}>{children}</PageWrapper>
    </>
  );
};

export default IntegrationsLayout;
