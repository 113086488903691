import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { AccountContext } from "views/AccountWrapper";
import { ButtonProps } from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import Tooltip from "ds/components/Tooltip";
import { Stack } from "types/generated";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ButtonNew from "ds/components/Button/New";
import SplitControlButton from "ds/components/SplitControl/Button";
import SplitControlWrapper from "ds/components/SplitControl/Wrapper";
import SplitControlDropdown from "ds/components/SplitControl/Dropdown";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { getVendorName } from "utils/vendor";
import { getDisabledTriggerMessage } from "shared/Stack/utils";
import useTriggerStack from "shared/Stack/useTriggerStack";
import { canTriggerStack } from "shared/Stack/useTriggerStack/accessValidation";

import styles from "./styles.module.css";
import TriggerWithCustomConfigDrawer from "./TriggerWithCustomConfigDrawer";

type TriggerButtonProps = {
  stack: Stack;
  text: string;
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  allowCustomConfig?: boolean;
  analyticsPage?: AnalyticsPage;
};

const TriggerButton = ({
  stack,
  text,
  size = "medium",
  variant = "primary",
  allowCustomConfig = false,
  analyticsPage,
}: TriggerButtonProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { reportSuccess } = useTypedContext(FlashContext);

  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [triggerRun, { loading }] = useTriggerStack();

  const canTrigger = canTriggerStack(stack, viewer);

  const disableTriggerButtonMessage = getDisabledTriggerMessage(stack);

  const onClick = (customRuntimeConfig: string | null = null) =>
    triggerRun({ stack: stack.id, customRuntimeConfig }, (data) => {
      const id = data?.runTrigger.id;
      if (id) {
        reportSuccess({ message: `Run ${id} successfully triggered` });
        trackSegmentAnalyticsEvent?.("Run Triggered", {
          stackVendor: getVendorName(stack.vendorConfig?.__typename),
          customRuntimeConfig: !!customRuntimeConfig,
        });
        navigate(`/stack/${stack.id}/run/${id}`);
      }
    });
  if (!canTrigger) {
    return null;
  }

  return (
    <>
      {allowCustomConfig && (
        <TriggerWithCustomConfigDrawer
          isVisible={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onTrigger={onClick}
        />
      )}
      {/** TODO: use DS SplitButton here once implemented */}
      {allowCustomConfig ? (
        <SplitControlWrapper size={size} variant={variant}>
          <SplitControlButton
            tooltip={disableTriggerButtonMessage}
            disabled={loading || !!disableTriggerButtonMessage}
            loading={loading}
            id="stack-view--trigger-split-button"
            onPress={() => onClick()}
          >
            {text}
          </SplitControlButton>
          <SplitControlDropdown
            tooltip={disableTriggerButtonMessage || "More trigger options"}
            disabled={loading || !!disableTriggerButtonMessage}
            className={styles.dropdownButton}
          >
            {({ close }) => (
              <DropdownSection>
                <DropdownSectionItem
                  onClick={() => {
                    onClick();
                    close();
                  }}
                >
                  {text}
                </DropdownSectionItem>
                <DropdownSectionItem
                  onClick={() => {
                    setIsDrawerOpen(true);
                    close();
                  }}
                >
                  Trigger with custom runtime config
                </DropdownSectionItem>
              </DropdownSection>
            )}
          </SplitControlDropdown>
        </SplitControlWrapper>
      ) : (
        <Tooltip
          active={!!disableTriggerButtonMessage}
          on={(tooltipProps) => (
            <ButtonNew
              {...tooltipProps}
              disabled={loading || !!disableTriggerButtonMessage}
              loading={loading}
              variant={variant}
              id="trigger-button"
              size={size}
              onPress={() => onClick()}
            >
              {text}
            </ButtonNew>
          )}
          placement="top"
        >
          {disableTriggerButtonMessage}
        </Tooltip>
      )}
    </>
  );
};

export default TriggerButton;
