import { createContext, useMemo, useState, ReactNode, useCallback } from "react";

import { SCHEDULING_TYPE, SchedulingItem } from "shared/Stack/Scheduling/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackSchedulingContextDataProps, StackSchedulingContextApiProps } from "./types";
import { getScheduleDrawerEntityTypePerItem } from "./helpers";

export const StackSchedulingContextData = createContext<
  StackSchedulingContextDataProps | undefined
>(undefined);
StackSchedulingContextData.displayName = "StackSchedulingContextData";

export const StackSchedulingContextApi = createContext<StackSchedulingContextApiProps | undefined>(
  undefined
);
StackSchedulingContextApi.displayName = "StackSchedulingContextApi";

type StackSchedulingContextProviderProps = {
  children: ReactNode;
};

const StackSchedulingContextProvider = ({ children }: StackSchedulingContextProviderProps) => {
  const [focusedItem, setFocusedItem] = useState<SchedulingItem | undefined>(undefined);
  const [drawerType, setDrawerType] = useState<SCHEDULING_TYPE | undefined>(undefined);
  const [driftDetectionExist, setDriftDetectionExist] = useState<boolean>(false);

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackScheduling,
    callbackTrackProviders: { segment: true },
  });

  const handleItemEditing = useCallback(
    (item: SchedulingItem) => {
      const schedulingType = getScheduleDrawerEntityTypePerItem(item);
      setFocusedItem(item);
      setDrawerType(schedulingType);
      trackSegmentEvent("Schedule Edit Clicked", { type: schedulingType });
    },
    [trackSegmentEvent]
  );

  const handleItemCreating = useCallback((type: SCHEDULING_TYPE) => {
    setDrawerType(type);
  }, []);

  const handleClose = useCallback(() => {
    setFocusedItem(undefined);
    setDrawerType(undefined);
  }, []);

  const apiStore = useMemo(
    () => ({
      onEdit: handleItemEditing,
      onCreate: handleItemCreating,
      onClose: handleClose,
      setDriftDetectionExist,
    }),
    [handleClose, handleItemCreating, handleItemEditing]
  );

  const dataStore = useMemo(
    () => ({
      focusedItem,
      drawerEntityType: drawerType,
      driftDetectionExist,
    }),
    [drawerType, driftDetectionExist, focusedItem]
  );

  return (
    <StackSchedulingContextData.Provider value={dataStore}>
      <StackSchedulingContextApi.Provider value={apiStore}>
        {children}
      </StackSchedulingContextApi.Provider>
    </StackSchedulingContextData.Provider>
  );
};

export default StackSchedulingContextProvider;
