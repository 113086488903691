import { useEffect, useRef } from "react";
import fromUnixTime from "date-fns/fromUnixTime";
import classNames from "classnames";

import Box from "ds/components/Box";
import ListEntitiesItem from "components/ListEntitiesItem";
import { AuditTrailEntry } from "types/generated";
import Typography from "ds/components/Typography";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Link from "ds/components/Link";
import { formatDateTimeByLocale } from "utils/date";
import useApplyFilter from "components/Filters/useApplyFilter";
import Dropdown from "ds/components/Dropdown";
import TextEllipsis from "ds/components/TextEllipsis";
import { Details } from "components/icons";
import ButtonIcon from "ds/components/ButtonIcon";

import {
  COLUMN_GAP,
  COLUMN_ORDER,
  FILTER_ITEMS_DICTIONARY,
  AuditTrailEntrySuggestions,
} from "../../constants";
import EventTypeBadge from "./EventTypeBadge";
import CreatedBy from "./CreatedBy";
import { formatMetadata } from "../../helpers";
import styles from "./styles.module.css";

type AuditTrailListItemProps = {
  item: AuditTrailEntry;
  setRowHeight?: (size: number) => void;
  onOpenDetails: (item: AuditTrailEntry) => void;
};

function AuditTrailListItem({ item, setRowHeight, onOpenDetails }: AuditTrailListItemProps) {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(handleRowHeight);

  const { applyFilter } = useApplyFilter<AuditTrailEntrySuggestions>({
    searchSuggestionsDictionary: FILTER_ITEMS_DICTIONARY,
  });

  const {
    typeLabel: affectedResourceTypeLabel,
    linkLabel: affectedResourceLinkLabel,
    linkUrl: affectedResourceLinkUrl,
  } = formatMetadata(item.affectedResource.resourceType, item.affectedResource.metadata);

  const {
    typeLabel: relatedResourceTypeLabel,
    linkLabel: relatedResourceLinkLabel,
    linkUrl: relatedResourceLinkUrl,
  } = formatMetadata(item.relatedResource?.resourceType, item.relatedResource?.metadata);

  return (
    <ListEntitiesItem
      align="center"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
    >
      <Box align="start" justify="center" direction="column" gap="small" grow="0">
        <Typography tag="span" variant="p-body2">
          {affectedResourceTypeLabel}
        </Typography>
        {affectedResourceLinkLabel && affectedResourceLinkUrl && (
          <Dropdown
            position="bottomRight"
            className={styles.dropdown}
            renderTriggerComponent={({ onClick, ariaProps, isVisible }) => (
              <TextEllipsis
                tooltip={affectedResourceLinkLabel}
                tooltipWidthMode="maxWidthSm"
                isTooltipActive={!isVisible}
              >
                {(ellipsisProps) => (
                  <Link
                    {...ariaProps}
                    {...ellipsisProps}
                    disabled={!item.affectedResource.resourceId}
                    onClick={onClick}
                    justify="start"
                    className={classNames(ellipsisProps.className, styles.link)}
                  >
                    {affectedResourceLinkLabel}
                  </Link>
                )}
              </TextEllipsis>
            )}
          >
            {({ closeDropdown }) => {
              return (
                <DropdownSection>
                  {item.affectedResource.resourceId && (
                    <DropdownSectionItem
                      onClick={() => {
                        applyFilter(AuditTrailEntrySuggestions.AffectedResourceID)(
                          item.affectedResource.resourceId!
                        );
                        closeDropdown();
                      }}
                    >
                      Add resource to filters
                    </DropdownSectionItem>
                  )}
                  <DropdownSectionItem
                    onClick={() => {
                      applyFilter(AuditTrailEntrySuggestions.AffectedResourceType)(
                        item.affectedResource.resourceType
                      );
                      closeDropdown();
                    }}
                  >
                    Add resource type to filters
                  </DropdownSectionItem>
                  <DropdownSectionItem to={affectedResourceLinkUrl}>
                    Go to {affectedResourceTypeLabel}
                  </DropdownSectionItem>
                </DropdownSection>
              );
            }}
          </Dropdown>
        )}
      </Box>
      <Box align="start" justify="center" direction="column" gap="small">
        {item.relatedResource ? (
          <>
            <Typography tag="span" variant="p-body2">
              {relatedResourceTypeLabel}
            </Typography>
            {relatedResourceTypeLabel && relatedResourceLinkUrl && (
              <Dropdown
                position="bottomRight"
                className={styles.dropdown}
                renderTriggerComponent={({ onClick, ariaProps, isVisible }) => (
                  <TextEllipsis
                    tooltip={relatedResourceLinkLabel}
                    tooltipWidthMode="maxWidthSm"
                    isTooltipActive={!isVisible}
                  >
                    {(ellipsisProps) => (
                      <Link
                        {...ariaProps}
                        {...ellipsisProps}
                        disabled={!item.relatedResource!.resourceId}
                        onClick={onClick}
                        justify="start"
                        className={classNames(ellipsisProps.className, styles.link)}
                      >
                        {relatedResourceLinkLabel}
                      </Link>
                    )}
                  </TextEllipsis>
                )}
              >
                {({ closeDropdown }) => {
                  return (
                    <DropdownSection>
                      {item.relatedResource!.resourceId && (
                        <DropdownSectionItem
                          onClick={() => {
                            applyFilter(AuditTrailEntrySuggestions.RelatedResourceID)(
                              item.relatedResource!.resourceId!
                            );
                            closeDropdown();
                          }}
                        >
                          Add resource to filters
                        </DropdownSectionItem>
                      )}
                      <DropdownSectionItem
                        onClick={() => {
                          applyFilter(AuditTrailEntrySuggestions.RelatedResourceType)(
                            item.relatedResource!.resourceType
                          );
                          closeDropdown();
                        }}
                      >
                        Add resource type to filters
                      </DropdownSectionItem>
                      <DropdownSectionItem to={relatedResourceLinkUrl}>
                        Go to {relatedResourceTypeLabel}
                      </DropdownSectionItem>
                    </DropdownSection>
                  );
                }}
              </Dropdown>
            )}
          </>
        ) : (
          <Typography tag="span" variant="p-body2">
            -
          </Typography>
        )}
      </Box>
      <Box align="center">
        <EventTypeBadge eventType={item.eventType} />
      </Box>
      <Box align="center">
        <Typography tag="span" variant="s-body3" className={styles.action}>
          {item.action}
        </Typography>
      </Box>
      <Box align="center">
        <CreatedBy value={item.actor} />
      </Box>
      <Box align="center">
        <Typography tag="span" variant="p-body2">
          {formatDateTimeByLocale({ date: fromUnixTime(item.createdAt), format: "dateTimeFull" })}
        </Typography>
      </Box>
      <Box align="center">
        <ButtonIcon
          icon={Details}
          size="small"
          variant="secondary"
          onClick={() => onOpenDetails(item)}
        >
          Show log details
        </ButtonIcon>
      </Box>
    </ListEntitiesItem>
  );
}

export default AuditTrailListItem;
