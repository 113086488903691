import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import EmptyState from "ds/components/EmptyState";
import { StacksColored } from "components/icons";
import DocumentationButton from "components/DocumentationButton";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { SpacesContext } from "views/account/SpacesProvider";
import { getDocsUrl } from "utils/getDocsUrl";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import { Widget } from "../types";

type DashboardResourcesChartEmptyNoStacksProps = {
  widget?: Widget;
};

const DashboardResourcesChartEmptyNoStacks = ({
  widget,
}: DashboardResourcesChartEmptyNoStacksProps) => {
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  return (
    <TileWrapper padding="x-large" fullWidth justify="center">
      <EmptyState
        title="You don’t have any stacks yet"
        icon={StacksColored}
        caption="Start creating your stacks to see the resources breakdown."
      >
        <Box gap="medium">
          <DocumentationButton
            to={getDocsUrl("/concepts/stack")}
            label="Documentation"
            analyticsPage={AnalyticsPageDashboard.Dashboard}
            analyticsTitle="Widget - Documentation Clicked"
            analyticsProps={{ widget }}
          />
          {hasEntityCreateAccess && (
            <Button variant="primary" to="/new/stack">
              Create stack
            </Button>
          )}
        </Box>
      </EmptyState>
    </TileWrapper>
  );
};

export default DashboardResourcesChartEmptyNoStacks;
