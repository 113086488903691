import { useMemo } from "react";
import { scaleOrdinal } from "@visx/scale";

import styles from "./styles.module.css";
import Box from "../../Box";
import OrdinalLegend from "../components/OrdinalLegend";
import Chart, { BarChartBaseProps } from "./Chart";

type BarChartWithLegendProps = BarChartBaseProps & {
  legendTitle?: string;
};

const BarChart = ({ legendTitle, ...barChartProps }: BarChartWithLegendProps) => {
  const colorScale = useMemo(
    () =>
      scaleOrdinal({
        domain: barChartProps.items,
        range: barChartProps.colors,
      }),
    [barChartProps.colors, barChartProps.items]
  );

  return (
    <Box className={styles.barChart} direction="column" align="center" gap="large" fullWidth>
      <Box grow="1" shrink="1" fullWidth className={styles.chartContainer}>
        <Chart {...barChartProps} colorScale={colorScale} />
      </Box>
      {legendTitle && <OrdinalLegend legendTitle={legendTitle} colorScale={colorScale} />}
    </Box>
  );
};

export default BarChart;
