import { RefObject } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import { Space } from "types/generated";
import { TreeNode } from "utils/tree";
import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import { ChevronNew, LockNew } from "components/icons";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import Tooltip from "ds/components/Tooltip";
import Icon from "ds/components/Icon";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import SearchHighlight from "components/SearchHighlight";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import useTypedContext from "hooks/useTypedContext";

import { COLUMN_GAP, COLUMN_ORDER, LEVEL_MARGIN, SPACE_TREE_LIST_ROW_TEST_ID } from "./constants";
import { createNavigationLink, isReadonlySpace } from "../../utils";
import { SpacesViewActionsContext } from "../../ActionsContext";

type SpacesTreeGridListRowProps = {
  node: TreeNode<Space>;
  isActive: boolean;
  isExpanded: boolean;
  isRootAdmin: boolean;
  searchQuery?: string;
  onToggle: () => void;
  innerRef?: RefObject<HTMLDivElement>;
};

const SpacesTreeGridListRow = ({
  isActive,
  isExpanded,
  isRootAdmin,
  searchQuery,
  onToggle,
  node,
  innerRef,
}: SpacesTreeGridListRowProps) => {
  const { onFilter, onDelete, onSelect, onCreate } = useTypedContext(SpacesViewActionsContext);

  const rowLevel = node.path.length;

  const getRowMargin = (isExpandable: boolean) => {
    const level = rowLevel - 1;
    const expandable = isExpandable ? "0px" : LEVEL_MARGIN;
    const res = `calc(${LEVEL_MARGIN} * ${level} + ${expandable})`;
    return res;
  };

  const handleSelect = (callback: () => void) => () => {
    callback();
    onSelect(node.id);
  };

  const handleCreate = (callback: () => void) => () => {
    callback();
    onCreate(node.id);
    if (!isExpanded) {
      onToggle();
    }
  };

  const handleDelete = (callback: () => void) => () => {
    callback();
    onDelete(node.id);
  };

  const handleFilter = (id: string, path: string, callback: () => void) => () => {
    callback();

    return onFilter(id, path);
  };

  const canBeDeleted = !isReadonlySpace(node.id) && node.children?.length === 0;

  return (
    <ListEntitiesItem
      key={node.id}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
      data-testid={SPACE_TREE_LIST_ROW_TEST_ID}
      ref={innerRef}
      role="row"
      aria-expanded={isExpanded}
      aria-level={rowLevel}
    >
      <Box
        gap="medium"
        align="center"
        style={{ marginLeft: getRowMargin(node.children && node.children.length > 0) }}
        role="rowheader"
      >
        {node.children && node.children.length > 0 && (
          <Box shrink="0">
            <IconAction
              icon={ChevronNew}
              rotate={isExpanded ? "90" : undefined}
              onClick={onToggle}
              tooltip={isExpanded ? "Collapse" : "Expand"}
            />
          </Box>
        )}
        <ListEntitiesItemLink
          title={node.name}
          to={createNavigationLink(`/spaces/${node.id}`)}
          searchQuery={searchQuery}
        />
        {isReadonlySpace(node.id) && isRootAdmin && (
          <Tooltip disablePortal on={(tooltipProps) => <Icon {...tooltipProps} src={LockNew} />}>
            This space is not editable.
          </Tooltip>
        )}

        {!isRootAdmin && <SpaceAccessBadge accessLevel={node.item.accessLevel} />}
      </Box>

      <Box direction="row" align="center" shrink="0" role="gridcell">
        <TextEllipsis tooltip={node.id}>
          {(props) => (
            <Typography {...props} variant="p-body2" tag="span">
              {searchQuery ? (
                <SearchHighlight fullText={node.id} searchQuery={searchQuery} />
              ) : (
                node.id
              )}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
      <Box direction="row" align="center" shrink="0" role="gridcell">
        <Typography tag="span" variant="p-body2">
          {node.item.inheritEntities ? "Yes" : "No"}
        </Typography>
      </Box>
      <Box direction="row" align="center" shrink="0" role="gridcell">
        <DropdownEllipsis tooltip="Space actions" dotsSize="small">
          {({ closeDropdown }) => (
            <>
              <DropdownSection>
                <DropdownSectionItem onClick={handleCreate(closeDropdown)}>
                  Add child space
                </DropdownSectionItem>
                <DropdownSectionItem onClick={handleSelect(closeDropdown)}>
                  {isReadonlySpace(node.id) ? "Details" : "Edit"}
                </DropdownSectionItem>

                <CopyFieldDropdownItem title="Copy ID" value={node.id} callback={closeDropdown} />

                {canBeDeleted && (
                  <DropdownSectionItem onClick={handleDelete(closeDropdown)} danger>
                    Delete
                  </DropdownSectionItem>
                )}
              </DropdownSection>
              <DropdownSection title="Filters">
                <DropdownSectionItem onClick={handleFilter(node.id, "/stacks", closeDropdown)}>
                  View Stacks
                </DropdownSectionItem>
                <DropdownSectionItem onClick={handleFilter(node.id, "/blueprints", closeDropdown)}>
                  View Blueprints
                </DropdownSectionItem>
                <DropdownSectionItem onClick={handleFilter(node.id, "/webhooks", closeDropdown)}>
                  View Webhooks
                </DropdownSectionItem>
              </DropdownSection>
            </>
          )}
        </DropdownEllipsis>
      </Box>
    </ListEntitiesItem>
  );
};

export default SpacesTreeGridListRow;
