import { useMemo } from "react";

import { useToggle } from "hooks/useToggle";
import ProgressBar from "ds/components/ProgressBar";
import CollapsiblePanel from "components/CollapsiblePanel";
import CollapsiblePanelHeader from "components/CollapsiblePanel/Header";
import CollapsiblePanelToggleTrigger from "components/CollapsiblePanel/ToggleTrigger";
import CollapsiblePanelToggleIndicator from "components/CollapsiblePanel/ToggleIndicator";
import CollapsiblePanelTitle from "components/CollapsiblePanel/Title";
import CollapsiblePanelContent from "components/CollapsiblePanel/Content";
import Box from "ds/components/Box";

import useOnboardingProgress from "../useOnboardingProgress";
import { GET_STARTED_FEATURES, GET_STARTED_FEATURES_COUNT } from "./features";
import GetStartedChecklistFeatureTile from "./FeatureTile";
import { Feature } from "../types";
import LaunchPadGetStartedChecklistSkeleton from "./Skeleton";

const LaunchPadGetStartedChecklist = () => {
  const [isCollapsed, handleToggle] = useToggle(false);
  const { onboardingProgressInitialLoading, progressByFeature } = useOnboardingProgress();

  const completedFeatures = useMemo(
    () => Object.values(progressByFeature).filter((feature) => feature.isCompleted).length,
    [progressByFeature]
  );

  return (
    <CollapsiblePanel
      variant="section"
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      withTransition
    >
      <CollapsiblePanelHeader justify="between" padding="x-large" align="center">
        <Box role="heading" aria-level={2} grow="1">
          <CollapsiblePanelToggleTrigger align="center" gap="medium">
            <CollapsiblePanelToggleIndicator />
            <CollapsiblePanelTitle variant="p-t5">Get started checklist</CollapsiblePanelTitle>
          </CollapsiblePanelToggleTrigger>
        </Box>
        {!onboardingProgressInitialLoading && (
          <ProgressBar
            ariaLabel="Number of completed features"
            max={GET_STARTED_FEATURES_COUNT}
            current={completedFeatures}
          />
        )}
      </CollapsiblePanelHeader>
      <CollapsiblePanelContent padding="0 x-large x-large x-large" gap="large">
        {onboardingProgressInitialLoading ? (
          <LaunchPadGetStartedChecklistSkeleton />
        ) : (
          Object.entries(GET_STARTED_FEATURES).map(
            ([type, { title, url, icon, analyticsTitle }]) => (
              <GetStartedChecklistFeatureTile
                key={type}
                title={title}
                to={url}
                icon={icon}
                isCompleted={progressByFeature[type as unknown as Feature].isCompleted}
                analyticsTitle={analyticsTitle}
              />
            )
          )
        )}
      </CollapsiblePanelContent>
    </CollapsiblePanel>
  );
};

export default LaunchPadGetStartedChecklist;
