import { AccountContext } from "views/AccountWrapper";
import { NamedWebhooksIntegration, SpaceAccessLevel } from "types/generated";
import { ButtonProps } from "ds/components/Button";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

type WebhookOptionDropdownProps = {
  id: NamedWebhooksIntegration["id"];
  space: NamedWebhooksIntegration["space"];
  dotSize?: ButtonProps["size"];
  onDeleteClick: () => void;
};

const WebhookOptionDropdown = (props: WebhookOptionDropdownProps) => {
  const { dotSize, id, space, onDeleteClick } = props;
  const { viewer } = useTypedContext(AccountContext);

  const canManageWebhook =
    space.accessLevel === SpaceAccessLevel.Admin || space.accessLevel === SpaceAccessLevel.Write;

  return (
    <DropdownEllipsis dotsSize={dotSize}>
      {({ close }) => (
        <DropdownSection>
          <CopyFieldDropdownItem title="Copy webhook ID" value={id} callback={close} />

          {(viewer.admin || canManageWebhook) && (
            <>
              <DropdownSectionItem to={`/webhook/${id}/edit`}>Edit webhook</DropdownSectionItem>
              <DropdownSectionItem
                onClick={() => {
                  close();
                  onDeleteClick();
                }}
                danger
              >
                Delete webhook
              </DropdownSectionItem>
            </>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default WebhookOptionDropdown;
