import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { Maybe, PolicyStackAttachment } from "types/generated";

import { DETACH_POLICY_FROM_ENTITY } from "./gql";
import { EntityType } from "./types";

const useDetachPolicy = (entityType: EntityType, refetchQueries?: string[]) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentEvent = useAnalytics({
    page:
      entityType === "stack"
        ? AnalyticsPageStack.StacksPolicies
        : AnalyticsPageModule.ModulePolicies,
    callbackTrackProviders: {
      segment: true,
    },
  });

  const [detachPolicy, { loading }] = useMutation<{ policyDetach: Maybe<PolicyStackAttachment> }>(
    DETACH_POLICY_FROM_ENTITY,
    {
      onError,
      refetchQueries,
      awaitRefetchQueries: true,
    }
  );

  const handleDetachPolicy = useCallback(
    (attachmentId: string, successCallback?: () => void) => {
      detachPolicy({ variables: { attachmentId } })
        .then(({ data }) => {
          if (data?.policyDetach) {
            reportSuccess({
              message: "Policy was successfully detached",
            });
            trackSegmentEvent("Policy detached");
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [detachPolicy, onError, reportSuccess, trackSegmentEvent]
  );

  return {
    detachPolicy: handleDetachPolicy,
    loading,
  };
};

export default useDetachPolicy;
