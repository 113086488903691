import { memo } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { WorkerPool } from "types/generated";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import WorkerPoolResetAction from "../WorkerPool/ResetAction";
import WorkerPoolDeleteAction from "../WorkerPool/DeleteAction";
import WorkerPoolCycleAction from "../WorkerPool/CycleAction";
import { showCycleConfirmation } from "../WorkerPool/CycleConfirmation";
import { showDeleteConfirmation } from "../WorkerPool/DeleteConfirmation";
import { showResetConfirmation } from "../WorkerPool/ResetConfirmation";

type PrivateWorkerPoolActionsDropdownProps = {
  workerPoolId: WorkerPool["id"];
  workerPoolName: WorkerPool["name"];
  hasUsers: boolean;
  hasBusyWorkers: boolean;
  onEdit: () => void;
  canManageWorkerPools: boolean;
};

const PrivateWorkerPoolActionsDropdown = ({
  workerPoolId,
  workerPoolName,
  hasUsers,
  hasBusyWorkers,
  onEdit,
  canManageWorkerPools,
}: PrivateWorkerPoolActionsDropdownProps) => {
  const handleOnEdit = (callback: () => void) => () => {
    onEdit();
    callback();
  };

  const handleOnCycle = (callback: () => void) => () => {
    showCycleConfirmation({ id: workerPoolId, name: workerPoolName });
    callback();
  };

  const handleOnReset = (callback: () => void) => () => {
    showResetConfirmation({ id: workerPoolId, name: workerPoolName, viewPageMode: true });
    callback();
  };

  const handleOnDelete = (callback: () => void) => () => {
    showDeleteConfirmation({ id: workerPoolId, name: workerPoolName, viewPageMode: true });
    callback();
  };

  return (
    <DropdownEllipsis>
      {({ close }) => (
        <DropdownSection>
          <CopyFieldDropdownItem title="Copy ID" value={workerPoolId} callback={close} />
          {canManageWorkerPools && (
            <>
              <DropdownSectionItem onClick={handleOnEdit(close)}>Edit</DropdownSectionItem>
              <WorkerPoolCycleAction
                onClick={handleOnCycle(close)}
                hasBusyWorkers={hasBusyWorkers}
              />
              <WorkerPoolResetAction
                onClick={handleOnReset(close)}
                hasBusyWorkers={hasBusyWorkers}
              />
              <WorkerPoolDeleteAction
                onClick={handleOnDelete(close)}
                hasUsers={hasUsers}
                hasBusyWorkers={hasBusyWorkers}
              />
            </>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default memo(PrivateWorkerPoolActionsDropdown);
