import { TypographyColors } from "types/Colors";
import { IconColors } from "ds/components/Icon/types";

import { FormFieldColor } from "./types";

type ReturnType = {
  primaryTextColor: TypographyColors;
  primaryIconColor: IconColors;
  secondaryColor: TypographyColors;
  dangerColor: TypographyColors;
};

export const getColors = (color?: FormFieldColor): ReturnType => {
  switch (color) {
    case "inversed":
      return {
        primaryTextColor: "on-solid-primary",
        primaryIconColor: "on-solid-primary",
        secondaryColor: "on-solid-secondary",
        dangerColor: "danger-inversed",
      };
    case "default":
    default:
      return {
        primaryTextColor: "primary",
        primaryIconColor: "primary",
        secondaryColor: "secondary",
        dangerColor: "danger",
      };
  }
};
