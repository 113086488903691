import { CalendarCell, CalendarGrid, Heading } from "react-aria-components";

import { ChevronNew } from "components/icons";

import IconActionButton from "../IconAction/Button";
import styles from "./styles.module.css";
import Box from "../Box";

const DatepickerCalendarContent = () => (
  <>
    <Box align="center" justify="between" margin="0 0 large 0">
      <IconActionButton
        tooltip="Previous"
        slot="previous"
        icon={ChevronNew}
        rotate="180"
        disableTooltipPortal
      />
      <Heading className={styles.calendarHeading} />
      <IconActionButton tooltip="Next" slot="next" icon={ChevronNew} disableTooltipPortal />
    </Box>
    <CalendarGrid weekdayStyle="short">
      {(date) => <CalendarCell className={styles.calendarCell} date={date} />}
    </CalendarGrid>
  </>
);

export default DatepickerCalendarContent;
