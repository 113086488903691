import { getApolloClient } from "apollo/client";

import { IDENTIFY_USER_EVENT } from "./gql";

export const identifyUserEvent = (traits?: Record<string, string>) => {
  const apolloClient = getApolloClient();

  if (!apolloClient) {
    return;
  }

  let variables: Record<string, string> | undefined;

  try {
    const context = JSON.stringify({
      traits,
    });
    variables = {
      context,
    };
  } catch {
    variables = undefined;
  }

  apolloClient
    .mutate({
      mutation: IDENTIFY_USER_EVENT,
      variables,
    })
    .catch(() => {
      // do nothing, this is the best effort approach
    });
};
