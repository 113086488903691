import { ReactNode, RefObject, useState } from "react";

import { isEllipsisActive } from "utils/browser";

import styles from "./styles.module.css";
import Tooltip from "../Tooltip";
import { TooltipProps, TooltipReferenceProps } from "../Tooltip/types";

type TextEllipsisProps = {
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  tooltipDisablePortal?: boolean;
  children: (props: TooltipReferenceProps, isActive: boolean) => ReactNode;
  tooltip: ReactNode;
  delay?: number;
  mode?: "single" | "line-clamp";
  isTooltipActive?: boolean;
  tooltipOffset?: number;
};

const TextEllipsis = (props: TextEllipsisProps) => {
  const {
    children,
    tooltip,
    delay,
    tooltipPlacement,
    tooltipWidthMode,
    tooltipDisablePortal,
    mode = "single",
    tooltipOffset,
  } = props;

  const [isTooltipActive, setTooltipActive] = useState(false);

  const initializeEllipsis = (innerRef: RefObject<HTMLElement>) => {
    if (innerRef.current) {
      setTooltipActive(isEllipsisActive(innerRef.current, mode === "line-clamp"));
    }
  };

  return (
    <Tooltip
      active={isTooltipActive}
      widthMode={tooltipWidthMode}
      placement={tooltipPlacement}
      disablePortal={tooltipDisablePortal}
      onChildrenChangedCallback={initializeEllipsis}
      delay={delay}
      triggerClassName={styles.textEllipsis}
      on={(props) => children(props, isTooltipActive)}
      offset={tooltipOffset}
    >
      {tooltip}
    </Tooltip>
  );
};

export default TextEllipsis;
