import { type ReactNode } from "react";

import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSection from "ds/components/Dropdown/Section";

import { BulkActionsVariant } from "../../types";
import { BUTTON_PROPS_BY_VARIANT } from "../helpers";

type BulkActionsChooseActionStepActionMoreDropdownProps = {
  variant: BulkActionsVariant;
  children: ReactNode;
};

const BulkActionsChooseActionStepActionMoreDropdown = ({
  variant,
  children,
}: BulkActionsChooseActionStepActionMoreDropdownProps) => {
  const buttonProps = BUTTON_PROPS_BY_VARIANT[variant];
  return (
    <DropdownEllipsis buttonVariant={buttonProps.variant} dotsSize={buttonProps.size}>
      {() => <DropdownSection>{children}</DropdownSection>}
    </DropdownEllipsis>
  );
};

export default BulkActionsChooseActionStepActionMoreDropdown;
