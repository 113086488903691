import { useMemo } from "react";
import fromUnixTime from "date-fns/fromUnixTime";
import subDays from "date-fns/subDays";
import getUnixTime from "date-fns/getUnixTime";
import addDays from "date-fns/addDays";

import Tooltip from "ds/components/Tooltip";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type RunOriginDropdownLinkProps = {
  runId: string;
  runCreatedAt: number;
};

const RunOriginDropdownLink = ({ runId, runCreatedAt }: RunOriginDropdownLinkProps) => {
  const startDate = useMemo(
    () => getUnixTime(subDays(fromUnixTime(runCreatedAt), 3)),
    [runCreatedAt]
  );

  const endDate = useMemo(
    () => getUnixTime(addDays(fromUnixTime(runCreatedAt), 3)),
    [runCreatedAt]
  );

  const dateRangeParam = {
    startDate,
    endDate,
    label: "",
  };
  const dateRange = `${btoa(JSON.stringify(dateRangeParam))}`;

  return (
    <Tooltip
      widthMode="maxWidthSm"
      placement="left"
      delay={0}
      on={({ ref, ...props }) => (
        <DropdownSectionItem
          {...props}
          innerRef={ref}
          to={{
            pathname: `/runs`,
            search: `?dateRange=${dateRange}&zoomTarget=${btoa(JSON.stringify(runId))}`,
          }}
        >
          Run origin
        </DropdownSectionItem>
      )}
    >
      A visualization of the action(s) that have triggered this run. It also shows all of the
      related runs.
    </Tooltip>
  );
};

export default RunOriginDropdownLink;
