import { ErrorColored } from "components/icons";
import Link from "ds/components/Link";
import SystemMessage from "components/SystemMessage";
import Typography from "ds/components/Typography";
import useTitle from "hooks/useTitle";

import styles from "./styles.module.css";

const UnauthorizedPage = () => {
  useTitle("Unauthorized · Spacelift");

  return (
    <SystemMessage title="Unauthorized" text="" icon={ErrorColored}>
      <Typography variant="p-body2" tag="p" className={styles.text}>
        Sorry, looks like you're not authorized to access this Spacelift account. This could well be
        an error - try{" "}
        <Link to="/login" size="medium">
          logging in again
        </Link>{" "}
        to see if that helps.
      </Typography>
    </SystemMessage>
  );
};

export default UnauthorizedPage;
