import { useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import { WebhookDelivery } from "types/generated";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import WebhookDeliveriesStatus from "views/account/Webhook/ListItem/Status";
import TextEllipsis from "ds/components/TextEllipsis";
import Timestamp from "components/time/Timestamp";
import Typography from "ds/components/Typography";

import { COLUMN_ORDER } from "./constants";

type StackSettingsIntegrationsWebhookDeliveryListItemProps = {
  item: WebhookDelivery;
  setRowHeight?: (size: number) => void;
};

const StackSettingsIntegrationsWebhookDeliveryListItem = ({
  item,
  setRowHeight,
}: StackSettingsIntegrationsWebhookDeliveryListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap="0 large"
      ref={rowRef}
    >
      <Box align="center">
        <ListEntitiesItemLink
          to={`delivery/${encodeURIComponent(item.id)}`}
          title={item.id}
          v5Compat
        />
      </Box>
      <Box align="center">
        <WebhookDeliveriesStatus status={item.status} />
      </Box>
      <Box align="center">
        <TextEllipsis
          tooltip={<Timestamp timestamp={item.timestamp} />}
          tooltipWidthMode="maxWidthSm"
        >
          {(props) => (
            <Typography {...props} tag="span" variant="p-body2">
              <Timestamp timestamp={item.timestamp} />
            </Typography>
          )}
        </TextEllipsis>
      </Box>
    </ListEntitiesItem>
  );
};

export default StackSettingsIntegrationsWebhookDeliveryListItem;
