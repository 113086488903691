import { useMemo } from "react";

import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { ChevronNew } from "components/icons";
import BadgeNext from "ds/components/BadgeNext";
import IconAction from "ds/components/IconAction";
import { RunPolicyReceipt } from "types/generated";
import { useToggle } from "hooks/useToggle";
import Tree, { TreeBranch, TreeBranchLink } from "ds/components/Tree";

import HistoryEntryDate from "../../components/HistoryEntryDate";
import { getPolicyEvaluation } from "./utils";
import HistoryEntryWrapperSeparator from "../../components/HistoryEntryWrapper/Separator";
import HistoryEntryPolicy from "../../components/HistoryEntryPolicy";

type PoliciesProps = {
  policies: RunPolicyReceipt[];
  stackId: string;
  runId: string;
  isModuleRun: boolean;
};

function Policies({ policies, stackId, runId, isModuleRun }: PoliciesProps) {
  const [isOpen, toggle] = useToggle(true);

  const evaluation = useMemo(() => getPolicyEvaluation(policies), [policies]);

  return (
    <TreeBranch>
      <TreeBranchLink>
        <Box align="center" gap="medium" padding="small 0" fullWidth>
          <IconAction
            icon={ChevronNew}
            onClick={() => toggle()}
            rotate={isOpen ? "90" : undefined}
            tooltip={isOpen ? "Collapse" : "Expand"}
          />

          <Typography variant="p-body3" tag="span" color="secondary">
            Policies evaluated to
          </Typography>

          <BadgeNext
            text={evaluation.label.toUpperCase()}
            variant={evaluation.badgeVariant}
            type="strong"
          />

          <HistoryEntryWrapperSeparator margin="0" />

          <HistoryEntryDate timestamp={evaluation.timestamp!} />
        </Box>
      </TreeBranchLink>
      {isOpen && (
        <Tree margin="0 0 0 large">
          {policies.map((policy) => (
            <HistoryEntryPolicy
              key={policy.id}
              item={policy}
              runId={runId}
              stackId={stackId}
              isModuleRun={isModuleRun}
            />
          ))}
        </Tree>
      )}
    </TreeBranch>
  );
}

export default Policies;
