import { useCallback, useMemo, useState, useEffect, useRef } from "react";

import { convertToTreeStructure, isNodeVisible, updateTreeRendering } from "utils/tree";
import Box from "ds/components/Box";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";

import ConfigManagementTreeGridListSortHeader from "./SortHeader";
import styles from "./styles.module.css";
import ConfigManagementTreeGridListRow from "./Row";
import { ConfigNode } from "../../types";
import ConfigManagementTreeGridListSortHeaderStatic from "./SortHeaderStatic";

type ConfigManagementTreeGridListProps = {
  nodes: ConfigNode[];
  activeId?: string;
  sortable?: boolean;
  analyticsPage?: AnalyticsPage;
};
const ConfigManagementTreeGridList = ({
  nodes,
  activeId,
  sortable,
  analyticsPage,
}: ConfigManagementTreeGridListProps) => {
  const selectedRef = useRef<HTMLDivElement>(null);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const { flatList } = useMemo(
    () => convertToTreeStructure<ConfigNode>(nodes, "id", "parent", "name"),
    [nodes]
  );

  const allKeysSet = useMemo(() => {
    const allKeys = flatList.map((item) => item.id);
    return new Set(allKeys);
  }, [flatList]);

  const [expandedKeys, setExpandedKeys] = useState(new Set<string>());
  const [loadingKeys, setLoadingKeys] = useState(new Set<string>());
  const [renderedKeys, setRenderedKeys] = useState(new Set<string>());
  const visibleNodes = useMemo(
    () => flatList.filter((node) => isNodeVisible(node, renderedKeys, expandedKeys)),
    [expandedKeys, renderedKeys, flatList]
  );
  useEffect(() => {
    selectedRef?.current?.scrollIntoView({ block: "center", inline: "nearest" });
  }, []);

  useEffect(() => {
    const { renderedNodes } = updateTreeRendering(
      flatList,
      flatList.map((node) => node.path)
    );

    setRenderedKeys(renderedNodes);
  }, [allKeysSet, flatList]);

  useEffect(() => {
    if (activeId && !expandedKeys.has(activeId)) {
      const item = flatList.find(({ id }) => id === activeId);

      if (item?.path && item.parentId && !expandedKeys.has(item.parentId)) {
        const { expandedNodes } = updateTreeRendering(flatList, [item?.path]);
        setExpandedKeys(expandedNodes);
      }
    }
  }, [activeId]);

  const toggleKey = useCallback(
    (key: string, hasChildrenToLoad?: boolean) => () => {
      // TODO: in the next iteration when API ready add query to load children and the switch keys
      if (!hasChildrenToLoad) {
        setExpandedKeys((prev) => {
          const newKeys = new Set(prev);
          if (newKeys.has(key)) {
            newKeys.delete(key);
            trackSegmentAnalyticsEvent?.("List collapsed");
          } else {
            trackSegmentAnalyticsEvent?.("List expanded");
            newKeys.add(key);
          }
          return newKeys;
        });
      } else {
        setLoadingKeys((prev) => {
          const newKeys = new Set(prev);
          if (newKeys.has(key)) {
            newKeys.delete(key);
            trackSegmentAnalyticsEvent?.("List collapsed");
          } else {
            newKeys.add(key);
            trackSegmentAnalyticsEvent?.("List expanded");
          }
          return newKeys;
        });
        // TODO: then unset when loaded or error
      }
    },
    [setExpandedKeys, trackSegmentAnalyticsEvent]
  );

  return (
    <Box direction="column" grow="1" role="treegrid">
      {visibleNodes.length > 0 && sortable && <ConfigManagementTreeGridListSortHeader />}
      {visibleNodes.length > 0 && !sortable && <ConfigManagementTreeGridListSortHeaderStatic />}

      <Box direction="column" grow="1" className={styles.listWrapper} role="rowgroup" fullWidth>
        {visibleNodes.map((node) => (
          <ConfigManagementTreeGridListRow
            key={node.id}
            node={node}
            isActive={activeId === node.id}
            loading={loadingKeys.has(node.id)}
            hasChildrenToLoad={node.item.hasChildrenToLoad}
            isExpanded={expandedKeys.has(node.id)}
            onToggle={toggleKey(node.id, node.item.hasChildrenToLoad)}
            innerRef={activeId === node.id ? selectedRef : undefined}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ConfigManagementTreeGridList;
