import { useState } from "react";

import ListEntitiesNew from "components/ListEntitiesNew";
import { ManagedUser, ManagedUserGroup } from "types/generated";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UsersListVirtualizedItem from "./VirtualizedItem";
import UsersDetailsDrawer from "../DetailsDrawer";
import styles from "./styles.module.css";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";

type UsersListProps = {
  users: ManagedUser[];
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersList = ({ users, managedUserGroups }: UsersListProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const [user, setUser] = useState<ManagedUser | undefined>(undefined);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const handleUserDetailsClick = (details: ManagedUser) => {
    trackSegmentAnalyticsEvent("Access Details Click");
    setUser(details);
  };
  const handleCloseUserDetailsDrawer = () => setUser(undefined);

  return (
    <Box direction="column" grow="1">
      <UsersDetailsDrawer
        managedUserGroups={managedUserGroups}
        isDrawerVisible={!!user}
        handleCloseDrawer={handleCloseUserDetailsDrawer}
        currentUser={user}
      />
      <div className={styles.wrapper}>
        <ListEntitiesNew
          itemCount={users.length}
          itemProps={{
            users,
            handleUserDetailsClick,
          }}
          virtualizedItem={UsersListVirtualizedItem}
          itemKey={(index) => users[index].id}
        />
      </div>
    </Box>
  );
};

export default UsersList;
