import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { NetworkStatus } from "@apollo/client";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import useUpdateStack from "shared/Stack/useUpdateStack";
import FormField from "ds/components/Form/Field";
import Select from "ds/components/Select";
import { useWorkerPoolOptions } from "shared/WorkerPool/useWorkerPoolOptions";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import MissingDataBanner from "components/MissingDataBanner";
import { stringIsRequired } from "utils/formValidators";
import Input from "ds/components/Input";
import { VENDOR_CONFIG_TYPENAME } from "constants/vendor";
import ToggleField from "ds/components/Form/ToggleField";
import { isSelfHostedDistribution } from "utils/distribution";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import useTypedFlags from "hooks/useTypedFlags";
import StackEnableSensitiveOutputUploadFormField from "components/Forms/Stack/EnableSensitiveOutputUploadFormField";

import { StackSettingsContextData } from "../Context";
import { getFormDefaultValues, mapCreateStackBehaviorUpdateInput } from "./helpers";
import StackSettingsFormFooter from "../components/FormFooter";
import { REFETCH_STACK_AND_STACK_SETTINGS_QUERIES } from "../constants";
import StackSettingsBehaviorInfoBanner from "./InfoBanner";
import {
  getAdministrativeTooltip,
  getAllowRunPromotionTooltip,
  getAutodeployTooltip,
  getAutoretryTooltip,
  getEnableSecretMaskingTooltip,
  getLocalPreviewEnabledTooltip,
  getProtectFromDeletionTooltip,
  getRunnerImageTooltip,
  getWorkerPoolTooltip,
} from "./getTooltips";

const isSelfHosted = isSelfHostedDistribution();

export type StackSettingsBehaviorFormFields = {
  workerPool: string;
  runnerImage: string;
  administrative: boolean;
  githubActionDeploy: boolean;
  autodeploy: boolean;
  autoretry: boolean;
  protectFromDeletion: boolean;
  localPreviewEnabled: boolean;
  enableWellKnownSecretMasking: boolean;
  enableSensitiveOutputUpload: boolean;
};

const StackSettingsBehaviorEdit = () => {
  const { showLeakingSensitiveOutputsThroughDependencies } = useTypedFlags();

  const { stackSettings } = useTypedContext(StackSettingsContextData);
  const isPulumiVendor = stackSettings.vendorConfig?.__typename === VENDOR_CONFIG_TYPENAME.PULUMI;

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsBehavior,
    callbackTrackProviders: { segment: true },
  });

  const {
    workerPoolsOptions,
    hasData: hasWorkerPoolsOptions,
    refetch: refetchWorkerPoolsOptions,
    loading: workerPoolsOptionsLoading,
    networkStatus: workerPoolsOptionsNetworkStatus,
  } = useWorkerPoolOptions(stackSettings.spaceDetails.id);

  const { stackUpdate, loading } = useUpdateStack({
    stack: stackSettings,
    refetchQueries: REFETCH_STACK_AND_STACK_SETTINGS_QUERIES,
  });

  const stackBehaviorForm = useForm<StackSettingsBehaviorFormFields>({
    defaultValues: getFormDefaultValues(stackSettings),
    mode: "onChange",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    reset,
    setValue,
    watch,
  } = stackBehaviorForm;

  const selectedWorkerPool = watch("workerPool");

  const handleWorkerPoolChange = (workerPool: string) => {
    setValue("workerPool", workerPool, { shouldValidate: true, shouldDirty: true });

    if (workerPool === WORKER_POOL_SHARED_VALUE) {
      setValue("autoretry", false);
    }
  };

  const onSubmit = (formData: StackSettingsBehaviorFormFields) => {
    stackUpdate(mapCreateStackBehaviorUpdateInput(formData), () => {
      trackSegmentEvent("Saved");
    });
  };

  useEffect(() => {
    reset(getFormDefaultValues(stackSettings));
  }, [reset, stackSettings]);

  useEffect(() => {
    if (!selectedWorkerPool) {
      if (isSelfHosted && workerPoolsOptions?.length) {
        setValue("workerPool", workerPoolsOptions[0].value, { shouldValidate: true });
      }
    } else if (hasWorkerPoolsOptions || (!workerPoolsOptionsLoading && !hasWorkerPoolsOptions)) {
      // validate worker pool shared via link
      const isNotAvailable = !workerPoolsOptions.find(({ value }) => {
        return value === selectedWorkerPool;
      });
      if (isNotAvailable) {
        setValue("workerPool", "");
      }
    }
  }, [
    workerPoolsOptions,
    setValue,
    selectedWorkerPool,
    hasWorkerPoolsOptions,
    workerPoolsOptionsLoading,
  ]);

  return (
    <FormProvider {...stackBehaviorForm}>
      <Controller
        name="workerPool"
        rules={{
          required: "Worker pool is required",
        }}
        control={control}
        render={({ field, fieldState }) => (
          <FormField
            error={fieldState.error?.message}
            label="Worker pool"
            {...getWorkerPoolTooltip()}
            noMargin
          >
            {({ ariaInputProps }) => (
              <>
                <Select
                  disabled={workerPoolsOptions.length === 1}
                  value={field.value}
                  options={workerPoolsOptions}
                  onChange={handleWorkerPoolChange}
                  error={!!fieldState.error?.message}
                  placeholder="Select worker pool"
                  ariaInputProps={ariaInputProps}
                />
                {!hasWorkerPoolsOptions && !workerPoolsOptionsLoading && (
                  <Box direction="column" margin="large 0 0 0">
                    <MissingDataBanner
                      text="Couldn't load your private worker pools, please refresh or come back later"
                      refreshHandler={refetchWorkerPoolsOptions}
                      refreshLoading={
                        workerPoolsOptionsLoading &&
                        workerPoolsOptionsNetworkStatus === NetworkStatus.refetch
                      }
                    />
                  </Box>
                )}
              </>
            )}
          </FormField>
        )}
      />
      <FormField
        label="Runner image"
        error={errors?.runnerImage?.message}
        isOptional={!isPulumiVendor}
        noMargin
        {...getRunnerImageTooltip()}
      >
        {({ ariaInputProps }) => (
          <Input
            placeholder="Runner image"
            {...register("runnerImage", {
              setValueAs: (value: string) => value.trim(),
              ...(isPulumiVendor && { validate: stringIsRequired() }),
            })}
            error={!!errors?.runnerImage?.message}
            {...ariaInputProps}
          />
        )}
      </FormField>
      <Controller
        name="administrative"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Administrative"
            description="Stack will receive a runtime environment variable giving administrative access to other stacks within the same account"
            tooltipInfo={getAdministrativeTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="githubActionDeploy"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Allow run promotion"
            description="Proposed runs can be promoted (deployed) using the Spacelift API or GitHub Actions"
            tooltipInfo={getAllowRunPromotionTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="autodeploy"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Autodeploy"
            description="Changes to the stack will be applied automatically"
            tooltipInfo={getAutodeployTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="autoretry"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            disabled={selectedWorkerPool === WORKER_POOL_SHARED_VALUE}
            onChange={field.onChange}
            checked={field.value}
            title="Autoretry"
            description="Tracked runs will automatically retry invalidated proposed runs"
            tooltipInfo={getAutoretryTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="localPreviewEnabled"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Enable local preview"
            description="Run local previews using spacectl."
            tooltipInfo={getLocalPreviewEnabledTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="enableWellKnownSecretMasking"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Enable secret masking"
            description="Indicates whether secret patterns will be automatically redacted from logs."
            tooltipInfo={getEnableSecretMaskingTooltip().tooltipInfo}
          />
        )}
      />
      <Controller
        name="protectFromDeletion"
        control={control}
        render={({ field }) => (
          <ToggleField
            variant="switch"
            onChange={field.onChange}
            checked={field.value}
            title="Protect from deletion (recommended)"
            description="Manage deletion protection"
            tooltipInfo={getProtectFromDeletionTooltip().tooltipInfo}
          />
        )}
      />
      {showLeakingSensitiveOutputsThroughDependencies && (
        <StackEnableSensitiveOutputUploadFormField<StackSettingsBehaviorFormFields> name="enableSensitiveOutputUpload" />
      )}
      <StackSettingsBehaviorInfoBanner />
      <StackSettingsFormFooter
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => reset(getFormDefaultValues(stackSettings))}
        isDirty={isDirty}
        isSubmitDisabled={!isValid || !isDirty || loading}
        submitLoading={loading}
      />
    </FormProvider>
  );
};

export default StackSettingsBehaviorEdit;
