import { gql, TypedDocumentNode } from "@apollo/client";

import { Stack } from "types/generated";

export type FetchListIgnoredRunsData = {
  stack: Pick<
    Stack,
    | "id"
    | "canWrite"
    | "searchRunIgnoredTriggers"
    | "trackedCommit"
    | "trackedBranchHead"
    | "trackedCommitSetBy"
    | "state"
  >;
};

export type FetchListIgnoredRunsVariables = {
  stackId: string;
  after?: string;
};

export const LIST_IGNORED_RUNS: TypedDocumentNode<
  FetchListIgnoredRunsData,
  FetchListIgnoredRunsVariables
> = gql`
  query ListIgnoredRuns($stackId: ID!, $after: String) {
    stack(id: $stackId) {
      id
      canWrite
      searchRunIgnoredTriggers(
        input: { first: 50, orderBy: { field: "createdAt", direction: DESC }, after: $after }
      ) {
        edges {
          node {
            id
            createdAt
            reasonType
            policyAttachments {
              policy {
                id
                name
              }
              policyName
            }
            commit {
              hash
              timestamp
              authorLogin
              authorName
              url
              message
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      trackedCommit {
        url
        hash
      }
      trackedBranchHead {
        url
        hash
      }
      trackedCommitSetBy
      state
    }
  }
`;
