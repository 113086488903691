import { forwardRef, InputHTMLAttributes, useState } from "react";

import { Invisible, Visible } from "components/icons";

import Input, { InputProps } from "../Input";
import styles from "./styles.module.css";

type SecretInputProps = {
  disablePreview?: boolean;
  size?: InputProps["size"];
  error?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "color">;

const SecretInput = forwardRef(function SecretInput(
  props: SecretInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { size = "regular", error = false, disablePreview, ...restProps } = props;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Input
        size={size}
        className={styles.input}
        error={error}
        type={isVisible && !disablePreview ? "text" : "password"}
        {...restProps}
        ref={ref}
      />
      {!disablePreview && (
        <button
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          className={styles.button}
          aria-label={isVisible ? "Hide" : "Show"}
        >
          {isVisible ? <Invisible className={styles.icon} /> : <Visible className={styles.icon} />}
        </button>
      )}
    </div>
  );
});

export default SecretInput;
