import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRunTriggerArgs, Stack } from "types/generated";

export type FetchStackTriggerData = { runTrigger: Pick<Stack, "id" | "state"> };
export type MutationRunTriggerArgsShared = Pick<
  // using Pick here, as we're only using these fields in the trigger run mutation. If you need to use more fields, you can add them here.
  MutationRunTriggerArgs,
  "stack" | "customRuntimeConfig"
>;

export const STACK_TRIGGER: TypedDocumentNode<FetchStackTriggerData, MutationRunTriggerArgsShared> =
  gql`
    mutation StackRunTrigger($stack: ID!, $customRuntimeConfig: String) {
      runTrigger(stack: $stack, customRuntimeConfig: $customRuntimeConfig) {
        id
        state
      }
    }
  `;
