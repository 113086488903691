import { DiffEditor } from "@monaco-editor/react";
import { useState } from "react";

import Modal from "ds/components/Modal";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import Toggle from "ds/components/Toggle";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";
import Box from "ds/components/Box";

import { prettify } from "./helpers";
import styles from "./styles.module.css";
import { EncodedJsonValueTuple } from "./types";

type JsonDiffModalProps = {
  value: EncodedJsonValueTuple | null;
  onClose: (callback?: () => void) => () => unknown;
};

function JsonDiffModal({ value, onClose }: JsonDiffModalProps) {
  const [sideBySide, setSideBySide] = useState(true);

  const { isDarkMode, currentDarkModeSyntaxTheme, currentLightModeSyntaxTheme } =
    useTypedContext(ThemeContext);

  const handleSideBySideChange = () => setSideBySide((currentValue) => !currentValue);

  return (
    <Modal
      forceIsVisible={!!value}
      setForceModalVisibility={onClose()}
      size="medium"
      preventClosing
    >
      {({ closeModal }) => (
        <>
          <ModalHeader title="JSON Diff" hideModal={onClose(closeModal)} />
          <ModalContent>
            {value && (
              <DiffEditor
                original={prettify(value[0])}
                modified={prettify(value[1])}
                language="json"
                className={styles.jsonViewEditor}
                theme={isDarkMode ? currentDarkModeSyntaxTheme : currentLightModeSyntaxTheme}
                options={{
                  renderSideBySide: sideBySide,
                  glyphMargin: false,
                  readOnly: true,
                  scrollBeyondLastLine: false,
                  scrollbar: {
                    verticalScrollbarSize: 4,
                    horizontalScrollbarSize: 4,
                    alwaysConsumeMouseWheel: false,
                  },
                  unicodeHighlight: {
                    invisibleCharacters: false,
                  },
                }}
              />
            )}
          </ModalContent>
          <ModalFooter>
            <Box justify="between" fullWidth>
              <Toggle
                variant="checkbox"
                id="sidebyside"
                checked={sideBySide}
                onChange={handleSideBySideChange}
                ariaLabel={
                  sideBySide
                    ? "Show the differences in a single editor"
                    : "Show the differences in two side-by-side editors"
                }
              >
                Show side by side
              </Toggle>
              <Button variant="primary" onClick={onClose(closeModal)}>
                Close
              </Button>
            </Box>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default JsonDiffModal;
