import { AnsibleHost, AnsibleTaskStatus } from "types/generated";

import { TASK_SEARCH_KEY } from "./constants";
import { Components, ConfigNode } from "../types";

const STATUS_HIERARCHY = [
  AnsibleTaskStatus.Skipped,
  AnsibleTaskStatus.Ok,
  AnsibleTaskStatus.Changed,
  AnsibleTaskStatus.Rescued,
  AnsibleTaskStatus.Ignored,
  AnsibleTaskStatus.Unreachable,
  AnsibleTaskStatus.Failed,
];

const STATUS_ORDER = [
  AnsibleTaskStatus.Ok,
  AnsibleTaskStatus.Changed,
  AnsibleTaskStatus.Unreachable,
  AnsibleTaskStatus.Failed,
  AnsibleTaskStatus.Skipped,
  AnsibleTaskStatus.Rescued,
  AnsibleTaskStatus.Ignored,
];

export const createAnsibleNodes = (
  ansibleHosts: Pick<AnsibleHost, "id" | "name" | "taskExecutions">[],
  isChart: boolean,
  drawerLinkPath?: string
) => {
  return ansibleHosts.reduce((acc, next) => {
    const host = {
      id: next.id,
      name: next.name,
      type: Components.Text,
      isHost: true,
    } as ConfigNode;
    const config = [...acc, host];
    const hostIndex = acc.length;

    const playbooks: Record<string, ConfigNode> = {};
    const roles: Record<string, ConfigNode> = {};
    const tasks: ConfigNode[] = [];
    let taskOrder = 1;
    let taskOrderUnderRole = 1;
    let prevRoleId: string | undefined = undefined;
    let prevPlaybookId: string | undefined = undefined;

    next.taskExecutions.forEach((taskExecution, i) => {
      if (
        isChart &&
        config[hostIndex] &&
        (!config[hostIndex].status ||
          STATUS_HIERARCHY.indexOf(config[hostIndex].status) <
            STATUS_HIERARCHY.indexOf(taskExecution.status))
      ) {
        config[hostIndex].status = taskExecution.status;
      }

      // create playbook group
      const playbookName = taskExecution.task.playbook.name;
      const playbookPath = taskExecution.task.playbook.path;
      const playbookId = `${next.id}-${playbookPath}`;

      if (prevPlaybookId !== playbookId && prevPlaybookId) {
        taskOrder = 1;
        taskOrderUnderRole = 1;
        tasks[tasks.length - 1].last = true;
      }

      if (!playbooks[playbookId]) {
        playbooks[playbookId] = {
          id: playbookId,
          name: playbookName,
          parent: next.id,
          status: taskExecution.status,
          group: [],
          isPlaybook: true,
          type: isChart ? Components.Group : Components.Text,
        };
      } else if (
        playbooks[playbookId]?.status &&
        STATUS_HIERARCHY.indexOf(playbooks[playbookId].status) <
          STATUS_HIERARCHY.indexOf(taskExecution.status)
      ) {
        playbooks[playbookId].status = taskExecution.status;
      }

      // create role group
      const roleName = taskExecution.task.role?.name;
      const rolePath = taskExecution.task.role?.path;
      const roleId = rolePath ? `${playbookId}-${rolePath}` : undefined;

      if (prevRoleId !== roleId || !roleId) {
        taskOrderUnderRole = 1;
      } else {
        taskOrderUnderRole++;
      }

      if (prevRoleId !== roleId && prevRoleId) {
        tasks[tasks.length - 1].lastUnderRole = true;
      }

      if (roleId && roleName && !roles[roleId]) {
        roles[roleId] = {
          id: roleId,
          name: roleName,
          parent: playbookId,
          order: taskOrder,
          last: i === next.taskExecutions.length - 1,
          status: taskExecution.status,
          type: Components.Text,
        };
      } else if (
        roleId &&
        roles[roleId]?.status &&
        STATUS_HIERARCHY.indexOf(roles[roleId].status) <
          STATUS_HIERARCHY.indexOf(taskExecution.status)
      ) {
        roles[roleId].status = taskExecution.status;
      }

      // create task
      const taskId = `${roleId || playbookId}-${taskExecution.task.path}-${taskExecution.timestamp}`;
      const task = {
        id: taskId,
        name: taskExecution.task.name,
        parent: roleId || playbookId,
        timestamp: taskExecution.timestamp,
        status: taskExecution.status,
        link: drawerLinkPath
          ? {
              path: drawerLinkPath,
              queryKey: TASK_SEARCH_KEY,
              queryValue: taskId,
            }
          : undefined,
        isTask: true,
        order: roleId ? taskOrderUnderRole : taskOrder,
        hasParentTask: !!roleId,
        parentTaskId: roleId,
        roleName,
        limitMargin: true,
        last: i === next.taskExecutions.length - 1,
        type: Components.Text,
        checkMode: taskExecution.checkMode,
        logs: taskExecution.stdout,
      };

      if (isChart && playbooks[playbookId].group) {
        playbooks[playbookId].group.push(task);
      }

      tasks.push(task);

      if (prevRoleId !== roleId || !roleId) {
        taskOrder++;
      }

      prevRoleId = roleId;
      prevPlaybookId = playbookId;
    });

    Object.values(playbooks).forEach((value) => config.push(value));

    [...Object.values(roles), ...tasks]
      .sort((a, b) => (b.order && a.order ? a.order - b.order : 0))
      .forEach((value) => config.push(value));

    return config;
  }, [] as ConfigNode[]);
};

export const createLegendItems = (nodes: ConfigNode[]) => {
  const values = STATUS_ORDER.reduce(
    (acc, next) => ({ ...acc, [next]: 0 }),
    {} as Record<AnsibleTaskStatus, number>
  );

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (node.isTask && node.status) {
      values[node.status]++;
    }
  }

  return values;
};
