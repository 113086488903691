import { DEFAULT_SPACE_NAME } from "views/constants";
import { TerraformWorkflowTool } from "types/generated";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";

import { ModuleCreationWizardNavStep, ModuleCreationWizardStep, ModuleFormFields } from "./types";

export const availableSteps: Array<ModuleCreationWizardNavStep> = [
  {
    name: "Connect to source code",
    summaryName: "Source code",
    step: ModuleCreationWizardStep.Vcs,
  },
  {
    name: "Module details",
    summaryName: "Details",
    step: ModuleCreationWizardStep.Details,
  },
  {
    name: "Define behavior",
    isOptional: true,
    summaryName: "Behavior",
    step: ModuleCreationWizardStep.Behavior,
  },
  {
    name: "Share module",
    isOptional: true,
    summaryName: "Sharing",
    step: ModuleCreationWizardStep.Share,
  },
  {
    name: "Attach cloud",
    isOptional: true,
    summaryName: "Cloud integration",
    step: ModuleCreationWizardStep.Cloud,
  },
  {
    name: "Attach policies",
    isOptional: true,
    summaryName: "Attached policies",
    step: ModuleCreationWizardStep.Policies,
  },
  {
    name: "Attach contexts",
    isOptional: true,
    summaryName: "Attached contexts",
    step: ModuleCreationWizardStep.Contexts,
  },
  {
    name: "Summary",
    step: ModuleCreationWizardStep.Summary,
    summaryName: "Summary",
    isOptional: false,
  },
];

export const INITIAL_FORM_DATA: ModuleFormFields = {
  [ModuleCreationWizardStep.Vcs]: {
    provider: undefined,
    vcsIntegrationId: undefined,
    repository: "",
    namespace: "",
    branch: "",
    space: DEFAULT_SPACE_NAME,
    projectRoot: "",
  },
  [ModuleCreationWizardStep.Details]: {
    name: "",
    labels: [],
    description: "",
  },
  [ModuleCreationWizardStep.Share]: {},
  [ModuleCreationWizardStep.Behavior]: {
    workerPool: WORKER_POOL_SHARED_VALUE,
    workflowTool: TerraformWorkflowTool.TerraformFoss,
    administrative: false,
    localPreviewEnabled: false,
    protectFromDeletion: true,
  },
  [ModuleCreationWizardStep.Contexts]: null,
  [ModuleCreationWizardStep.Policies]: null,
  [ModuleCreationWizardStep.Cloud]: null,
  [ModuleCreationWizardStep.Summary]: null,
};
