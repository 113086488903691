import { useEffect, useRef } from "react";

import Box from "ds/components/Box";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import MetaInfoListItem from "components/MetaInfoList/Item";
import Timestamp from "components/time/Timestamp";
import { MenuSpaces } from "components/icons";
import ListEntitiesItem from "components/ListEntitiesItem";
import { Context } from "types/generated";
import { withTestId } from "utils/withTestId";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import { ContextSuggestions, searchContextSuggestionsDictionary } from "constants/context";
import useApplyFilter from "components/Filters/useApplyFilter";
import TagsListFilterable from "components/TagsList/Filterable";

import { COLUMN_GAP, COLUMN_ORDER, CONTEXTS_LIST_ITEM_TEST_ID } from "../constants";
import ContextListItemDropdown from "../ListItemDropdown";

type ContextListItemProps = {
  item: Context;
  setRowHeight?: (size: number) => void;
  onShowFullDescription: (context: Context) => void;
  onEdit: (context: Context) => void;
  isActive?: boolean;
  canManageContexts: boolean;
};

function ContextListItem({
  item,
  setRowHeight,
  onShowFullDescription,
  onEdit,
  canManageContexts,
  isActive = false,
}: ContextListItemProps) {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  const { applyFilter, applySpaceFilter, applyLabelFilter } = useApplyFilter<ContextSuggestions>({
    searchSuggestionsDictionary: searchContextSuggestionsDictionary,
  });

  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isActive}
      {...withTestId(CONTEXTS_LIST_ITEM_TEST_ID)}
    >
      <Box direction="column" justify="center">
        <Box align="center">
          <ListEntitiesItemLink title={item.name} to={`/context/${item.id}`} />
        </Box>

        {item.description && (
          <ListEntitiesItemDescription
            description={item.description}
            onShowFullDescription={handleShowFullDescription}
          />
        )}
        <TagsListFilterable
          tags={item.labels}
          applyLabelFilter={applyLabelFilter(ContextSuggestions.Label)}
          applyFolderFilter={applyFilter(ContextSuggestions.Folder)}
          onExpand={handleRowHeight}
        />
      </Box>

      <MetaInfoListItem
        applyFilter={() => applySpaceFilter(ContextSuggestions.Space)(item.spaceDetails.id)}
        icon={MenuSpaces}
        linkText={item.spaceDetails.name}
        href={`/spaces/${item.spaceDetails.id}`}
        type="space"
      />

      <Box align="center">
        <MetaInfoListItem>
          <Timestamp timestamp={item.createdAt} />
        </MetaInfoListItem>
      </Box>

      <Box shrink="0" grow="0">
        <ContextListItemDropdown
          onEdit={onEdit}
          context={item}
          canManageContexts={canManageContexts}
        />
      </Box>
    </ListEntitiesItem>
  );
}

export default ContextListItem;
