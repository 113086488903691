import { AuditTrailResourceMetadata, AuditTrailResourceType } from "types/generated";

import { FILTER_ITEMS_DICTIONARY, FilterItemKeys } from "./constants";

export const getFilterKey = (key: FilterItemKeys | string) => {
  return FILTER_ITEMS_DICTIONARY[key as FilterItemKeys] || key;
};

export const titleCase = (str: string) => {
  const separated = str.replace(/_/g, " ");

  if (separated.length === 0) {
    return "";
  }

  return separated.charAt(0).toUpperCase() + separated.slice(1).toLowerCase();
};

export const formatMetadata = (
  resourceType: AuditTrailResourceType | undefined,
  metadata: AuditTrailResourceMetadata | undefined
) => {
  if (!resourceType || !metadata) {
    return {
      typeLabel: "-",
      linkLabel: null,
      linkUrl: null,
    };
  }

  const hasId = "id" in metadata;
  const hasName = "name" in metadata;

  let typeLabel = titleCase(resourceType);
  let linkLabel = hasId ? metadata.id : null;
  let linkUrl = null;

  switch (resourceType) {
    case AuditTrailResourceType.Stack: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/stack/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Run: {
      if (hasId && "stackId" in metadata) {
        linkUrl = `/stack/${metadata.stackId}/run/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Context: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/context/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.GitlabIntegration: {
      if (hasId && hasName) {
        typeLabel = "GitLab Integration";
        linkLabel = metadata.name;
        linkUrl = `/vcs/integration/gitlab/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Policy: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/policy/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Space: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/spaces/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Blueprint: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/blueprint/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Module: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/module/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.WorkerPool: {
      if (hasId && hasName) {
        linkLabel = metadata.name;
        linkUrl = `/worker-pool/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.NamedWebhooksIntegration: {
      if (hasId && hasName) {
        typeLabel = "Webhook";
        linkLabel = metadata.name;
        linkUrl = `/webhook/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.GithubEnterpriseIntegration: {
      if (hasId && hasName) {
        typeLabel = "GitHub Enterprise Integration";
        linkLabel = metadata.name;
        linkUrl = `/vcs/integration/github-enterprise/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.TerraformProvider: {
      if ("type" in metadata) {
        typeLabel = "Terraform Provider";
        linkLabel = metadata.type;
        linkUrl = `/provider/${metadata.type}`;
      }
      break;
    }
    case AuditTrailResourceType.AzureDevopsRepoIntegration: {
      if (hasId && hasName) {
        typeLabel = "Azure DevOps Repo Integration";
        linkLabel = metadata.name;
        linkUrl = `/vcs/integration/azure-devops/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.Account: {
      if ("subdomain" in metadata) {
        typeLabel = "Account";
        linkLabel = metadata.subdomain;
      }
      break;
    }
    case AuditTrailResourceType.BitbucketDatacenterIntegration: {
      if (hasId && hasName) {
        typeLabel = "Bitbucket Data Center Integration";
        linkLabel = metadata.name;
        linkUrl = `/vcs/integration/bitbucket-data-center/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.BitbucketCloudIntegration: {
      if (hasId && hasName) {
        typeLabel = "Bitbucket Cloud Integration";
        linkLabel = metadata.name;
        linkUrl = `/vcs/integration/bitbucket-cloud/${metadata.id}`;
      }
      break;
    }
    case AuditTrailResourceType.User:
      break;
    case AuditTrailResourceType.Version: {
      if (hasId && "moduleId" in metadata) {
        linkLabel = metadata.moduleId;
        linkUrl = `/module/${metadata.moduleId}/version/${metadata.id}`;
      }
      break;
    }

    default: {
      typeLabel = `Not handled ${resourceType}`;
      linkLabel = "TBA";
    }
  }

  return {
    typeLabel,
    linkLabel,
    linkUrl,
  };
};
