import React from "react";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import SearchInput from "components/SearchInput";
import PageInfo from "components/PageWrapper/Info";
import Button from "ds/components/Button";
import { SpacesContext } from "views/account/SpacesProvider";
import TerraformRegistryViewHeader from "views/account/TerraformRegistry/ViewHeader";

import { FILTERS_ORDER_SETTINGS_KEY } from "../../constants";

type ModulesPageLayoutProps = {
  children: React.ReactNode;
};

const ModulesPageLayout = ({ children }: ModulesPageLayoutProps) => {
  const { accountName } = useTypedContext(AccountContext);
  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  useTitle(`Modules · ${accountName}`);

  return (
    <>
      <TerraformRegistryViewHeader />
      <PageInfo title="Modules">
        <SearchInput
          placeholder="Search by name, ID, etc..."
          filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
        />
        {hasEntityCreateAccess && (
          <Button to="/new/module" variant="primary">
            Create module
          </Button>
        )}
      </PageInfo>

      {children}
    </>
  );
};

export default ModulesPageLayout;
