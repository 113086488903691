import { useCallback } from "react";

import Box from "ds/components/Box";
import DocumentationIconButton from "components/DocumentationIconButton";
import useTypedContext from "hooks/useTypedContext";
import Button from "ds/components/Button";
import { ArrowThin } from "components/icons";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import { TrackAnalyticsEventProperties } from "shared/Analytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import { getDocsUrl } from "utils/getDocsUrl";

import { ContextFormContext } from "../context";
import { CONTEXT_ANALYTICS_VERSION } from "../constants";
import styles from "./styles.module.css";
import NewContextFooterContinueButton from "./ContinueButton";
import NewContextFooterCreateContextButton from "./CreateContextButton";

type NewContextFooterProps = {
  onCreateContextClick: () => void;
  isCreatingContext: boolean;
  onContinueClick?: () => void;
  onBackClick?: () => void;
  shouldDisableNextStep?: boolean;
  continueClickAnalyticsProps?: TrackAnalyticsEventProperties;
};

const NewContextFooter = ({
  onCreateContextClick,
  isCreatingContext,
  onContinueClick,
  onBackClick,
  shouldDisableNextStep,
  continueClickAnalyticsProps,
}: NewContextFooterProps) => {
  const { currentStepData, goToNextStep, isFirstStep, isLastStep, goToPreviousStep } =
    useTypedContext(ContextFormContext);

  const handleOnContinueClick = useCallback(() => {
    if (onContinueClick) {
      onContinueClick();
      return;
    }
    goToNextStep();
  }, [goToNextStep, onContinueClick]);

  const handleOnBackClick = useCallback(() => {
    if (onBackClick) {
      onBackClick();
      return;
    }
    goToPreviousStep();
  }, [goToPreviousStep, onBackClick]);

  const createContextButton = (
    <NewContextFooterCreateContextButton
      isConfirmationActive={!isLastStep}
      variant={isLastStep ? "primary" : "secondary"}
      onClickHandler={onCreateContextClick}
      loading={isCreatingContext}
    >
      Create context
    </NewContextFooterCreateContextButton>
  );

  return (
    <FullScreenModalFooter justify="between">
      <DocumentationIconButton
        href={getDocsUrl("/concepts/configuration/context")}
        tooltipText="Go to contexts documentation"
        analyticsPage={AnalyticsPageContext.ContextNew}
        analyticsTitle="Docs click"
        analyticsProps={{
          location: currentStepData?.shortName,
          version: CONTEXT_ANALYTICS_VERSION,
        }}
      />
      <Box direction="row" gap="large">
        {!isLastStep && createContextButton}

        {!isFirstStep && (
          <Button
            analyticsTitle="Back click"
            analyticsPage={AnalyticsPageContext.ContextNew}
            analyticsProps={{
              location: currentStepData?.shortName,
              version: CONTEXT_ANALYTICS_VERSION,
            }}
            variant="secondary"
            onClick={handleOnBackClick}
            className={styles.buttonWithIcon}
            disabled={isCreatingContext}
            startIcon={ArrowThin}
            startIconRotate="270"
          >
            Back
          </Button>
        )}

        {!isLastStep && (
          <NewContextFooterContinueButton
            analyticsTitle={`Continue click on ${currentStepData?.shortName} step`}
            analyticsPage={AnalyticsPageContext.ContextNew}
            analyticsProps={{
              version: CONTEXT_ANALYTICS_VERSION,
              ...continueClickAnalyticsProps,
            }}
            variant="primary"
            onClickHandler={handleOnContinueClick}
            disabled={shouldDisableNextStep || isCreatingContext}
          />
        )}

        {isLastStep && createContextButton}
      </Box>
    </FullScreenModalFooter>
  );
};

export default NewContextFooter;
