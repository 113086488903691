import PageInfo from "components/PageWrapper/Info";
import Toggle from "ds/components/Toggle";
import Tooltip from "ds/components/Tooltip";
import { Module } from "types/generated";
import ModuleVersionPageIntoTitle from "views/account/Module/Version/components/PageIntoTitle";

import TriggerButton from "../TriggerButton";

type PageLayoutProps = {
  hideFailedVersions?: boolean;
  module?: Module;
  toggleHideFailedVersions?: () => void;
  children: React.ReactNode;
};

const PageLayout = ({
  hideFailedVersions,
  module,
  toggleHideFailedVersions,
  children,
}: PageLayoutProps): JSX.Element => {
  const displayToggle = hideFailedVersions !== undefined && toggleHideFailedVersions !== undefined;

  return (
    <>
      <PageInfo
        title={<ModuleVersionPageIntoTitle title="Versions" tooltip="Published module versions" />}
      >
        {displayToggle && (
          <Toggle
            variant="switch"
            onChange={toggleHideFailedVersions}
            checked={!hideFailedVersions}
          >
            Show failed versions
          </Toggle>
        )}
        {module?.canWrite && (
          <Tooltip
            on={(props) => (
              <span {...props}>
                <TriggerButton moduleId={module.id} disabled={module.isDisabled} />
              </span>
            )}
            active={module.isDisabled}
          >
            Module is disabled
          </Tooltip>
        )}
      </PageInfo>
      {children}
    </>
  );
};

export default PageLayout;
