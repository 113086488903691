import { ReactNode } from "react";
import cx from "classnames";
import { MenuItem, MenuItemProps } from "react-aria-components";

import Tooltip from "ds/components/Tooltip";
import { TooltipProps } from "ds/components/Tooltip/types";

import styles from "./styles.module.css";

export type DropdownMenuItemProps = MenuItemProps & {
  active?: boolean;
  danger?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipWidthMode?: TooltipProps["widthMode"];
  tooltipDelay?: TooltipProps["delay"];
  isTooltipActive?: boolean;
  tooltip?: ReactNode;
};

const DropdownMenuItem = ({
  children,
  danger,
  isDisabled,
  className,
  active,
  onAction,
  tooltip,
  isTooltipActive = true,
  tooltipPlacement,
  tooltipWidthMode,
  tooltipDelay,
  ...restProps
}: DropdownMenuItemProps) => {
  const classNames = cx(
    styles.item,
    {
      [styles.danger]: danger,
      [styles.active]: active,
    },
    className
  );

  if (tooltip && isTooltipActive) {
    return (
      <Tooltip
        delay={tooltipDelay}
        placement={tooltipPlacement}
        widthMode={tooltipWidthMode}
        on={(tooltipProps) => (
          <MenuItem
            className={classNames}
            isDisabled={isDisabled}
            onAction={onAction}
            {...restProps}
            {...tooltipProps}
          >
            {children}
          </MenuItem>
        )}
      >
        {tooltip}
      </Tooltip>
    );
  }

  return (
    <MenuItem className={classNames} isDisabled={isDisabled} onAction={onAction} {...restProps}>
      {children}
    </MenuItem>
  );
};

export default DropdownMenuItem;
