import { gql } from "@apollo/client";

export const PRIVATE_WORKER_POOL_FIELDS = gql`
  fragment PrivateWorkerPoolFields on WorkerPool {
    id
    deleted
    description
    labels
    name
    updatedAt
    spaceDetails {
      id
      name
      accessLevel
    }
    busyWorkers
    queuedRunsCount
    schedulableRunsCount
    usersCount
    workersCount
  }
`;
