import { useMutation } from "@apollo/client";
import { FormProvider, useForm } from "react-hook-form";
import { Base64 } from "js-base64";

import { ConfigElement, ConfigType } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { useToggle } from "hooks/useToggle";
import { fileToBase64 } from "utils/file";

import { ADD_CONTEXT_CONFIG } from "../Form/gql";
import { ContextContext } from "../../Context";
import { ContextMountedFilesListItemProps, ContextMountedFileFormFields } from "./types";
import ContextMountedFilesListItemDraft from "./Draft";
import ContextMountedFilesListItemRegular from "./Regular";

const ContextMountedFilesListItem = ({
  item,
  onShowFullDescription,
  onRemoveFileDraft,
  onOpenDetailsDrawer,
  onOpenFileDetailsDrawer,
}: ContextMountedFilesListItemProps) => {
  const { context } = useTypedContext(ContextContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [isCollapsed, toggle] = useToggle(!item.isDraft);
  const [addConfig] = useMutation<{
    contextConfigAdd: ConfigElement;
  }>(ADD_CONTEXT_CONFIG, {
    refetchQueries: ["GetContext"],
    variables: { contextId: context.id },
  });

  const handleSave = async (formData: ContextMountedFileFormFields) => {
    const base64 = formData.file && (await fileToBase64(formData.file));
    const base64File = base64 ? base64.toString().split(",")[1] : "";
    const fileValue = formData.file ? base64File : Base64.encode(formData.fileBody);

    const input = {
      id: item.id,
      value: fileValue,
      writeOnly: item.writeOnly,
      type: ConfigType.FileMount,
      description: item.description,
      fileMode: null,
    };

    return addConfig({ variables: { input } })
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Mounted file ${data.contextConfigAdd.id} successfully ${
              item.isDraft ? "added" : "edited"
            }`,
          });

          if (item.isDraft) {
            onRemoveFileDraft?.();
          } else {
            toggle(true);
          }
        }
      })
      .catch(onError);
  };

  const form = useForm<ContextMountedFileFormFields>({
    mode: "onChange",
    defaultValues: {
      fileBody: "",
      file: null,
    },
    shouldUnregister: true,
  });

  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSave)}>
        {item.isDraft ? (
          <ContextMountedFilesListItemDraft
            onShowFullDescription={handleShowFullDescription}
            item={item}
            onRemoveFileDraft={onRemoveFileDraft}
            onOpenDetailsDrawer={onOpenDetailsDrawer}
            isCollapsed={isCollapsed}
            toggle={toggle}
          />
        ) : (
          <ContextMountedFilesListItemRegular
            key={item.createdAt}
            onShowFullDescription={handleShowFullDescription}
            item={item}
            onOpenFileDetailsDrawer={onOpenFileDetailsDrawer}
            isCollapsed={isCollapsed}
            toggle={toggle}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default ContextMountedFilesListItem;
