import cx from "classnames";
import { forwardRef, LegacyRef } from "react";
import camelCase from "lodash-es/camelCase";

import { IconComponent } from "types/Icon";

import styles from "./styles.module.css";
import { IconColors } from "./types";

export type IconProps = React.SVGProps<SVGSVGElement> & {
  src: IconComponent;
  size?: "default" | "large";
  rotate?: "90" | "180" | "270";
  color?: IconColors;
  noShrink?: boolean;
};

const Icon = forwardRef(function Icon(
  { src: IconSVG, size = "default", className, rotate, color, noShrink, ...rest }: IconProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <IconSVG
      ref={ref as LegacyRef<SVGSVGElement>}
      className={cx(
        styles.icon,
        styles[size],
        rotate && styles[`rotate${rotate}`],
        noShrink && styles.noShrink,
        color && styles[camelCase(`iconColor-${color}`)],
        className
      )}
      aria-hidden
      {...rest}
    />
  );
});

export default Icon;
