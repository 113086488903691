import fromUnixTime from "date-fns/fromUnixTime";

import FormField from "ds/components/Form/Field";
import Typography from "ds/components/Typography";
import { AuditTrailEntry } from "types/generated";
import { formatDateTimeByLocale } from "utils/date";
import Link from "ds/components/LinkNew";

import { titleCase, formatMetadata } from "../../helpers";
import CreatedBy from "../ListItem/CreatedBy";

type AuditTrailEntriesDetailsMetadataProps = {
  item: AuditTrailEntry;
  onApplyFilter: () => void;
};

const AuditTrailEntriesDetailsMetadata = ({
  item,
  onApplyFilter,
}: AuditTrailEntriesDetailsMetadataProps) => {
  const {
    typeLabel: affectedResourceTypeLabel,
    linkLabel: affectedResourceLinkLabel,
    linkUrl: affectedResourceLinkUrl,
  } = formatMetadata(item.affectedResource.resourceType, item.affectedResource.metadata);

  const {
    typeLabel: relatedResourceTypeLabel,
    linkLabel: relatedResourceLinkLabel,
    linkUrl: relatedResourceLinkUrl,
  } = formatMetadata(item.relatedResource?.resourceType, item.relatedResource?.metadata);

  return (
    <>
      <FormField label="Created by" noMargin align="start">
        <CreatedBy value={item.actor} onApplyFilter={onApplyFilter} />
      </FormField>

      <FormField label="Timestamp">
        <Typography tag="span" variant="p-body2">
          {formatDateTimeByLocale({ date: fromUnixTime(item.createdAt), format: "dateTimeFull" })}
        </Typography>
      </FormField>

      <FormField label="IP address">
        <Typography tag="span" variant="p-body2">
          TBA
        </Typography>
      </FormField>

      <FormField label="Affected resource type">
        <Typography tag="span" variant="p-body2">
          {affectedResourceTypeLabel}
        </Typography>
      </FormField>

      {affectedResourceLinkLabel && affectedResourceLinkUrl && (
        <FormField label="Affected resource" align="start">
          <Link href={affectedResourceLinkUrl}>{affectedResourceLinkLabel}</Link>
        </FormField>
      )}

      {item.relatedResource !== null && (
        <>
          <FormField label="Related resource type">
            <Typography tag="span" variant="p-body2">
              {relatedResourceTypeLabel}
            </Typography>
          </FormField>

          {relatedResourceLinkLabel && relatedResourceLinkUrl && (
            <FormField label="Related resource" align="start">
              <Link href={relatedResourceLinkUrl}>{relatedResourceLinkLabel}</Link>
            </FormField>
          )}
        </>
      )}

      <FormField label="Action">
        <Typography tag="span" variant="p-body2">
          {item.action}
        </Typography>
      </FormField>

      <FormField label="Event type">
        <Typography tag="span" variant="p-body2">
          {titleCase(item.eventType)}
        </Typography>
      </FormField>
    </>
  );
};

export default AuditTrailEntriesDetailsMetadata;
