import DropdownSectionItem from "ds/components/Dropdown/SectionItem";

type WorkerPoolCycleActionProps = {
  onClick: () => void;
  hasBusyWorkers: boolean;
};

const WorkerPoolCycleAction = ({ onClick, hasBusyWorkers }: WorkerPoolCycleActionProps) => (
  <DropdownSectionItem
    tooltip={
      <>
        {hasBusyWorkers && (
          <>
            Please drain all workers first to make sure that no work is being performed by this
            worker pool.
            <br />
            <br />
          </>
        )}
        Cycling worker pool sends a self-destruct signal to all the workers. The process can take up
        to 20 seconds to complete.
      </>
    }
    tooltipWidthMode="maxWidthSm"
    tooltipPlacement="left"
    onClick={onClick}
    disabled={hasBusyWorkers}
  >
    Cycle
  </DropdownSectionItem>
);

export default WorkerPoolCycleAction;
