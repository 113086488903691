import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

import { ContextContext } from "../../Context";
import { ContextMountedFilesListItemActionsProps } from "./types";
import DownloadFileButton from "./Download";
import { showDeleteConfirmation } from "./DeleteConfirmation";

const ContextMountedFilesListItemActions = ({
  item,
  onEdit,
  forceOverwrite,
}: ContextMountedFilesListItemActionsProps) => {
  const { context, canManageContext } = useTypedContext(ContextContext);

  if (!canManageContext) return null;

  const handleDeleteClick = (callback: () => void) => {
    showDeleteConfirmation({ id: item.id, contextId: context.id });
    callback();
  };

  const handleEditClick = (callback: () => void) => {
    onEdit();
    callback();
  };

  return (
    <Box align="center" justify="end">
      <DropdownEllipsis dotsSize="small">
        {({ close }) => (
          <DropdownSection>
            <DropdownSectionItem
              tooltip="This file is too large to edit, you can only overwrite it."
              tooltipPlacement="left"
              isTooltipActive={forceOverwrite}
              onClick={() => handleEditClick(close)}
            >
              {item.writeOnly || forceOverwrite ? "Overwrite" : "Edit file"}
            </DropdownSectionItem>

            {!item.writeOnly && item.value && (
              <DownloadFileButton fileName={item.id} href={item.value} />
            )}

            <DropdownSectionItem onClick={() => handleDeleteClick(close)} danger>
              Delete
            </DropdownSectionItem>
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </Box>
  );
};

export default ContextMountedFilesListItemActions;
