import { useForm, FormProvider, Controller } from "react-hook-form";
import { useEffect, useCallback } from "react";
import { NetworkStatus } from "@apollo/client";
import isEqual from "lodash-es/isEqual";

import useTypedContext from "hooks/useTypedContext";
import Typography from "ds/components/Typography";
import FormField from "ds/components/Form/Field";
import ToggleField from "ds/components/Form/ToggleField";
import FormLoading from "components/form/components/loading";
import { isSelfHostedDistribution } from "utils/distribution";
import { TerraformWorkflowTool } from "types/generated";
import Select from "ds/components/Select";
import Box from "ds/components/Box";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import MissingDataBanner from "components/MissingDataBanner";
import useErrorHandlerSourceCode from "components/Forms/SourceCode/useErrorHandlerSourceCode";
import { TerraformWorkflowToolOptions } from "constants/terraform_workflow_tools";
import { getDocsUrl } from "utils/getDocsUrl";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";

import NewModuleFooter from "../Footer";
import { ModuleFormContext } from "../context";
import { ModuleBehaviorFormFields, ModuleCreationWizardStep } from "../types";
import { getSwitchToggleAnalyticsProps, getTooltipAnalyticsProps } from "../utils";
import Documentation from "./Documentation";
import { useWorkerPoolOptions } from "./useWorkerPoolOptions";
import { useNewModuleAnalyticsSegmentEvent } from "../useNewModuleAnalyticsSegmentEvent";

const isSelfHosted = isSelfHostedDistribution();

const getWorkerPoolInitialData = (workerPool: string) => {
  if (isSelfHosted && workerPool === WORKER_POOL_SHARED_VALUE) {
    return "";
  }

  return workerPool;
};

const NewModuleBehavior = () => {
  const {
    currentStep,
    updateStepData,
    formData,
    setInternalFormData,
    internalData,
    setAccountOrAuthErrorContent,
  } = useTypedContext(ModuleFormContext);
  const stepData = formData[ModuleCreationWizardStep.Behavior];

  const { workerPoolsOptions, loading, hasData, error, refetch, networkStatus } =
    useWorkerPoolOptions(formData[ModuleCreationWizardStep.Vcs].space);

  useErrorHandlerSourceCode(setAccountOrAuthErrorContent, error);

  const initialWorkerPool = getWorkerPoolInitialData(stepData.workerPool);

  const trackSegmentEvent = useNewModuleAnalyticsSegmentEvent();

  const builderForm = useForm<ModuleBehaviorFormFields>({
    defaultValues: {
      ...stepData,
      workerPool: initialWorkerPool,
    },
    mode: "onChange",
  });

  const {
    control,
    formState: { isValid },
    setValue,
    watch,
    trigger,
  } = builderForm;

  const newStepData = watch();

  const processStepData = () => {
    const workerPoolOption = workerPoolsOptions.find(
      ({ value }) => value === newStepData.workerPool
    );
    setInternalFormData({ ...internalData, workerPoolLabel: workerPoolOption?.label });

    return updateStepData(currentStep, newStepData);
  };

  const isDataChanged = !isEqual(newStepData, stepData);

  const selectedWorkerPool = watch("workerPool");

  useEffect(() => {
    if (!selectedWorkerPool) {
      if (isSelfHosted && workerPoolsOptions?.length) {
        setValue("workerPool", workerPoolsOptions[0].value, { shouldValidate: true });
      }
    } else if (hasData || (!loading && !hasData)) {
      // validate worker pool shared via link
      const isNotAvailable = !workerPoolsOptions.find(({ value }) => {
        return value === selectedWorkerPool;
      });
      if (isNotAvailable) {
        setValue("workerPool", "");
      }
    }
  }, [workerPoolsOptions, setValue, selectedWorkerPool, hasData, loading]);

  const handleWorkerPoolChange = (workerPool: string) => {
    setValue("workerPool", workerPool, { shouldValidate: true });
  };

  const handleWorkflowToolChange = useCallback(
    (value: TerraformWorkflowTool) => {
      setValue("workflowTool", value);

      trigger();

      trackSegmentEvent("Workflow tool", { tool: value, provider: "Terraform / OpenTofu" });
    },
    [setValue, trigger, trackSegmentEvent]
  );

  const isNextStepDisabled = (isSelfHosted && !selectedWorkerPool) || !isValid;

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return <FormLoading />;
  }

  return (
    <>
      <FullScreenModalBody>
        <Typography tag="h1" variant="p-t4" align="center">
          Define behavior (optional)
        </Typography>
        <Typography tag="p" variant="p-body2" align="center" color="secondary" margin="small 0 0 0">
          Define additional module settings
        </Typography>
        <Box margin="x-large 0 large 0">
          <FormProvider {...builderForm}>
            <Box direction="column" gap="large">
              <Controller
                name="workerPool"
                rules={{
                  required: "Worker pool is required",
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    label="Worker pool"
                    tooltipInfoVariant="modal"
                    {...getTooltipAnalyticsProps("Behavior", "Worker pool")}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Worker pool</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            By using a private worker pool you make sure that you have full control
                            over your infrastructure changes, and can even access resources that
                            would otherwise not be reachable from the public internet.
                          </Typography>
                          <ReadMoreDocsLink docsUrl={getDocsUrl("/concepts/worker-pools")} />
                        </TooltipModalBody>
                      </>
                    }
                  >
                    {({ ariaInputProps }) => (
                      <>
                        <Select
                          disabled={workerPoolsOptions.length === 1}
                          value={field.value}
                          options={workerPoolsOptions}
                          onChange={handleWorkerPoolChange}
                          error={!!fieldState.error?.message}
                          placeholder="Select worker pool"
                          ariaInputProps={ariaInputProps}
                        />
                        {!hasData && (
                          <Box direction="column" margin="large 0 0 0">
                            <MissingDataBanner
                              text="Couldn't load your private worker pools, please refresh or come back later"
                              refreshHandler={refetch}
                              refreshLoading={loading && networkStatus === NetworkStatus.refetch}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  </FormField>
                )}
              />
              <Controller
                name="workflowTool"
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    error={fieldState.error?.message}
                    noMargin
                    label="Workflow tool"
                    {...getTooltipAnalyticsProps("Vendor", "Workflow tool", {
                      provider: "Terraform / OpenTofu",
                    })}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Workflow tool</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          The tool used to execute the workflow commands. This can be an open source
                          (FOSS) version of Terraform, OpenTofu or a custom tool.
                        </TooltipModalBody>
                      </>
                    }
                    tooltipInfoVariant="modal"
                  >
                    {({ ariaInputProps }) => (
                      <Select
                        value={field.value as TerraformWorkflowTool}
                        options={TerraformWorkflowToolOptions}
                        onChange={handleWorkflowToolChange}
                        error={!!fieldState.error?.message}
                        ariaInputProps={ariaInputProps}
                      />
                    )}
                  </FormField>
                )}
              />

              <Controller
                name="administrative"
                control={control}
                render={({ field }) => (
                  <ToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Administrative"
                    description="Module will receive a runtime environment variable giving administrative access to other stacks within the same account"
                    {...getSwitchToggleAnalyticsProps("Behavior", "Administrative", true)}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Administrative module</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Administrative modules can create, update and destroy Spacelift
                            resources.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl("/concepts/stack/stack-settings#administrative")}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />

              <Controller
                name="localPreviewEnabled"
                control={control}
                render={({ field }) => (
                  <ToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Enable local preview"
                    description="Run local previews using spacectl."
                    {...getSwitchToggleAnalyticsProps("Behavior", "Enable local preview", true)}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Local preview</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            When set to true, proposed runs can be created based on user-uploaded
                            local workspaces. This way you can preview how your code changes will
                            execute without creating a commit.
                          </Typography>
                          <ReadMoreDocsLink
                            docsUrl={getDocsUrl(
                              "/concepts/stack/stack-settings#enable-local-preview"
                            )}
                          />
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
              <Controller
                name="protectFromDeletion"
                control={control}
                render={({ field }) => (
                  <ToggleField
                    variant="switch"
                    onChange={field.onChange}
                    checked={field.value}
                    title="Protect from deletion (recommended)"
                    description="Manage deletion protection"
                    {...getSwitchToggleAnalyticsProps("Behavior", "Protect from deletion", true)}
                    tooltipInfo={
                      <>
                        <TooltipModalTitle>Protect from deletion</TooltipModalTitle>
                        <TooltipModalBody align="start">
                          <Typography tag="p" variant="p-body3">
                            Deletion protection helps protect your modules from accidental deletion.
                            When enabled, any attempts to delete your module will fail.
                          </Typography>
                        </TooltipModalBody>
                      </>
                    }
                  />
                )}
              />
            </Box>
          </FormProvider>
        </Box>
      </FullScreenModalBody>
      <NewModuleFooter
        isDataChanged={isDataChanged}
        isNextStepDisabled={isNextStepDisabled}
        processStepData={processStepData}
        documentationLink={getDocsUrl("/vendors/terraform/module-registry#spacelift-setup")}
        documentationTitle="Define behavior"
        documentationBody={<Documentation />}
      />
    </>
  );
};

export default NewModuleBehavior;
