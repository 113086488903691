import { ReactNode, useEffect } from "react";
import { fromUnixTime, differenceInDays, getTime } from "date-fns";
import useLocalStorage from "@rehooks/local-storage";
import { useNavigate } from "react-router-dom-v5-compat";

import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import {
  createPlainDataLayer,
  getSubdomain,
  trackSegmentEvent,
  updateHubspotUser,
  updateSegmentUser,
} from "shared/Analytics";

const LOCAL_STORAGE_KEY = "redirectedToLaunchPad";

type LaunchPadRedirectWrapperProps = {
  children: ReactNode;
};

const LaunchPadRedirectWrapper = ({ children }: LaunchPadRedirectWrapperProps) => {
  const { viewer, accountId } = useTypedContext(AccountContext);
  // this helps us also to set GTM data layer only once
  const [redirectedToLaunchPad, setRedirectedToLaunchPad] =
    useLocalStorage<boolean>(LOCAL_STORAGE_KEY);

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectedToLaunchPad) {
      return;
    }

    const firstLoginTime = viewer.since ? fromUnixTime(viewer.since) : null;
    const now = new Date();

    const shouldOpenLaunchpad =
      viewer.admin && firstLoginTime && differenceInDays(now, firstLoginTime) < 1;

    if (shouldOpenLaunchpad) {
      trackSegmentEvent("Launchpad redirection");
      setRedirectedToLaunchPad(true);
      navigate("/launchpad", { replace: true });
    }

    // First user signup event
    if (viewer.isFirstUser) {
      createPlainDataLayer({
        event: "product_signup_firstuser",
        hostname: getSubdomain(),
        identityProvider: viewer.identityProvider,
        accountId,
      });

      updateHubspotUser(viewer.email, "first_login", "true");
      updateHubspotUser(viewer.email, "first_login_date", `${getTime(new Date())}`);
      updateSegmentUser(viewer.id, "isFirstUser", true);
    }

    // First signup event
    createPlainDataLayer({
      event: "product_signup",
      hostname: getSubdomain(),
      identityProvider: viewer.identityProvider,
      isFirstUser: viewer.isFirstUser ? "true" : "false",
    });
  }, [viewer, navigate, redirectedToLaunchPad, setRedirectedToLaunchPad, accountId]);

  return <>{children}</>;
};

export default LaunchPadRedirectWrapper;
