import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { Module, Version } from "types/generated";

import { showDeleteConfirmation } from "./Modal";

type DeleteVersionButtonProps = {
  id: Version["id"];
  moduleId: Module["id"];
  number: Version["number"];
  onClickCb?: () => void;
};

const DeleteVersionButton = (props: DeleteVersionButtonProps) => {
  const { moduleId, id, number, onClickCb } = props;

  const handleShowModal = () => {
    showDeleteConfirmation({
      id,
      moduleId,
      number,
    });

    onClickCb?.();
  };

  return (
    <DropdownSectionItem onClick={handleShowModal} danger>
      Delete
    </DropdownSectionItem>
  );
};

export default DeleteVersionButton;
