import { useNavigate } from "react-router-dom-v5-compat";

import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import { showDeleteConfirmation } from "../Policies/DeleteConfirmation";

type PolicyDetailsManageDropdownProps = {
  handlePolicyEditDetailsClick: () => void;
  id: string;
  name: string;
  size?: "small" | "medium";
  canManagePolicies: boolean;
  hasAttachedEntities: boolean;
};

const PolicyDetailsManageDropdown = ({
  handlePolicyEditDetailsClick,
  id,
  name,
  size = "small",
  canManagePolicies,
  hasAttachedEntities,
}: PolicyDetailsManageDropdownProps) => {
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePolicy.PolicyDetails,
    callbackTrackProviders: { segment: true },
  });

  const copyFieldCallback = () => trackSegmentAnalyticsEvent("Copy ID");

  const handleCopyFieldClick = (clb: () => void) => {
    copyFieldCallback();
    clb();
  };

  const onEditClick = (clb: () => void) => {
    handlePolicyEditDetailsClick();
    clb();
  };

  const handleDelete = (closeDropdown: () => void) => {
    closeDropdown();
    trackSegmentAnalyticsEvent("Start Delete");
    showDeleteConfirmation({
      id,
      name,
    }).then((finished) => {
      if (finished) {
        navigate(`/policies`);
        trackSegmentAnalyticsEvent("Confirm Delete");
      } else {
        trackSegmentAnalyticsEvent("Cancel Delete");
      }
    });
  };

  return (
    <DropdownEllipsis dotsSize={size}>
      {({ close }) => (
        <DropdownSection>
          {canManagePolicies && (
            <DropdownSectionItem onClick={() => onEditClick(close)}>
              Edit details
            </DropdownSectionItem>
          )}
          <CopyFieldDropdownItem
            title="Copy ID"
            value={id}
            callback={() => handleCopyFieldClick(close)}
          />
          {canManagePolicies && (
            <DropdownSectionItem
              tooltip="Before deleting policy assure it is not attached to any stacks or modules."
              isTooltipActive={hasAttachedEntities}
              tooltipWidthMode="maxWidthSm"
              tooltipPlacement="left"
              disabled={hasAttachedEntities}
              onClick={() => handleDelete(close)}
              danger
            >
              Delete
            </DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default PolicyDetailsManageDropdown;
