import { gql, useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import Button from "components/button/Button";
import useTypedContext from "hooks/useTypedContext";
import { GET_TIER } from "views/account/SubscriptionWrapper";

import { GET_USAGE } from "../gql";

export const DELETE_SUBSCRIPTION = gql`
  mutation DeleteSubscription {
    billingSubscriptionDelete {
      isActive
    }
  }
`;

type BillingDeleteButtonProps = {
  onModalClose: () => void;
};

const BillingDeleteButton = ({ onModalClose }: BillingDeleteButtonProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [deleteSubscription, { loading }] = useMutation(DELETE_SUBSCRIPTION, {
    refetchQueries: [
      {
        query: GET_USAGE,
      },
      { query: GET_TIER },
    ],
  });

  const handleDelete = () => {
    deleteSubscription()
      .then(() => {
        reportSuccess({ message: "Subscription plan was canceled" });
        onModalClose();
      })
      .catch(onError);
  };

  return (
    <Button type="button" pill full loading={loading} onClick={() => handleDelete()}>
      Cancel subscription
    </Button>
  );
};

export default BillingDeleteButton;
