import { ReactNode, useCallback, useEffect, useMemo, useState, useId } from "react";
import cx from "classnames";

import { HierarchyFilterItemMapOption, HierarchyFilterOptionsMap } from "components/Filters/types";
import { ChevronNew } from "components/icons";
import IconAction from "ds/components/IconAction";

import styles from "../styles.module.css";
import FilterOption from "./FilterOption";

type HierarchyFilterOptionProps = {
  item: HierarchyFilterItemMapOption;
  optionsMap: HierarchyFilterOptionsMap;
  handleSelectValue: (value: string) => void;
  selectedValues: Set<string>;
};

const HierarchyFilterOption = ({
  handleSelectValue,
  item,
  optionsMap,
  selectedValues,
}: HierarchyFilterOptionProps) => {
  const groupId = useId();
  const [isOpen, setIsOpen] = useState(false);

  const handleItemSelectValue = () => {
    handleSelectValue(item.id.toString());
  };

  useEffect(() => {
    // if we check parent and children item was checked we should uncheck it
    if (
      item.parentId &&
      selectedValues.has(item.parentId?.toString()) &&
      selectedValues.has(item?.id.toString())
    ) {
      handleSelectValue(item.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const isDisabled = useMemo(
    () =>
      (item.parentId && selectedValues.has(item?.parentId.toString())) ||
      (item.parentId &&
        Array.from(selectedValues).some(
          (value) => item.id.toString() !== value && item.id.toString().includes(`${value}/`)
        )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedValues]
  );

  const toggleSectionOpen = useCallback(() => {
    const newValue = !isOpen;
    setIsOpen(newValue);
  }, [isOpen]);

  const children = item.children.reduce<ReactNode[]>((acc, childId) => {
    const childItem = optionsMap.get(childId);

    if (childItem) {
      acc.push(
        <HierarchyFilterOption
          key={childId}
          item={childItem}
          optionsMap={optionsMap}
          handleSelectValue={handleSelectValue}
          selectedValues={selectedValues}
        />
      );
    }

    return acc;
  }, []);

  const isSelected =
    selectedValues.has(item.value.toString()) ||
    !!(item.parentId && selectedValues.has(item?.id.toString())) ||
    !!isDisabled;

  const wrapperClass = cx({
    [styles.selectOptionEmpty]: children.length === 0,
    [styles.selectOptionMultiWrapper]: children.length > 0,
    [styles.selectOptionMultiWrapperFull]: isOpen,
  });

  const childrenWrapper = cx(styles.childrenOptions, {
    [styles.selectOptionMulti]: children.length > 0,
    [styles.childrenOptionFull]: isOpen,
  });

  return (
    <div className={wrapperClass}>
      <div className={styles.toggleWrapper}>
        {children.length > 0 && (
          <IconAction
            onClick={toggleSectionOpen}
            className={cx(styles.toggle, { [styles.iconActive]: isOpen })}
            icon={ChevronNew}
            aria-label={`Toggle nested options for ${item.value.toString()}`}
            aria-controls={groupId}
            aria-expanded={isOpen}
          />
        )}

        <FilterOption
          key={item.value.toString()}
          className={styles.selectOption}
          value={item.value.toString()}
          label={item.value.toString()}
          postfix={item.count}
          onChange={handleItemSelectValue}
          checked={isSelected}
          disabled={!!isDisabled}
        />
      </div>

      {children.length > 0 && isOpen && (
        <div
          id={groupId}
          role="group"
          aria-label={item.value.toString()}
          className={childrenWrapper}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default HierarchyFilterOption;
