import { CrossNew } from "components/icons";
import IconAction from "ds/components/IconAction";

type BulkActionsDrawerItemDismissProps = {
  onDismiss: () => void;
  tooltip?: string;
};

const BulkActionsDrawerItemDismiss = ({
  onDismiss,
  tooltip = "Unselect",
}: BulkActionsDrawerItemDismissProps) => {
  return <IconAction icon={CrossNew} color="danger" onClick={onDismiss} tooltip={tooltip} />;
};

export default BulkActionsDrawerItemDismiss;
