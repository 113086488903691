import { memo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Banner from "ds/components/Banner";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { IdentityProvider, ManagedUser } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import { validateEmail } from "utils/formValidators";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";

import { InviteDrawerFields } from "./types";
import useInviteUser from "../InviteForm/useInviteUser";
import { showInviteSuccessModal } from "../InviteSuccessModal";
import styles from "./styles.module.css";
import { UserManagementActivationStatus } from "../../types";
import { SettingsContext } from "../../Context";
import { getManagementStrategy } from "../../helpers";

type UsersInviteDrawerProps = {
  user?: ManagedUser;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  usersCount: number;
  activeUsersCount: number;
};

const UsersInviteDrawer = ({
  user,
  isDrawerVisible,
  setDrawerVisibility,
  activeUsersCount,
  usersCount,
}: UsersInviteDrawerProps) => {
  const isEditMode = !!user;
  const { viewer } = useTypedContext(AccountContext);
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
      usersCount,
      activeUsersCount,
    },
  });

  const userInviteForm = useForm<InviteDrawerFields>({
    defaultValues: {
      email: user?.invitationEmail || "",
      userName: "",
      spaces: [{ space: undefined, spaceAccessLevel: undefined }],
      slackMemberID: "",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
    trigger,
  } = userInviteForm;

  const { onCreate } = useInviteUser();

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const onSubmit: SubmitHandler<InviteDrawerFields> = (formData) => {
    trackSegmentAnalyticsEvent("Drawer Send Invite");

    const input = {
      // username is either username field value or email field if username field is empty
      username: formData.userName || formData.email,
      email: formData.email,
      invitationEmail: formData.email,
      accessRules: formData.spaces,
      slackMemberID: formData.slackMemberID,
    };
    onCreate(input)
      .then(({ data }) => {
        if (data) {
          handleCloseDrawer();
          showInviteSuccessModal({ activationStatus });
        }
      })
      .catch(onError);
  };

  const isEmailFieldEmpty = getValues("email").length === 0;

  const isGithubUser = viewer.identityProvider === IdentityProvider.Github;

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleCloseDrawer} position="fixedRight">
      <FormProvider {...userInviteForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title={isEditMode ? "Access details" : "Invite to spacelift"} />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          {!isUserManagementActive && (
            <Banner variant="warning" title="User management is inactive">
              Invites will be sent and access rules will take effect once you change management
              strategy from login policy to user management
            </Banner>
          )}
          <FormField
            label="Username"
            helperText={
              isGithubUser
                ? "Provide GitHub username"
                : "Provide username if it is different than email"
            }
            error={errors?.userName?.message}
            isOptional={!isGithubUser}
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder={isGithubUser ? "Enter GitHub username" : "Enter username"}
                error={!!errors?.userName}
                {...register("userName", {
                  ...(isGithubUser && { required: "Username field is required." }),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
          <FormField label="Email" error={errors?.email?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter email"
                error={!!errors?.email}
                {...register("email", {
                  required: "Email field is required.",
                  validate: validateEmail,
                  // fixes validation when user paste url
                  onChange: () => trigger(),
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldSpace
            isDisabled={!!errors?.email || isEmailFieldEmpty}
            analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
          />
          <Box direction="column" className={styles.integrationWrapper}>
            <Typography variant="p-t5" tag="h5">
              Integrations
            </Typography>
            <Typography variant="p-body2" tag="p" className={styles.integrationDescription}>
              Give this user permissions while using third-party applications.{" "}
              <Link
                href={getDocsUrl("/concepts/user-management/admin#slack-integration")}
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </Link>
            </Typography>
            <FormField
              label="Slack member ID"
              error={errors?.slackMemberID?.message}
              isOptional
              tooltipDisablePortal
              tooltipInfoVariant="modal"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Slack member ID</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Id of Slack account that belongs to this user
                    <Link
                      href={getDocsUrl("/concepts/user-management/admin#slack-integration")}
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            >
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter member ID"
                  error={!!errors?.slackMemberID}
                  {...register("slackMemberID", {})}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>

          <DrawerFooter sticky>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={handleCloseDrawer}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                {isUserManagementActive ? "Send invite" : "Prepare invite"}
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(UsersInviteDrawer);
