import { ReactNode } from "react";

import { InfoNew } from "components/icons";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import TooltipModal from "ds/components/TooltipModal";
import { AnalyticsCommonProps } from "hooks/useAnalytics";
import { TooltipProps } from "ds/components/Tooltip/types";

import { IconColors } from "../Icon/types";
import IconAction from "../IconAction";

export type TooltipInfoVariant = "standard" | "modal";

type TooltipInfoProps = Omit<TooltipProps, "on" | "children"> & {
  children: ReactNode;
  variant?: TooltipInfoVariant;
  widthMode?: TooltipProps["widthMode"];
  iconClassName?: string;
  iconColor?: IconColors;
  disablePortal?: TooltipProps["disablePortal"];
} & AnalyticsCommonProps;

const TooltipInfoFloating = ({
  children,
  iconClassName,
  variant = "standard",
  iconColor = "primary",
  analyticsTitle,
  analyticsPage,
  analyticsProps,
  widthMode,
  ...tooltipProps
}: TooltipInfoProps) => {
  if (variant === "standard") {
    return (
      <Tooltip
        widthMode={widthMode}
        on={(props) => <Icon {...props} src={InfoNew} className={iconClassName} />}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <TooltipModal
      on={(props, isOpen) => (
        <IconAction
          {...props}
          isActive={isOpen}
          icon={InfoNew}
          color={iconColor}
          className={iconClassName}
          analyticsPage={analyticsPage}
          analyticsTitle={analyticsTitle}
          analyticsProps={analyticsProps}
          tooltip="Open more information"
          isTooltipActive={!isOpen}
        />
      )}
      {...tooltipProps}
    >
      {children}
    </TooltipModal>
  );
};

export default TooltipInfoFloating;
