import { useCallback, useState } from "react";

import PageLoading from "components/loading/PageLoading";
import FullScreenModal from "ds/components/FullScreenModal";
import useErrorHandle from "hooks/useErrorHandle";
import useTitle from "hooks/useTitle";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import VCSIntegrationsManageGitLabForm from "../Form";
import useCloseFullScreenModal from "../../useCloseFullScreenModal";
import useGetGitLabIntegration from "./useGetGitLabIntegration";

type VCSIntegrationsManageGitLabEditProps = {
  id: string;
};

const VCSIntegrationsManageGitLabEdit = ({ id }: VCSIntegrationsManageGitLabEditProps) => {
  const [isDirty, setIsDirty] = useState(false);

  const { integration, isPageLoading, error } = useGetGitLabIntegration(id);
  const { handleOnClose } = useCloseFullScreenModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const modalCloseClickHandler = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    handleOnClose();
    trackSegmentAnalyticsEvent("Integration Edit - Exit", { integration: "GitLab" });
  }, [handleOnClose, trackSegmentAnalyticsEvent, isDirty]);

  const toggleIsDirty = useCallback((value: boolean) => {
    setIsDirty(value);
  }, []);

  useTitle(`Edit integration · ${integration?.name}`);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  return (
    <FullScreenModal title="Edit GitLab integration" onClose={modalCloseClickHandler}>
      {isPageLoading && <PageLoading />}
      {!isPageLoading && (
        <VCSIntegrationsManageGitLabForm
          integration={integration}
          onIsDirtyChange={toggleIsDirty}
        />
      )}
    </FullScreenModal>
  );
};

export default VCSIntegrationsManageGitLabEdit;
