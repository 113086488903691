import { BillingTierFeature } from "types/generated";
import { SubscriptionContext } from "views/account/SubscriptionWrapper";

import useTypedContext from "../../../hooks/useTypedContext";

const useTierFeature = (tierFeature?: BillingTierFeature) => {
  const { tierFeatures } = useTypedContext(SubscriptionContext);

  if (!tierFeature) return false;

  return tierFeatures.includes(tierFeature);
};

export default useTierFeature;
