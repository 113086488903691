import { memo } from "react";

import Drawer from "ds/components/Drawer";
import { ApiKey } from "types/generated";
import { UserManagementActivationStatus } from "views/account/settings/types";

import ApiKeysDetailsDrawerContent from "./Content";

type ApiKeysDetailsDrawerProps = {
  apiKey?: ApiKey;
  handleCloseDrawer: () => void;
  activationStatus: UserManagementActivationStatus;
  openFullDescription: (description: string) => void;
};

const ApiKeysDetailsDrawer = ({
  apiKey,
  handleCloseDrawer,
  activationStatus,
  openFullDescription,
}: ApiKeysDetailsDrawerProps) => {
  return (
    <Drawer visible={!!apiKey} onOutsideClick={handleCloseDrawer}>
      {apiKey && (
        <ApiKeysDetailsDrawerContent
          apiKey={apiKey}
          activationStatus={activationStatus}
          handleCloseDrawer={handleCloseDrawer}
          openFullDescription={openFullDescription}
        />
      )}
    </Drawer>
  );
};

export default memo(ApiKeysDetailsDrawer);
