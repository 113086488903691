import cx from "classnames";
import { useCallback, useMemo } from "react";

import { FiltersContext } from "components/Filters";
import { ActiveFilter } from "components/Filters/types";
import { ChevronNew, CrossNew } from "components/icons";
import BaseAction from "ds/components/BaseAction";
import IconAction from "ds/components/IconAction";
import useTypedContext from "hooks/useTypedContext";
import { pluralize } from "shared/Pluralize";

import { ADD_ANOTHER_LABEL_TEXT } from "./constants";
import styles from "./styles.module.css";

type FilterActivatedSectionProps = {
  isLabelFilter: boolean;
  isLastLabelsFilter: boolean;
  filterName: string;
  toggleSectionOpen: () => void;
  addAnotherLabelFilter: () => void;
  activeFilter: ActiveFilter;
  disabled?: boolean;
};

const FilterActivatedSection = ({
  isLabelFilter,
  isLastLabelsFilter,
  filterName,
  toggleSectionOpen,
  addAnotherLabelFilter,
  activeFilter,
  disabled,
}: FilterActivatedSectionProps) => {
  const { deleteActiveFilter } = useTypedContext(FiltersContext);

  const handleDeleteFilter = useCallback(() => {
    if (activeFilter) {
      deleteActiveFilter(activeFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter]);

  // avoid re-rendering on the Context change
  return useMemo(() => {
    return (
      <>
        <div
          className={cx(styles.doneFilter, {
            [styles.labelFilter]: isLabelFilter,
          })}
        >
          {!disabled && (
            <IconAction
              icon={CrossNew}
              className={styles.deleteFilter}
              onClick={handleDeleteFilter}
              tooltip="Remove filter"
            />
          )}

          <BaseAction onClick={toggleSectionOpen} className={cx(styles.item, styles.doneItem)}>
            <span className={styles.property}>
              <ChevronNew className={styles.icon} />
              <span className={styles.text}>{filterName}</span>
            </span>

            <span className={styles.values}>
              {activeFilter.values.length} {pluralize("value", activeFilter.values.length)}
            </span>
          </BaseAction>
        </div>

        {isLastLabelsFilter && (
          <BaseAction
            onClick={addAnotherLabelFilter}
            className={cx(styles.item, styles.labelFilter)}
          >
            <ChevronNew className={styles.icon} />
            {ADD_ANOTHER_LABEL_TEXT}
          </BaseAction>
        )}
      </>
    );
  }, [
    activeFilter.values.length,
    addAnotherLabelFilter,
    filterName,
    handleDeleteFilter,
    isLabelFilter,
    isLastLabelsFilter,
    toggleSectionOpen,
  ]);
};

export default FilterActivatedSection;
