import { gql } from "@apollo/client";

export const GET_POLICY = gql`
  query GetPolicyDetails($policyId: ID!) {
    authorizationScheme
    policy(id: $policyId) {
      id
      name
      body
      space
      labels
      updatedAt
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdateLoginPolicy(
    $id: ID!
    $name: String!
    $body: String!
    $labels: [String!]
    $space: ID
  ) {
    policyUpdate(id: $id, name: $name, body: $body, labels: $labels, space: $space) {
      id
      name
      body
      space
    }
  }
`;

export const DELETE_POLICY = gql`
  mutation DeletePolicy($id: ID!) {
    policyDelete(id: $id) {
      id
      name
    }
  }
`;
