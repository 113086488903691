import DropdownEllipsisIcon from "ds/components/DropdownEllipsis/Icon";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import useTypedContext from "hooks/useTypedContext";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";

import { DashboardContext } from "../../context";
import { OverviewStaticWidget } from "../../types";

const DashboardOverviewColumnOnboardingDropdown = () => {
  const { updateTabOverviewColumnConfig, config } = useTypedContext(DashboardContext);

  const onHide = () => {
    if (config["overview"].overviewColumn) {
      updateTabOverviewColumnConfig(
        config["overview"].overviewColumn.map((item) =>
          item.value === OverviewStaticWidget.LaunchPad ? { ...item, hidden: true } : item
        )
      );
    }
  };

  return (
    <DropdownEllipsisIcon tooltip="Settings">
      {() => (
        <DropdownSection>
          <DropdownSectionItem onClick={onHide}>Hide</DropdownSectionItem>
          <DropdownSectionItem
            analyticsPage={AnalyticsPageDashboard.Dashboard}
            analyticsTitle="Dashboard - Go to Launchpad Clicked"
            to="/launchpad"
          >
            Go to LaunchPad
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsisIcon>
  );
};

export default DashboardOverviewColumnOnboardingDropdown;
