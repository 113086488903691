import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";

import { showDeleteConfirmation } from "./DeleteConfirmation";

type StackSettingsIntegrationsWebhooksDropdownProps = {
  onEditClick: () => void;
  onDetailsClick: () => void;
  canManageStack: boolean;
  endpoint: string;
  id: string;
  refetchQueriesOnDelete?: string[];
  stackId: string;
};

const StackSettingsIntegrationsWebhooksDropdown = ({
  onEditClick,
  refetchQueriesOnDelete,
  endpoint,
  id,
  stackId,
  onDetailsClick,
  canManageStack,
}: StackSettingsIntegrationsWebhooksDropdownProps) => {
  const handleDeleteClick = () => {
    showDeleteConfirmation({ endpoint, id, refetchQueriesOnDelete, stackId });
  };

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={() => (onDetailsClick(), close())}>
            Details
          </DropdownSectionItem>
          {canManageStack && (
            <DropdownSectionItem onClick={() => (onEditClick(), close())}>Edit</DropdownSectionItem>
          )}
          {canManageStack && (
            <DropdownSectionItem onClick={() => (handleDeleteClick(), close())} danger>
              Delete
            </DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default StackSettingsIntegrationsWebhooksDropdown;
