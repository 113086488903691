import { memo, useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";

import FormFieldTags from "components/FormFields/Tags";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Textarea from "ds/components/Textarea";
import Button from "ds/components/Button";
import useAnalytics from "hooks/useAnalytics";
import useTypedContext from "hooks/useTypedContext";
import { Policy, PolicyType } from "types/generated";
import FlashContext from "components/FlashMessages/FlashContext";
import Box from "ds/components/Box";
import Banner from "ds/components/Banner";
import { AnalyticsPagePolicy } from "hooks/useAnalytics/pages/policy";
import FormFieldSpace from "components/FormFields/Space";
import FormFieldSpaceTooltipInfo from "components/FormFields/Space/TooltipInfo";

import { ADD_POLICY_DRAWER_TEST_ID } from "./constants";
import { CreatePolicyFields } from "./types";
import { UPDATE_POLICY } from "./gql";

type PolicyEditDrawerProps = {
  isDrawerVisible: boolean;
  onCloseDrawer: () => void;
  takenPolicyNames: string[];
  refetchQueries?: InternalRefetchQueriesInclude;
  policy?: Policy;
  analyticsPage: AnalyticsPagePolicy;
};

const PolicyEditDrawer = ({
  isDrawerVisible,
  onCloseDrawer,
  takenPolicyNames,
  refetchQueries,
  policy,
  analyticsPage,
}: PolicyEditDrawerProps) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [updatePolicy, { loading: updatePolicyLoading }] = useMutation<{
    policyUpdate: { name: string };
  }>(UPDATE_POLICY, {
    refetchQueries,
    // APOLLO CLIENT UPDATE
    onCompleted: (data) => {
      if (data) {
        reportSuccess({ message: "Policy successfully updated" });
        onCloseDrawer();
      }
    },
    onError,
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const updatePolicyForm = useForm<CreatePolicyFields>({
    defaultValues: {
      name: policy?.name,
      space: policy?.spaceDetails.id,
      labels: policy?.labels ? policy?.labels.map((value) => ({ value })) : [],
      description: policy?.description || "",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
  } = updatePolicyForm;

  useEffect(() => {
    reset({
      name: policy?.name,
      space: policy?.spaceDetails.id,
      labels: policy?.labels ? policy?.labels.map((value) => ({ value })) : [],
      description: policy?.description || "",
    });
  }, [policy, reset]);

  const type = watch("type");
  const space = watch("space");

  // Allow access policy only for legacy space
  useEffect(() => {
    if (type === PolicyType.Access && space !== "legacy") {
      setValue("space", "legacy");
    }
  }, [type, space, setValue]);

  const handleCloseDrawer = () => {
    onCloseDrawer();
    reset();
  };

  const cancelHandler = () => {
    trackSegmentAnalyticsEvent("Edit Canceled");
    handleCloseDrawer();
  };

  const saveChanges: SubmitHandler<CreatePolicyFields> = (formData) => {
    updatePolicy({
      variables: {
        id: policy?.id,
        name: formData.name.trim(),
        body: policy?.body,
        description: formData.description?.trim(),
        space: formData.space,
        labels: formData.labels?.map(({ value }) => value),
      },
    })
      .then(() => {
        trackSegmentAnalyticsEvent("Policy edited");
      })
      .catch(onError);
  };

  const validateName = (value: string) => {
    const name = value && value.trim();

    if (!name) {
      return "Name field is required.";
    }

    if (takenPolicyNames.includes(name.toLowerCase()) && name !== policy?.name) {
      return "Policy with this name already exists";
    }

    return true;
  };

  return (
    <Drawer
      visible={isDrawerVisible}
      onOutsideClick={handleCloseDrawer}
      dataTestId={ADD_POLICY_DRAWER_TEST_ID}
    >
      <FormProvider {...updatePolicyForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Edit details" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight>
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Name of your policy"
                error={!!errors?.name}
                {...register("name", {
                  validate: validateName,
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldSpace
            tooltipAnalyticsPage={AnalyticsPagePolicy.PoliciesList}
            tooltipAnalyticsTitle="Tooltip click"
            tooltipAnalyticsProps={{ location: "Edit drawer", name: "Space" }}
            disabled={policy?.type === PolicyType.Access}
            tooltipInfo={
              <FormFieldSpaceTooltipInfo>
                Remember that you will only be able to attach policy to stacks and modules that are
                in the same space.
              </FormFieldSpaceTooltipInfo>
            }
          />

          {policy?.type === PolicyType.Access && (
            <Box margin="large 0 0 0" direction="column">
              <Banner variant="info">Access policy type is available only for legacy space.</Banner>
            </Box>
          )}

          <FormField label="Description" isOptional error={errors?.description?.message}>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter policy description here..."
                error={!!errors?.description}
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <FormFieldTags name="labels" tagName="label" label="Labels" isOptional />

          <DrawerFooter>
            <DrawerFooterActions>
              <Button variant="secondary" onClick={cancelHandler}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit(saveChanges)}
                disabled={!isValid || !policy?.id || !isDirty}
                loading={updatePolicyLoading}
              >
                Save changes
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(PolicyEditDrawer);
