import { useBulkTriggerStack } from "shared/Stack/useTriggerStack";
import { canTriggerStackStrict } from "shared/Stack/useTriggerStack/accessValidation";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";

import { StackBulkActions } from "../types";
import { BULK_ACTION_NAMES, BULK_ACTION_RESULTS_TITLE } from "../constants";
import { StackActionItemForBulkActions } from "./types";

const useTriggerStackBulkAction = (): StackActionItemForBulkActions => {
  const { viewer } = useTypedContext(AccountContext);

  const [stackTrigger] = useBulkTriggerStack();

  return {
    key: StackBulkActions.Trigger,
    title: BULK_ACTION_NAMES[StackBulkActions.Trigger],
    resultTitle: BULK_ACTION_RESULTS_TITLE[StackBulkActions.Trigger],
    condition: (stack) => canTriggerStackStrict(stack, viewer),
    mutation: (stack) =>
      stackTrigger({
        stack: stack.id,
        customRuntimeConfig: null,
      }),
  };
};

export default useTriggerStackBulkAction;
