import { useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import useOutsideClick from "hooks/useOutsideClick";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormFieldViewText from "components/FormFields/ViewText";
import FormField from "ds/components/Form/Field";
import Badge from "ds/components/Badge";
import Box from "ds/components/Box";
import ThemedLogs from "components/ThemedLogs";
import { prettify } from "views/shared/run/ResourcesDiff/helpers";
import ThemedLogsContent from "components/ThemedLogs/Content";

import { TASK_SEARCH_KEY } from "./constants";
import { ConfigNode } from "../types";

type ConfigManagementTreeGridDrawerProps = {
  task?: ConfigNode;
};

const ConfigManagementTreeGridDrawer = ({ task }: ConfigManagementTreeGridDrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const newUrlParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.delete(TASK_SEARCH_KEY);
    return params;
  }, [location.search]);

  const handleCloseDrawer = () => {
    navigate(`${location.pathname}${newUrlParams ? `?${newUrlParams}` : ""}`);
  };

  useOutsideClick(drawerRef, handleCloseDrawer, "mouseup");

  return (
    <Drawer visible={!!task} onOutsideClick={handleCloseDrawer}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Task details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody direction="column" fullWidth>
        <FormFieldViewText label="Name" value={task?.name} />
        {task?.status && (
          <FormField label="Status">
            <Box gap="medium">
              <Badge variant="ansibleStatus" state={task?.status} />
              {task?.checkMode && <Badge state="neutral">CHECK MODE</Badge>}
            </Box>
          </FormField>
        )}
        {task?.roleName && <FormFieldViewText label="Role" value={task?.roleName} />}
        {task?.logs ? (
          <FormField label="Logs">
            <ThemedLogs>
              <ThemedLogsContent>{prettify(task.logs)}</ThemedLogsContent>
            </ThemedLogs>
          </FormField>
        ) : (
          <FormFieldViewText label="Logs" value="No logs available" />
        )}
      </DrawerBody>
    </Drawer>
  );
};

export default ConfigManagementTreeGridDrawer;
