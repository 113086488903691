import React, { MouseEvent, useState } from "react";
import cx from "classnames";
import { Handle, NodeProps, Position } from "reactflow";

import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import {
  Dots,
  LockNew,
  MenuBlueprints,
  MenuStacks,
  MenuWebhooks,
  Plus,
  Trash,
} from "components/icons";
import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import IconAction from "ds/components/IconAction";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownIconAction from "ds/components/DropdownIconAction";
import PopoverActionBar from "ds/components/PopoverActionBar";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import { withTestId } from "utils/withTestId";
import BaseAction from "ds/components/BaseAction";
import Icon from "ds/components/Icon";
import Tooltip from "ds/components/Tooltip";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldIcon from "components/CopyField/Icon";

import styles from "./styles.module.css";
import { SpacesViewContext } from "../Context";
import { SpaceItemType } from "../Diagram/types";
import { SPACE_NODE_TEST_ID } from "../Diagram/constants";
import { SpacesViewActionsContext } from "../ActionsContext";

const SpaceNode = ({ data }: NodeProps<SpaceItemType>) => {
  const { currentSpace, isRootAdmin } = useTypedContext(SpacesViewContext);

  const {
    onSelect: onSpaceSelect,
    onCreate: onSpaceCreate,
    onFilter,
    onDelete,
  } = useTypedContext(SpacesViewActionsContext);

  const [closeDropdownTrigger, setCloseDropdownTrigger] = useState(0);

  const isPredefinedSpace = data.item.id === "root" || data.item.id === "legacy";
  const isActive = data.item.id === currentSpace?.id;

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onSpaceSelect(data.item.id);
  };

  const handleCreateSpace = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    onSpaceCreate(data.item.id);
  };

  const handleHoverLeave = () => {
    setCloseDropdownTrigger((state) => state + 1);
  };

  const handleFilter = (id: string, path: string, clb: () => void) => (e?: MouseEvent) => {
    e?.stopPropagation();
    clb();

    return onFilter(id, path);
  };

  return (
    <>
      <Box
        direction="row"
        align="center"
        justify="start"
        gap="medium"
        className={cx(styles.node, { [styles.active]: isActive })}
        onClick={handleOnClick}
        onMouseLeave={handleHoverLeave}
        {...withTestId(SPACE_NODE_TEST_ID)}
      >
        {data.item.parentSpace && (
          <Handle
            id={data.item.id}
            type="target"
            position={Position.Top}
            className={styles.topPoint}
          />
        )}

        <TextEllipsis tooltip={data.item.name} tooltipWidthMode="maxWidthXl" tooltipDisablePortal>
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {data.item.name}
            </Typography>
          )}
        </TextEllipsis>

        {isPredefinedSpace && isRootAdmin && (
          <Tooltip disablePortal on={(tooltipProps) => <Icon {...tooltipProps} src={LockNew} />}>
            This space is not editable.
          </Tooltip>
        )}

        {!isRootAdmin && <SpaceAccessBadge accessLevel={data.item.accessLevel} />}

        {isRootAdmin && (
          <Tooltip
            disablePortal
            on={(tooltipProps) => (
              <BaseAction
                {...tooltipProps}
                className={styles.createIcon}
                onClick={handleCreateSpace}
              >
                <Icon src={Plus} />
              </BaseAction>
            )}
            placement="bottom"
          >
            Create space
          </Tooltip>
        )}

        <PopoverActionBar className={styles.controls}>
          <DropdownIconAction
            icon={Dots}
            tooltip="Show more actions"
            disableTooltipPortal
            closeDropdownTrigger={closeDropdownTrigger}
          >
            {({ closeDropdown }) => (
              <DropdownSection>
                <DropdownSectionItem onClick={handleFilter(data.item.id, "/stacks", closeDropdown)}>
                  <MenuStacks className={styles.filterIcon} />
                  View Stacks
                </DropdownSectionItem>
                <DropdownSectionItem
                  onClick={handleFilter(data.item.id, "/blueprints", closeDropdown)}
                >
                  <MenuBlueprints className={styles.filterIcon} />
                  View Blueprints
                </DropdownSectionItem>
                <DropdownSectionItem
                  onClick={handleFilter(data.item.id, "/webhooks", closeDropdown)}
                >
                  <MenuWebhooks className={styles.filterIcon} />
                  View Webhooks
                </DropdownSectionItem>
              </DropdownSection>
            )}
          </DropdownIconAction>

          <CopyFieldIcon disableTooltipPortal title="Copy Space ID" value={data.item.id} />

          {isRootAdmin && !isPredefinedSpace && (
            <IconAction
              icon={Trash}
              color="danger"
              onClick={() => onDelete(data.item.id)}
              aria-label="Delete space"
              disableTooltipPortal
              tooltip="Delete space"
            />
          )}
        </PopoverActionBar>
      </Box>

      <Handle
        id={data.item.id}
        className={cx(styles.sourceHandle, { [styles.visible]: data.children.length > 0 })}
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </>
  );
};

export default SpaceNode;
