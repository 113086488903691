import { useLocation } from "react-router-dom-v5-compat";
import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { WorkerPool, WorkerPoolUser } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getSortOptionFromURI } from "components/Filters/helpers";
import { uniqByKey } from "utils/uniq";
import {
  ITEMS_LIMIT,
  POLL_INTERVAL,
  INITIAL_SORT_OPTION,
  INITIAL_SORT_DIRECTION,
} from "views/account/WorkerPool/UsedByView/constants";

import { SEARCH_PRIVATE_WORKER_POOL_USED_BY } from "./gql";

const useSearchPrivateWorkerPoolUsedBy = (workerPoolId?: string) => {
  const { state: sharedPrivateWorkerPoolState }: { state: WorkerPool | undefined } =
    useLocation(); /*<WorkerPool | undefined> TODO: use generic once react-router-dom is updated to v6 */

  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<WorkerPoolUser[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const { error, loading, data, stopPolling, fetchMore, networkStatus, previousData } = useQuery<{
    workerPool: WorkerPool;
  }>(SEARCH_PRIVATE_WORKER_POOL_USED_BY, {
    variables: {
      workerPoolId,
      input: {
        predicates: null,
        fullTextSearch: searchInput,
        first: ITEMS_LIMIT,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [workerPool, rawSearchUsers] = useMemo(() => {
    if (data?.workerPool) {
      const { searchUsers, ...workerPool } = data.workerPool;

      // FYI: data.workerPool could contain the huge qty of searchUsers fields
      // so we need to separate them from the workerPool object that stays to has WorkerPool type
      // TODO: implement dynamic types base on a query
      return [workerPool as WorkerPool, searchUsers];
    }

    return [undefined, undefined];
  }, [data?.workerPool]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchUsers?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchUsers?.edges, loading]);

  const loadMoreItems = async () => {
    try {
      if (rawSearchUsers?.pageInfo.endCursor && rawSearchUsers?.pageInfo.hasNextPage) {
        await fetchMore({
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              fetchMoreResult?.workerPool.searchUsers &&
              fetchMoreResult.workerPool.searchUsers.edges.length > 0
            ) {
              return {
                workerPool: {
                  ...prev.workerPool,
                  ...fetchMoreResult.workerPool,
                  searchUsers: {
                    ...fetchMoreResult.workerPool.searchUsers,
                    edges: uniqByKey(
                      [
                        ...(prev.workerPool.searchUsers?.edges || []),
                        ...fetchMoreResult.workerPool.searchUsers.edges,
                      ],
                      "cursor"
                    ),
                  },
                },
              };
            }

            return prev;
          },
          variables: {
            input: {
              first: ITEMS_LIMIT,
              after: rawSearchUsers.pageInfo.endCursor,
              fullTextSearch: searchInput,
              predicates: null,
              ...(sortOptionFields && { orderBy: sortOptionFields }),
            },
          },
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  const workerPoolResult = workerPool || previousData?.workerPool || sharedPrivateWorkerPoolState;

  return {
    workerPool: workerPoolResult,
    entities: memoizedEntities,
    error,
    stopPolling,
    isPageRefetching: loading && networkStatus !== NetworkStatus.loading,
    isPageEmpty: !!data && !memoizedEntities.length && !searchInput,
    hasNoFilteringResults: !!data && !memoizedEntities.length && !!searchInput,
    loadMoreItems,
    isPageLoading: loading && !workerPoolResult && networkStatus === NetworkStatus.loading,
  };
};

export default useSearchPrivateWorkerPoolUsedBy;
