import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useAnalytics from "hooks/useAnalytics";
import { ButtonProps } from "ds/components/Button";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import { ManagementStrategy } from "../settings/types";
import { showDeleteConfirmation } from "./DeleteConfirmation";

type LoginPolicyManageDropdownProps = {
  handleLoginPolicyEditDetailsClick: () => void;
  loginPolicyDeleteCallback?: () => void;
  refetchQueriesOnDelete?: string[];
  id: string;
  name: string;
  buttonVariant?: ButtonProps["variant"];
  size?: "small" | "medium";
  isLoginPolicyActive: boolean;
  analyticsPage:
    | AnalyticsPageOrganization.OrganizationLoginPoliciesList
    | AnalyticsPageOrganization.OrganizationLoginPolicyDetails;
};

const LoginPolicyManageDropdown = ({
  handleLoginPolicyEditDetailsClick,
  loginPolicyDeleteCallback,
  refetchQueriesOnDelete,
  id,
  name,
  buttonVariant = "secondary",
  size = "small",
  isLoginPolicyActive,
  analyticsPage,
}: LoginPolicyManageDropdownProps) => {
  const managementStrategy = isLoginPolicyActive
    ? ManagementStrategy.LOGIN_POLICY
    : ManagementStrategy.USER_MANAGEMENT;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { managementStrategy },
  });

  const onDeleteClick = (clb: () => void) => {
    clb();
    trackSegmentAnalyticsEvent("Start Delete");

    showDeleteConfirmation({
      isLoginPolicyActive,
      name,
      id,
      refetchQueries: refetchQueriesOnDelete,
    }).then((confirmed) => {
      if (confirmed) {
        trackSegmentAnalyticsEvent("Confirm Delete");
        loginPolicyDeleteCallback?.();
      } else {
        trackSegmentAnalyticsEvent("Cancel Delete");
      }
    });
  };

  const handleEdit = (callback: () => void) => {
    handleLoginPolicyEditDetailsClick();
    callback();
  };

  const copyCallback = (callback: () => void) => {
    trackSegmentAnalyticsEvent("Copy ID");
    callback();
  };

  return (
    <DropdownEllipsis dotsSize={size} buttonVariant={buttonVariant}>
      {({ close }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={() => handleEdit(close)}>Edit details</DropdownSectionItem>
          <CopyFieldDropdownItem title="Copy ID" value={id} callback={() => copyCallback(close)} />
          <DropdownSectionItem onClick={() => onDeleteClick(close)} danger>
            Delete
          </DropdownSectionItem>
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default LoginPolicyManageDropdown;
