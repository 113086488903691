import useTitle from "hooks/useTitle";
import { AccountContext } from "views/AccountWrapper";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import useTypedContext from "hooks/useTypedContext";
import Callout from "ds/components/Callout";
import Link from "ds/components/LinkNew";

const AuditTrailInfo = () => {
  const { accountName } = useTypedContext(AccountContext);

  useTitle(`Organization Settings · Audit trail · ${accountName}`);

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle tag="h2">Audit Trail</ViewHeaderTitle>
      </ViewHeader>
      <Callout variant="warning">
        <Link href="/audit-trail" size="small">
          Audit Trail
        </Link>{" "}
        page was moved and can be accessed from the dropdown menu in the left side navigation.
      </Callout>
    </>
  );
};

export default AuditTrailInfo;
