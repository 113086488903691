import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import cx from "classnames";

import { withEnterKeyPress } from "utils/browser";

import TreeChartGroupNodeRectMiddleGroup from "./RectMiddleGroup";
import { Group } from "./types";
import styles from "./styles.module.css";
import {
  ITEMS_PER_ROW,
  ITEM_SIZE,
  ITEM_SPACE,
  ITEM_BORDER_RADIUS,
  TEXT_GAP,
  TEXT_HEIGHT,
  MOUSE_LEAVE_TIMEOUT,
} from "./contstants";

type TreeChartGroupNodeRectsProps = {
  items: Group[];
  createMouseEnterHandler: (
    tooltipValue: ReactNode
  ) => (event: React.MouseEvent<SVGTextElement | SVGRectElement>) => void;
  onMouseLeave: () => void;
  activeId?: string;
};

const TreeChartGroupNodeRects = ({
  items,
  createMouseEnterHandler,
  onMouseLeave,
  activeId,
}: TreeChartGroupNodeRectsProps) => {
  const navigate = useNavigate();
  const mouseLeaveTimeoutId = useRef<number>();
  const [activeRectId, setActiveRectId] = useState<string | undefined>();

  useEffect(() => {
    // clear on component unmount
    return () => {
      clearTimeout(mouseLeaveTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    setActiveRectId(activeId);
  }, [activeId]);

  const handleMouseRectLeave = useCallback(() => {
    mouseLeaveTimeoutId.current = window.setTimeout(() => {
      setActiveRectId(activeId || undefined);
      onMouseLeave();
    }, MOUSE_LEAVE_TIMEOUT);
  }, [onMouseLeave, activeId]);

  const createMouseEnterRectHandler = useCallback(
    (id: string, tooltipValue: ReactNode) => (event: React.MouseEvent<SVGRectElement>) => {
      if (mouseLeaveTimeoutId.current) clearTimeout(mouseLeaveTimeoutId.current);
      setActiveRectId(id);
      createMouseEnterHandler(tooltipValue)(event);
    },
    [createMouseEnterHandler]
  );

  return (
    <>
      {items.map(({ status, name, link, id, parentTaskId, roleName }, i) => {
        const x = (i % ITEMS_PER_ROW) * (ITEM_SIZE + ITEM_SPACE);
        const y = Math.floor(i / ITEMS_PER_ROW) * (ITEM_SIZE + ITEM_SPACE) + TEXT_HEIGHT + TEXT_GAP;

        const newUrlParams = new URLSearchParams(location.search);
        if (link?.queryKey && link?.queryValue) {
          newUrlParams.set(link?.queryKey, link?.queryValue);
        }

        const linkAction = link
          ? () => {
              navigate(`${link?.path}?${newUrlParams}`, { replace: true });
            }
          : undefined;

        return (
          <Fragment key={id}>
            <rect
              className={cx(
                styles.groupRect,
                status && styles[status.toLowerCase()],
                activeRectId && id !== activeRectId && styles.unactive
              )}
              y={y}
              x={x}
              ry={ITEM_BORDER_RADIUS}
              rx={ITEM_BORDER_RADIUS}
              width={ITEM_SIZE}
              height={ITEM_SIZE}
              onMouseLeave={handleMouseRectLeave}
              onMouseEnter={createMouseEnterRectHandler(
                id,
                roleName ? (
                  <>
                    {name} <br /> {`Role: ${roleName}`}{" "}
                  </>
                ) : (
                  name
                )
              )}
              role="button"
              aria-label={linkAction ? `Open ${name}` : name}
              tabIndex={linkAction ? 0 : -1}
              onClick={linkAction}
              onKeyDown={withEnterKeyPress<SVGGElement>(() => linkAction?.())}
            />
            {parentTaskId && items && (
              <TreeChartGroupNodeRectMiddleGroup
                x={x}
                y={y}
                prevTaskHasTheSameParent={items[i - 1]?.parentTaskId === parentTaskId}
                nextTaskHasTheSameParent={items[i + 1]?.parentTaskId === parentTaskId}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default TreeChartGroupNodeRects;
