import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_MANAGED_USER_GROUP_INTEGRATION } from "../../gql";

const useDeleteIntegration = () => {
  const { onError } = useTypedContext(FlashContext);

  const [deleteManagedUserGroupIntegration] = useMutation(DELETE_MANAGED_USER_GROUP_INTEGRATION, {
    onError,
    refetchQueries: ["GetManagedUserGroupIntegrations"],
  });

  const onDelete = (id: string) => {
    return deleteManagedUserGroupIntegration({
      variables: {
        id,
      },
    }).catch(onError);
  };

  return { onDelete };
};

export default useDeleteIntegration;
