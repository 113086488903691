import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";

type VCSIntegrationsBuiltInGitHubListItemDropdownActionsProps = {
  onShowDetails: (callback: () => void) => void;
  onEdit: (callback: () => void) => void;
  canManageVCSIntegrations: boolean;
};

const VCSIntegrationsBuiltInGitHubListItemDropdownActions = ({
  onShowDetails,
  onEdit,
  canManageVCSIntegrations,
}: VCSIntegrationsBuiltInGitHubListItemDropdownActionsProps) => {
  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          <DropdownSectionItem onClick={() => onShowDetails(close)}>
            See details
          </DropdownSectionItem>
          {canManageVCSIntegrations && (
            <DropdownSectionItem onClick={() => onEdit(close)}>Edit</DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default VCSIntegrationsBuiltInGitHubListItemDropdownActions;
