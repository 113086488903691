import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import Filters from "components/Filters";
import useURLParams from "hooks/useURLParams";
import {
  SearchQueryPredicate,
  SearchSuggestionsFieldType,
  SearchSuggestionsOutput,
} from "types/generated";
import { FilterItem, FiltersItemsOptionsMap, SortOption } from "components/Filters/types";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { makeFilterItemOptionsFromSuggestionField } from "components/Filters/helpers";
import FiltersSidebar from "components/Filters/Sidebar";
import FiltersSplit from "components/Filters/Split";
import { getSearchQuery } from "components/SearchInput/helpers";
import { SavedFilterView } from "components/Filters/types";
import FiltersContentWrapper from "components/Filters/ContentWrapper";
import { useCachedFilterFields } from "components/Filters/hooks";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { SEARCH_ANSIBLE_SUGGESTIONS } from "../gql";
import {
  POLL_INTERVAL,
  FILTERS_ORDER_SETTINGS_KEY,
  initialSortDirection,
  initialSortOption,
} from "../constants";
import { getFilterKey } from "../utils";

type ConfigManagementFiltersLayoutProps = {
  predicates: SearchQueryPredicate[];
  children: React.ReactNode;
};

const ConfigManagementFiltersLayout = ({
  predicates,
  children,
}: ConfigManagementFiltersLayoutProps) => {
  const [currentSavedView, setCurrentSavedView] = useState<SavedFilterView | undefined>();
  const fieldsRef = useRef<string[] | null>(null);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const { onError } = useTypedContext(FlashContext);

  const [loadSearchAnsibleHostsSuggestions, { data: filteringData, loading, refetch }] =
    useLazyQuery<{
      searchAnsibleHostsSuggestions: SearchSuggestionsOutput;
    }>(SEARCH_ANSIBLE_SUGGESTIONS, { pollInterval: POLL_INTERVAL });

  const cachedFiltersData = useCachedFilterFields(
    filteringData?.searchAnsibleHostsSuggestions?.fields
  );

  const sortOptions = useMemo((): SortOption[] => {
    return (
      cachedFiltersData
        .filter((field) => field.orderable)
        .map((field) => {
          return {
            value: field.name,
            // TODO: distinguish labels from keys
            label: getFilterKey(field.name),
          };
        }) || []
    );
  }, [cachedFiltersData]);

  const [filters, filtersItemsOptionsMap] = useMemo<[FilterItem[], FiltersItemsOptionsMap]>(() => {
    const filtersItemsOptionsMap: FiltersItemsOptionsMap = new Map([]);

    return [
      cachedFiltersData
        .filter((field) => field.filterable)
        .map((field) => {
          const options = makeFilterItemOptionsFromSuggestionField(field);

          if (options) {
            filtersItemsOptionsMap.set(field.name, options);
          }

          return {
            key: getFilterKey(field.name),
            filterName: field.name,
            //SearchSuggestionsFieldType is only available if the field is filterable
            type: field.type as SearchSuggestionsFieldType,
          };
        }) || [],
      filtersItemsOptionsMap,
    ];
  }, [cachedFiltersData]);

  const loadSearchSuggestions = (initial = false) => {
    try {
      loadSearchAnsibleHostsSuggestions({
        variables: {
          input: {
            fullTextSearch: searchInput,
            predicates,
            fields: initial ? null : fieldsRef.current,
          },
        },
      });
    } catch (error) {
      onError(error);
    }
  };

  const handlePollingActiveSections = (fields: string[]) => {
    fieldsRef.current = fields;

    refetch({
      input: {
        fullTextSearch: searchInput,
        predicates,
        fields,
      },
    });
  };

  useEffect(() => {
    loadSearchSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predicates, searchInput]);

  // initial request with nullish fields
  useEffect(() => {
    loadSearchSuggestions(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Filters
      filters={filters}
      filtersItemsOptionsMap={filtersItemsOptionsMap}
      filtersLoading={loading}
      sortOptions={sortOptions}
      initialSortOption={initialSortOption}
      initialSortDirection={initialSortDirection}
      pollActiveSections={handlePollingActiveSections}
      filtersOrderSettingsKey={FILTERS_ORDER_SETTINGS_KEY}
      filtersType="configurationManagement"
      currentSavedView={currentSavedView}
      setCurrentSavedView={setCurrentSavedView}
    >
      <FiltersSplit>
        <FiltersSidebar analyticsPage={AnalyticsPageStack.Ansible} />
        <FiltersContentWrapper>{children}</FiltersContentWrapper>
      </FiltersSplit>
    </Filters>
  );
};

export default memo(ConfigManagementFiltersLayout);
