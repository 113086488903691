import ViewHeaderNavigation from "components/ViewHeader/Navigation";
import useTypedContext from "hooks/useTypedContext";
import Tab from "ds/components/Tab";
import Typography from "ds/components/Typography";
import DeltaCounts from "components/DeltaCounts";
import Box from "ds/components/Box";
import Badge from "ds/components/Badge";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import useTypedFlags from "hooks/useTypedFlags";

import { ansibleRunHasTasks } from "../../helpers";
import { RunContext } from "../../Context";

type RunHeaderAnsibleChangesNavProps = {
  analyticsPage?: AnalyticsPage;
};

const RunHeaderAnsibleChangesNav = ({ analyticsPage }: RunHeaderAnsibleChangesNavProps) => {
  const { hiddenBetaTagsAnsible } = useTypedFlags();
  const { run } = useTypedContext(RunContext);
  const hasTasks = !!run.delta && ansibleRunHasTasks(run.delta);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  return (
    <ViewHeaderNavigation>
      <Tab
        exact
        end
        id="History"
        onClick={() => trackSegmentAnalyticsEvent?.("Tab switched", { tab: "History" })}
        label="History"
        to="."
        v5Compat
      />

      {!!run.delta && !hasTasks && (
        <Typography variant="p-t6" tag="div" margin="0 medium">
          No tasks
        </Typography>
      )}

      {hasTasks && (
        <Tab
          id="Tasks"
          to="tasks"
          v5Compat
          label={
            <Box gap="medium" align="center">
              Tasks
              {!hiddenBetaTagsAnsible && (
                <Badge size="small" state="success">
                  BETA
                </Badge>
              )}
            </Box>
          }
          onClick={() => trackSegmentAnalyticsEvent?.("Tab switched", { tab: "Tasks" })}
          suffixComponent={
            <DeltaCounts isAnsible delta={run?.delta} skipEmpty variant="flexible" size="small" />
          }
        />
      )}
    </ViewHeaderNavigation>
  );
};

export default RunHeaderAnsibleChangesNav;
