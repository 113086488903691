import cx from "classnames";

import { ReactComponent as LogoText } from "./logo-text.svg";
import { ReactComponent as LogoSymbol } from "./logo-symbol.svg";
import styles from "./styles.module.css";

type StaticSpaceliftLogoProps = {
  short?: boolean;
  className?: string;
};

const StaticSpaceliftLogo = ({ short, className }: StaticSpaceliftLogoProps) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      {!short && <LogoText className={styles.logoText} />}
      <LogoSymbol className={styles.logoSymbol} />
    </div>
  );
};

export default StaticSpaceliftLogo;
