import { WORKER_POOL_SHARED_VALUE } from "constants/worker_pool";
import { Module } from "types/generated";
import { isSelfHostedDistribution } from "utils/distribution";

const isSelfHosted = isSelfHostedDistribution();

const getWorkerPoolInitialData = (workerPool: string | undefined) => {
  if (isSelfHosted && workerPool === WORKER_POOL_SHARED_VALUE) {
    return "";
  }

  return workerPool || "";
};

export const getDefaultValues = (module: Module) => {
  const initialWorkerPool = getWorkerPoolInitialData(
    module.workerPool?.id || WORKER_POOL_SHARED_VALUE
  );

  return {
    administrative: module.administrative,
    localPreviewEnabled: module.localPreviewEnabled,
    protectFromDeletion: module.protectFromDeletion,
    workflowTool: module.workflowTool,
    workerPool: initialWorkerPool,
  };
};
