import { useMutation } from "@apollo/client";

import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";

import { REVOKE_GPG_KEY } from "../gql";
import { GpgKeyListItemDropdownProps, RevokeGpgKeyGql } from "./types";
import { showGpgKeyListItemDropdownDeleteConfirmation } from "./DeleteConfirmation";

const GpgKeyListItemDropdown = ({ item, onEdit }: GpgKeyListItemDropdownProps) => {
  const { onError, reportSuccess, reportError } = useTypedContext(FlashContext);
  const { viewer } = useTypedContext(AccountContext);

  const [revokeGpgKey] = useMutation<RevokeGpgKeyGql>(REVOKE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const handleRevokeGpgKey = (callback?: () => void) => () => {
    revokeGpgKey({ variables: { id: item.id } })
      .then(({ data }) => {
        if (data?.gpgKeyRevoke?.revokedAt) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyRevoke.name}" was successfully revoked`,
          });
        } else {
          reportError({
            message: "Something went wrong while revoking GPG key, please try again.",
          });
        }
      })
      .catch(onError);

    callback?.();
  };

  const handleEditGpgKey = (callback?: () => void) => () => {
    onEdit(item);
    callback?.();
  };

  const canRevokeGpgKey = !item.revokedAt && viewer.admin;

  const onRevokeConfirmation = (callback?: () => void) => () => {
    showGpgKeyListItemDropdownDeleteConfirmation({
      onConfirm: handleRevokeGpgKey(callback),
      keyName: item.name,
    });
  };

  return (
    <DropdownEllipsis tooltip="GPG key actions" dotsSize="small">
      {({ closeDropdown }) => (
        <DropdownSection>
          {viewer.admin && (
            <DropdownSectionItem onClick={handleEditGpgKey(closeDropdown)}>
              Edit
            </DropdownSectionItem>
          )}
          <CopyFieldDropdownItem title="Copy ID" value={item.id} callback={closeDropdown} />
          {canRevokeGpgKey && (
            <DropdownSectionItem onClick={onRevokeConfirmation(closeDropdown)} danger>
              Revoke
            </DropdownSectionItem>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default GpgKeyListItemDropdown;
