import { ChangeEvent, DragEvent, useId, useState, forwardRef } from "react";
import cx from "classnames";

import { Documentation, DropFile, Migration, Spinner, Trash } from "components/icons";
import Typography from "ds/components/Typography";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import { withTestId } from "utils/withTestId";
import LoadingIndicator from "ds/components/LoadingIndicator";

import Box from "../../Box";
import IconAction from "../../IconAction";
import styles from "./styles.module.css";
import { FILE_INPUT_TEST_ID } from "./constants";

type DragDropFileUploadProps = {
  name?: string;
  caption?: string;
  className?: string;
  textAlign?: "left" | "center";
  dragBoxClassName?: string;
  fileListClassName?: string;
  file: File | null;
  loading?: boolean;
  onChange: (file: File | null) => void;
};

const DragDropFileUpload = forwardRef(function DragDropFileUpload(
  {
    onChange,
    file,
    name,
    caption = "Drag and drop the file here",
    className,
    textAlign = "center",
    dragBoxClassName,
    fileListClassName,
    loading,
  }: DragDropFileUploadProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const id = useId();
  const [isDragActivated, setIsDragActivated] = useState(false);

  const disableDragActivatedMode = () => {
    setIsDragActivated(false);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragActivated(true);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    if (file) {
      onChange(file);
    }

    disableDragActivatedMode();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    e.stopPropagation();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const file = e.target.files?.length && e.target.files[0];
    if (file) {
      onChange(file);
    }
  };

  return file ? (
    <Box
      direction="row"
      justify="between"
      align="center"
      gap="large"
      className={cx(styles.uploadedFileField, className, fileListClassName)}
    >
      <Box direction="row" align="center" gap="medium">
        <Icon src={Documentation} className={styles.docIcon} />
        <TextEllipsis tooltip={file.name} tooltipWidthMode="maxWidthXl">
          {(props) => (
            <Typography {...props} variant="p-body2" tag="span">
              {file.name}
            </Typography>
          )}
        </TextEllipsis>
      </Box>
      <LoadingIndicator size="large" loading={loading} icon={Spinner}>
        <IconAction icon={Trash} color="danger" onClick={() => onChange(null)} tooltip="Delete" />
      </LoadingIndicator>
    </Box>
  ) : (
    <Box
      direction="column"
      align="center"
      justify="center"
      fullWidth
      className={cx(
        styles.dropArea,
        styles[textAlign],
        { [styles.dragActivated]: isDragActivated },
        className,
        dragBoxClassName
      )}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={disableDragActivatedMode}
      onDragEnd={disableDragActivatedMode}
    >
      <input
        name={name}
        type="file"
        id={id}
        ref={ref}
        onChange={handleInputChange}
        {...withTestId(FILE_INPUT_TEST_ID)}
      />
      <Box
        direction={textAlign === "left" ? "row" : "column"}
        align={textAlign === "left" ? "start" : "center"}
        gap={textAlign === "left" ? "medium" : "0"}
      >
        <Icon src={Migration} className={styles.fileUploadIcon} />
        <Icon src={DropFile} className={styles.onDropIcon} />
        <Box
          className={styles.fileUploadCaption}
          direction="column"
          align={textAlign === "left" ? "start" : "center"}
        >
          <Typography variant="p-body2" tag="p">
            {caption}
          </Typography>
          <Typography htmlFor={id} variant="p-body2" tag="label" className={styles.fileUploadLink}>
            or browse files
          </Typography>
        </Box>
        <Typography variant="p-body2" tag="span" className={styles.onDropCaption}>
          Drop the file here
        </Typography>
      </Box>
    </Box>
  );
});

export default DragDropFileUpload;
