import type { ReactNode } from "react";

import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import { Copy } from "components/icons";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type FormFieldViewTextCopyWrapperProps = {
  children?: ReactNode;
  value: string;
};

const FormFieldViewTextCopyWrapper = ({ value, children }: FormFieldViewTextCopyWrapperProps) => {
  const copyHandler = useCopyToClipboard(value);

  return (
    <Box direction="row" gap="medium" justify="between" align="center">
      {children}
      <IconAction tooltip="Copy" onClick={copyHandler} icon={Copy} />
    </Box>
  );
};

export default FormFieldViewTextCopyWrapper;
