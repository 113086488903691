import { dropdownEllipsisWidth } from "ds/components/DropdownEllipsis/constants";
import { Spacing } from "types/Spacing";
import { SearchQueryOrderDirection } from "types/generated";
export const POLL_INTERVAL = 9000;
export const ITEMS_LIMIT = 50;

export const FILTERS_ORDER_SETTINGS_KEY = "workerPoolsFiltersOrder";

export enum SortableColumns {
  NAME = "name",
  SPACE = "space",
}

export const INITIAL_SORT_OPTION = SortableColumns.NAME;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;

export const COLUMN_ORDER = `4rem minmax(120px, 4.5fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) minmax(90px, 1fr) ${dropdownEllipsisWidth}`;
export const COLUMN_GAP: Spacing = "large";

export const PRIVATE_WORKER_POOL_CALLOUT_STORAGE_KEY = "privateWorkerPoolCallout";
