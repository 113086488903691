import BulkActionsDrawerItem from "components/BulkActions/Drawer/Item";
import BulkActionsDrawerItemDismiss from "components/BulkActions/Drawer/Item/Dismiss";
import {
  BulkActionActionConfirmationTabs,
  BulkActionItemID,
  BulkActionsActionDescriptor,
  BulkActionsStep,
} from "components/BulkActions/types";
import Box from "ds/components/Box";
import useAnalytics, { AnalyticsPage } from "hooks/useAnalytics";
import { ActionAvailabilityBanner } from "components/BulkActions/Drawer/Item/ActionAvailabiltyBanner";
import BulkActionsDrawerItemLink from "components/BulkActions/Drawer/Item/Link";
import RunStateBadge from "views/shared/RunNext/components/StateBadge";
import MetaInfoListItem from "components/MetaInfoList/Item";
import RunMetaInfoListItem from "views/Stack/Runs/MetaInfoListItem";
import Icon from "ds/components/Icon";
import { MenuRuns } from "components/icons";

import styles from "./styles.module.css";
import { getRunLink } from "./helpers";
import { BulkItemRunWithPosition, BulkWorkerPoolQueuedRunsActions } from "./types";

type WorkerPoolQueuedRunsBulkActionsSelectedItemProps = {
  item: BulkItemRunWithPosition;
  onDismiss: (id: BulkActionItemID) => void;
  analyticsPage: AnalyticsPage;
} & (
  | {
      step: BulkActionsStep.ActionConfirmation;
      itemCategory: BulkActionActionConfirmationTabs;
      itemActions?: never;
      allActionsQty?: never;
    }
  | {
      step: BulkActionsStep.ChooseAction;
      itemCategory?: never;
      itemActions: BulkActionsActionDescriptor<BulkWorkerPoolQueuedRunsActions>[] | undefined;
      allActionsQty: number;
    }
);

const WorkerPoolQueuedRunsBulkActionsSelectedItem = ({
  item,
  itemActions,
  itemCategory,
  onDismiss,
  allActionsQty,
  step,
  analyticsPage,
}: WorkerPoolQueuedRunsBulkActionsSelectedItemProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleDismiss = () => {
    onDismiss(item.id);
    trackSegmentAnalyticsEvent("Bulk Actions - Drawer - Item dismissed");
  };

  const isChooseActionStep = step === BulkActionsStep.ChooseAction;

  const actionTexts = (itemActions || []).map((action) => action.title);

  return (
    <BulkActionsDrawerItem key={item.id} direction="column" align="start">
      <Box gap="medium" align="start" justify="between" fullWidth>
        <RunStateBadge state={item.run.state} />

        <BulkActionsDrawerItemDismiss onDismiss={handleDismiss} />
      </Box>

      <Box fullWidth zeroMinWidth>
        <BulkActionsDrawerItemLink
          link={getRunLink(item)}
          text={item.run.title}
          step={step}
          itemCategory={itemCategory}
        />
      </Box>
      <RunMetaInfoListItem run={item.run} stackId={item.stackId} />
      <MetaInfoListItem>
        <Box gap="medium">
          <Icon src={MenuRuns} color="secondary" />
          <span className={styles.runType}>{item.run.type}</span>
        </Box>
      </MetaInfoListItem>
      {isChooseActionStep && (
        <ActionAvailabilityBanner itemActions={actionTexts} allActionsQty={allActionsQty} />
      )}
    </BulkActionsDrawerItem>
  );
};

export default WorkerPoolQueuedRunsBulkActionsSelectedItem;
