import useLocalStorage from "@rehooks/local-storage";
import { ChangeEvent } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import ToggleField from "ds/components/Form/ToggleField";
import { HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY } from "constants/local_storage_keys";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";

const NotificationsCustomization = () => {
  const { accountName } = useTypedContext(AccountContext);
  useTitle(`Personal Settings · Notifications customization · ${accountName}`);

  const [hideIgnoredRunNotice, setHideIgnoredRunNotice] = useLocalStorage(
    HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY,
    false
  );

  const toggleHideIgnoredRunNotice = (e: ChangeEvent<HTMLInputElement>) => {
    setHideIgnoredRunNotice(!e.target.checked);
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderTitle subtitle="Decide what alerts you’d like to see in the app." tag="h2">
          Notifications customization
        </ViewHeaderTitle>
      </ViewHeader>

      <Box direction="column" grow="1" padding="x-large" fullWidth>
        <Box direction="column" gap="medium" fullWidth>
          <Typography variant="p-t6" tag="h3">
            Runs notifications
          </Typography>

          <ToggleField
            variant="switch"
            onChange={toggleHideIgnoredRunNotice}
            checked={!hideIgnoredRunNotice}
            title="Ignored runs"
            description="When enabled, Spacelift will notify you about ignored runs on the tracked runs view."
          />
        </Box>
      </Box>
    </>
  );
};

export default NotificationsCustomization;
