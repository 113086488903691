import moment from "moment";
import cx from "classnames";

import { formatDateTimeByLocale } from "utils/date";
import { getNowTime } from "utils/time";

type TimestampProps = {
  timestamp: number;
  className?: string;
  prefix?: string;
};

const Timestamp = ({ timestamp, className, prefix }: TimestampProps) => {
  const then = moment.unix(timestamp);

  return (
    <span
      className={cx("timestamp", className)}
      title={formatDateTimeByLocale({
        date: then.toDate(),
        format: "dateTimeFull",
      })}
    >
      {prefix}
      {then.from(getNowTime())}
    </span>
  );
};

export default Timestamp;
