import { useMemo } from "react";

import { Stack } from "types/generated";
import Box from "ds/components/Box";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";
import { ErrorSolid, WarningSolid } from "components/icons";
import Icon from "ds/components/Icon";
import Typography from "ds/components/Typography";

type StackListItemIssuesCellProps = { stack: Stack };

const StackListItemIssuesCell = ({ stack }: StackListItemIssuesCellProps) => {
  const stackRunNeedsApproval = isRunReviewable(stack.blocker);

  const driftDetectedResource = useMemo(
    () => stack.entities.find((entity) => entity.drifted),
    [stack.entities]
  );

  return (
    <Box direction="column" gap="medium" align="start">
      {driftDetectedResource && (
        <Box gap="medium" align="center">
          <Icon src={ErrorSolid} color="danger" />
          <Typography tag="span" variant="p-body3">
            Drift detected
          </Typography>
        </Box>
      )}

      {stackRunNeedsApproval && (
        <Box gap="medium" align="center">
          <Icon src={WarningSolid} color="warning" />
          <Typography tag="span" variant="p-body3">
            Needs approval
          </Typography>
        </Box>
      )}

      {!driftDetectedResource && !stackRunNeedsApproval && (
        <Typography tag="span" variant="p-body3">
          -
        </Typography>
      )}
    </Box>
  );
};

export default StackListItemIssuesCell;
