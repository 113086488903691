import { gql } from "@apollo/client";

export const GET_POLICY = gql`
  query GetPolicyForDetails($policyId: ID!) {
    policy(id: $policyId) {
      id
      name
      body
      description
      spaceDetails {
        id
        name
        accessLevel
      }
      labels
      createdAt
      updatedAt
      type
      attachedStacks {
        isModule
        stackName
        id
        stackId
        stackSpaceDetails {
          name
          id
        }
      }
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdatePolicyDetails(
    $id: ID!
    $name: String!
    $body: String!
    $labels: [String!]
    $space: ID
    $description: String
  ) {
    policyUpdate(
      id: $id
      name: $name
      body: $body
      labels: $labels
      space: $space
      description: $description
    ) {
      id
      name
      body
      space
    }
  }
`;
