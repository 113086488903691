import TierInfo from "components/TierInfo";
import { BillingTierFeature } from "types/generated";
import useTierFeature from "views/account/hooks/useTierFeature";

const DESCRIPTION = "You can still create schedules, but to use them please upgrade your plan.";

const ADVANCED_SCHEDULING_STORAGE_KEY = "advancedSchedulingTierInfo";

const StackSchedulingTierInfo = () => {
  const isDriftDetectionEnabled = useTierFeature(BillingTierFeature.DriftDetection);

  if (!isDriftDetectionEnabled) {
    return (
      <TierInfo
        type="callout"
        variant="promo"
        feature={BillingTierFeature.DriftDetection}
        title="Drift detection and advanced scheduling are not supported by your plan."
      >
        {DESCRIPTION}
      </TierInfo>
    );
  }

  return (
    <TierInfo
      type="callout"
      variant="promo"
      feature={BillingTierFeature.AdvancedScheduling}
      title="Tasks and stack deletion scheduling are not supported by your plan."
      storageKey={ADVANCED_SCHEDULING_STORAGE_KEY}
    >
      {DESCRIPTION}
    </TierInfo>
  );
};

export default StackSchedulingTierInfo;
