import { AccountContext } from "views/AccountWrapper";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUser } from "types/generated";
import DropdownSection from "ds/components/Dropdown/Section";

import { showUserListRevokeAccessConfirmation } from "./ConfirmationModal";
import styles from "./styles.module.css";

type UsersListInviteDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListDropdown = ({ handleUserDetailsClick, item }: UsersListInviteDropdownProps) => {
  const { viewer } = useTypedContext(AccountContext);

  const confirmationModalShow = (close: () => void) => {
    showUserListRevokeAccessConfirmation({
      id: item.id,
      userName: item.username,
    });

    close();
  };

  return (
    <div className={styles.wrapper}>
      <DropdownEllipsis tooltip="User actions" dotsSize="small">
        {({ closeDropdown }) => (
          <DropdownSection>
            <DropdownSectionItem onClick={() => handleUserDetailsClick(item)}>
              Access details
            </DropdownSectionItem>
            {viewer.id !== item.username && (
              <DropdownSectionItem danger onClick={() => confirmationModalShow(closeDropdown)}>
                Revoke access
              </DropdownSectionItem>
            )}
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </div>
  );
};

export default UsersListDropdown;
