import { FieldErrors } from "react-hook-form";

import { StackCreationVendor, StackVendorFormFields } from "../types";

const getVendorErrors = (
  vendor: StackCreationVendor,
  errors: FieldErrors<StackVendorFormFields>
) => {
  switch (vendor) {
    case StackCreationVendor.Terraform:
      return errors.terraform;
    case StackCreationVendor.Pulumi:
      return errors.pulumi;
    case StackCreationVendor.CloudFormation:
      return errors.cloudFormation;
    case StackCreationVendor.Terragrunt:
      return errors.terragrunt;
    default:
      return {};
  }
};

export const hasCurrentVendorErrors = (
  vendor: StackCreationVendor,
  errors: FieldErrors<StackVendorFormFields>
) => {
  const currentVendorErrors = getVendorErrors(vendor, errors);
  return currentVendorErrors && !!Object.keys(currentVendorErrors).length;
};
