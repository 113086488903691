import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { PolicyStackAttachment } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { AnalyticsPageModule } from "hooks/useAnalytics/pages/module";

import { EntityType } from "./types";
import { ATTACH_POLICY_TO_ENTITY } from "./gql";

const useAttachPolicy = (entityType: EntityType, refetchQueries?: string[]) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentEvent = useAnalytics({
    page:
      entityType === "stack"
        ? AnalyticsPageStack.StacksPolicies
        : AnalyticsPageModule.ModulePolicies,
    callbackTrackProviders: {
      segment: true,
    },
  });

  const [attachPolicy, { loading }] = useMutation<{
    policyAttach: PolicyStackAttachment;
  }>(ATTACH_POLICY_TO_ENTITY, {
    onError,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleAttachPolicy = useCallback(
    (policyId: string, entityId: string, successCallback?: () => void) => {
      attachPolicy({
        variables: {
          policyId,
          entityId,
        },
      })
        .then(({ data }) => {
          if (data?.policyAttach) {
            reportSuccess({
              message: "Policy was successfully attached",
            });
            trackSegmentEvent("Policy attached");
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [attachPolicy, onError, reportSuccess, trackSegmentEvent]
  );

  return {
    attachPolicy: handleAttachPolicy,
    loading,
  };
};

export default useAttachPolicy;
