import { CrossNew } from "components/icons";
import Countdown from "components/time/Countdown";

import IconAction from "../../IconAction";
import styles from "./styles.module.css";

type ToastCloseProps = {
  timeout?: number;
  isPaused?: boolean;
  onClose: () => void;
};

const ToastClose = ({ timeout, isPaused, onClose }: ToastCloseProps) => {
  return (
    <div className={styles.toastClose}>
      {!!timeout && (
        <Countdown ttl={timeout} className={styles.toastCloseCountdown} isPaused={isPaused} />
      )}

      <IconAction
        aria-label="Close"
        icon={CrossNew}
        onClick={onClose}
        color="on-solid-primary"
        className={styles.toastCloseButton}
      />
    </div>
  );
};

export default ToastClose;
