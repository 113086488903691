import { gql } from "@apollo/client";

export const GET_ONBOARDING_PROGRESS = gql`
  query GetOnboardingProgress(
    $searchVcsIntegrationsInput: SearchInput!
    $searchStacksInput: SearchInput!
  ) {
    providers
    searchVCSIntegrations(input: $searchVcsIntegrationsInput) {
      edges {
        cursor
      }
    }
    searchStacks(input: $searchStacksInput) {
      edges {
        cursor
      }
    }
    azureIntegrations {
      id
    }
    awsIntegrations(includeLegacy: false) {
      id
    }
    managedUsers {
      latestInvitation {
        sentAt
      }
    }
  }
`;
