import Link from "ds/components/Link";
import SystemMessage from "components/SystemMessage";
import Typography from "ds/components/Typography";
import { ErrorColored } from "components/icons";

import styles from "./styles.module.css";

const SPACELIFT_SIGNUP_LINK = "https://spacelift.io/free-trial";

const AccountNotFoundError = () => {
  return (
    <SystemMessage title="Account not found" text="" icon={ErrorColored}>
      <Typography variant="p-body2" tag="p" className={styles.text}>
        Looks like you don't have account yet.
        <br></br>
        You can create one{" "}
        <Link href={SPACELIFT_SIGNUP_LINK} size="medium">
          here
        </Link>
      </Typography>
    </SystemMessage>
  );
};

export default AccountNotFoundError;
