import { createContext, useCallback, useEffect, useState } from "react";

type HubspotContextData = {
  setHubspotWidgetVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isHubspotWidgetVisible: boolean;
  handleHubspotClose: () => void;
};

export const HubspotContext = createContext<HubspotContextData | undefined>(undefined);
HubspotContext.displayName = "HubspotContext";

type HubspotContextProviderProps = {
  children: React.ReactNode;
};

export const HubspotContextProvider = ({ children }: HubspotContextProviderProps) => {
  const [isHubspotWidgetVisible, setHubspotWidgetVisible] = useState(false);

  const handleHubspotClose = useCallback(() => {
    window.HubSpotConversations?.widget?.remove();
    setHubspotWidgetVisible(false);
  }, []);

  useEffect(() => {
    if (window.HubSpotConversations) {
      setHubspotWidgetVisible(true);
    } else {
      window.hsConversationsOnReady = [
        () => {
          setHubspotWidgetVisible(true);
        },
      ];
    }
  }, []);

  const contextValue = {
    isHubspotWidgetVisible,
    setHubspotWidgetVisible,
    handleHubspotClose,
  };

  return <HubspotContext.Provider value={contextValue}>{children}</HubspotContext.Provider>;
};
