import { useCallback, useRef, MouseEvent } from "react";
import cx from "classnames";

import useTypedContext from "hooks/useTypedContext";
import Box, { BoxProps } from "ds/components/Box";
import useAnalytics, { AnalyticsCommonProps } from "hooks/useAnalytics";

import { CollapsiblePanelContext } from "./Context";
import styles from "./styles.module.css";

type CollapsiblePanelToggleTriggerProps = BoxProps &
  AnalyticsCommonProps & {
    ariaLabel?: string;
    focusVisible?: boolean;
  };

function CollapsiblePanelToggleTrigger({
  children,
  className,
  focusVisible,
  ariaLabel,
  analyticsPage,
  analyticsTitle,
  analyticsProps,
  ...rest
}: CollapsiblePanelToggleTriggerProps) {
  const { onToggle, isCollapsed, contentId } = useTypedContext(CollapsiblePanelContext);
  const toggleTriggerRef = useRef<HTMLDivElement>(null);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const trackAnalytics = useCallback(() => {
    if (analyticsTitle) {
      trackSegmentAnalyticsEvent?.(analyticsTitle, { ...analyticsProps, visible: !isCollapsed });
    }
  }, [analyticsTitle, analyticsProps, trackSegmentAnalyticsEvent, isCollapsed]);

  const handleKeyboardToggle = useCallback(
    (e: React.KeyboardEvent) => {
      // If the user presses Enter or Space, toggle the panel and prevent the default action
      if (["Enter", " "].includes(e.key)) {
        e.preventDefault();
        onToggle();
        trackAnalytics();
      }
    },
    [onToggle, trackAnalytics]
  );

  const handleClickToggle = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      // If the user clicks directly on the trigger, toggle the panel
      if (target === toggleTriggerRef.current) {
        onToggle();
        trackAnalytics();
        return;
      }

      const selector = "a, button, input, select, textarea, label, [tabindex]";
      const isTargetInteractive = target.matches(selector);

      // Skip if the target is interactive
      if (isTargetInteractive) {
        return;
      }

      // Look for closest interactive parent
      const targetsInteractiveParent = target.closest(selector);

      // Toggle if the closest interactive parent is the trigger or if there is no interactive parent
      if (targetsInteractiveParent === toggleTriggerRef.current || !targetsInteractiveParent) {
        onToggle();
        trackAnalytics();

        return;
      }
    },
    [onToggle, trackAnalytics]
  );

  return (
    <Box
      ref={toggleTriggerRef}
      {...rest}
      align="center"
      className={cx(styles.toggleTrigger, focusVisible && styles.focusVisible, className)}
      onClick={handleClickToggle}
      onKeyDown={handleKeyboardToggle}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
      aria-expanded={!isCollapsed}
      aria-controls={contentId}
    >
      {children}
    </Box>
  );
}

export default CollapsiblePanelToggleTrigger;
