import {
  ComponentType,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useEffect,
  RefObject,
  MutableRefObject,
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListChildComponentProps, ListProps, VariableSizeList } from "react-window";

import { useHideOnScrollContext } from "components/HideOnScroll/HideOnScrollContext";

import styles from "./styles.module.css";
import { VIRTUALISED_LIST_TEST_ID } from "./constants";

export type ListEntitiesBaseItemProps = {
  setRowHeight: (index: number, size: number) => void;
  windowWidth: number;
};

export type ListEntitiesNewProps<K> = {
  itemProps: K;
  virtualizedItem: ComponentType<ListChildComponentProps<K & ListEntitiesBaseItemProps>>;
  itemKey: (index: number) => string;
  width?: string;
  itemCount: number;
  onItemsRendered?: ListProps["onItemsRendered"];
  indexToScroll?: number;
  listClassName?: string;
  /**
   * Allows to inject things into the list scrolling container (like sticky elements)
   */
  outerContainerRef?: RefObject<HTMLElement>;
};

const ListEntitiesNew = <K,>({
  itemProps,
  virtualizedItem,
  itemKey,
  itemCount,
  width = "100%",
  onItemsRendered,
  indexToScroll,
  listClassName,
  outerContainerRef,
}: ListEntitiesNewProps<K>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef<Record<string, number>>({});
  const { setScrollableElRef } = useHideOnScrollContext();

  const handleRefs = (value: HTMLElement) => {
    setScrollableElRef(value);
    if (outerContainerRef) {
      (outerContainerRef as MutableRefObject<HTMLElement>).current = value;
    }
  };

  const [windowWidth, setWindowWidth] = useState(0);

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || 84;
  };

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const scrollToElement = (index: number) => {
    if (listRef?.current) listRef.current.scrollToItem(index, "center");
  };

  const itemData = useMemo(
    () => ({ ...itemProps, setRowHeight, windowWidth, scrollToElement }),
    [itemProps, windowWidth]
  );

  useEffect(() => {
    if (listRef?.current && (indexToScroll || indexToScroll === 0)) {
      listRef.current.scrollToItem(indexToScroll, "center");
    }
  }, [listRef, indexToScroll]);

  useLayoutEffect(() => {
    const element = wrapperRef.current;

    if (element) {
      const ro = new ResizeObserver((entries) => {
        const targetElement = entries[0].contentRect;
        setWindowWidth(targetElement.width);
      });

      ro.observe(element);

      return () => {
        ro.disconnect();
      };
    }

    return undefined;
  }, []);

  return (
    <div ref={wrapperRef} className={styles.listEntities} data-testid={VIRTUALISED_LIST_TEST_ID}>
      <AutoSizer disableWidth>
        {({ height }) => (
          <VariableSizeList<K & ListEntitiesBaseItemProps>
            itemKey={itemKey}
            itemData={itemData}
            itemCount={itemCount}
            itemSize={getRowHeight}
            ref={listRef}
            outerRef={handleRefs}
            width={width}
            height={height}
            onItemsRendered={onItemsRendered}
            className={listClassName}
          >
            {virtualizedItem}
          </VariableSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

export default ListEntitiesNew;
