import Modal from "ds/components/Modal";
import ModalHeader from "ds/components/Modal/Header";
import ModalContent from "ds/components/Modal/Content";
import ModalFooter from "ds/components/Modal/Footer";
import CodeEditor from "components/CodeEditor";

import { prettify } from "./helpers";
import styles from "./styles.module.css";
import { EncodedJsonValue } from "./types";

type JsonModalProps = {
  value: EncodedJsonValue | null;
  onClose: (callback?: () => void) => () => unknown;
};

function JsonModal({ value, onClose }: JsonModalProps) {
  return (
    <Modal
      forceIsVisible={!!value}
      setForceModalVisibility={onClose()}
      size="medium"
      preventClosing
    >
      {({ closeModal }) => (
        <>
          <ModalHeader title="JSON View" hideModal={onClose(closeModal)} />
          <ModalContent>
            {value && (
              <CodeEditor
                body={prettify(value)}
                language="json"
                className={styles.jsonViewEditor}
                options={{
                  glyphMargin: false,
                  unicodeHighlight: {
                    invisibleCharacters: false,
                  },
                }}
                readOnly
              />
            )}
          </ModalContent>
          <ModalFooter mainActionCallback={onClose(closeModal)} mainActionText="Close" />
        </>
      )}
    </Modal>
  );
}

export default JsonModal;
