import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom-v5-compat";

import { AccountContext } from "views/AccountWrapper";
import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";
import NoAccessPage from "components/error/NoAccessPage";
import FullScreenModal from "ds/components/FullScreenModal";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";
import WarningContextProvider from "components/WarningContext/Provider";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import { AttachedProject } from "./AttachedProject/types";
import NewContextDetails from "./Details";
import { ContextFormContext } from "./context";
import {
  ContextCreationWizardStep,
  contextCreationWizardNavSteps,
  defaultHooksValues,
  CONTEXT_ANALYTICS_VERSION,
} from "./constants";
import NewContextStepper from "./Stepper";
import NewAttachContext from "./AttachContext";
import { ContextFormFields } from "./types";
import NewContextSetupEnvironment from "./SetupEnvironment";
import NewContextAddHooks from "./AddHooks";
import NewContextSummary from "./Summary";
import { SpacesContext } from "../SpacesProvider";

const defaultValues = {
  description: "",
  labels: [],
  contextName: "",
  space: "",
  attachedProjectIds: [],
  attachedVariables: [],
  attachedMountedFiles: [],
  hooks: defaultHooksValues,
};

type NewContextProps = {
  initialStep?: ContextCreationWizardStep;
};

const NewContext = ({ initialStep = ContextCreationWizardStep.Details }: NewContextProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { hasManageableSpaces } = useTypedContext(SpacesContext);
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [attachedProjects, setAttachedProjects] = useState<AttachedProject[]>([]);
  const [autoAttachedProjects, setAutoAttachedProjects] = useState<AttachedProject[]>([]);
  const navigate = useNavigate();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.ContextNew,
    callbackTrackProviders: { segment: true },

    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const handleStepChange = useCallback((step: ContextCreationWizardStep) => {
    setCurrentStep(step);
  }, []);

  const goToNextStep = useCallback(() => {
    setCurrentStep((prevStep) =>
      Math.min(Object.keys(ContextCreationWizardStep).length, prevStep + 1)
    );
  }, []);

  const goToPreviousStep = useCallback(() => {
    setCurrentStep((prevStep) => Math.max(0, prevStep - 1));
  }, []);

  const currentStepData = contextCreationWizardNavSteps.find((item) => item.step === currentStep);

  const contextFormContextValue = useMemo(() => {
    const setProjectsData = (data: AttachedProject[], isAuto?: boolean) => {
      if (isAuto) {
        setAutoAttachedProjects(data);
      } else {
        setAttachedProjects(data);
      }
    };
    const stepCount = Object.keys(ContextCreationWizardStep).length / 2;
    const lastStep = stepCount - 1;
    return {
      currentStep,
      currentStepData,
      setCurrentStep: handleStepChange,
      goToNextStep,
      goToPreviousStep,
      isFirstStep: currentStep === 0,
      isLastStep: currentStep === lastStep,
      attachedProjects,
      autoAttachedProjects,
      setAttachedProjects: setProjectsData,
    };
  }, [
    currentStep,
    currentStepData,
    goToNextStep,
    goToPreviousStep,
    handleStepChange,
    attachedProjects,
    autoAttachedProjects,
  ]);

  const form = useForm<ContextFormFields>({
    defaultValues,
    mode: "onChange",
  });

  const modalCloseClickHandler = useCallback(async () => {
    if (form.formState.isDirty) {
      await showSimpleLeaveConfirmation({
        title: "Do you want to leave context creation process?",
        message: "Your changes will not be saved.",
      });
    }

    trackSegmentAnalyticsEvent("Close", { location: currentStepData?.shortName });
    navigate("/contexts");
  }, [navigate, currentStepData, trackSegmentAnalyticsEvent, form.formState.isDirty]);

  if (!viewer.admin && !hasManageableSpaces) {
    return <NoAccessPage />;
  }

  return (
    <ContextFormContext.Provider value={contextFormContextValue}>
      <FullScreenModal title="Create context" onClose={modalCloseClickHandler}>
        <WarningContextProvider>
          <Box grow="1" fullWidth scrollable>
            <NewContextStepper />
            <Box fullWidth direction="column" relative>
              <FormProvider {...form}>
                {currentStep === ContextCreationWizardStep.Details && <NewContextDetails />}
                {currentStep === ContextCreationWizardStep.Attachment && <NewAttachContext />}
                {currentStep === ContextCreationWizardStep.Environment && (
                  <NewContextSetupEnvironment />
                )}
                {currentStep === ContextCreationWizardStep.Hooks && <NewContextAddHooks />}
                {currentStep === ContextCreationWizardStep.Summary && <NewContextSummary />}
              </FormProvider>
            </Box>
          </Box>
        </WarningContextProvider>
      </FullScreenModal>
    </ContextFormContext.Provider>
  );
};

export default NewContext;
