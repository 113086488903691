import { useContext, Context } from "react";

const useTypedContext = <T>(Context: Context<T | undefined>): T => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      `Passed ${Context.displayName || "UnknownContext"} must be within own Provider`
    );
  }

  return context;
};

export default useTypedContext;
