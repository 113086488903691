import { useMemo } from "react";
import { useParams } from "react-router-dom-v5-compat";

import { AwsIntegration, SpaceAccessLevel } from "types/generated";
import NotFoundPage from "components/error/NotFoundPage";
import Button from "ds/components/Button";
import { ArrowThin } from "components/icons";
import IconAction from "ds/components/IconAction";
import { AccountContext } from "views/AccountWrapper";
import useTypedContext from "hooks/useTypedContext";

import DeleteButton from "../DeleteButton";
import styles from "./styles.module.css";
import AwsIntegrationViewForm from "./Form";

type AwsIntegrationViewProps = {
  integrations: AwsIntegration[];
};

type AwsIntegrationViewRouteParams = {
  integrationId: string;
};

const AwsIntegrationView = ({ integrations }: AwsIntegrationViewProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { integrationId } = useParams<AwsIntegrationViewRouteParams>();
  const integration = useMemo(
    () => integrations.find((i) => i.id === integrationId),
    [integrations, integrationId]
  );

  if (!integration) {
    return <NotFoundPage />;
  }

  const canManageItem =
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Admin ||
    integration.spaceDetails.accessLevel === SpaceAccessLevel.Write;

  return (
    <>
      <div className={styles.header}>
        <h3 className={styles.title}>
          <IconAction
            icon={ArrowThin}
            to="/cloud-integrations/aws"
            className={styles.backButton}
            tooltip="Go back"
          />
          Integration details
        </h3>
        {(viewer.admin || canManageItem) && (
          <div className={styles.buttons}>
            <DeleteButton integration={integration} />
            <Button
              to={`/cloud-integrations/aws/${integrationId}/edit`}
              variant="secondary"
              size="small"
              className={styles.editBtn}
            >
              Edit
            </Button>
          </div>
        )}
      </div>

      <AwsIntegrationViewForm integration={integration} />
    </>
  );
};

export default AwsIntegrationView;
