import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FetchMoreOptions, useQuery } from "@apollo/client";

import Toggle from "ds/components/Toggle";
import FlashContext from "components/FlashMessages/FlashContext";
import { AccountContext } from "views/AccountWrapper";
import InfiniteScroll from "components/scroll/InfiniteScroll";
import PageLoading from "components/loading/PageLoading";
import { uniqById } from "utils/uniq";
import { useInterval } from "hooks";
import useTitle from "hooks/useTitle";
import useTypedContext from "hooks/useTypedContext";
import { Run, Stack, VcsProvider } from "types/generated";
import LockNotice from "components/LockNotice";
import DisabledStackNotice from "components/DisabledStackNotice";
import useErrorHandle from "hooks/useErrorHandle";
import NotFoundPage from "components/error/NotFoundPage";
import useBreadcrumbs from "components/Breadcrumbs/useBreadcrumbs";
import PageWrapper from "components/PageWrapper";
import PageInfo from "components/PageWrapper/Info";
import FiltersSortHeaderWrapper from "components/Filters/SortHeader/Wrapper";
import FiltersSortHeaderStaticColumn from "components/Filters/SortHeader/StaticColumn";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import { RunsColored } from "components/icons";
import EmptyState from "ds/components/EmptyState";
import useTypedFlags from "hooks/useTypedFlags";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";
import { isAnsibleStackVendor } from "utils/stack";
import useBulkActionsSelection from "components/BulkActions/useBulkActionsSelection";
import { isRunReviewable } from "shared/Run/useReviewRun/accessValidation";

import { StackContext } from "../Context";
import { LIST_RUNS } from "./gql";
import {
  getLastIgnoredRunFromStack,
  shouldShowIgnoredRunCallout,
} from "./IgnoredRunCallout/helpers";
import RunsGroup from "./RunsGroup";
import RunElement from "./RunElement";
import { groupPerCommitOnTimeline } from "../helpers";
import TriggerButton from "./components/TriggerButton";
import SyncButton from "./components/SyncButton";
import StackLockOrUnlockButton from "../components/LockOrUnlockButton";
import { COLUMN_GAP, COLUMN_ORDER_APPROVAL, COLUMN_ORDER_USER } from "./constants";
import { POLL_INTERVAL, RUNS_PER_PAGE } from "../constants";
import StackDetachedIntegrationCallout from "../components/DetachedIntegrationCallout";
import StackHeader from "../components/Header";
import { getStacksBackUrl } from "../helpers";
import StackRunsBulkActions from "./BulkActions";
import StackRunsIgnoredRunCallout from "./IgnoredRunCallout";
import styles from "./styles.module.css";

type TData = { stack: Stack };

const Runs = () => {
  const { triggerRunWithCustomConfigFrontend, vcsEventsShowIgnoredRuns } = useTypedFlags();

  const [hasError, setHasError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState<Set<string>>(new Set());

  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const {
    stack: stackCtx,
    hasAtLeastWriteAccess,
    configurationManagementViewEnabled,
  } = useTypedContext(StackContext);

  const {
    allSelected,
    selectedSet,
    syncAllSelectedItems,
    selectItem,
    unselectItem,
    onBulkResetAll,
    onBulkContinueWith,
    syncSelectedItemsWithVisibleOnes,
  } = useBulkActionsSelection();
  const listRef = useRef(null);

  useTitle(`Runs · ${stackCtx.name}`);

  useBreadcrumbs([
    {
      title: "Stacks",
      link: getStacksBackUrl(),
    },
    {
      title: stackCtx.name,
    },
  ]);

  const { loading, error, data, fetchMore, refetch } = useQuery<TData>(LIST_RUNS, {
    onError,
    variables: { stackId: stackCtx.id },
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
  });

  const updateQuery: FetchMoreOptions<TData>["updateQuery"] = (previous, { fetchMoreResult }) => {
    if (fetchMoreResult?.stack) {
      return {
        stack: {
          ...fetchMoreResult.stack,
          runs: uniqById([...fetchMoreResult.stack.runs, ...previous.stack.runs]).sort(
            (a, b) => b.createdAt - a.createdAt
          ),
        },
      };
    }

    return { stack: { ...previous.stack } };
  };

  useInterval(
    async () => {
      if (data) {
        try {
          await fetchMore({ updateQuery });
        } catch (error) {
          // force error rendering in result of using "cache-first"
          setHasError(true);
          onError(error);
        }
      }
    },
    hasError || error ? null : POLL_INTERVAL
  );

  const stack = useMemo(() => {
    if (data?.stack) {
      return {
        ...stackCtx,
        ...(data?.stack ? data.stack : {}),
      };
    }

    return undefined;
  }, [data?.stack, stackCtx]);

  const runsListPerCommit = useMemo(
    () => groupPerCommitOnTimeline<Run>(stack?.runs || []),
    [stack?.runs]
  );

  const handleScrollEnd = useCallback(async () => {
    const lastRun = stack?.runs[stack?.runs.length - 1];

    if (lastRun) {
      try {
        await fetchMore({
          variables: { before: lastRun.id },
          updateQuery,
        }).then(({ data: moreData }) => {
          if (moreData) {
            setHasMore(moreData.stack.runs.length === RUNS_PER_PAGE);
          }
        });
      } catch (err) {
        // force error rendering in result of using "cache-first"
        setHasError(true);
        onError(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMore, stack]);

  const handleBulkResetAll = useCallback(() => {
    onBulkResetAll();
    setSelectedGroups(new Set([]));
  }, [onBulkResetAll]);

  const onItemCheck = (item: Run, newChecked: boolean, hash: string) => {
    if (newChecked) {
      return selectItem(item.id);
    }

    unselectItem(item.id);

    if (selectedGroups.has(hash)) {
      setSelectedGroups((state) => {
        state.delete(hash);
        return new Set([...state]);
      });
    }
  };

  const handleBulkActionsFinish = async () => {
    await refetch();
  };

  const handleGroupSelect = (groups: Run[], hash: string) => {
    setSelectedGroups((state) => {
      if (selectedGroups.has(hash)) {
        groups.forEach((element) => {
          unselectItem(element.id);
        });
        state.delete(hash);
        return new Set([...state]);
      } else {
        groups.forEach((element) => {
          selectItem(element.id);
        });
        return new Set([...state, hash]);
      }
    });
  };

  const runsMap = useMemo(() => {
    const runsEntities = data?.stack
      ? data.stack?.runs.map<[string, Run]>((run) => [run.id, run])
      : [];

    return new Map(runsEntities);
  }, [data?.stack]);

  // mark selected new loaded stacks if allSelected is true
  useEffect(() => {
    if (allSelected && stack?.runs) {
      syncAllSelectedItems(new Set(stack.runs.map((run) => run.id)));
    }
  }, [allSelected, stack?.runs, syncAllSelectedItems]);

  // sync the selected items with the visible items on the list (filter out the ones that are not visible)
  useEffect(() => {
    if (selectedSet.size) {
      syncSelectedItemsWithVisibleOnes(runsMap);
    }
  }, [runsMap, selectedSet.size, syncSelectedItemsWithVisibleOnes]);

  const ErrorContent = useErrorHandle(error);

  if (ErrorContent) {
    return ErrorContent;
  }

  // Do not show 'loading' when polling in the background.
  if (loading && !data?.stack) {
    return <PageLoading />;
  }

  if (!data?.stack || !stack) {
    return <NotFoundPage />;
  }

  const mostRecentCommit = runsListPerCommit[0]?.[0];
  const lastIgnoredRun = getLastIgnoredRunFromStack(stack);

  return (
    <InfiniteScroll onScrollEnd={handleScrollEnd} hasMore={hasMore}>
      <StackHeader />

      <PageInfo title="Tracked runs">
        <SyncButton />
        <StackLockOrUnlockButton analyticsPage={AnalyticsPageStack.StacksRunList} />
        <TriggerButton
          stack={stack}
          analyticsPage={AnalyticsPageStack.StacksRunList}
          text="Trigger"
          allowCustomConfig={triggerRunWithCustomConfigFrontend}
        />
      </PageInfo>

      <StackDetachedIntegrationCallout />
      {vcsEventsShowIgnoredRuns &&
        shouldShowIgnoredRunCallout(stack, lastIgnoredRun, mostRecentCommit) && (
          <StackRunsIgnoredRunCallout stack={stack} ignoredRun={lastIgnoredRun} />
        )}

      <PageWrapper>
        {(stack.isDisabled || stack.vcsDetached) && <DisabledStackNotice />}

        {stack.lockedBy !== null && (
          <LockNotice
            lockedAt={stack.lockedAt}
            lockedBy={stack.lockedBy}
            viewer={viewer.id}
            note={stack.lockNote}
          />
        )}

        {runsListPerCommit.length > 0 && (
          <Box ref={listRef} gap="large" direction="column">
            {runsListPerCommit.map(([hash, group], i) => {
              const someRunsShouldBeApproved = group.some(isRunReviewable);

              return (
                <RunsGroup
                  someRunsShouldBeApproved={someRunsShouldBeApproved}
                  key={`${hash}_${i}`}
                  run={group[0]}
                  provider={stack.provider}
                  count={group.length}
                  isOpen
                >
                  <Box direction="column" className={styles.tableScrollableWrapper}>
                    <Box direction="column" className={styles.tableInnerWrapper}>
                      <FiltersSortHeaderWrapper
                        columnOrder={
                          hasAtLeastWriteAccess ? COLUMN_ORDER_APPROVAL : COLUMN_ORDER_USER
                        }
                        columnGap={COLUMN_GAP}
                      >
                        {hasAtLeastWriteAccess && (
                          <FiltersSortHeaderStaticColumn>
                            <Toggle
                              variant="checkbox"
                              id={`${hash}_${i}`}
                              onChange={() => handleGroupSelect(group, hash)}
                              checked={selectedGroups.has(hash)}
                              ariaLabel={
                                selectedGroups.has(hash) ? "Unselect this run" : "Select this run"
                              }
                            />
                          </FiltersSortHeaderStaticColumn>
                        )}
                        <FiltersSortHeaderStaticColumn>
                          <Typography tag="span" variant="p-t6">
                            Status
                          </Typography>
                        </FiltersSortHeaderStaticColumn>
                        <FiltersSortHeaderStaticColumn>
                          <Typography tag="span" variant="p-t6">
                            Data
                          </Typography>
                        </FiltersSortHeaderStaticColumn>
                        <FiltersSortHeaderStaticColumn>
                          <Typography tag="span" variant="p-t6">
                            Version
                          </Typography>
                        </FiltersSortHeaderStaticColumn>
                        <FiltersSortHeaderStaticColumn>
                          <Typography tag="span" variant="p-t6">
                            Delta
                          </Typography>
                        </FiltersSortHeaderStaticColumn>
                      </FiltersSortHeaderWrapper>
                      {group.map((run) => (
                        <RunElement
                          key={run.id}
                          stackId={stack.id}
                          vendorConfig={stack.vendorConfig || undefined}
                          run={run}
                          checked={selectedSet.has(run.id)}
                          onCheck={onItemCheck}
                          hash={hash}
                          isAnsible={
                            configurationManagementViewEnabled && isAnsibleStackVendor(stack)
                          }
                        />
                      ))}
                    </Box>
                  </Box>
                </RunsGroup>
              );
            })}
          </Box>
        )}

        {stack.runs && stack.runs.length === 0 && stack.provider !== VcsProvider.Git && (
          <EmptyState
            icon={RunsColored}
            title="No runs yet"
            caption={
              <>
                {" "}
                As you push new commits to the <em>{stack.branch}</em> branch of the{" "}
                <em>
                  {stack.namespace}/{stack.repository}
                </em>{" "}
                Git repository, new Runs will appear on this list.{" "}
                {stack.canWrite && (
                  <>
                    You can also start one now manually by clicking on the <em>Trigger</em> button
                    above.
                  </>
                )}
              </>
            }
          />
        )}
        {stack.runs && stack.runs.length === 0 && stack.provider === VcsProvider.Git && (
          <EmptyState
            icon={RunsColored}
            title="No runs yet"
            caption={
              <>
                You can start a run now manually by clicking on the <em>Trigger</em> button above.
              </>
            }
          />
        )}

        {hasAtLeastWriteAccess && (
          <StackRunsBulkActions
            listRef={listRef}
            selectedSet={selectedSet}
            runsMap={runsMap}
            stack={stack}
            onBulkResetAll={handleBulkResetAll}
            onBulkContinueWith={onBulkContinueWith}
            onItemDismiss={(id) => {
              const run = runsMap.get(id);
              if (run) {
                onItemCheck(run, false, run.commit.hash);
              } else {
                unselectItem(id);
              }
            }}
            onFinish={handleBulkActionsFinish}
          />
        )}
      </PageWrapper>
    </InfiniteScroll>
  );
};

export default Runs;
