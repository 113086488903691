import FlashContext from "components/FlashMessages/FlashContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import useEnableModule from "shared/Module/useEnableModule";

type EnableButtonProps = {
  moduleId: Module["id"];
  refetchQueries?: string[];
};

const EnableButton = ({ moduleId, refetchQueries }: EnableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [enableModule, { loading }] = useEnableModule({
    refetchQueries,
  });

  const handleClick = () => {
    enableModule({ id: moduleId }, (data) => {
      if (data?.moduleEnable) {
        reportSuccess({ message: "Module successfully enabled" });
      }
    });
  };

  return (
    <DropdownSectionItem onClick={handleClick} loading={loading} disabled={loading}>
      Enable
    </DropdownSectionItem>
  );
};

export default EnableButton;
