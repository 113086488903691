import Box from "ds/components/Box";
import useTypedContext from "hooks/useTypedContext";

import styles from "./styles.module.css";
import DashboardOverviewColumnOnboarding from "./Onboarding";
import DashboardOverviewColumnTestPilot from "./TestPilot";
import DashboardOverviewColumnLeaveFeedback from "./LeaveFeedback";
import DashboardOverviewColumnUserActivity from "./UserActivity";
// import DashboardOverviewColumnAnnouncement from "./Announcement";
import { DashboardContext } from "../context";
import { OverviewStaticWidget } from "../types";

const DashboardOverviewColumn = () => {
  const { config } = useTypedContext(DashboardContext);
  const tab = config["overview"];
  const widgets = tab.overviewColumn
    ? tab.overviewColumn.reduce(
        (acc, next) => (!next.hidden ? [...acc, next.value] : acc),
        [] as OverviewStaticWidget[]
      )
    : [];

  return (
    <Box
      className={styles.overviewColumn}
      gap="x-large"
      direction="column"
      padding="x-large x-large x-large 0"
    >
      {widgets.includes(OverviewStaticWidget.LaunchPad) && <DashboardOverviewColumnOnboarding />}
      {widgets.includes(OverviewStaticWidget.UserActivity) && (
        <DashboardOverviewColumnUserActivity />
      )}
      <DashboardOverviewColumnTestPilot />
      <DashboardOverviewColumnLeaveFeedback />
      {/* Uncomment when annoucement available */}
      {/* <DashboardOverviewColumnAnnouncement
        id="AWS-24-April"
        expirationDate={new Date(2020, 1, 3)}
      /> */}
    </Box>
  );
};

export default DashboardOverviewColumn;
