import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import {
  GithubEnterpriseIntegration,
  MutationGithubEnterpriseIntegrationCreateArgs,
} from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { CREATE_GITHUB_ENTERPRISE_INTEGRATION } from "./gql";

const useCreateGitHubEnterpriseIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const [githubEnterpriseIntegrationCreate, { loading }] = useMutation<{
    githubEnterpriseIntegrationCreate: GithubEnterpriseIntegration;
  }>(CREATE_GITHUB_ENTERPRISE_INTEGRATION, {
    onError,
  });

  const createGitHubEnterpriseIntegration = useCallback(
    (input: MutationGithubEnterpriseIntegrationCreateArgs, successCallback?: () => void) => {
      githubEnterpriseIntegrationCreate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.githubEnterpriseIntegrationCreate) {
            reportSuccess({
              message: `Integration ${data.githubEnterpriseIntegrationCreate.name} was successfully created`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("GitHub Manual - Setup Finished", {
              labels: input.customInput?.labels?.length,
              space: input.customInput?.spaceID,
            });
          }
        })
        .catch(onError);
    },
    [githubEnterpriseIntegrationCreate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    createGitHubEnterpriseIntegration,
    loading,
  };
};

export default useCreateGitHubEnterpriseIntegration;
