import { useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import { FocusScope, usePreventScroll } from "react-aria";
import capitalize from "lodash-es/capitalize";
import lowerCase from "lodash-es/lowerCase";

import IconAction from "ds/components/IconAction";
import { RunState } from "types/generated";
import { useToggle } from "hooks/useToggle";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import DocumentationIconButton from "components/DocumentationIconButton";
import useEscapeKeypress from "hooks/useEscapeKeyPress";
import Box from "ds/components/Box";
import { FullscreenNew } from "components/icons";
import Typography from "ds/components/Typography";
import { CrossNew } from "components/icons";
import { getDocsUrl } from "utils/getDocsUrl";
import ThemedLogs from "components/ThemedLogs";
import ThemedLogsAnsiContent from "components/ThemedLogs/AnsiContent";
import ButtonIcon from "ds/components/ButtonIcon";

import { processLogs } from "./helpers";
import styles from "./styles.module.css";
import DownloadLogsButton from "../DownloadLogsButton";
import { useAutoScroll } from "./useAutoScroll";
import { useGetRunLogs } from "./useGetRunLogs";

type RunLogsProps = {
  state: RunState;
  stateVersion?: number | null;
  until: number | null;
  runId: string;
  stackId: string;
  isModuleRun: boolean;
};

const RunLogs = ({ state, stateVersion, until, runId, stackId, isModuleRun }: RunLogsProps) => {
  const [fullScreen, toggleFullScreen] = useToggle(false);
  const container = useRef<HTMLDivElement>(null);

  const { runLogs, error, loading } = useGetRunLogs({
    until,
    state,
    stateVersion,
    isModuleRun,
    runId,
    stackId,
  });

  useEscapeKeypress(fullScreen, toggleFullScreen);

  usePreventScroll({ isDisabled: !fullScreen });

  const { handleScroll, autoScrollToBottom } = useAutoScroll(container);

  const content = useMemo(
    () =>
      processLogs({
        logsMessages: runLogs?.messages,
        exists: !!runLogs?.exists,
        expired: !!runLogs?.expired,
        loading: loading && !runLogs,
        error: !!error,
      }),
    [runLogs, loading, error]
  );

  useEffect(() => {
    if (content) {
      autoScrollToBottom();
    }
  }, [content, autoScrollToBottom]);

  const expired = runLogs?.expired !== false;

  return (
    <FocusScope contain={fullScreen}>
      {fullScreen && (
        <Box justify="between" align="center" className={styles.header}>
          <Typography variant="p-t3" tag="h3" color="primary">
            {capitalize(lowerCase(state))}
          </Typography>

          <Box gap="medium">
            <IconAction icon={CrossNew} onClick={() => toggleFullScreen()} tooltip="Close" />
          </Box>
        </Box>
      )}
      <ThemedLogs
        ref={container}
        onScroll={handleScroll}
        className={cx({
          [styles.fullScreen]: fullScreen,
        })}
      >
        {!fullScreen && (
          <Box gap="medium" className={styles.actions}>
            <ButtonIcon
              disabled={expired}
              variant="secondary"
              icon={FullscreenNew}
              onClick={() => toggleFullScreen()}
              size="small"
              aria-expanded={fullScreen}
            >
              Full screen mode
            </ButtonIcon>
            <DownloadLogsButton
              expired={expired}
              state={state}
              stateVersion={stateVersion}
              runId={runId}
              stackId={stackId}
              isModuleRun={isModuleRun}
            />
          </Box>
        )}
        <ThemedLogsAnsiContent value={content} />
      </ThemedLogs>

      {fullScreen && (
        <FullScreenModalFooter justify="between" className={styles.footer}>
          <DocumentationIconButton
            href={getDocsUrl("/concepts/policy#policy-workbench")}
            tooltipText="Go to policies workbench documentation"
          />
          <DownloadLogsButton
            expired={expired}
            state={state}
            stateVersion={stateVersion}
            runId={runId}
            stackId={stackId}
            isModuleRun={isModuleRun}
          />
        </FullScreenModalFooter>
      )}
    </FocusScope>
  );
};

export default RunLogs;
