import BadgeNext from "ds/components/BadgeNext";
import Box from "ds/components/Box";
import { MenuStacks, MenuModules, MenuSpaces, Trash } from "components/icons";
import Icon from "ds/components/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import IconAction from "ds/components/IconAction";

import styles from "./styles.module.css";
import { AttachedProject } from "./types";

type NewContextAttachedProjectProps = {
  item: AttachedProject;
  onDetach?: (id: string) => void;
};

const NewContextAttachedProject = ({ item, onDetach }: NewContextAttachedProjectProps) => {
  const { name, id, isModule, spaceDetails } = item;
  const canDetach = !!onDetach;

  const handleDetach = () => onDetach?.(id);

  const gridTemplate = canDetach
    ? "1.6rem minmax(0,3fr) minmax(10rem,0.75fr) 2rem"
    : "1.6rem minmax(0,3fr) minmax(10rem,0.75fr)";

  return (
    <Box
      align="center"
      gap="large"
      grid
      gridTemplate={gridTemplate}
      className={styles.item}
      padding={canDetach ? "large" : "medium large"}
      fullWidth
    >
      <Icon src={isModule ? MenuModules : MenuStacks} />
      <TextEllipsis tooltip={name} tooltipWidthMode="maxWidthSm">
        {(props) => (
          <Typography {...props} variant="p-body2" tag="span">
            {name}
          </Typography>
        )}
      </TextEllipsis>

      <div className={styles.badgeWrapper}>
        <BadgeNext
          variant="gray"
          text={spaceDetails.name}
          startIcon={MenuSpaces}
          textEllipsis
          type="weak"
        />
      </div>
      {canDetach && (
        <IconAction tooltip="Detach" icon={Trash} color="danger" onClick={handleDetach} />
      )}
    </Box>
  );
};

export default NewContextAttachedProject;
