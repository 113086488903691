import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import Box from "ds/components/Box";
import DropdownSection from "ds/components/Dropdown/Section";
import Tab from "ds/components/Tab";
import useHiddenItemsList, { UseHiddenItemsListReturnType } from "hooks/useHiddenItemsList";
import Badge from "ds/components/Badge";

import { getResizableItems, STACK_MENU_ITEM_DATA_ATTRIBUTE } from "./helpers";
import ViewHeaderNavigationResizeItem from "./ResizeItem";
import { NavigationItem, NavigationItemsWidth } from "./types";

type ViewHeaderNavigationProps = {
  items: NavigationItem[];
  /** FYI:
   * the order is
   * from the first item - should be hidden first
   * to the last one - should be hidden last
   *
   * Be aware: when the order is changed, please reload the page to reset the previous "shared state" order
   */
  hideOrder: string[];
};

const emptyNavigationItemsWidths: Array<NavigationItemsWidth> = [];

/**
 * @description
 * get tabs & calculate the tabs width sum
 * -> use the ResizeObserver to calculate how many tabs should be hidden and which items
 * -> hide tabs
 */
const ViewHeaderNavigationResize = ({ items, hideOrder }: ViewHeaderNavigationProps) => {
  // FYI: "sharedNavigationState" is used to share the previously calculated state between the Stack tabs to avoid menu "blinking"
  const {
    state: sharedNavigationState,
  }: { state: UseHiddenItemsListReturnType<NavigationItem>["sharedState"] | undefined } =
    useLocation();

  const { wrapperRef, hiddenItems, visibleItems, sharedState } = useHiddenItemsList<NavigationItem>(
    {
      defaultItemsWidths: sharedNavigationState?.itemsWidths || emptyNavigationItemsWidths,
      defaultHideItemsQty: sharedNavigationState?.hideItemsQty || 0,
      getResizableItems,
      hideOrder,
      items,
    }
  );

  useEffect(() => {
    // reset router shared state when browser reloads
    const replaceHistory = () => {
      window.history.replaceState({}, document.title);
    };

    window.addEventListener("beforeunload", replaceHistory);
    return () => {
      window.removeEventListener("beforeunload", replaceHistory);
    };
  }, []);

  return (
    <Box ref={wrapperRef} align="center" justify="start" margin="medium 0 0" gap="medium" fullWidth>
      {visibleItems.map((item) => (
        <Tab
          exact={item.exact}
          key={item.key}
          to={sharedState ? { pathname: item.link, state: sharedState } : item.link}
          {...{ [STACK_MENU_ITEM_DATA_ATTRIBUTE]: item.key }}
          label={
            item.beta ? (
              <Box gap="medium" align="center">
                {item.title}
                <Badge size="small" state="success">
                  BETA
                </Badge>
              </Box>
            ) : (
              item.title
            )
          }
        />
      ))}

      {hiddenItems.length > 0 && (
        <DropdownEllipsis dotsSize="small">
          {() => (
            <DropdownSection>
              {hiddenItems.map((item) => (
                <ViewHeaderNavigationResizeItem
                  key={item.key}
                  item={item}
                  sharedState={sharedState}
                />
              ))}
            </DropdownSection>
          )}
        </DropdownEllipsis>
      )}
    </Box>
  );
};

export default memo(ViewHeaderNavigationResize);
