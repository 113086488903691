import { gql } from "@apollo/client";

export const SEARCH_AUDIT_TRAIL_ENTRIES = gql`
  fragment AuditTrailResourceDetails on AuditTrailResource {
    resourceType
    resourceId
    metadata {
      __typename
      ... on AuditTrailAccountMetadata {
        subdomain
      }
      ... on AuditTrailAPIKeyMetadata {
        id
      }
      ... on AuditTrailAWSIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailAzureDevOpsRepoIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailAzureIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailBitbucketCloudIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailBitbucketDatacenterIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailBlueprintMetadata {
        id
        name
      }
      ... on AuditTrailContextMetadata {
        id
        name
      }
      ... on AuditTrailDriftDetectionIntegrationMetadata {
        id
        stackId
      }
      ... on AuditTrailGithubEnterpriseIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailGitlabIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailGPGKeyMetadata {
        id
        name
      }
      ... on AuditTrailLoginMetadata {
        username
      }
      ... on AuditTrailModuleMetadata {
        id
        name
      }
      ... on AuditTrailNamedWebhooksIntegrationMetadata {
        id
        name
      }
      ... on AuditTrailPolicyMetadata {
        id
        name
      }
      ... on AuditTrailRunMetadata {
        id
        stackId
      }
      ... on AuditTrailScheduledDeleteMetadata {
        id
        stackId
      }
      ... on AuditTrailScheduledTaskMetadata {
        id
        stackId
      }
      ... on AuditTrailSecurityKeyMetadata {
        id
        name
      }
      ... on AuditTrailSessionMetadata {
        id
      }
      ... on AuditTrailSpaceMetadata {
        id
        name
      }
      ... on AuditTrailStackMetadata {
        id
        name
      }
      ... on AuditTrailTaskMetadata {
        id
        stackId
      }
      ... on AuditTrailTerraformProviderMetadata {
        type
      }
      ... on AuditTrailTerraformProviderVersionMetadata {
        id
        type
        versionNumber
      }
      ... on AuditTrailUserGroupMetadata {
        id
        name
      }
      ... on AuditTrailUserMetadata {
        username
      }
      ... on AuditTrailVersionMetadata {
        id
        moduleId
      }
      ... on AuditTrailWebhookMetadata {
        id
        webhookStackId: stackId
        webhookModuleId: moduleId
      }
      ... on AuditTrailWorkerMetadata {
        id
        workerPoolId
      }
      ... on AuditTrailWorkerPoolMetadata {
        id
        name
      }
    }
  }
  query SearchAuditTrailEntries($input: SearchInput!) {
    searchAuditTrailEntries(input: $input) {
      edges {
        cursor
        node {
          affectedResource {
            ...AuditTrailResourceDetails
          }
          relatedResource {
            ...AuditTrailResourceDetails
          }
          body
          createdAt
          eventType
          id
          updatedAt
          action
          actor {
            username
            run {
              id
              stackId
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const SEARCH_AUDIT_TRAIL_ENTRIES_SUGGESTIONS = gql`
  query SearchAuditTrailEntriesSuggestions($input: SearchSuggestionsInput!) {
    searchAuditTrailEntriesSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;
