import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { GitlabIntegration, MutationGitlabIntegrationUpdateArgs } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import { UPDATE_GITLAB_INTEGRATION } from "./gql";

const useUpdateGitLabIntegration = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const [gitlabIntegrationUpdate, { loading }] = useMutation<{
    gitlabIntegrationUpdate: GitlabIntegration;
  }>(UPDATE_GITLAB_INTEGRATION, {
    onError,
    refetchQueries: ["SearchVCSIntegrations"],
  });

  const updateGitLabIntegration = useCallback(
    (input: MutationGitlabIntegrationUpdateArgs, successCallback?: () => void) => {
      gitlabIntegrationUpdate({
        variables: input,
      })
        .then(({ data }) => {
          if (data?.gitlabIntegrationUpdate) {
            reportSuccess({
              message: `Integration ${data.gitlabIntegrationUpdate.name} was successfully updated`,
            });
            successCallback?.();

            trackSegmentAnalyticsEvent("Integration Edit - Finish", {
              integration: "GitLab",
            });
          }
        })
        .catch(onError);
    },
    [gitlabIntegrationUpdate, onError, reportSuccess, trackSegmentAnalyticsEvent]
  );

  return {
    updateGitLabIntegration,
    loading,
  };
};

export default useUpdateGitLabIntegration;
