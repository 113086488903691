import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { ContextStackAttachment } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";

import { ATTACH_CONTEXT_TO_ENTITY } from "./gql";

const useAttachContext = (refetchQueries: string[], successMessage?: string) => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [attachContext, { loading }] = useMutation<{
    contextAttach: ContextStackAttachment;
  }>(ATTACH_CONTEXT_TO_ENTITY, {
    onError,
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleAttachContext = useCallback(
    (contextId: string, entityId: string, priority: number, successCallback?: () => void) => {
      attachContext({
        variables: {
          contextId,
          entityId,
          priority,
        },
      })
        .then(({ data }) => {
          if (data?.contextAttach) {
            reportSuccess({
              message: successMessage || "Context was successfully attached",
            });
            successCallback?.();
          }
        })
        .catch(onError);
    },
    [attachContext, onError, reportSuccess]
  );

  return {
    attachContext: handleAttachContext,
    loading,
  };
};

export default useAttachContext;
