import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import cx from "classnames";

import Box from "ds/components/Box";
import FlashContext from "components/FlashMessages/FlashContext";
import CodeEditor from "components/CodeEditor";
import IconAction from "ds/components/IconAction";
import Link from "ds/components/Link";
import Button from "ds/components/Button";
import ButtonIcon from "ds/components/ButtonIcon";
import Typography from "ds/components/Typography";
import { PolicyEvaluationSample, RunPolicyReceipt } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import { FullscreenNew, CrossNew } from "components/icons";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import DocumentationIconButton from "components/DocumentationIconButton";
import { getDocsUrl } from "utils/getDocsUrl";
import { beautifyCode } from "utils/strings";
import { EMPTY_POLICY_SAMPLE_RESULTS_MSG } from "shared/Policy/constants";
import { SIMULATE_POLICY } from "shared/Policy/gql";

import styles from "./styles.module.css";

type RunSimulationPanelProps = {
  sample: PolicyEvaluationSample;
  policyReceipt: RunPolicyReceipt;
};

const RunSimulationPanel = (props: RunSimulationPanelProps) => {
  const { sample, policyReceipt } = props;

  const { onError } = useTypedContext(FlashContext);

  const [sampleBody, setSampleBody] = useState(sample.body);
  const [sampleInput, setSampleInput] = useState(beautifyCode(sample.input));
  const [simulationResult, setSimulationResult] = useState(EMPTY_POLICY_SAMPLE_RESULTS_MSG);
  const [isFullScreen, setFullScreen] = useState(false);

  const [simulatePolicy, { loading: simulateLoading }] = useMutation<{
    policySimulate: string;
  }>(SIMULATE_POLICY, {
    variables: {
      body: sampleBody,
      input: sampleInput,
      type: policyReceipt.policyType,
    },
    onError,
    onCompleted: (data) => {
      setSimulationResult(beautifyCode(data.policySimulate));
    },
  });

  const [isPolicyBodyChanged, isSampleInputChanged] = useMemo(
    () => [sampleBody !== sample.body, sampleInput !== beautifyCode(sample.input || "")],
    [sample.body, sample.input, sampleBody, sampleInput]
  );

  const handleResetBody = useCallback(() => {
    if (isPolicyBodyChanged) {
      setSampleBody(sample.body);
    }
  }, [sample, isPolicyBodyChanged]);

  const handleResetInput = useCallback(() => {
    if (isSampleInputChanged) {
      setSampleInput(beautifyCode(sample.input));
    }
  }, [sample.input, isSampleInputChanged]);

  const handleSimulation = () => {
    void simulatePolicy();
  };

  const handleBodyEditorChange = (value?: string) => {
    setSampleBody(value || "");
  };

  const handleInputEditorChange = (value?: string) => {
    setSampleInput(value || "");
  };

  return (
    <Box
      direction="column"
      className={cx(styles.wrapper, isFullScreen && styles.fullScreen)}
      fullWidth
    >
      <Box justify="between" align="center" className={styles.header}>
        <Typography variant={isFullScreen ? "p-t3" : "p-t7"} tag="h3" color="primary">
          Simulation panel
        </Typography>

        <Box gap="medium">
          {isFullScreen ? (
            <IconAction
              icon={CrossNew}
              onClick={() => setFullScreen(!isFullScreen)}
              tooltip="Close"
            />
          ) : (
            <ButtonIcon
              variant="secondary"
              size="small"
              icon={FullscreenNew}
              onClick={() => setFullScreen(!isFullScreen)}
            >
              Expand
            </ButtonIcon>
          )}

          {!isFullScreen && (
            <Button
              variant="contrast"
              size={isFullScreen ? "medium" : "small"}
              onClick={handleSimulation}
              loading={simulateLoading}
            >
              Simulate
            </Button>
          )}
        </Box>
      </Box>
      <Box grid gridTemplate="50% 50%" fullWidth grow="1">
        <Box direction="column" className={styles.bodyEditorWrapper} shrink="1">
          <Box padding="large" gap="medium" className={styles.editorHeader}>
            <Typography variant="p-t7" tag="h4">
              Policy body
            </Typography>
            {isPolicyBodyChanged && (
              <Link onClick={handleResetBody} size="small">
                Reset
              </Link>
            )}
          </Box>
          <Box>
            <CodeEditor
              className={styles.bodyEditor}
              body={sampleBody}
              onChange={handleBodyEditorChange}
              language="rego"
            />
          </Box>
        </Box>
        <Box direction="column" shrink="1">
          <Box direction="column" className={styles.inputWrapper}>
            <Box padding="large" gap="medium" className={styles.editorHeader}>
              <Typography variant="p-t7" tag="h4">
                Input
              </Typography>
              {isSampleInputChanged && (
                <Link onClick={handleResetInput} size="small">
                  Reset
                </Link>
              )}
            </Box>
            <Box grow="1">
              <CodeEditor
                className={styles.editor}
                body={sampleInput}
                onChange={handleInputEditorChange}
                language="json"
              />
            </Box>
          </Box>
          <Box direction="column" className={styles.outputWrapper}>
            <Box padding="large" gap="medium" className={styles.editorHeader}>
              <Typography variant="p-t7" tag="h4">
                Output
              </Typography>
            </Box>
            <CodeEditor
              className={styles.simulationResult}
              body={simulationResult}
              language="json"
            />
          </Box>
        </Box>
      </Box>

      {isFullScreen && (
        <FullScreenModalFooter justify="between">
          <DocumentationIconButton
            href={getDocsUrl("/concepts/policy#policy-workbench")}
            tooltipText="Go to policies workbench documentation"
          />
          <Button
            variant="contrast"
            size={isFullScreen ? "medium" : "small"}
            onClick={handleSimulation}
            loading={simulateLoading}
          >
            Simulate
          </Button>
        </FullScreenModalFooter>
      )}
    </Box>
  );
};

export default RunSimulationPanel;
