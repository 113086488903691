import { useState } from "react";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import { ApiKey, ApiKeyType } from "types/generated";
import ListEntitiesItem from "components/ListEntitiesItem";
import TabPanel from "ds/components/TabPanel";
import Typography from "ds/components/Typography";
import SpaceAccessBadge from "components/SpaceAccessBadge";
import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import useTypedContext from "hooks/useTypedContext";
import TabWithCounter from "ds/components/Tab/WithCounter";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import Button from "ds/components/Button";
import { UserManagementActivationStatus } from "views/account/settings/types";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { SpacesContext } from "views/account/SpacesProvider";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DescriptionDetails from "components/DescriptionDetails";

import { formatApiKeyType } from "../utils";
import styles from "./styles.module.css";

type ApiKeysDetailsDrawerContentProps = {
  apiKey: ApiKey;
  activationStatus: UserManagementActivationStatus;
  handleCloseDrawer: () => void;
  openFullDescription: (description: string) => void;
};

enum Tabs {
  Spaces = "spaces",
  Groups = "groups",
}

const ApiKeysDetailsDrawerContent = ({
  apiKey,
  activationStatus,
  handleCloseDrawer,
  openFullDescription,
}: ApiKeysDetailsDrawerContentProps) => {
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.Spaces);
  const { spaces } = useTypedContext(SpacesContext);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Access details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        {activationStatus === UserManagementActivationStatus.INACTIVE && (
          <Banner variant="warning" title="User management is inactive">
            Access rules will not take effect until you activate user management.
            <FeedbackActions>
              <Button
                to="/settings/management-strategy/"
                analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
                analyticsTitle="Callout Strategy Click"
                variant="contrast"
                size="small"
              >
                Choose strategy
              </Button>
            </FeedbackActions>
          </Banner>
        )}
        <FormFieldViewText label="Name" value={apiKey.name} />
        <FormFieldViewText label="Key ID" value={apiKey.id} withCopy />
        <FormField label="Type">
          <Typography tag="span" variant="p-body2">
            {formatApiKeyType(apiKey.type)}
          </Typography>
        </FormField>
        {apiKey.type === ApiKeyType.Oidc && (
          <>
            <FormFieldViewText label="Issuer" value={apiKey.oidcSettings?.issuer} />
            <FormFieldViewText label="Client ID (audience)" value={apiKey.oidcSettings?.clientId} />
            <FormFieldViewText
              label="Subject expression"
              value={apiKey.oidcSettings?.subjectExpression}
            />
          </>
        )}
        {apiKey.creator && <FormFieldViewText label="Creator" value={apiKey.creator} />}
        {apiKey.lastUsedAt && (
          <FormFieldTimestamp label="Last used" timestamp={apiKey.lastUsedAt} />
        )}
        <DescriptionDetails
          description={apiKey.description}
          onOpenFullDescription={() => openFullDescription(apiKey.description)}
        />

        <Box direction="row" gap="medium" align="center" className={styles.tabs}>
          <TabWithCounter<Tabs>
            onClick={setCurrentTab}
            isActive={currentTab === Tabs.Spaces}
            id={Tabs.Spaces}
            label="Spaces"
            count={apiKey.spaceCount}
          />
          <TabWithCounter<Tabs>
            onClick={setCurrentTab}
            isActive={currentTab === Tabs.Groups}
            id={Tabs.Groups}
            label="Groups"
            count={apiKey.teamCount}
          />
        </Box>

        <TabPanel isActive={currentTab === Tabs.Spaces} id={Tabs.Spaces}>
          <div className={styles.listWrapper}>
            {apiKey.accessRules.map(({ space, spaceAccessLevel }, index) => {
              return (
                <ListEntitiesItem key={index} className={styles.listItem}>
                  <Box align="center" justify="between">
                    <MetaInfoListItem
                      icon={MenuSpaces}
                      linkText={spaces.find(({ id }) => id === space)?.name}
                      href={`/spaces/${space}`}
                      type="space"
                      className={styles.listItemMeta}
                    />
                    <SpaceAccessBadge accessLevel={spaceAccessLevel} />
                  </Box>
                </ListEntitiesItem>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel isActive={currentTab === Tabs.Groups} id={Tabs.Groups}>
          <div className={styles.listWrapper}>
            {apiKey.teams.map((item, index) => {
              return (
                <ListEntitiesItem key={`${item}-${index}`} className={styles.listItem}>
                  <Typography tag="span" variant="p-body2">
                    {item}
                  </Typography>
                </ListEntitiesItem>
              );
            })}
          </div>
        </TabPanel>
      </DrawerBody>
    </>
  );
};

export default ApiKeysDetailsDrawerContent;
