import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import { VcsProvider, Version } from "types/generated";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FormFieldViewText from "components/FormFields/ViewText";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import DescriptionDetails from "components/DescriptionDetails";
import UserLinkNew from "views/account/Module/components/UserLinkNew";
import FormField from "ds/components/Form/Field";
import MetaInfoCommit from "components/MetaInfoList/Commit";
import Box from "ds/components/Box";

type ModuleVersionDetailsDrawerProps = {
  moduleProvider: VcsProvider;
  version: Version;
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  onOpenFullDescription: () => void;
};

const ModuleVersionDetailsDrawer = ({
  moduleProvider,
  version,
  isDrawerVisible,
  setDrawerVisibility,
  onOpenFullDescription,
}: ModuleVersionDetailsDrawerProps) => {
  const handleClose = () => setDrawerVisibility(false);
  return (
    <Drawer visible={isDrawerVisible} onOutsideClick={handleClose}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Version details" />
        <DrawerCloseIcon handleCloseDrawer={handleClose} />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name/ID" value={version.id} withCopy />

        <FormFieldTimestamp label="Created at" timestamp={version.createdAt} />

        {version.triggeredBy && (
          <FormFieldViewText label="Triggered by" value={version.triggeredBy} />
        )}

        <DescriptionDetails
          description={version.notes}
          onOpenFullDescription={onOpenFullDescription}
        />

        <FormField label="Committed by">
          <Box align="start">
            <UserLinkNew
              login={version.commit.authorLogin}
              name={version.commit.authorName}
              provider={VcsProvider.Github}
            />
          </Box>
        </FormField>

        <FormField label="Commit">
          <MetaInfoCommit commit={version.commit} provider={moduleProvider} />
        </FormField>

        <FormFieldTimestamp label="Committed at" timestamp={version.commit.timestamp} />

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </Drawer>
  );
};

export default ModuleVersionDetailsDrawer;
