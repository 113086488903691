import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { AnsibleHost, SearchAnsibleHostsOutput } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";

import { initialSortDirection, initialSortOption } from "./constants";
import { SEARCH_ANSIBLE } from "./gql";

const useSearchAnsibleHosts = () => {
  const { onError } = useTypedContext(FlashContext);
  const cachedAnsibleEdges = useRef<AnsibleHost[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, initialSortOption, initialSortDirection),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const input = {
    first: 500,
    after: null,
    fullTextSearch: searchInput,
    predicates,
    ...(sortOptionFields && { orderBy: sortOptionFields }),
  };

  const { data, loading, networkStatus, error } = useQuery<{
    searchAnsibleHosts: SearchAnsibleHostsOutput;
  }>(SEARCH_ANSIBLE, {
    variables: { input },
    onError,
  });

  const ansibleHosts = useMemo(() => {
    const sourceEdges = data?.searchAnsibleHosts?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedAnsibleEdges.current : sourceEdges;

    if (!loading) {
      cachedAnsibleEdges.current = sourceEdges;
    }

    return edges;
  }, [data?.searchAnsibleHosts?.edges, loading]);

  return {
    entities: ansibleHosts,
    error,
    predicates,
    loading,
    isPageLoading: loading && !ansibleHosts.length && networkStatus === NetworkStatus.loading,
    isPageEmpty: !!(data && !ansibleHosts.length && !searchInput && predicates.length === 0),
    hasNoFilteringResults:
      !!data && !ansibleHosts.length && (!!searchInput || predicates.length > 0),
  };
};

export default useSearchAnsibleHosts;
