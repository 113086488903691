import cx from "classnames";
import {
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Label,
  Popover,
  Calendar,
  TimeField,
  TimeValue,
} from "react-aria-components";
import { ZonedDateTime } from "@internationalized/date";
import { useCallback } from "react";

import { Calendar as CalendarIcon } from "components/icons";

import IconActionButton from "../IconAction/Button";
import styles from "./styles.module.css";
import Box from "../Box";
import { DatepickerProps } from "./types";
import { formatAbsoluteToZonedDateTime, getDateParts } from "./helpers";
import DatepickerCalendarContent from "./CalendarContent";

const adEraStart = new Date(Date.parse("0001-01-01"));

const Datepicker = ({
  date = new Date(),
  onChange,
  minDate = adEraStart,
  maxDate,
  withTime,
  hasError,
  disabled,
  placement = "bottom end",
  fullWidth,
  renderTimezone,
}: DatepickerProps) => {
  const minZonedDate = formatAbsoluteToZonedDateTime(minDate, renderTimezone);
  const maxZonedDate = maxDate && formatAbsoluteToZonedDateTime(maxDate, renderTimezone);

  const { zonedDateTime, time } = getDateParts(date, renderTimezone);

  const onDateChange = (value: ZonedDateTime | null) => {
    if (value?.era === "AD") {
      onChange(value.toDate());
    }
  };

  const onTimeChange = useCallback(
    (value: TimeValue) => {
      onChange(zonedDateTime.set({ hour: value.hour, minute: value.minute }).toDate());
    },
    [onChange, zonedDateTime]
  );

  return (
    <DatePicker
      onChange={onDateChange}
      value={zonedDateTime}
      granularity={withTime ? "minute" : "day"}
      aria-label="Choose date"
    >
      <Box direction="column" gap="small" align="start">
        <Group
          isDisabled={disabled}
          isInvalid={hasError}
          className={cx(styles.input, {
            [styles.fullWidth]: fullWidth,
          })}
        >
          <DateInput className={styles.inputInner}>
            {(segment) => <DateSegment segment={segment} />}
          </DateInput>
          <IconActionButton
            className={styles.inputButton}
            tooltip="Open datepicker"
            icon={CalendarIcon}
          />
        </Group>
      </Box>

      <Popover placement={placement}>
        <Dialog className={styles.dialog}>
          <Box direction="column" align="center" justify="center">
            <Calendar className={styles.calendar} minValue={minZonedDate} maxValue={maxZonedDate}>
              <DatepickerCalendarContent />
            </Calendar>
            {withTime && (
              <TimeField
                className={cx(styles.timeField, styles.timeWrapper)}
                value={time}
                onChange={onTimeChange}
              >
                <Label className={styles.timeLabel}>Time</Label>
                <DateInput className={cx(styles.input, styles.timeInput)}>
                  {(segment) => <DateSegment segment={segment} />}
                </DateInput>
              </TimeField>
            )}
          </Box>
        </Dialog>
      </Popover>
    </DatePicker>
  );
};

export default Datepicker;
