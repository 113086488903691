import subDays from "date-fns/subDays";
import subHours from "date-fns/subHours";

import { PeriodValue } from "ds/components/Datepicker/types";
import { Spacing } from "types/Spacing";
import { SearchQueryFieldConstraintTimeInLast, SearchQueryOrderDirection } from "types/generated";

export const COLUMN_ORDER = "minmax(110px, 1fr) minmax(110px, 1fr) 10rem 24rem 24rem 12rem 4rem";
export const COLUMN_GAP: Spacing = "large";

export enum FilterItemKeys {
  CreatedAt = "createdAt",
  AffectedResourceType = "affected_resource_type",
  EventType = "event_type",
  Username = "username",
  AffectedResourceID = "affected_resource_id",
  RelatedResourceType = "related_resource_type",
  RelatedResourceID = "related_resource_id",
  Action = "action",
}

export enum AuditTrailEntrySuggestions {
  AffectedResourceType = "affected_resource_type",
  AffectedResourceID = "affected_resource_id",
  RelatedResourceType = "related_resource_type",
  RelatedResourceID = "related_resource_id",
  Username = "username",
}

export const FILTER_ITEMS_DICTIONARY: Partial<Record<FilterItemKeys, string>> = {
  [FilterItemKeys.AffectedResourceType]: "Affected resource type",
  [FilterItemKeys.AffectedResourceID]: "Affected resource ID",
  [FilterItemKeys.RelatedResourceType]: "Related resource type",
  [FilterItemKeys.RelatedResourceID]: "Related resource ID",
  [FilterItemKeys.EventType]: "Event type",
  [FilterItemKeys.Action]: "Action",
};

export const INITIAL_SORT_OPTION = FilterItemKeys.CreatedAt;
export const INITIAL_SORT_DIRECTION = SearchQueryOrderDirection.Desc;
export const FILTERS_ORDER_SETTINGS_KEY = "auditTrailEntriesFiltersOrder";

export const AUDIT_TRAIL_ENTRIES_PER_PAGE = 50;

export const POLL_INTERVAL = 10000;

export const INITIAL_PERIOD = SearchQueryFieldConstraintTimeInLast.Last14D;

export const PERIODS: Record<string, PeriodValue> = {
  [SearchQueryFieldConstraintTimeInLast.Last1H]: {
    label: "1 hour",
    range: () => ({
      start: subHours(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last4H]: {
    label: "4 hours",
    range: () => ({
      start: subHours(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last1D]: {
    label: "1 day",
    range: () => ({
      start: subDays(new Date(), 1),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last7D]: {
    label: "7 days",
    range: () => ({
      start: subDays(new Date(), 7),
      end: new Date(),
    }),
  },
  [SearchQueryFieldConstraintTimeInLast.Last14D]: {
    label: "14 days",
    range: () => ({
      start: subDays(new Date(), 14),
      end: new Date(),
    }),
  },
};
