import { useState, useCallback } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { WebhookDelivery, Stack } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import LoadingSpinner from "components/form/components/loading";
import { withEnterKeyPress } from "utils/browser";

import { GET_WEBHOOK_DELIVERY } from "./gql";
import WebhookDeliveriesStatus from "./Status";
import WebhookDetails from "./Details";
import styles from "./styles.module.css";

type WebhookDeliveriesListItemProps = {
  item: WebhookDelivery;
  webhookId?: string;
  stackId?: string;
  openDeliveryCallback?: () => void;
};

type StackWebhookGql = {
  stack: Stack;
};

const WebhookDeliveriesListItem = ({
  item,
  webhookId,
  stackId,
  openDeliveryCallback,
}: WebhookDeliveriesListItemProps) => {
  const { onError } = useTypedContext(FlashContext);
  const [getDelivery, { loading, data }] = useLazyQuery<StackWebhookGql>(GET_WEBHOOK_DELIVERY, {
    onError,
  });
  const [active, setIsActive] = useState(false);

  const handleClick = useCallback(() => {
    if (!data?.stack) {
      getDelivery({ variables: { key: item.id, id: webhookId, stack: stackId } });
    }

    if (!active) {
      openDeliveryCallback?.();
    }
    setIsActive(!active);
  }, [active, data?.stack, getDelivery, item.id, stackId, webhookId]);

  return (
    <li>
      <div
        className={styles.listItem}
        onClick={handleClick}
        onKeyDown={withEnterKeyPress(handleClick)}
        role="button"
        tabIndex={0}
      >
        <span className={styles.id}>{item.id}</span>
        <WebhookDeliveriesStatus status={item.status} />
        <span className={styles.date}>
          {moment.unix(item.timestamp).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </div>
      {active && loading && <LoadingSpinner />}
      {data?.stack?.integrations?.webhook?.deliveryDetails && active && (
        <WebhookDetails item={data.stack.integrations.webhook.deliveryDetails} />
      )}
    </li>
  );
};

export default WebhookDeliveriesListItem;
