import { useCallback, useState } from "react";

import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import IconAction from "ds/components/IconAction";
import { Pencil, Trash } from "components/icons";
import BadgeNext from "ds/components/BadgeNext";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";

import styles from "./styles.module.css";
import ContextVariablesEditForm from "../ContextVariables/EditForm";
import { ContextAttachedVariable } from "../types";

type NewContextVariableProps = {
  item: ContextAttachedVariable;
  onSave?: (index: number, item: ContextAttachedVariable) => void;
  onRemove?: (index: number) => void;
  index: number;
  onShowFullDescription: (description: string) => void;
};

const NewContextVariable = ({
  item,
  onSave,
  onRemove,
  onShowFullDescription,
  index,
}: NewContextVariableProps) => {
  const value = item.isSecret ? "•••••" : item.value;

  const [isEditing, setIsEditing] = useState(false);
  const canEdit = !!onSave;

  const handleRemove = useCallback(() => onRemove?.(index), [index, onRemove]);

  const handleSave = useCallback(
    (item: ContextAttachedVariable) => {
      onSave?.(index, item);
      setIsEditing(false);
    },
    [index, onSave]
  );

  const handleEdit = useCallback(() => setIsEditing(true), []);

  if (isEditing) {
    return <ContextVariablesEditForm index={index} onSave={handleSave} />;
  }

  return (
    <Box
      align="center"
      gap="large"
      className={styles.item}
      padding="large"
      fullWidth
      grid
      gridTemplate={
        canEdit
          ? "minmax(0, 1fr) minmax(0, max-content) min-content"
          : "minmax(0, 1fr) minmax(0, max-content)"
      }
      gridAreas={canEdit ? `"info value actions"` : `"info value"`}
    >
      <Box direction="column" gridArea="info">
        <TextEllipsis tooltip={item.variableName} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} variant="p-t6" tag="span">
              {item.variableName}
            </Typography>
          )}
        </TextEllipsis>
        <ListEntitiesItemDescription
          description={item.description}
          onShowFullDescription={onShowFullDescription}
        />
      </Box>
      <Box gridArea="value">
        <BadgeNext variant="gray" className={styles.badge} text={value} textEllipsis type="weak" />
      </Box>
      {canEdit && (
        <Box gap="medium" gridArea="actions">
          <IconAction icon={Pencil} tooltip="Edit" onClick={handleEdit} />
          <IconAction icon={Trash} tooltip="Delete" color="danger" onClick={handleRemove} />
        </Box>
      )}
    </Box>
  );
};

export default NewContextVariable;
