import { useState } from "react";

import { InfoNew } from "components/icons";
import Drawer from "ds/components/Drawer";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerBody from "ds/components/Drawer/Body";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import IconAction from "ds/components/IconAction";
import Typography from "ds/components/Typography";
import { AnalyticsCommonProps } from "hooks/useAnalytics";

import styles from "./styles.module.css";

const LabelsInfoDrawer = ({
  analyticsPage,
  analyticsTitle,
  analyticsProps,
}: AnalyticsCommonProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  return (
    <>
      <IconAction
        analyticsPage={analyticsPage}
        analyticsTitle={analyticsTitle}
        analyticsProps={analyticsProps}
        icon={InfoNew}
        onClick={() => setIsDrawerVisible(true)}
        tooltip="Open documentation drawer"
        isTooltipActive={!isDrawerVisible}
      />
      <Drawer visible={isDrawerVisible} onOutsideClick={() => setIsDrawerVisible(false)}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Add Filtering & Magic labels" />
          <DrawerCloseIcon handleCloseDrawer={() => setIsDrawerVisible(false)} />
        </DrawerHeader>
        <DrawerBody className={styles.documentationBody}>
          <Typography tag="div" variant="p-body2">
            Labels are useful for searching and grouping things, but also work extremely well with
            policies.
            <br />
            <br />
            <b>List of the most useful labels:</b>
            <ul>
              <li>
                <b>infracost</b> → Enables Infracost on your stack
              </li>
              <li>
                <b>feature:add_plan_pr_comment</b> → Enables Pull Request Plan Commenting
              </li>
              <li>
                <b>feature:disable_resource_sanitization</b> → Disables resource sanitization
              </li>
              <li>
                <b>terragrunt</b> → Old way of using Terragrunt from the Terraform backend
              </li>
              <li>
                <b>ghenv:Name</b> → GitHub Deployment environment (defaults to the stack name)
              </li>
              <li>
                <b>ghenv: - </b> → Disables the creation of GitHub deployment environments
              </li>
              <li>
                <b>autoattach:label</b> → Used for policies and contexts to autoattach them to all
                stacks containing that label
              </li>
            </ul>
          </Typography>
        </DrawerBody>
        {/* <DrawerFooter>
        <Button
          // TODO: update docs
          href={getDocsUrl()}
          variant="secondary"
          size="medium"
          target="_blank"
          startIcon={Documentation}
          analyticsTitle="Full documentation Click"
          className={styles.documentationButton}
        >
          Open documentation
        </Button>
      </DrawerFooter> */}
      </Drawer>
    </>
  );
};

export default LabelsInfoDrawer;
