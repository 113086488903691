import Toggle from "ds/components/ToggleNew";

import useSelectionStore from "./useSelectionStore";

type TableRowCheckboxProps = {
  id: string;
  name: string;
};
const TableRowCheckbox = ({ id, name }: TableRowCheckboxProps) => {
  const [selected, toggle] = useSelectionStore(({ selected, toggle }) => [
    selected.has(id),
    toggle,
  ]);

  return (
    <Toggle
      aria-label={`Select ${name}`}
      slot={null}
      variant="checkbox"
      isSelected={selected}
      onChange={() => toggle(id)}
    />
  );
};

export default TableRowCheckbox;
