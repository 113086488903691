import cx from "classnames";

import { ReactComponent as LogoText } from "ds/components/StaticSpaceliftLogo/logo-text.svg";
import { ReactComponent as LogoSymbol } from "ds/components/StaticSpaceliftLogo/logo-symbol.svg";

import styles from "./styles.module.css";

type AnimatedSpaceliftLogoProps = {
  short?: boolean;
};

const AnimatedSpaceliftLogo = ({ short }: AnimatedSpaceliftLogoProps) => {
  return (
    <div className={cx(styles.wrapper, short && styles.short)}>
      <div className={styles.logoTextWrapper}>
        <LogoText className={styles.logoText} />
      </div>
      <LogoSymbol className={styles.logoSymbol} />
    </div>
  );
};

export default AnimatedSpaceliftLogo;
