import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_POLICY } from "./List/gql";
import { REFETCH_QUERIES_ON_DELETE } from "./List/constants";

const useDeletePolicy = () => {
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [deletePolicy, { loading }] = useMutation(DELETE_POLICY, {
    refetchQueries: REFETCH_QUERIES_ON_DELETE,
    onError,
  });

  const onDelete = (id: string) => {
    return deletePolicy({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Policy was deleted`,
          });
        }
      })
      .catch(onError);
  };

  return { onDelete, loading };
};

export default useDeletePolicy;
