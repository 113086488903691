import { useCallback, useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import { VcsIntegration } from "types/generated";
import { MenuSpaces } from "components/icons";
import Box from "ds/components/Box";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import { VCS_PROVIDERS_URL_KEYS } from "views/account/VCS/constants";
import useTestVCSIntegrationConnection from "views/account/VCS/useTestVCSIntegrationConnection";
import VCSProviderIcon from "views/account/VCS/components/ProviderIcon";
import useDeleteVcsIntegration from "views/account/VCS/useDeleteVcsIntegration";
import VCSIntegrationFeedback from "views/account/VCS/components/IntegrationFeedback";
import BadgeNext from "ds/components/BadgeNext";
import { isTestIntegrationConnectionAvailable } from "views/account/VCS/helpers";
import { hasSpaceManageAccess } from "utils/user";
import useTypedContext from "hooks/useTypedContext";

import VCSIntegrationsListItemDropdownActions from "./DropdownActions";
import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import { VCSIntegrationsListContext } from "../Context";

type VCSIntegrationsListItemProps = {
  item: VcsIntegration;
  onEdit: (item: VcsIntegration) => void;
  setRowHeight?: (size: number) => void;
  onShowDetails: (item: VcsIntegration) => void;
  onShowFullDescription: (item: VcsIntegration) => void;
  isActive?: boolean;
};

const VCSIntegrationsListItem = ({
  item,
  onEdit,
  setRowHeight,
  onShowDetails,
  onShowFullDescription,
  isActive,
}: VCSIntegrationsListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const { refetchDefaultIntegrations } = useTypedContext(VCSIntegrationsListContext);

  const isTestConnectionAvailable = isTestIntegrationConnectionAvailable(item.details);
  const canManageVCSIntegrations = hasSpaceManageAccess(item.space.accessLevel);

  const { deleteVcsIntegration } = useDeleteVcsIntegration();
  const { testIntegrationConnection, loading } = useTestVCSIntegrationConnection({
    id: item.id,
    name: item.name || `Default ${HUMANIZE_PROVIDER[item.provider]}`,
    provider: item.provider,
  });

  const totalUsedByEntitiesQty = item?.searchUsersSuggestions?.filteredCount || 0;

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  const handleShowDetails = useCallback(() => onShowDetails(item), [item, onShowDetails]);

  const handleTestConnection = useCallback(
    () => testIntegrationConnection(),
    [testIntegrationConnection]
  );

  const handleIntegrationDelete = useCallback(() => {
    if (item.id) {
      deleteVcsIntegration(
        item.id,
        item.provider,
        totalUsedByEntitiesQty,
        refetchDefaultIntegrations
      );
    }
  }, [
    deleteVcsIntegration,
    item.id,
    item.provider,
    refetchDefaultIntegrations,
    totalUsedByEntitiesQty,
  ]);

  const handleIntegrationEdit = useCallback(() => {
    onEdit(item);
  }, [item, onEdit]);

  const itemPath = `/vcs/integration/${VCS_PROVIDERS_URL_KEYS[item.provider]}/${item.id}`;

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      isActive={isActive}
    >
      <VCSProviderIcon provider={item.provider} tile isDefault={item.isDefault} />

      <Box direction="column" gap="medium">
        <Box gap="medium" align="center">
          <ListEntitiesItemLink title={item.name} to={itemPath} v5Compat />

          {item.isDefault && <BadgeNext variant="gray" type="strong" text="DEFAULT" />}
        </Box>

        {item.description && (
          <Box direction="row" align="start" fullWidth>
            <ListEntitiesItemDescription
              description={item.description}
              onShowFullDescription={handleShowFullDescription}
            />
          </Box>
        )}

        <VCSIntegrationFeedback integration={item} />

        <TagsList tags={item.labels} onExpand={handleRowHeight} withTopMargin={false} />
      </Box>

      {item.isDefault ? (
        <MetaInfoListItem>&minus;</MetaInfoListItem>
      ) : (
        <MetaInfoListItem
          icon={MenuSpaces}
          linkText={item.space.name}
          href={`/spaces/${item.space.id}`}
        />
      )}

      <Box shrink="0">
        <VCSIntegrationsListItemDropdownActions
          id={item.id}
          name={item.name}
          onShowDetails={handleShowDetails}
          onIntegrationDelete={handleIntegrationDelete}
          onEdit={handleIntegrationEdit}
          {...(isTestConnectionAvailable && { onTestConnection: handleTestConnection })}
          isTestingConnection={loading}
          canManageVCSIntegrations={canManageVCSIntegrations}
          totalUsedByEntitiesQty={totalUsedByEntitiesQty}
          usedByPath={itemPath}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default VCSIntegrationsListItem;
