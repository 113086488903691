import { ReactNode, forwardRef } from "react";

import { IconComponent } from "types/Icon";

import IconAction from "../IconAction";
import Dropdown from "../Dropdown";

type DropdownIconActionProps = {
  children: ({ closeDropdown }: { closeDropdown: () => void }) => ReactNode;
  icon: IconComponent;
  onVisibilityChange?: (isVisible: boolean) => void;
  closeDropdownTrigger?: number;
  disableTooltipPortal?: boolean;
  tooltip: string;
};

const DropdownIconAction = forwardRef(function DropdownIconAction(
  {
    children,
    icon,
    onVisibilityChange,
    closeDropdownTrigger,
    disableTooltipPortal,
    tooltip,
  }: DropdownIconActionProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <Dropdown
      closeDropdownTrigger={closeDropdownTrigger}
      onVisibilityChange={onVisibilityChange}
      renderTriggerComponent={({ onClick, isVisible, ariaProps }) => (
        <IconAction
          disableTooltipPortal={disableTooltipPortal}
          ref={ref}
          icon={icon}
          onClick={onClick}
          isTooltipActive={!isVisible}
          tooltip={tooltip}
          {...ariaProps}
        />
      )}
    >
      {children}
    </Dropdown>
  );
});

export default DropdownIconAction;
