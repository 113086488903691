import { ReactNode } from "react";

import { IconComponent } from "types/Icon";
import Typography from "ds/components/Typography";
import ModalConfirmation from "components/ModalConfirmation";
import Tile from "ds/components/Tile";

import styles from "./styles.module.css";

const noop = () => void 0;

type ManagementStrategyItemProps = {
  active: boolean;
  title: string;
  icon: IconComponent;
  children: ReactNode;
  confirmationDescription: ReactNode;
  confirmationCallback: () => void;
};

const ManagementStrategyItem = ({
  active,
  children,
  icon,
  title,
  confirmationDescription,
  confirmationCallback,
}: ManagementStrategyItemProps) => {
  const renderTile = (selected: boolean) => (
    <Tile
      title={title}
      icon={icon}
      description={children}
      selected={selected}
      indicator="radio"
      onClick={noop}
    />
  );

  if (active) {
    return renderTile(true);
  }

  return (
    <ModalConfirmation
      title={`Enable ${title.toLowerCase()}`}
      confirmCallback={confirmationCallback}
      size="regular"
      confirmVariant="primary"
      confirmText="Enable"
      triggerClassName={styles.trigger}
      triggerComponent={renderTile(false)}
    >
      <Typography tag="p" variant="p-body2">
        {confirmationDescription}
      </Typography>
    </ModalConfirmation>
  );
};

export default ManagementStrategyItem;
