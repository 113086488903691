import { useFormContext } from "react-hook-form";
import cx from "classnames";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";

import styles from "./styles.module.css";

type FormFieldTextConfirmProps = {
  fieldName: string;
  textToConfirm: string;
  disabled?: boolean;
};

const FormFieldTextConfirm = ({
  fieldName,
  textToConfirm,
  disabled,
}: FormFieldTextConfirmProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<Record<string, string>>();

  return (
    <FormField
      label={
        <Typography tag="span" variant="p-body3" className={styles.labelFlex}>
          Please type{" "}
          <TextEllipsis tooltip={textToConfirm} tooltipWidthMode="maxWidthXl">
            {({ className, ...restProps }) => (
              <Typography
                {...restProps}
                className={cx(styles.ellipsisValue, className)}
                tag="span"
                variant="p-t7"
              >
                {textToConfirm}
              </Typography>
            )}
          </TextEllipsis>{" "}
          to confirm.
        </Typography>
      }
      error={errors?.[fieldName]?.message}
      noMargin
    >
      {({ ariaInputProps }) => (
        <Input
          {...register(fieldName, {
            validate: (value) => value === textToConfirm,
          })}
          error={!!errors?.[fieldName]?.message}
          disabled={disabled}
          {...ariaInputProps}
        />
      )}
    </FormField>
  );
};

export default FormFieldTextConfirm;
