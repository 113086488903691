import { useState } from "react";

import { Dots } from "components/icons";
import Box from "ds/components/Box";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import DropdownIconAction from "ds/components/DropdownIconAction";
import TextEllipsis from "ds/components/TextEllipsis";
import Typography from "ds/components/Typography";
import { StackDependencyReference } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import Tooltip from "ds/components/Tooltip";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";
import OutputReferencesDrawerForm from "./Form";
import OutputReferencesDrawerDeleteAction from "./DeleteAction";
import { StackDependenciesOutputReferencesContextApi } from "./Context";

type OutputReferencesDrawerListItemProps = {
  stackDependencyId: string;
  outputReference: StackDependencyReference;
  onGoToOutput: (anchor: string) => void;
};

const OutputReferencesDrawerListItem = ({
  stackDependencyId,
  outputReference,
  onGoToOutput,
}: OutputReferencesDrawerListItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const { checkIsOutputExist } = useTypedContext(StackDependenciesOutputReferencesContextApi);

  const handleSetEditMode = () => setIsEditMode(true);

  const handleUnsetEditMode = () => setIsEditMode(false);

  const handleGoToOutput = () => {
    onGoToOutput(outputReference.outputName);
  };

  const isOutputExist = checkIsOutputExist(outputReference.outputName);

  if (isEditMode) {
    return (
      <OutputReferencesDrawerForm
        stackDependencyId={stackDependencyId}
        onEditCancel={handleUnsetEditMode}
        onEditSuccess={handleUnsetEditMode}
        outputReference={outputReference}
      />
    );
  }

  return (
    <Box
      direction="row"
      margin="medium 0"
      padding="large"
      gap="x-large"
      align="center"
      justify="between"
      className={styles.listItem}
      fullWidth
    >
      <Box direction="column" gap="small" zeroMinWidth>
        <TextEllipsis tooltipWidthMode="maxWidthSm" tooltip={outputReference.inputName}>
          {(props) => (
            <Typography {...props} variant="p-t6" tag="span">
              {outputReference.inputName}
            </Typography>
          )}
        </TextEllipsis>

        <Box direction="column" gap="0" zeroMinWidth>
          <TextEllipsis tooltipWidthMode="maxWidthSm" tooltip={outputReference.outputName}>
            {(props) => (
              <Typography {...props} variant="p-body3" tag="span" color="secondary">
                Output: {outputReference.outputName}
              </Typography>
            )}
          </TextEllipsis>

          {outputReference.triggerAlways && (
            <Typography variant="p-body3" tag="span" color="secondary">
              Always triggers
            </Typography>
          )}

          {!isOutputExist && (
            <Typography variant="p-body3" tag="span" color="warning">
              The output does not exist.
            </Typography>
          )}
        </Box>
      </Box>

      <DropdownIconAction tooltip="Show more actions" icon={Dots}>
        {() => (
          <DropdownSection>
            <Tooltip
              active={!isOutputExist}
              on={({ ref, ...rest }) => (
                <DropdownSectionItem
                  innerRef={ref}
                  onClick={handleGoToOutput}
                  disabled={!isOutputExist}
                  {...rest}
                >
                  Go to the output
                </DropdownSectionItem>
              )}
            >
              The output does not exist.
            </Tooltip>
            <DropdownSectionItem onClick={handleSetEditMode}>Edit reference</DropdownSectionItem>
            <OutputReferencesDrawerDeleteAction
              name={outputReference.inputName}
              referenceId={outputReference.id}
            />
          </DropdownSection>
        )}
      </DropdownIconAction>
    </Box>
  );
};

export default OutputReferencesDrawerListItem;
