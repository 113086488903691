import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import { downloadFileFromURL } from "utils/file";

type DownloadLogsButtonProps = {
  fileName: string;
  href: string;
};

const DownloadFileButton = ({ href, fileName }: DownloadLogsButtonProps) => {
  const createUrl = (blob: Blob) => window.URL.createObjectURL(blob);

  const toBlob = (base64Value: string) => {
    const byteString = atob(base64Value);
    const buffer = new ArrayBuffer(byteString.length);
    const array = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
      array[i] = byteString.charCodeAt(i);
    }

    return new Blob([array], { type: "application/octet-stream" });
  };
  const downloadLogs = () => {
    if (href) {
      downloadFileFromURL(createUrl(toBlob(href)), fileName);
    }
  };

  return <DropdownSectionItem onClick={downloadLogs}>Download</DropdownSectionItem>;
};

export default DownloadFileButton;
