import { ReactNode } from "react";

import useTypedFlags from "hooks/useTypedFlags";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import { Info } from "components/icons";
import ListContentWrapper from "components/ListContentWrapper";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UserManagementWarningCallout from "../UserManagementWarningCallout";
import NonRootApiKeysToggle from "./NonRootApiKeysToggle";

type ApiKeysPageLayoutProps = {
  children: ReactNode;
  openCreateApiKeyDrawer?: () => void;
  openInfoDrawer: () => void;
};

const ApiKeysPageLayout = ({
  children,
  openCreateApiKeyDrawer,
  openInfoDrawer,
}: ApiKeysPageLayoutProps) => {
  const { enableApiKeyCreationForNonRootAdminsFrontend } = useTypedFlags();

  return (
    <>
      <UserManagementWarningCallout analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys} />
      <ViewHeader firstLevel>
        <Box direction="column" fullWidth>
          <Box fullWidth justify="between">
            <Box direction="row" align="center" __deprecatedGap="1.1rem">
              <ViewHeaderTitle tag="h2">API Keys</ViewHeaderTitle>
              <IconAction onClick={openInfoDrawer} icon={Info} tooltip="Open info drawer" />
            </Box>
            <ViewHeaderWrapper direction="row">
              {openCreateApiKeyDrawer && (
                <Button onClick={openCreateApiKeyDrawer} variant="primary">
                  Create API key
                </Button>
              )}
            </ViewHeaderWrapper>
          </Box>
          {enableApiKeyCreationForNonRootAdminsFrontend && (
            <Box margin="large 0 small">
              <NonRootApiKeysToggle />
            </Box>
          )}
        </Box>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default ApiKeysPageLayout;
