import { Stack } from "types/generated";
import LinkNew from "ds/components/LinkNew";
import { Expand } from "components/icons";
import { generateBranchUrl } from "utils/urls";
import { getCommmitDigitsCount } from "utils/commit";

import styles from "./styles.module.css";
import useStackListAnalytics from "../useStackListAnalytics";

type StackListItemCommitCellProps = { stack: Stack };

const StackListItemCommitCell = ({ stack }: StackListItemCommitCellProps) => {
  const {
    apiHost,
    branch,
    namespace,
    provider,
    repository,
    repositoryURL,
    trackedCommit,
    trackedBranchHead,
    trackedCommitSetBy,
  } = stack;

  const trackAnalytics = useStackListAnalytics();

  if (!apiHost) return null;

  if (trackedCommit) {
    const trackedCommitIsOutdated =
      trackedCommit && trackedBranchHead && trackedCommit.hash !== trackedBranchHead.hash;

    return (
      <LinkNew
        size="small"
        endIcon={Expand}
        target="_blank"
        href={trackedCommit.url}
        tooltip={
          (trackedCommitSetBy || trackedCommitIsOutdated) && (
            <>
              {trackedCommitSetBy && `${trackedCommitSetBy}.`}
              {trackedCommitIsOutdated && (
                <span className={styles.outdatedMessage}>
                  Stack's tracked commit is not equal to the head of the tracked branch.
                </span>
              )}
            </>
          )
        }
      >
        <>
          {trackedCommit.hash.slice(0, getCommmitDigitsCount(provider))}
          {trackedCommitIsOutdated && <span className={styles.outdatedIndicator}>&nbsp;</span>}
        </>
      </LinkNew>
    );
  }

  return (
    <LinkNew
      size="small"
      endIcon={Expand}
      href={generateBranchUrl({
        apiHost,
        namespace,
        repository,
        repositoryURL,
        provider,
        branch,
      })}
      onPress={() => trackAnalytics("Commit link clicked")}
      tooltip={trackedCommitSetBy}
    >
      HEAD
    </LinkNew>
  );
};

export default StackListItemCommitCell;
