import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import IconAction from "ds/components/IconAction";
import { Pencil } from "components/icons";
import CollapsibleList from "components/CollapsibleList";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackFormContext } from "../context";
import { StackCreationWizardStep } from "../types";
import { useNewStackAnalyticsSegementEvent } from "../useNewStackAnalyticsSegementEvent";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";

type NewStackSummaryPanelProps = {
  name: string;
  step: StackCreationWizardStep;
  children: ReactNode;
  goToStepDisabled?: boolean;
  count?: number;
};

const NewStackSummaryPanel = ({
  name,
  step,
  children,
  count,
  goToStepDisabled,
}: NewStackSummaryPanelProps) => {
  const { setCurrentStep } = useTypedContext(StackFormContext);
  const trackSegmentEvent = useNewStackAnalyticsSegementEvent();
  const goToStep = () => {
    trackSegmentEvent("Summary Edit Click", {
      step: name,
    });
    setCurrentStep(step);
  };

  const analyticsVersion = useStackCreationAnalyticsVersion();

  return (
    <CollapsibleList
      ariaLevel={2}
      title={name}
      count={count}
      initialIsCollapsed={false}
      analyticsPage={AnalyticsPageStack.StackNew}
      analyticsProps={{ version: analyticsVersion, step: name }}
      analyticsTitle="Summary section toggle"
      action={
        <IconAction
          disabled={goToStepDisabled}
          icon={Pencil}
          onClick={goToStep}
          tooltip="Edit step"
        />
      }
    >
      {children}
    </CollapsibleList>
  );
};

export default NewStackSummaryPanel;
