import { ReactNode, useCallback, useState } from "react";
import { PopoverProps } from "react-aria-components";

import { Dots } from "components/icons";
import { withTestId } from "utils/withTestId";

import { DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID } from "./constants";
import DropdownNew from "../Dropdown/New";
import ButtonIconNew, { ButtonIconNewProps } from "../ButtonIcon/New";

type DropdownEllipsisProps = {
  children: ({ close }: { close: () => void }) => ReactNode;
  dotsSize?: ButtonIconNewProps["size"];
  buttonVariant?: ButtonIconNewProps["variant"];
  placement?: PopoverProps["placement"];
  disabled?: boolean;
  tooltip?: string;
  onPress?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  className?: string;
};

const DropdownEllipsis = ({
  children,
  dotsSize = "medium",
  buttonVariant = "secondary",
  placement = "bottom right",
  disabled,
  tooltip = "More actions",
  onPress,
  onOpenChange,
  className,
}: DropdownEllipsisProps) => {
  const [isButtonActive, setIsButtonActive] = useState(false);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      setIsButtonActive(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  return (
    <DropdownNew
      onOpenChange={handleOpenChange}
      placement={placement}
      triggerComponent={
        <ButtonIconNew
          {...withTestId(DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID)}
          disabled={disabled}
          onPress={onPress}
          variant={buttonVariant}
          icon={Dots}
          size={dotsSize}
          active={isButtonActive}
          aria-label={tooltip}
          className={className}
        >
          {tooltip}
        </ButtonIconNew>
      }
    >
      {children}
    </DropdownNew>
  );
};

export default DropdownEllipsis;
