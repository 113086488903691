import { FunctionComponent } from "react";

import Box from "ds/components/Box";
import { AwsIntegration, AzureIntegration } from "types/generated";
import Tile from "ds/components/Tile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import IconTile from "ds/components/IconTile";
import TileTitle from "ds/components/Tile/Title";
import TileCheckboxGrid from "components/Forms/SourceCode/TileCheckboxGrid";
import MissingDataBanner from "components/MissingDataBanner";
import { CLOUD_INTEGRATIONS } from "shared/CloudIntegration/types";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import StackSettingsIntegrationsCloudAttachFormAWSIntegration from "./AWSIntegration";
import StackSettingsIntegrationsCloudAttachFormAzureIntegration from "./AzureIntegration";
import StackSettingsIntegrationsCloudAttachFormGCPIntegration from "./GCPIntegration";

type StackSettingsIntegrationsCloudAttachFormProps = {
  cloudConfig: Array<{
    name: string;
    logo: FunctionComponent;
    type: CLOUD_INTEGRATIONS;
  }>;
  attachableAzureIntegrations?: AzureIntegration[];
  attachableAwsIntegrations?: AwsIntegration[];
  cloud?: CLOUD_INTEGRATIONS;
  setCloud: (value?: CLOUD_INTEGRATIONS) => void;
  hasData: boolean;
  refetch: () => void;
  refetching: boolean;
  entityId: string;
  onSuccess?: () => void;
  onCancel: () => void;
  refetchQueries?: string[];
  canAttachGcpIntegration?: boolean;
};

const StackSettingsIntegrationsCloudAttachForm = ({
  cloudConfig,
  attachableAzureIntegrations,
  attachableAwsIntegrations,
  cloud,
  setCloud,
  hasData,
  refetch,
  refetching,
  entityId,
  onSuccess,
  onCancel,
  refetchQueries,
  canAttachGcpIntegration,
}: StackSettingsIntegrationsCloudAttachFormProps) => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackSettingsIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const handleOnSuccess = () => {
    trackSegmentEvent("Integrated", { type: cloud });
    setCloud(undefined);
    onSuccess?.();
  };

  return (
    <>
      {!hasData && (
        <Box margin="0 0 x-large" direction="column">
          <MissingDataBanner
            text="Couldn't load cloud integrations. Please try to refresh or come back later."
            refreshHandler={refetch}
            refreshLoading={refetching}
          />
        </Box>
      )}
      <Box direction="column" gap="x-large" margin="0 0 x-large">
        <TileCheckboxGrid>
          {cloudConfig.map(({ name, logo, type }) =>
            cloudConfig.length === 1 ? (
              <TileWrapper key={type} selected={cloud === type} onClick={() => setCloud(type)}>
                <TileContent direction="row" align="center">
                  <IconTile icon={logo} variant={cloud === type ? "primary" : "secondary"} />
                  <TileTitle>{name}</TileTitle>
                </TileContent>
              </TileWrapper>
            ) : (
              <Tile
                key={type}
                selected={cloud === type}
                title={name}
                icon={logo}
                onClick={() => setCloud(type)}
                indicator="radio"
              />
            )
          )}
        </TileCheckboxGrid>
      </Box>

      {cloud === CLOUD_INTEGRATIONS.AWS && (
        <StackSettingsIntegrationsCloudAttachFormAWSIntegration
          attachableIntegrations={attachableAwsIntegrations}
          entityId={entityId}
          onSuccess={handleOnSuccess}
          onCancel={onCancel}
          refetchQueries={refetchQueries}
        />
      )}

      {cloud === CLOUD_INTEGRATIONS.Azure && (
        <StackSettingsIntegrationsCloudAttachFormAzureIntegration
          attachableIntegrations={attachableAzureIntegrations}
          entityId={entityId}
          onSuccess={handleOnSuccess}
          onCancel={onCancel}
          refetchQueries={refetchQueries}
        />
      )}

      {cloud === CLOUD_INTEGRATIONS.GCP && canAttachGcpIntegration && (
        <StackSettingsIntegrationsCloudAttachFormGCPIntegration
          entityId={entityId}
          onSuccess={handleOnSuccess}
          onCancel={onCancel}
          refetchQueries={refetchQueries}
        />
      )}
    </>
  );
};

export default StackSettingsIntegrationsCloudAttachForm;
