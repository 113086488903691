import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";

import { DELETE_MANAGED_USER_GROUP } from "../../gql";

const useDeleteGroup = () => {
  const { onError } = useTypedContext(FlashContext);

  const [deleteManagedUserGroup] = useMutation(DELETE_MANAGED_USER_GROUP, {
    onError,
    refetchQueries: ["GetManagedUserGroups"],
  });

  const onDelete = (id: string) => {
    return deleteManagedUserGroup({
      variables: {
        id,
      },
    });
  };

  return { onDelete };
};

export default useDeleteGroup;
