import { memo, useCallback } from "react";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import DropdownSection from "ds/components/Dropdown/Section";
import CopyFieldDropdownItem from "components/CopyField/DropdownItem";
import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import { showVCSDeleteIntegrationModal } from "views/account/VCS/components/DeleteIntegrationModal";

type VCSIntegrationsListItemDropdownActionsProps = {
  id: string;
  name: string;
  totalUsedByEntitiesQty: number;
  onShowDetails: () => void;
  onIntegrationDelete: () => void;
  onTestConnection?: () => void;
  onEdit: () => void;
  isTestingConnection?: boolean;
  canManageVCSIntegrations: boolean;
  usedByPath: string;
};

const VCSIntegrationsListItemDropdownActions = ({
  id,
  name,
  totalUsedByEntitiesQty,
  onShowDetails,
  onIntegrationDelete,
  onTestConnection,
  onEdit,
  isTestingConnection,
  canManageVCSIntegrations,
  usedByPath,
}: VCSIntegrationsListItemDropdownActionsProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const handleShowDetails = useCallback(
    (callback: () => void) => () => {
      callback();
      onShowDetails();
    },
    [onShowDetails]
  );

  const onDeleteConfirmationModalShow = useCallback(() => {
    showVCSDeleteIntegrationModal({
      attachedEntitiesQty: totalUsedByEntitiesQty,
      integrationName: name,
      linkPath: usedByPath,
      onConfirm: onIntegrationDelete,
      onClose: () => trackSegmentAnalyticsEvent("Integration - Unlink Exit"),
    });

    trackSegmentAnalyticsEvent("Integration - Unlink Start");
  }, [name, onIntegrationDelete, totalUsedByEntitiesQty, trackSegmentAnalyticsEvent, usedByPath]);

  return (
    <DropdownEllipsis dotsSize="small">
      {({ close }) => (
        <DropdownSection>
          {id && <CopyFieldDropdownItem title="Copy ID" value={id} callback={close} />}
          <DropdownSectionItem onClick={handleShowDetails(close)}>See details</DropdownSectionItem>
          {canManageVCSIntegrations && (
            <>
              {onTestConnection && (
                <DropdownSectionItem onClick={onTestConnection} loading={isTestingConnection}>
                  Test
                </DropdownSectionItem>
              )}
              <DropdownSectionItem onClick={onEdit}>Edit</DropdownSectionItem>
              <DropdownSectionItem onClick={onDeleteConfirmationModalShow} danger>
                Delete
              </DropdownSectionItem>
            </>
          )}
        </DropdownSection>
      )}
    </DropdownEllipsis>
  );
};

export default memo(VCSIntegrationsListItemDropdownActions);
