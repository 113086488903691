import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";

import useURLParams from "hooks/useURLParams";
import { AnsibleHost } from "types/generated";
import TreeChart from "components/TreeChart";
import TreeChartTextNode from "components/TreeChart/TextNode";
import { ConfigNode } from "components/TreeChart/types";
import Box from "ds/components/Box";
import TreeChartGroupNode from "components/TreeChart/GroupNode";
import { getNodeHeight } from "components/TreeChart/GroupNode/getNodeHeight";
import { AnalyticsPage } from "hooks/useAnalytics";

import ConfigManagementTreeGridList from "./List";
import { TASK_SEARCH_KEY } from "./constants";
import { createAnsibleNodes } from "./utils";
import ConfigManagementTreeGridDrawer from "./Drawer";
import { Components } from "../types";
import { LayoutMode } from "./types";
import ConfigManagementTreeGridLegend from "./Legend";

type ConfigManagementTreeGridProps = {
  ansibleHosts: AnsibleHost[];
  sortable?: boolean;
  layoutMode?: LayoutMode;
  chartClassName?: string;
  analyticsPage?: AnalyticsPage;
};

const nodeTypes = {
  [Components.Text]: {
    Component: TreeChartTextNode,
    height: () => 60,
  },
  [Components.Group]: {
    Component: TreeChartGroupNode,
    height: getNodeHeight,
  },
};

const ConfigManagementTreeGrid = ({
  ansibleHosts,
  sortable,
  layoutMode,
  chartClassName,
  analyticsPage,
}: ConfigManagementTreeGridProps) => {
  const urlParams = useURLParams();
  const location = useLocation();

  const nodes = useMemo(
    () => createAnsibleNodes(ansibleHosts, layoutMode === LayoutMode.Diagram, location.pathname),
    [ansibleHosts, layoutMode, location.pathname]
  );

  const chartNodes = useMemo(() => nodes.filter((node) => node.isHost || node.isPlaybook), [nodes]);

  const taskId = urlParams.get(TASK_SEARCH_KEY) as string;
  const task = taskId ? nodes.find((node) => node.id === taskId) : undefined;

  return (
    <>
      {layoutMode === LayoutMode.Diagram ? (
        <Box direction="column" surfaceColor="primary" className={chartClassName}>
          <TreeChart
            analyticsPage={analyticsPage}
            nodes={chartNodes as ConfigNode<Components>[]}
            margin={{ top: 30, left: 30, right: 30 }}
            nodeTypes={nodeTypes}
            activeId={taskId}
          />
          <ConfigManagementTreeGridLegend nodes={nodes} />
        </Box>
      ) : (
        <>
          <ConfigManagementTreeGridList
            analyticsPage={analyticsPage}
            sortable={sortable}
            nodes={nodes}
            activeId={taskId}
          />
        </>
      )}
      <ConfigManagementTreeGridDrawer task={task} />
    </>
  );
};

export default ConfigManagementTreeGrid;
