import { useCallback, useEffect, useRef } from "react";

import ListEntitiesItem from "components/ListEntitiesItem";
import { WorkerPool } from "types/generated";
import Box from "ds/components/Box";
import TagsList from "components/TagsList";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { Incognito, MenuSpaces, WarningSolid } from "components/icons";
import Icon from "ds/components/Icon";
import BadgeNext from "ds/components/BadgeNext";
import Tooltip from "ds/components/Tooltip";
import { NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE } from "views/account/WorkerPool/constants";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import { hasSpaceManageAccess } from "utils/user";
import ListEntitiesItemLink from "components/ListEntitiesItem/Link";

import WorkerPoolsListItemDropdown from "../ListItemDropdown";
import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import styles from "./styles.module.css";

type WorkerPoolListItemProps = {
  item: WorkerPool;
  onEdit: (workerPool: WorkerPool) => void;
  setRowHeight?: (size: number) => void;
  onShowFullDescription: (workerPool: WorkerPool) => void;
  isActive?: boolean;
};

const WorkerPoolListItem = ({
  item,
  onEdit,
  setRowHeight,
  onShowFullDescription,
  isActive = false,
}: WorkerPoolListItemProps) => {
  const workerPoolLink = `worker-pool/${item.id}`;

  const rowRef = useRef<HTMLDivElement>(null);

  const handleEdit = useCallback(() => {
    onEdit(item);
  }, [onEdit, item]);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  const hasUsers = item.usersCount > 0;
  const hasBusyWorkers = item.busyWorkers > 0;
  const runsCount = item.schedulableRunsCount;
  const hasWarning = item.workersCount === 0 && runsCount > 0;
  const canManageWorkerPools = hasSpaceManageAccess(item.spaceDetails.accessLevel);

  // on every render, update the row height
  useEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      ref={rowRef}
      direction="row"
      justify="between"
      align="center"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      isActive={isActive}
    >
      <Box direction="row" align="center" justify="center" className={styles.workerTypeIcon}>
        <Icon size="large" src={Incognito} />
      </Box>

      <Box direction="column" align="stretch" justify="center">
        <Box direction="row" align="start">
          <ListEntitiesItemLink to={workerPoolLink} title={item.name} />
        </Box>

        {item.description && (
          <Box direction="row" align="start" fullWidth>
            <ListEntitiesItemDescription
              description={item.description}
              onShowFullDescription={handleShowFullDescription}
            />
          </Box>
        )}

        <TagsList tags={item.labels} onExpand={handleRowHeight} />
      </Box>

      {item.spaceDetails && (
        <MetaInfoListItem
          icon={MenuSpaces}
          linkText={item.spaceDetails.name}
          href={`/spaces/${item.spaceDetails.id}`}
        />
      )}

      <MetaInfoListItem>{item.workersCount}</MetaInfoListItem>

      <MetaInfoListItem>{item.busyWorkers}</MetaInfoListItem>

      <MetaInfoListItem>
        {hasWarning ? (
          <Tooltip
            on={(props) => (
              <BadgeNext
                variant="red"
                startIcon={WarningSolid}
                text={`${runsCount}`}
                type="weak"
                {...props}
              />
            )}
          >
            {NO_PRIVATE_WORKERS_AVAILABLE_MESSAGE}
          </Tooltip>
        ) : (
          runsCount
        )}
      </MetaInfoListItem>

      <Box direction="row" shrink="0" grow="0">
        <WorkerPoolsListItemDropdown
          workerPoolId={item.id}
          workerPoolName={item.name}
          canManageWorkerPools={canManageWorkerPools}
          hasBusyWorkers={hasBusyWorkers}
          hasUsers={hasUsers}
          onEdit={handleEdit}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default WorkerPoolListItem;
