import React from "react";

import StaticSpaceliftLogo from "ds/components/StaticSpaceliftLogo";
import Box from "components/box/Box";

import styles from "./styles.module.css";

type CLIWrapperProps = {
  children: React.ReactNode;
};

const CLIWrapper = ({ children }: CLIWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      <Box className={styles.innerWrapper}>
        <div className={styles.logoWrapper}>
          <div className={styles.logo}>
            <div className={`${styles.logoBg} ${styles.logoBgBig}`}>
              <div className={`${styles.logoBg} ${styles.logoBgSmall}`}>
                <StaticSpaceliftLogo short />
              </div>
            </div>
          </div>
        </div>

        {children}
      </Box>
    </div>
  );
};

export default CLIWrapper;
