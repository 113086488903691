import { gql } from "@apollo/client";

export const SEARCH_PUBLIC_WORKER_POOL_USED_BY = gql`
  query SearchPublicWorkerPoolUsedBy($input: SearchInput!) {
    publicWorkerPool {
      queuedRunsCount
      usersCount
      searchUsers(input: $input) {
        edges {
          cursor
          node {
            stackId
            stackName
            isModule
            spaceDetails {
              id
              name
              accessLevel
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const SEARCH_PUBLIC_WORKER_POOL_USED_BY_SUGGESTIONS = gql`
  query SearchPublicWorkerPoolUsedBySuggestions($input: SearchSuggestionsInput!) {
    publicWorkerPool {
      queuedRunsCount
      usersCount
      searchUsersSuggestions(input: $input) {
        filteredCount
        fields {
          name
          orderable
        }
      }
    }
  }
`;
