import { memo, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import ListEntitiesItem from "components/ListEntitiesItem";
import { Blueprint, BlueprintState } from "types/generated";
import Typography from "ds/components/Typography";
import Box from "ds/components/Box";
import TextEllipsis from "ds/components/TextEllipsis";
import Button from "ds/components/Button";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { MenuSpaces } from "components/icons";
import Timestamp from "components/time/Timestamp";
import ListEntitiesItemDescription from "components/ListEntitiesItem/Description";
import { hasSpaceManageAccess } from "utils/user";
import useTypedContext from "hooks/useTypedContext";
import { BlueprintSuggestions, searchBlueprintSuggestionsDictionary } from "constants/blueprint";
import useApplyFilter from "components/Filters/useApplyFilter";
import TagsListFilterable from "components/TagsList/Filterable";
import { SpacesContext } from "views/account/SpacesProvider";

import styles from "./styles.module.css";
import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import BlueprintsListItemDropdown from "../ListItemDropdown";
import BlueprintStateBadgeDropdown from "../StateBadge/Dropdown";
import BlueprintsFeatureGateTooltip from "../FeatureGate/Tooltip";
import { BlueprintActions } from "../FeatureGate/types";

type BlueprintListItemProps = {
  item: Blueprint;
  setRowHeight?: (size: number) => void;
  onCreateStack: (item: Blueprint) => void;
  onEditMetadata: (item: Blueprint) => void;
  onCloneBlueprint: (item: Blueprint) => void;
  onShowFullDescription: (item: Blueprint) => void;
  isActive?: boolean;
};

const BLueprintListItem = ({
  item,
  setRowHeight,
  onCreateStack,
  onEditMetadata,
  onCloneBlueprint,
  onShowFullDescription,
  isActive = false,
}: BlueprintListItemProps) => {
  const blueprintLink = `/blueprint/${item.id}`;

  const rowRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const { hasEntityCreateAccess } = useTypedContext(SpacesContext);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  const handleNavigateToBlueprint = () => {
    navigate(blueprintLink);
  };

  const handleShowFullDescription = () => {
    onShowFullDescription(item);
  };

  const { applyFilter, applySpaceFilter, applyLabelFilter } = useApplyFilter<BlueprintSuggestions>({
    searchSuggestionsDictionary: searchBlueprintSuggestionsDictionary,
  });

  // on every render, update the row height
  useEffect(handleRowHeight);

  const isPublished = item.state === BlueprintState.Published;
  const canManageBlueprint = hasSpaceManageAccess(item.space.accessLevel);

  return (
    <ListEntitiesItem ref={rowRef} isActive={isActive}>
      <Box
        direction="row"
        justify="between"
        grid
        gridTemplate={COLUMN_ORDER}
        gap={`0 ${COLUMN_GAP}`}
      >
        <Box direction="column" align="stretch" justify="center">
          <Box direction="row" align="start" gap="large">
            <TextEllipsis tooltip={item.name} tooltipWidthMode="maxWidthSm" delay={400}>
              {(props) => (
                <Link to={blueprintLink} className={styles.nameLink}>
                  <Typography {...props} variant="p-t5" tag="h3">
                    {item.name}
                  </Typography>
                </Link>
              )}
            </TextEllipsis>

            <BlueprintStateBadgeDropdown
              state={item.state}
              applyFilter={applyFilter(BlueprintSuggestions.State)}
            />
          </Box>

          {item.description && (
            <Box direction="row" align="start" fullWidth>
              <ListEntitiesItemDescription
                description={item.description}
                onShowFullDescription={handleShowFullDescription}
              />
            </Box>
          )}
          {/* TODO add applyFolderFilter once backend add filter */}
          <TagsListFilterable
            tags={item.labels}
            applyLabelFilter={applyLabelFilter(BlueprintSuggestions.Label)}
            onExpand={handleRowHeight}
            className={styles.tagsList}
          />
        </Box>

        {item.space && (
          <MetaInfoListItem
            applyFilter={() => applySpaceFilter(BlueprintSuggestions.Space)(item.space.id)}
            type="space"
            icon={MenuSpaces}
            linkText={item.space.name}
            href={`/spaces/${item.space.id}`}
          />
        )}

        <Box direction="row" align="center" shrink="0">
          <span className={styles.info}>
            <Timestamp timestamp={item.updatedAt} />
          </span>
        </Box>

        <Box direction="row" align="center" justify="center" shrink="0" className={styles.actions}>
          {!isPublished && canManageBlueprint && (
            <Button onClick={handleNavigateToBlueprint} variant="secondary">
              Edit draft
            </Button>
          )}

          {isPublished && hasEntityCreateAccess && (
            <BlueprintsFeatureGateTooltip
              action={BlueprintActions.CreateStack}
              on={({ isDisabled, ...props }) => (
                <Button
                  {...props}
                  onClick={() => onCreateStack(item)}
                  variant="primary"
                  disabled={isDisabled}
                >
                  Create stack
                </Button>
              )}
            />
          )}
        </Box>
        <Box align="center">
          <BlueprintsListItemDropdown
            item={item}
            onEditMetadata={onEditMetadata}
            onCloneBlueprint={onCloneBlueprint}
          />
        </Box>
      </Box>
    </ListEntitiesItem>
  );
};

export default memo(BLueprintListItem);
