import { Stack } from "types/generated";
import DeltaCounts from "components/DeltaCounts";

type StackListItemDeltaCellProps = { stack: Stack };

const StackListItemDeltaCell = ({ stack }: StackListItemDeltaCellProps) => {
  if (!stack.delta) {
    return null;
  }

  return (
    <DeltaCounts
      size="small"
      align="start"
      variant="flexible"
      direction="column"
      delta={stack.delta}
    />
  );
};

export default StackListItemDeltaCell;
