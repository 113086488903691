import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Box from "ds/components/Box";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Icon from "ds/components/Icon";
import { ManagedUser, ManagedUserGroup, UserRole } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormFieldSpace from "components/FormFields/Spaces";
import Input from "ds/components/Input";
import Link from "ds/components/Link";
import ListEntitiesItem from "components/ListEntitiesItem";
import TabPanel from "ds/components/TabPanel";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import { getProviderLogo, getUserIntegrationFormValue } from "views/account/settings/Users/helpers";
import Tooltip from "ds/components/Tooltip";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import TabWithCounter from "ds/components/Tab/WithCounter";
import Banner from "ds/components/Banner";
import FeedbackActions from "ds/components/Feedback/Actions";
import { getDocsUrl } from "utils/getDocsUrl";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import UserStatusBadge from "../UsersList/UserStatusBadge";
import { UsersDrawerTabs, UpdateUserFields } from "./types";
import useUpdateUser from "./useUpdateUser";
import styles from "./styles.module.css";
import { getManagementStrategy } from "../../helpers";
import { SettingsContext } from "../../Context";
import { UserManagementActivationStatus } from "../../types";

type UsersDetailsDrawerContentProps = {
  user: ManagedUser;
  handleCloseDrawer: () => void;
  viewPageMode?: boolean;
  managedUserGroups: Pick<ManagedUserGroup, "identityProvider" | "groupName">[];
};

const UsersDetailsDrawerContent = ({
  user,
  handleCloseDrawer,
  managedUserGroups,
}: UsersDetailsDrawerContentProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const [currentTab, setCurrentTab] = useState("spaces");

  const userIntegrationOptions = getUserIntegrationFormValue(user);

  const userAddForm = useForm<UpdateUserFields>({
    defaultValues: {
      spaces: user.accessRules.length > 0 ? user.accessRules : [],
      slackMemberID: user.slackMemberID || "",
    },
    mode: "onChange",
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid, isDirty },
  } = userAddForm;

  const { onUpdate } = useUpdateUser(user.id);
  const ProviderIcon = getProviderLogo(user.loginMethod);

  const closeDrawer = () => {
    handleCloseDrawer();
    reset();
  };

  const onSubmit: SubmitHandler<UpdateUserFields> = (formData) => {
    trackSegmentAnalyticsEvent("Save Changes");

    const input = {
      accessRules: formData.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
      id: user.id,
      slackMemberID: formData.slackMemberID,
    };

    onUpdate(input)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `User access was updated`,
          });
          closeDrawer();
        }
      })
      .catch(onError);
  };

  return (
    <FormProvider {...userAddForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Access details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight hasStickyFooter>
        {activationStatus === UserManagementActivationStatus.INACTIVE && (
          <Banner variant="warning" title="User management is inactive">
            Access rules will not take effect until you activate user management.
            <FeedbackActions>
              <Button
                to="/settings/management-strategy/"
                analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
                analyticsTitle="Callout Strategy Click"
                variant="contrast"
                size="small"
              >
                Choose strategy
              </Button>
            </FeedbackActions>
          </Banner>
        )}

        <FormFieldViewText label="Username" value={user.username} />

        <FormField label="Login method">
          <Typography tag="span" variant="p-body2">
            {typeof ProviderIcon === "string" ? (
              ProviderIcon
            ) : (
              <Icon size="large" src={ProviderIcon} />
            )}
          </Typography>
        </FormField>
        {user.invitationEmail && <FormFieldViewText label="Email" value={user.invitationEmail} />}
        {user.lastLoginTime && (
          <FormFieldTimestamp timestamp={user.lastLoginTime} label="Last login" />
        )}

        <FormField label="Status" className={styles.status}>
          <UserStatusBadge status={user.status} />
        </FormField>

        <Box direction="row" gap="medium" align="center" className={styles.tabs}>
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === UsersDrawerTabs.Spaces}
            id={UsersDrawerTabs.Spaces}
            label="Spaces"
            count={user.spaceCount}
          />
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === UsersDrawerTabs.Groups}
            id={UsersDrawerTabs.Groups}
            label="Groups"
            count={user.groupCount || 0}
          />
          <TabWithCounter
            onClick={setCurrentTab}
            isActive={currentTab === UsersDrawerTabs.Integrations}
            id={UsersDrawerTabs.Integrations}
            label="Integrations"
            count={userIntegrationOptions.length}
          />
        </Box>

        <TabPanel isActive={currentTab === UsersDrawerTabs.Spaces} id={UsersDrawerTabs.Spaces}>
          <div className={styles.listWrapper}>
            <FormFieldSpace
              analyticsPage={AnalyticsPageOrganization.OrganizationUsers}
              isDisabled={user.role === UserRole.Owner}
              allowEmpty
            />
          </div>
        </TabPanel>
        <TabPanel isActive={currentTab === UsersDrawerTabs.Groups} id={UsersDrawerTabs.Groups}>
          <div className={styles.listWrapper}>
            {user.latestGroups.map((item, index) => {
              return (
                <ListEntitiesItem
                  key={`${item}-${index}`}
                  className={styles.listItem}
                  align="start"
                >
                  {managedUserGroups.find(
                    ({ identityProvider, groupName }) =>
                      identityProvider === user.loginMethod && groupName === item
                  ) ? (
                    <Link
                      className={styles.link}
                      to={`/settings/idp-group-mapping?identityProvider=${user.loginMethod}&groupName=${item}`}
                    >
                      <Typography tag="span" variant="p-t6">
                        {item}
                      </Typography>
                    </Link>
                  ) : (
                    <Tooltip
                      on={(props) => (
                        <Typography
                          {...props}
                          tag="span"
                          className={styles.itemTitle}
                          variant="p-t6"
                        >
                          {item}
                        </Typography>
                      )}
                      widthMode="maxWidthSm"
                    >
                      IdP group does not have any permissions assigned yet. You can do it in the IdP
                      group mapping section
                    </Tooltip>
                  )}
                </ListEntitiesItem>
              );
            })}
          </div>
        </TabPanel>

        <TabPanel
          isActive={currentTab === UsersDrawerTabs.Integrations}
          id={UsersDrawerTabs.Integrations}
        >
          <Box padding="x-large 0 0 0" fullWidth grow="1" direction="column">
            <FormField
              tooltipDisablePortal
              label="Slack member ID"
              error={errors?.slackMemberID?.message}
              tooltipInfoVariant="modal"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Slack member ID</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Id of Slack account that belongs to this user
                    <Link
                      href={getDocsUrl("/concepts/user-management/admin#slack-integration")}
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </TooltipModalBody>
                </>
              }
            >
              {({ ariaInputProps }) => (
                <Input
                  placeholder="Enter member ID"
                  error={!!errors?.slackMemberID}
                  {...register("slackMemberID", {})}
                  {...ariaInputProps}
                />
              )}
            </FormField>
          </Box>
        </TabPanel>
        <DrawerFooter sticky>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCloseDrawer}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || !isDirty}
            >
              Save changes
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default UsersDetailsDrawerContent;
