import { MenuSpaces } from "components/icons";
import MetaInfoListItem from "components/MetaInfoList/Item";
import TagsList from "components/TagsList";
import DescriptionDetails from "components/DescriptionDetails";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import useTypedContext from "hooks/useTypedContext";
import FormFieldTimestamp from "components/FormFields/Timestamp";
import FormFieldViewText from "components/FormFields/ViewText";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { ContextContext } from "../Context";

type ContextDetailsProps = {
  onOpenFullDescription: () => void;
  handleCloseDrawer: () => void;
};

const ContextDetails = ({ onOpenFullDescription, handleCloseDrawer }: ContextDetailsProps) => {
  const { context } = useTypedContext(ContextContext);

  return (
    <>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Context details" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody>
        <FormFieldViewText label="Name" value={context.name} />

        <FormField label="Space">
          <MetaInfoListItem
            icon={MenuSpaces}
            linkText={context.spaceDetails.name}
            href={`/spaces/${context.spaceDetails.id}`}
          />
        </FormField>

        <DescriptionDetails
          description={context.description}
          onOpenFullDescription={onOpenFullDescription}
        />

        {context.createdAt && (
          <FormFieldTimestamp timestamp={context.createdAt} label="Created at" />
        )}
        {context.updatedAt && (
          <FormFieldTimestamp timestamp={context.updatedAt} label="Updated at" />
        )}

        {context.labels.length > 0 && (
          <FormField label="Labels">
            <TagsList tags={context.labels} alwaysExpanded />
          </FormField>
        )}
      </DrawerBody>
    </>
  );
};

export default ContextDetails;
