import { useMemo, useRef } from "react";

import { SearchQueryOrderDirection, SearchSuggestionsField } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { URL_FILTER_KEYS_KEY } from "constants/url_query_keys";

import { DEFAULT_VIEW_ID, DEFAULT_VIEW_NAME } from "./constants";
import { decodeFilter } from "./helpers";
import { FilterItemSettings, SavedFilterView } from "./types";

export const useCachedFilterFields = (fields?: SearchSuggestionsField[]) => {
  const filtersRef = useRef<SearchSuggestionsField[]>([]);

  return useMemo(() => {
    if (fields) {
      const cachedFiltersMap = filtersRef.current.reduce<Map<string, SearchSuggestionsField>>(
        (acc, item) => {
          acc.set(item.name, item);

          return acc;
        },
        new Map<string, SearchSuggestionsField>()
      );

      fields.forEach((item) => {
        cachedFiltersMap.set(item.name, item);
      });

      const cache = [...cachedFiltersMap.values()];

      filtersRef.current = cache;
      return cache;
    }

    return filtersRef.current;
  }, [fields]);
};

export const useEmptyDefaultView = (
  filtersOrder: FilterItemSettings[],
  initialSortDirection: SearchQueryOrderDirection,
  initialSortOption: string,
  deprecatedFilterNames: string[]
): SavedFilterView => {
  const urlParams = useURLParams();
  const defaultFiltersOrder = useMemo(() => {
    if (!deprecatedFilterNames.length || !filtersOrder.length) {
      return filtersOrder.map((item) => ({ ...item, visible: true }));
    }

    const filterKeys = decodeFilter(urlParams.get(URL_FILTER_KEYS_KEY));

    return filtersOrder.map((item) => {
      const isDeprecatedKey = deprecatedFilterNames.includes(item.name);
      const hasKeyInUrl = filterKeys.includes(item.name);
      return {
        ...item,
        visible: !isDeprecatedKey || hasKeyInUrl,
      };
    });
  }, [deprecatedFilterNames, filtersOrder, urlParams]);

  return useMemo(
    () => ({
      id: DEFAULT_VIEW_ID,
      name: DEFAULT_VIEW_NAME,
      isPublic: false,
      order: defaultFiltersOrder,
      filters: new Map(),
      isMine: true,
      sortOption: initialSortOption,
      sortDirection: initialSortDirection,
      search: null,
    }),
    [defaultFiltersOrder, initialSortDirection, initialSortOption]
  );
};
