import { useLayoutEffect, useRef } from "react";
import cx from "classnames";

import ListEntitiesItem from "components/ListEntitiesItem";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import { ManagedUserGroupIntegration } from "types/generated";
import { pluralize } from "shared/Pluralize";
import TextEllipsis from "ds/components/TextEllipsis";
import { withTestId } from "utils/withTestId";
import { UserManagementActivationStatus } from "views/account/settings/types";

import { COLUMN_GAP, COLUMN_ORDER } from "../constants";
import GroupsIntegrationsListDropdown from "./Dropdown";
import { GROUP_INTEGRATION_LIST_ITEM_TEST_ID } from "./constants";
import styles from "./styles.module.css";

type ManageSlackAccessListItemProps = {
  activationStatus: UserManagementActivationStatus;
  item: ManagedUserGroupIntegration;
  handleGroupIntegrationClick: (details: ManagedUserGroupIntegration) => void;
  setRowHeight?: (size: number) => void;
};

const ManageSlackAccessListItem = ({
  activationStatus,
  item,
  setRowHeight,
  handleGroupIntegrationClick,
}: ManageSlackAccessListItemProps) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const handleRowHeight = () => {
    if (setRowHeight && rowRef.current) {
      setRowHeight(rowRef.current.getBoundingClientRect().height);
    }
  };

  // on every render, update the row height
  useLayoutEffect(handleRowHeight);

  return (
    <ListEntitiesItem
      {...withTestId(GROUP_INTEGRATION_LIST_ITEM_TEST_ID)}
      direction="row"
      align="center"
      justify="between"
      grid
      gridTemplate={COLUMN_ORDER}
      gap={`0 ${COLUMN_GAP}`}
      ref={rowRef}
      className={cx(activationStatus === UserManagementActivationStatus.INACTIVE && styles.item)}
    >
      <Box direction="row" align="center">
        <TextEllipsis tooltip={item.integrationName} tooltipWidthMode="maxWidthSm">
          {(props) => (
            <Typography {...props} tag="span" variant="p-t6">
              {item.integrationName}
            </Typography>
          )}
        </TextEllipsis>
      </Box>

      {item.slackChannelID && (
        <Box direction="row" align="center">
          <TextEllipsis tooltip={item.slackChannelID} tooltipWidthMode="maxWidthSm">
            {(props) => (
              <Typography {...props} tag="span" variant="p-t6">
                {item.slackChannelID}
              </Typography>
            )}
          </TextEllipsis>
        </Box>
      )}
      {!item.slackChannelID && (
        <Box direction="row" align="center">
          Unknown ID
        </Box>
      )}
      <Box direction="row" align="center">
        <Typography tag="span" variant="p-body2">
          {`${item.accessRules?.length} ${pluralize("space", item.accessRules?.length)}`}
        </Typography>
      </Box>
      <Box direction="row" align="center">
        <GroupsIntegrationsListDropdown
          item={item}
          handleGroupDetailsClick={handleGroupIntegrationClick}
        />
      </Box>
    </ListEntitiesItem>
  );
};

export default ManageSlackAccessListItem;
