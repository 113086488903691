import { MouseEventHandler, ReactNode, useCallback } from "react";

import Dropdown from "ds/components/Dropdown";
import { Dots } from "components/icons";
import ButtonIcon, { ButtonIconProps } from "ds/components/ButtonIcon";
import { withTestId } from "utils/withTestId";

import { DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID } from "./constants";

type DropdownEllipsisProps = {
  className?: string;
  children: ({ closeDropdown }: { closeDropdown: () => void }) => ReactNode;
  dotsSize?: ButtonIconProps["size"];
  onVisibilityChange?: (isVisible: boolean) => void;
  tooltip: string;
  id?: string;
};

const DropdownEllipsis = ({
  className,
  children,
  dotsSize = "medium",
  onVisibilityChange,
  tooltip,
  id,
}: DropdownEllipsisProps) => {
  const handleClick = useCallback(
    (callback: MouseEventHandler<HTMLButtonElement>): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        callback(e);
      },
    []
  );

  return (
    <Dropdown
      onVisibilityChange={onVisibilityChange}
      className={className}
      renderTriggerComponent={({ onClick, isVisible, ariaProps }) => (
        <ButtonIcon
          {...withTestId(DROPDOWN_ELLIPSIS_TRIGGER_TEST_ID)}
          {...ariaProps}
          variant="secondary"
          disableTooltip={isVisible}
          icon={Dots}
          onClick={handleClick(onClick)}
          active={isVisible}
          size={dotsSize}
          id={id}
        >
          {tooltip}
        </ButtonIcon>
      )}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownEllipsis;
