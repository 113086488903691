import { useState, useCallback, useMemo } from "react";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import { WebhookDelivery, AuditTrailWebhook } from "types/generated";
import useTypedContext from "hooks/useTypedContext";
import LoadingSpinner from "components/form/components/loading";
import { withEnterKeyPress } from "utils/browser";

import { GET_AUDIT_TRAIL_DELIVERY } from "./gql";
import WebhookDeliveriesStatus from "./Status";
import WebhookDetails from "./Details";
import styles from "./styles.module.css";

type WebhookDeliveriesListItemProps = {
  item: WebhookDelivery;
  openDeliveryCallback?: () => void;
};

type AuditTrailWebhookGql = {
  auditTrailWebhook: AuditTrailWebhook;
};

const WebhookDeliveriesListItem = ({
  item,
  openDeliveryCallback,
}: WebhookDeliveriesListItemProps) => {
  const { onError } = useTypedContext(FlashContext);
  const [getDelivery, { loading, data }] = useLazyQuery<AuditTrailWebhookGql>(
    GET_AUDIT_TRAIL_DELIVERY,
    {
      onError,
    }
  );
  const [active, setIsActive] = useState(false);

  const handleClick = useCallback(() => {
    if (!data?.auditTrailWebhook) {
      getDelivery({ variables: { key: item.id } });
    }

    if (!active) {
      openDeliveryCallback?.();
    }
    setIsActive(!active);
  }, [active, data, item.id, getDelivery]);

  const dateValue = useMemo(
    () => moment.unix(item.timestamp).format("YYYY-MM-DD HH:mm:ss"),
    [item]
  );

  return (
    <li>
      <div
        className={styles.listItem}
        onClick={handleClick}
        onKeyDown={withEnterKeyPress(handleClick)}
        role="button"
        tabIndex={0}
      >
        <span className={styles.id}>{item.id}</span>
        <WebhookDeliveriesStatus status={item.status} />
        <span className={styles.date}>{dateValue}</span>
      </div>
      {active && loading && <LoadingSpinner />}
      {data?.auditTrailWebhook?.deliveryDetails && active && (
        <WebhookDetails item={data.auditTrailWebhook.deliveryDetails} />
      )}
    </li>
  );
};

export default WebhookDeliveriesListItem;
