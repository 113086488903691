import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import useStackCreationAnalyticsVersion from "./useStackCreationAnalyticsVersion";

export const useNewStackAnalyticsSegementEvent = () => {
  const version = useStackCreationAnalyticsVersion();

  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackNew,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      version,
    },
  });

  return trackSegmentEvent;
};
