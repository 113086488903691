import FlashContext from "components/FlashMessages/FlashContext";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { Module } from "types/generated";
import useDisableModule from "shared/Module/useDisableModule";

type DisableButtonProps = {
  moduleId: Module["id"];
  refetchQueries?: string[];
};

const DisableButton = ({ moduleId, refetchQueries }: DisableButtonProps) => {
  const { reportSuccess } = useTypedContext(FlashContext);

  const [disableModule, { loading }] = useDisableModule({
    refetchQueries,
  });

  const handleClick = () => {
    disableModule({ id: moduleId }, (data) => {
      if (data?.moduleDisable) {
        reportSuccess({ message: "Module successfully disabled" });
      }
    });
  };

  return (
    <DropdownSectionItem onClick={handleClick} loading={loading} disabled={loading}>
      Disable
    </DropdownSectionItem>
  );
};

export default DisableButton;
