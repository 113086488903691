import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { Base64 } from "js-base64";
import { useNavigate } from "react-router-dom-v5-compat";

import { ConfigInput, ConfigType, Context, HooksInput } from "types/generated";
import { fileToBase64 } from "utils/file";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { PhaseName, adaptCommandsToFields } from "utils/hooks";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageContext } from "hooks/useAnalytics/pages/context";

import { CREATE_CONTEXT_V2 } from "../gql";
import { ContextFormFields } from "../types";
import { CONTEXT_ANALYTICS_VERSION } from "../constants";

type SaveContextCallback = (formData: ContextFormFields) => Promise<void>;

type UseSaveContext = [SaveContextCallback, boolean];

export const useSaveContext = (step?: string): UseSaveContext => {
  const { reportSuccess, onError } = useTypedContext(FlashContext);
  const navigate = useNavigate();

  const trackAnalyticsEvent = useAnalytics({
    page: AnalyticsPageContext.ContextNew,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { version: CONTEXT_ANALYTICS_VERSION },
  });

  const [saveContextMutation, { loading: isSavingContext }] = useMutation<{
    contextCreateV2: Context;
  }>(CREATE_CONTEXT_V2);

  const saveContext = useCallback<SaveContextCallback>(
    async (formData) => {
      try {
        const stackAttachments = formData.attachedProjectIds.map((id) => ({
          stack: id,
          priority: 0,
        }));

        const configAttachments: Omit<ConfigInput, "fileMode">[] = [];

        for (const variable of formData.attachedVariables) {
          configAttachments.push({
            description: variable.description,
            id: variable.variableName,
            type: ConfigType.EnvironmentVariable,
            value: variable.value,
            writeOnly: variable.isSecret,
          });
        }

        for (const mountedFile of formData.attachedMountedFiles) {
          const { file, content, description, path, isSecret } = mountedFile;
          let value = "";

          if (content) {
            value = Base64.encode(content);
          } else if (file) {
            const base64 = await fileToBase64(file as File);
            const base64File = base64 ? base64.toString().split(",")[1] : "";
            value = base64File;
          } else {
            continue;
          }

          configAttachments.push({
            description,
            id: path,
            type: ConfigType.FileMount,
            value: value,
            writeOnly: isSecret,
          });
        }

        let hooks = {} as HooksInput;

        for (const [phase, commands] of Object.entries(formData.hooks)) {
          hooks = {
            ...hooks,
            ...adaptCommandsToFields(commands, phase as PhaseName),
          };
        }

        const contextInput = {
          description: formData.description,
          labels: formData.labels.map((item) => item.value),
          name: formData.contextName,
          space: formData.space,
          stackAttachments,
          configAttachments,
          hooks,
        };

        const { data } = await saveContextMutation({
          variables: {
            input: contextInput,
          },
        });

        const contextId = data?.contextCreateV2?.id;

        if (contextId) {
          reportSuccess({
            message: `Context "${data.contextCreateV2.name}" was successfully created`,
          });
          trackAnalyticsEvent(`${step} Create Context Click`);
          navigate(`/context/${contextId}`);
        }
      } catch (e) {
        onError(e);
      }
    },
    [saveContextMutation, navigate, onError, reportSuccess, step, trackAnalyticsEvent]
  );

  return [saveContext, isSavingContext];
};
