import { useMemo, useState } from "react";
import JsonView from "@uiw/react-json-view";
import { lightTheme } from "@uiw/react-json-view/light";
import { darkTheme } from "@uiw/react-json-view/dark";
import { Transition } from "react-transition-group";
import cx from "classnames";

import CodeEditor from "components/CodeEditor";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import IconAction from "ds/components/IconAction";
import { MoveLeft } from "components/icons";
import useTypedContext from "hooks/useTypedContext";
import { ThemeContext } from "views/Theme";

import styles from "./styles.module.css";

type BlueprintEditorProps = {
  body: string;
  schema: string;
  onChange: (body?: string) => void;
  readOnly?: boolean;
};

const BlueprintEditor = ({ body, schema, onChange, readOnly }: BlueprintEditorProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { isDarkMode } = useTypedContext(ThemeContext);

  const toggleExpand = () => {
    setIsExpanded((state) => !state);
  };

  const jsonSchema = useMemo(() => {
    try {
      return JSON.parse(schema);
    } catch {
      //do nothing
      return { error: "Invalid schema" };
    }
  }, [schema]);

  const style = useMemo(
    () => ({
      ...(isDarkMode ? darkTheme : lightTheme),
      "--w-rjv-background-color": "transparent",
      "--w-rjv-font-family": "monospace",
      fontSize: "1.2rem",
      fontWeight: 400,
    }),
    [isDarkMode]
  );

  const iconActionKey = `schema_graph_visibility-${String(isExpanded)}`;

  return (
    <Box grow="1" justify="between">
      <Box direction="column" grow="1" zeroMinWidth>
        <CodeEditor body={body} onChange={onChange} language="yaml" readOnly={readOnly} />
      </Box>

      <Transition
        in={isExpanded}
        timeout={{
          appear: 0,
          enter: 200,
          exit: 0,
        }}
      >
        {(state) => (
          <Box
            direction="column"
            zeroMinWidth
            className={cx(styles.editorSchemaWrapper, {
              [styles.collapsed]: state === "exited",
            })}
          >
            <Box
              relative
              fullWidth
              justify="between"
              align="center"
              padding="large x-large"
              className={styles.header}
            >
              <Typography
                variant="p-t6"
                tag="h4"
                className={cx(styles.title, {
                  [styles.hidden]: state !== "entered",
                })}
              >
                Schema graph
              </Typography>

              <IconAction
                className={cx(styles.toggle, {
                  [styles.expanded]: isExpanded,
                })}
                key={iconActionKey}
                icon={MoveLeft}
                onClick={toggleExpand}
                tooltip={isExpanded ? "Collapse" : "Expand"}
              />
            </Box>

            <Box
              grow="0"
              direction="column"
              padding="medium x-large"
              className={cx(styles.jsonWrapper, {
                [styles.hidden]: state !== "entered",
              })}
              zeroMinWidth
            >
              <JsonView
                value={jsonSchema}
                collapsed={2}
                keyName="root"
                displayDataTypes={false}
                enableClipboard={false}
                style={style}
                className={styles.jsonViewer}
              />
            </Box>
          </Box>
        )}
      </Transition>
    </Box>
  );
};

export default BlueprintEditor;
