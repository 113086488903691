import cx from "classnames";
import { memo, ReactNode } from "react";
import { Checkbox, CheckboxProps } from "react-aria-components";

import { FlexAlign } from "types/Flex";

import styles from "./styles.module.css";
import ToggleContent from "./Content";

export type ToggleProps = CheckboxProps & {
  id?: string;
  color?: "default" | "inversed";
  variant: "checkbox" | "switch";
  className?: string;
  children?: ReactNode;
  align?: FlexAlign;
  tooltip?: (isSelected: boolean) => string;
};

/**
 * - Checkbox variant
 *   - Used within forms to indicate boolean state of form fields, requires confirmation by saving the form
 * - Switch variant
 *   - Used to trigger immediate interaction (toggling states)
 *   - Used in forms to hide/show form sections
 */
const Toggle = ({ children, className, color, align, variant, tooltip, ...rest }: ToggleProps) => {
  return (
    <Checkbox {...rest} className={cx(styles.toggleWrapper, className)}>
      {({ isDisabled, isIndeterminate, isSelected }) => (
        <ToggleContent
          color={color}
          align={align}
          variant={variant}
          isDisabled={isDisabled}
          isIndeterminate={isIndeterminate}
          isSelected={isSelected}
          tooltip={tooltip?.(isSelected || isIndeterminate)}
        >
          {children}
        </ToggleContent>
      )}
    </Checkbox>
  );
};

export default memo(Toggle);
