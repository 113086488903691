import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import capitalize from "lodash-es/capitalize";
import Skeleton from "react-loading-skeleton";

import MetricCard from "components/MetricCard";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import EmptyState from "ds/components/EmptyState";
import { CongratsColored, MenuSpaces, StacksColored } from "components/icons";
import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
} from "constants/url_query_keys";
import { StackState } from "types/generated";
import { IconComponent } from "types/Icon";
import TextEllipsis from "ds/components/TextEllipsis";
import MetaInfoListItem from "components/MetaInfoList/Item";
import { AnalyticsPageDashboard } from "hooks/useAnalytics/pages/dashboard";
import useAnalytics from "hooks/useAnalytics";
import MissingDataBanner from "components/MissingDataBanner";

import styles from "./styles.module.css";
import DashboardWidgetsEmptyStateStacks from "../EmptyStateStacks";
import { GET_STACKS_BY_STATE, GET_STACKS_COUNT_BY_STATE } from "./gql";
import DashboardWidgetsStackStateRecentItemsSkeleton from "./RecentItemsSkeleton";
import { GetStacksCountByState, GetStacksByState } from "./types";
import { Widget } from "../../types";
import usePollInterval from "../../usePollInterval";

const SUPPORTED_STATES = [StackState.Failed, StackState.Unconfirmed, StackState.Finished];

const EMPTY_STATES: Partial<Record<StackState, { title: string; icon: IconComponent }>> = {
  [StackState.Failed]: {
    title: "You don’t have any failed stacks!",
    icon: CongratsColored,
  },
  [StackState.Unconfirmed]: {
    title: "You don’t have any unconfirmed stacks!",
    icon: CongratsColored,
  },
  [StackState.Finished]: {
    title: "You don’t have any finished stacks!",
    icon: StacksColored,
  },
};

const LIMIT = 3;

const DashboardWidgetsStackState = () => {
  const pollInterval = usePollInterval();
  const [selectedState, setSelectedState] = useState<StackState | undefined>(StackState.Failed);
  const emptyState = selectedState && EMPTY_STATES[selectedState];

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageDashboard.Dashboard,
    callbackTrackProviders: { segment: true },
  });

  const {
    loading: loadingStacks,
    data: dataStacks,
    stopPolling: stopPollingStacksByState,
    startPolling: startPollingStacksByState,
    refetch: refetchStacksByState,
    error: errorStacksByState,
    networkStatus: networkStatusStacksByState,
  } = useQuery<GetStacksByState>(GET_STACKS_BY_STATE, {
    onError() {
      stopPollingStacksByState();
    },
    variables: {
      states: [selectedState],
    },
    skip: !selectedState,
  });

  const { loading, data, error, networkStatus, stopPolling, startPolling, refetch } =
    useQuery<GetStacksCountByState>(GET_STACKS_COUNT_BY_STATE, {
      onError() {
        stopPolling();
        stopPollingStacksByState();
      },
    });

  const refreshHandler = useCallback(() => {
    refetch();
    refetchStacksByState();
  }, [refetch, refetchStacksByState]);

  const refreshHandlerStacksByState = useCallback(() => {
    refetchStacksByState();
  }, [refetchStacksByState]);

  useEffect(() => {
    if (!error) {
      startPolling(pollInterval);
      startPollingStacksByState(pollInterval);
    }
  }, [error, startPolling, startPollingStacksByState, pollInterval]);

  useEffect(() => {
    if (!errorStacksByState) {
      startPollingStacksByState(pollInterval);
    }
  }, [errorStacksByState, startPollingStacksByState, pollInterval]);

  const isLoading = loading && !data?.metrics;
  const isLoadingStacks = loadingStacks && !dataStacks?.metrics;

  const handleCardSelect = useCallback(
    (value: StackState) => () => {
      trackSegmentAnalyticsEvent("Stack State Widget - Card select", {
        stackState: selectedState === value ? undefined : value,
        visible: String(selectedState !== value),
      });
      setSelectedState(selectedState === value ? undefined : value);
    },
    [selectedState, trackSegmentAnalyticsEvent]
  );

  const stacksCountByState = data?.metrics?.stacksCountByState;

  const metricValueByState: Partial<Record<StackState, number>> =
    stacksCountByState?.reduce(
      (acc, metric) => ({
        ...acc,
        [metric.labels[0]]: metric.value,
      }),
      {}
    ) ?? {};

  const hasStacks = !!dataStacks?.metrics?.hasStacks;
  const hasItems = hasStacks && selectedState && !!dataStacks?.metrics?.recentStacks?.length;

  const showErrorBanner = error || networkStatus === NetworkStatus.refetch;
  const showErrorStacksByStateBanner =
    errorStacksByState || networkStatusStacksByState === NetworkStatus.refetch;

  return (
    <Box direction="column" fullWidth>
      {showErrorBanner && (
        <Box margin="0 0 x-large 0" direction="column" fullWidth>
          <MissingDataBanner
            text="Couldn’t load stacks state data. Please try to refresh or come back later. "
            refreshHandler={refreshHandler}
            refreshLoading={loading}
          />
        </Box>
      )}

      <Box gap="x-large" fullWidth>
        {Object.values(SUPPORTED_STATES).map((value) => (
          <MetricCard
            key={value}
            grow="1"
            selected={selectedState === value}
            onClick={handleCardSelect(value)}
            titleIcon={<div className={cx(styles.dot, styles[value.toLowerCase()])} />}
            titleColor="primary"
            title={capitalize(value)}
            value={
              isLoading ? (
                <Skeleton count={1} height={28} width={40} style={{ margin: "5px 0" }} />
              ) : (
                (metricValueByState[value] ?? 0)
              )
            }
          />
        ))}
      </Box>

      {!showErrorBanner && selectedState && (
        <Box direction="column" margin="large 0 0 0" padding="large 0 0 0">
          <Box justify="between" align="center" grow="1">
            <Typography tag="span" variant="p-t6">
              {`Recently ${selectedState.toLowerCase()}`}
            </Typography>

            {!showErrorStacksByStateBanner && hasItems && (
              <Link
                to={{
                  pathname: `/stacks`,
                  search: `?${URL_FILTER_KEYS_KEY}=state&${URL_FILTER_TYPES_KEY}=ENUM&${URL_FILTER_VALUES_KEY}=${btoa(
                    encodeURIComponent(JSON.stringify([[selectedState.toUpperCase()]]))
                  )}`,
                }}
                analyticsPage={AnalyticsPageDashboard.Dashboard}
                analyticsTitle="Stack State Widget - See all clicked"
                analyticsProps={{
                  stackState: selectedState,
                }}
              >
                See all
              </Link>
            )}
          </Box>

          {showErrorStacksByStateBanner && (
            <Box margin="x-large 0 0 0" direction="column" fullWidth>
              <MissingDataBanner
                text={`Couldn’t load ${selectedState.toLowerCase()} stacks. Please try to refresh or come back later.`}
                refreshHandler={refreshHandlerStacksByState}
                refreshLoading={loadingStacks}
              />
            </Box>
          )}

          {!showErrorStacksByStateBanner && !hasStacks && !isLoadingStacks && (
            <Box margin="x-large 0 0 0" fullWidth>
              <DashboardWidgetsEmptyStateStacks widget={Widget.StacksState} />
            </Box>
          )}
          {!showErrorStacksByStateBanner && (hasStacks || isLoadingStacks) && (
            <Box direction="column" gap="medium" padding="x-large 0 0 0">
              {isLoadingStacks && <DashboardWidgetsStackStateRecentItemsSkeleton />}
              {!isLoadingStacks &&
                hasItems &&
                dataStacks?.metrics?.recentStacks
                  .slice(0, LIMIT)
                  .map(({ name, slug, spaceTile }, i) => (
                    <TileWrapper key={i}>
                      <TileContent direction="row" justify="between" align="center" gap="medium">
                        <TextEllipsis tooltip={name}>
                          {(props) => (
                            <Link
                              listLink
                              analyticsTitle="Stack State Widget - Stack Clicked"
                              analyticsPage={AnalyticsPageDashboard.Dashboard}
                              analyticsProps={{
                                stackState: selectedState,
                              }}
                              variant="secondary"
                              to={`/stack/${slug}`}
                            >
                              <Typography {...props} tag="span" variant="p-body2">
                                {name}
                              </Typography>
                            </Link>
                          )}
                        </TextEllipsis>

                        <Box justify="end" gap="medium" className={styles.spaceLink}>
                          <MetaInfoListItem
                            fullWidth
                            analyticsTitle="Stack State Widget - Space Clicked"
                            analyticsPage={AnalyticsPageDashboard.Dashboard}
                            analyticsProps={{
                              stackState: selectedState,
                            }}
                            to={`/spaces/${spaceTile.slug}`}
                            linkText={spaceTile.name}
                            icon={MenuSpaces}
                          />
                        </Box>
                      </TileContent>
                    </TileWrapper>
                  ))}
              {!isLoadingStacks && !dataStacks?.metrics?.recentStacks?.length && (
                <TileWrapper>
                  <Box padding="small" fullWidth justify="center">
                    <EmptyState icon={emptyState?.icon} caption={emptyState?.title} />
                  </Box>
                </TileWrapper>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DashboardWidgetsStackState;
