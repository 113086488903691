import { memo, useState } from "react";
import cx from "classnames";

import DropdownEllipsis from "ds/components/DropdownEllipsis/New";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Box from "ds/components/Box";

import TableCell from "./Cell";
import styles from "./styles.module.css";

type TableDropdownCellProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
  dropdownActions?: Array<{ title: string; action: () => void }>;
};

const TableDropdownCell = ({
  id,
  children,
  className,
  dropdownActions,
}: TableDropdownCellProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <TableCell
      id={id}
      className={cx(
        dropdownActions && styles.cellInteractive,
        isActive && styles.cellActive,
        className
      )}
    >
      <Box justify="between" align="start" gap="medium">
        <Box>
          <Box direction="column" className={styles.cellContent}>
            {children}
          </Box>
        </Box>

        {dropdownActions && (
          <DropdownEllipsis
            onOpenChange={setIsActive}
            className={styles.cellDropdown}
            tooltip="Cell options"
            dotsSize="small"
          >
            {({ close }) => (
              <DropdownSection>
                {dropdownActions.map(({ title, action }, i) => (
                  <DropdownSectionItem
                    key={i}
                    onClick={() => {
                      action();
                      close();
                    }}
                  >
                    {title}
                  </DropdownSectionItem>
                ))}
              </DropdownSection>
            )}
          </DropdownEllipsis>
        )}
      </Box>
    </TableCell>
  );
};

export default memo(TableDropdownCell);
