import { useMatch } from "react-router-dom-v5-compat";

import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import Box from "ds/components/Box";
import Badge from "ds/components/Badge";

import { NavigationItem } from "./types";

type ViewHeaderNavigationResizeItemProps<T> = {
  item: NavigationItem;
  sharedState?: T;
};

const ViewHeaderNavigationResizeItem = <T,>({
  item,
  sharedState,
}: ViewHeaderNavigationResizeItemProps<T>) => {
  const match = useMatch(item.link);

  const isActive = !!match?.pattern.end;
  const to = sharedState ? { pathname: item.link, state: sharedState } : item.link;

  return (
    <DropdownSectionItem key={item.link} active={isActive} to={to}>
      {item.beta ? (
        <Box gap="medium" align="center">
          {item.title}
          <Badge size="small" state="success">
            BETA
          </Badge>
        </Box>
      ) : (
        item.title
      )}
    </DropdownSectionItem>
  );
};

export default ViewHeaderNavigationResizeItem;
