import Link from "ds/components/LinkNew";
import { RunIgnoredTrigger, VcsProvider } from "types/generated";
import { getCommmitDigitsCount } from "utils/commit";

type StackRunsIgnoredRunCalloutCommitLinkProps = {
  ignoredRun: RunIgnoredTrigger;
  vcsProvider: VcsProvider;
};
const StackRunsIgnoredRunCalloutCommitLink = ({
  ignoredRun,
  vcsProvider,
}: StackRunsIgnoredRunCalloutCommitLinkProps) => (
  <Link
    variant="secondary"
    size="inherit"
    href={ignoredRun.commit.url}
    rel="noopener noreferrer"
    target="_blank"
  >
    {ignoredRun.commit.hash.slice(0, getCommmitDigitsCount(vcsProvider))}
  </Link>
);

export default StackRunsIgnoredRunCalloutCommitLink;
