import { RunState, RunType } from "types/generated";

export const DEFAULT_COLOR = "var(--semantic-color-surface-interactive-weak)";

// FYI this map contains only run states that are presented in the UI
export const RUN_STATE_COLOR_MAP: Partial<Record<RunState, string>> = {
  [RunState.Queued]: "#D948EF",
  [RunState.Initializing]: "#6EA41B",
  [RunState.Planning]: "#EC4699",
  [RunState.Unconfirmed]: "#F26B0A",
  [RunState.Preparing]: "#1D4FD7",
  [RunState.Ready]: "#49790B",
  [RunState.Confirmed]: "#BF185D",
  [RunState.Applying]: "#7E22CE",
  [RunState.PreparingApply]: "#0F756D",
  [RunState.Destroying]: "#C03F0C",
  [RunState.PendingReview]: "#A31DAF",
  [RunState.Performing]: "#3479E9",
  [RunState.PreparingReplan]: "#05A794",
  [RunState.ReplanRequested]: "#A855F7",
};

export const RUN_TYPE_COLOR_MAP: Record<RunType, string> = {
  [RunType.Proposed]: "#D948EF",
  [RunType.Tracked]: "#F26B0A",
  [RunType.Task]: "#3479E9",
  [RunType.Testing]: "#6EA41B",
  [RunType.Destroy]: "#A855F7",
  [RunType.Parse]: "#05A794",
  [RunType.Unknown]: DEFAULT_COLOR,
};

export const URL_GROUP_BY_KEY = "groupBy";
