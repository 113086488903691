import { useCallback, useState } from "react";

import FullScreenModal from "ds/components/FullScreenModal";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";
import { showSimpleLeaveConfirmation } from "ds/components/LeaveConfirmationModal/Simple";

import useCloseFullScreenModal from "../useCloseFullScreenModal";
import VCSIntegrationsManageBitbucketDataCenterForm from "./Form";

const VCSIntegrationsManageBitbucketDataCenter = () => {
  const [isDirty, setIsDirty] = useState(false);

  const { handleOnClose } = useCloseFullScreenModal();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const modalCloseClickHandler = useCallback(async () => {
    if (isDirty) {
      await showSimpleLeaveConfirmation();
    }

    handleOnClose();
    trackSegmentAnalyticsEvent("Bitbucket DC - Exit");
  }, [handleOnClose, trackSegmentAnalyticsEvent, isDirty]);

  const toggleIsDirty = useCallback((value: boolean) => {
    setIsDirty(value);
  }, []);

  return (
    <FullScreenModal
      title="Set up Bitbucket Data Center integration"
      onClose={modalCloseClickHandler}
    >
      <VCSIntegrationsManageBitbucketDataCenterForm onIsDirtyChange={toggleIsDirty} />
    </FullScreenModal>
  );
};

export default VCSIntegrationsManageBitbucketDataCenter;
