import { RunReview, RunReviewDecision } from "types/generated";
import Box from "ds/components/Box";
import { TreeBranch, TreeBranchLink } from "ds/components/Tree";

import Separator from "../../components/HistoryEntryWrapper/Separator";
import HistoryEntryDate from "../../components/HistoryEntryDate";
import HistoryEntryNote from "../../components/HistoryEntryNote";
import HistoryEntryLabel from "../../components/HistoryEntryLabel";

type ReviewProps = {
  review: RunReview;
};

function Review({ review }: ReviewProps) {
  return (
    <TreeBranch align="start">
      <TreeBranchLink>
        <Box gap="medium" align="center" padding="medium 0">
          <HistoryEntryLabel>
            <i>{review.author}</i>{" "}
            {review.decision === RunReviewDecision.Approve ? "approved" : "rejected"}
          </HistoryEntryLabel>

          <Separator margin="0" />

          <HistoryEntryDate timestamp={review.timestamp} />
        </Box>
      </TreeBranchLink>

      {review.note && <HistoryEntryNote margin="0 0 medium">{review.note}</HistoryEntryNote>}
    </TreeBranch>
  );
}

export default Review;
