import { Controller, useFormContext } from "react-hook-form";

import SegmentedControlFormField from "ds/components/SegmentedControlFormField";
import Typography from "ds/components/Typography";
import { SegmentedControlOption } from "ds/components/SegmentedControl";
import { BillingTierFeature, VcsProvider } from "types/generated";
import { HUMANIZE_PROVIDER } from "constants/vcs_providers";
import FormFieldSpace from "components/FormFields/Space";
import useTierFeature from "views/account/hooks/useTierFeature";
import TierInfo from "components/TierInfo";
import Box from "ds/components/Box";
import BadgeNext from "ds/components/BadgeNext";
import { Diamond } from "components/icons";

import { VCS_INTEGRATION_TYPES } from "../constants";

const getIntegrationSegments = (
  isDefaultOptionDisabled: boolean,
  provider: VcsProvider,
  isMultiVCSFeatureEnabled: boolean
): SegmentedControlOption[] => [
  {
    label: "Default (all spaces)",
    value: VCS_INTEGRATION_TYPES.DEFAULT,
    disabled: isDefaultOptionDisabled,
    tooltip:
      (isDefaultOptionDisabled && (
        <>
          A default <b>{HUMANIZE_PROVIDER[provider]}</b> source code integration already exists.
          Only one default source code integration of a given type can exist in an account. If you
          wish to change the settings of the default integration, please edit the existing default
          integration. Alternatively, you will need to delete the current default integration before
          you're able to create a new one.
        </>
      )) ||
      undefined,
  },
  {
    label: (
      <Box gap="medium" align="center">
        Space-specific
        {!isMultiVCSFeatureEnabled && (
          <BadgeNext type="strong" variant="purple" startIcon={Diamond} iconOnly />
        )}
      </Box>
    ),
    value: VCS_INTEGRATION_TYPES.MULTIPLE,
  },
];

type VCSIntegrationTypeFieldControlProps = {
  noMargin?: boolean;
  hasAlreadyDefaultIntegration: boolean;
  loading: boolean;
  provider: VcsProvider;
};

const VCSIntegrationTypeFieldControl = ({
  noMargin,
  hasAlreadyDefaultIntegration,
  loading,
  provider,
}: VCSIntegrationTypeFieldControlProps) => {
  const { control, watch, setValue } = useFormContext();
  const isMultiVCSAllowed = useTierFeature(BillingTierFeature.MultipleVcs);

  const integrationTypeValue = watch("integrationType");

  const handleIntegrationTypeChange = (value: string) => {
    setValue("integrationType", value, { shouldValidate: true });
  };

  return (
    <SegmentedControlFormField
      label="Integration type"
      tooltipInfo="Choose whether the integration is available within all spaces or within a specific space only. You can only have one default integration per source code provider."
      tooltipWidthMode="maxWidthSm"
      value={integrationTypeValue}
      segments={getIntegrationSegments(
        hasAlreadyDefaultIntegration && !loading,
        provider,
        isMultiVCSAllowed
      )}
      onChange={handleIntegrationTypeChange}
      noMargin={noMargin}
    >
      {integrationTypeValue === VCS_INTEGRATION_TYPES.DEFAULT && (
        <Typography tag="p" variant="p-body3" color="secondary" align="center">
          You are about to create a default integration. You will be able to attach this integration
          to any stack.
        </Typography>
      )}

      {integrationTypeValue === VCS_INTEGRATION_TYPES.MULTIPLE && (
        <>
          {isMultiVCSAllowed ? (
            <FormFieldSpace noMargin />
          ) : (
            <Controller
              name="space"
              control={control}
              // That's how we mimic the required validation for the space field
              rules={{ required: "Space field is required." }}
              render={() => (
                <TierInfo
                  feature={BillingTierFeature.MultipleVcs}
                  title="Concurrent VCS integrations"
                  variant="promo"
                  type="banner"
                >
                  Upgrade your plan to benefit from multiple concurrent VCS integrations of the same
                  type.
                </TierInfo>
              )}
            />
          )}
        </>
      )}
    </SegmentedControlFormField>
  );
};

export default VCSIntegrationTypeFieldControl;
