import { ReactNode } from "react";

import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import Button from "ds/components/Button";
import Box from "ds/components/Box";
import IconAction from "ds/components/IconAction";
import { Info } from "components/icons";
import ListContentWrapper from "components/ListContentWrapper";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";

import UserManagementWarningCallout from "../UserManagementWarningCallout";
import UserManagementActivationBadge from "../UserManagementActivationBadge";

type UsersPageLayoutProps = {
  children: ReactNode;
  openInviteDrawer?: () => void;
  openInfoDrawer: () => void;
};

const UsersPageLayout = ({ children, openInviteDrawer, openInfoDrawer }: UsersPageLayoutProps) => {
  return (
    <>
      <UserManagementWarningCallout analyticsPage={AnalyticsPageOrganization.OrganizationUsers} />
      <ViewHeader firstLevel>
        <Box direction="row" align="center" __deprecatedGap="1.1rem">
          <ViewHeaderTitle tag="h2">Users</ViewHeaderTitle>
          <IconAction onClick={openInfoDrawer} icon={Info} tooltip="Open info drawer" />
          <UserManagementActivationBadge />
        </Box>

        <ViewHeaderWrapper direction="row">
          {openInviteDrawer && (
            <Button onClick={openInviteDrawer} variant="primary">
              Invite user
            </Button>
          )}
        </ViewHeaderWrapper>
      </ViewHeader>
      <ListContentWrapper>{children}</ListContentWrapper>
    </>
  );
};

export default UsersPageLayout;
