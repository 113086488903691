import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isThisYear from "date-fns/isThisYear";

import Tooltip from "ds/components/Tooltip";
import Typography from "ds/components/Typography";

type HistoryEntryDateProps = {
  timestamp: number;
};

const HistoryEntryDate = ({ timestamp }: HistoryEntryDateProps) => {
  const timestampMs = timestamp * 1000;
  const shouldShowYear = !isThisYear(timestampMs);
  const finalFormat = shouldShowYear ? "MMM do, yyyy, pp" : "MMM do, pp";

  return (
    <Tooltip
      on={(props) => (
        <Typography {...props} tag="span" variant="p-body3" color="secondary">
          {format(timestampMs, finalFormat)}
        </Typography>
      )}
    >
      {formatDistanceToNow(timestampMs, { addSuffix: true, includeSeconds: true })}
    </Tooltip>
  );
};

export default HistoryEntryDate;
