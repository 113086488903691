import Box from "ds/components/Box";
import Button from "ds/components/Button";
import { ErrorColored } from "components/icons";
import PageWrapper from "components/PageWrapper";
import Typography from "ds/components/Typography";

import styles from "./styles.module.css";

const NotFoundPage = () => (
  <PageWrapper className={styles.wrapper}>
    <Box align="center" justify="center" direction="column" grow="1">
      <ErrorColored className={styles.icon} />
      <Typography variant="p-t4" tag="h3">
        Page not found
      </Typography>
      <Typography variant="p-body2" tag="p" className={styles.description}>
        Sorry, we couldn't find what you're looking for. If you're certain something should be here,
        you may try reloading the page. You can also try going back to the home page.
      </Typography>
      <Button variant="secondary" size="medium" to="/">
        Go to Homepage
      </Button>
    </Box>
  </PageWrapper>
);

export default NotFoundPage;
