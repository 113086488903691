import { gql, TypedDocumentNode } from "@apollo/client";

import { MutationRunPrioritizeSetArgs, Run } from "types/generated";

export type FetchRunSetPrioritizeData = { runPrioritizeSet: Pick<Run, "id" | "isPrioritized"> };
export const RUN_SET_PRIORITIZE: TypedDocumentNode<
  FetchRunSetPrioritizeData,
  MutationRunPrioritizeSetArgs
> = gql`
  mutation RunPrioritizeSet($stack: ID!, $run: ID!, $prioritize: Boolean!) {
    runPrioritizeSet(stack: $stack, run: $run, prioritize: $prioritize) {
      id
      isPrioritized
    }
  }
`;
