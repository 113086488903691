import { gql } from "@apollo/client";

export const DELETE_POLICY = gql`
  mutation DeletePolicy($id: ID!) {
    policyDelete(id: $id) {
      id
      name
    }
  }
`;

export const SEARCH_POLICIES = gql`
  query SearchPolicies($input: SearchInput!) {
    searchPolicies(input: $input) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          type
          labels
          createdAt
          updatedAt
          body
          description
          spaceDetails {
            id
            name
            accessLevel
          }
          hasAttachedStacks
        }
      }
    }
  }
`;

export const SEARCH_POLICIES_SUGGESTIONS = gql`
  query SearchPoliciesSuggestions($input: SearchSuggestionsInput!) {
    searchPoliciesSuggestions(input: $input) {
      filteredCount
      fields {
        name
        orderable
        filterable
        type
        possibleValuesBoolean {
          values
          counts
        }
        possibleValuesEnum {
          values
          counts
        }
        possibleValuesString {
          values
          counts
        }
        possibleValuesHierarchy {
          values {
            id
            parentId
            displayValue
          }
          counts
        }
      }
    }
  }
`;
