import { Copy } from "components/icons";
import IconAction, { IconActionProps } from "ds/components/IconAction";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type CopyFieldIconProps = {
  title: string;
  value: string;
  callback?: () => void;
} & Pick<IconActionProps, "disabled" | "disableTooltipPortal" | "className">;

const CopyFieldIcon = ({ title, value, callback, ...restProps }: CopyFieldIconProps) => {
  const handleCopy = useCopyToClipboard(value, callback);

  return <IconAction {...restProps} onClick={handleCopy} icon={Copy} tooltip={title} />;
};

export default CopyFieldIcon;
