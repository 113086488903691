import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";

import CardWrapper from "components/CardWrapper";
import AccountsField from "views/account/NewModule/Share/AccountsField";
import Box from "ds/components/Box";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";

import { useUpdateModule } from "../hooks/useUpdateModule";
import { ModuleSettingsContext } from "../Context";
import { getDefaultValues } from "./utils";

type FormValues = {
  sharedAccounts: Array<{ value: string }>;
};

const ModuleSettingsSharingEditForm = () => {
  const { module } = useTypedContext(ModuleSettingsContext);
  const { updateModule, loading } = useUpdateModule({ module });

  const builderForm = useForm<FormValues>({
    defaultValues: getDefaultValues(module),
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty, isSubmitSuccessful },
    reset,
  } = builderForm;

  const onSubmit = (values: FormValues) => {
    const sharedAccounts = values.sharedAccounts
      .map(({ value }) => value)
      .filter((value) => value.trim() !== "");

    return updateModule({
      sharedAccounts,
    });
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getDefaultValues(module));
    }
  }, [isSubmitSuccessful, module, reset]);

  return (
    <>
      <FormProvider {...builderForm}>
        <CardWrapper variant="filled">
          <AccountsField />
        </CardWrapper>
      </FormProvider>
      <Box justify="end" margin="large 0 0">
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isDirty || loading}
          loading={loading}
        >
          Save
        </Button>
      </Box>
    </>
  );
};

export default ModuleSettingsSharingEditForm;
