import { useModal } from "@ebay/nice-modal-react";

import Modal from "../ModalNew";
import ModalHeader from "../ModalNew/Header";
import ModalContent from "../ModalNew/Content";
import ModalFooter from "../ModalNew/Footer";
import ModalCancelButton from "../ModalNew/CancelButton";
import ConfirmationModalConfirmButton from "./ConfirmButton";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal = ({
  title = "Please confirm",
  confirmationButtonLabel = "Confirm",
  confirmationButtonVariant,
  children,
  onConfirm,
  onClose,
  isLoading,
  isDismissable,
  isConfirmationDisabled,
  size,
  shouldIgnoreOutsideClick,
}: ConfirmationModalProps) => {
  const modal = useModal();

  const handleClose = () => {
    onClose?.();
    modal.hide();
    modal.resolve();
  };

  return (
    <Modal
      shouldIgnoreOutsideClick={shouldIgnoreOutsideClick}
      size={size}
      role="alertdialog"
      isOpen={modal.visible}
      onClose={handleClose}
      onExit={modal.remove}
      isDismissable={isDismissable}
    >
      <ModalHeader title={title} />
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        <ModalCancelButton />
        <ConfirmationModalConfirmButton
          label={confirmationButtonLabel}
          variant={confirmationButtonVariant}
          onClick={onConfirm}
          isLoading={isLoading}
          isDisabled={isConfirmationDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
