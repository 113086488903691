import ViewHeaderTitle from "components/ViewHeader/Title";
import ViewHeader from "components/ViewHeader";
import Box from "ds/components/Box";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileContent from "ds/components/Tile/Content";
import TileTitle from "ds/components/Tile/Title";
import IconTile from "ds/components/IconTile";
import { Rocket, Documentation } from "components/icons";
import Typography from "ds/components/Typography";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageLaunchpad } from "hooks/useAnalytics/pages/launchpad";
import useTitle from "hooks/useTitle";
import FeedbackButton from "components/FeedbackButton";
import { getDocsUrl } from "utils/getDocsUrl";

import styles from "./styles.module.css";
import LaunchPadKnowledgeBase from "./KnowledgeBase";
import LaunchPadGetStartedChecklist from "./GetStartedChecklist";
import LaunchPadFAQ from "./FAQ";
import LaunchPadVideos from "./Videos";
import LaunchPadExploreNext from "./ExploreNext";

const LaunchPad = () => {
  useTitle("Spacelift LaunchPad");

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageLaunchpad.Launchpad,

    callbackTrackProviders: { segment: true },
  });

  const goToDocs = () => {
    trackSegmentAnalyticsEvent("Docs Click");
    window.open(getDocsUrl(), "_blank");
  };

  return (
    <>
      <ViewHeader>
        <ViewHeaderTitle titleSize="p-t3">
          <Box align="center" gap="medium">
            Welcome to LaunchPad
          </Box>
        </ViewHeaderTitle>
      </ViewHeader>
      <Box grid gridTemplate="3.2fr minmax(28.1rem, 1fr)" fullWidth>
        <Box className={styles.mainContainer} direction="column" gap="x-large">
          <LaunchPadGetStartedChecklist />
          <LaunchPadExploreNext />
          <LaunchPadKnowledgeBase />
          <LaunchPadVideos />
          <LaunchPadFAQ />
        </Box>
        <Box
          className={styles.sideContainer}
          padding="large"
          gap="large"
          direction="column"
          justify="start"
          align="center"
        >
          <Box fullWidth>
            <TileWrapper className={styles.feedback}>
              <TileContent direction="column">
                <Box justify="between" fullWidth margin="0 0 small 0">
                  <IconTile variant="neutral" icon={Rocket} />
                  <FeedbackButton id="pendo-launchpad-feedback" />
                </Box>
                <TileTitle>Feedback</TileTitle>
                <Typography tag="p" variant="p-body2">
                  Help us craft experience based on your needs by providing feedback.
                </Typography>
              </TileContent>
            </TileWrapper>
          </Box>
          <Box fullWidth>
            <TileWrapper onClick={goToDocs}>
              <TileContent direction="row" align="center">
                <IconTile icon={Documentation} />
                <TileTitle>See full documentation</TileTitle>
              </TileContent>
            </TileWrapper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LaunchPad;
