import { FunctionComponent } from "react";

import useTypedContext from "hooks/useTypedContext";
import Box from "ds/components/Box";
import IconTile from "ds/components/IconTile";
import TileWrapper from "ds/components/Tile/Wrapper";
import TileTitle from "ds/components/Tile/Title";
import TileDescription from "ds/components/Tile/Description";
import BadgeNext from "ds/components/BadgeNext";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageStack } from "hooks/useAnalytics/pages/stack";

import { StackFormContext } from "../context";
import { StackCreationWizardStep } from "../types";
import useStackCreationAnalyticsVersion from "../useStackCreationAnalyticsVersion";

type NewStackIntermediateConfirmationStepTileProps = {
  hasCopiedConfiguration: boolean;
  icon: FunctionComponent;
  title: string;
  description: string;
  action: {
    step: StackCreationWizardStep;
    title: string;
  };
};

const NewStackIntermediateConfirmationStepTile = ({
  hasCopiedConfiguration,
  icon: Icon,
  title,
  description,
  action,
}: NewStackIntermediateConfirmationStepTileProps) => {
  const trackSegmentEvent = useAnalytics({
    page: AnalyticsPageStack.StackNew,
    callbackTrackProviders: { segment: true },
  });

  const { setCurrentStep, setIsIntermediateStepActive } = useTypedContext(StackFormContext);
  const analyticsVersion = useStackCreationAnalyticsVersion();
  const actionHandler = () => {
    setIsIntermediateStepActive(false);
    setCurrentStep(action.step);
    trackSegmentEvent("Intermediate step tile click", {
      step: action.step,
      version: analyticsVersion,
    });
  };

  const isDefined =
    hasCopiedConfiguration &&
    (action.step === StackCreationWizardStep.Behaviour ||
      action.step === StackCreationWizardStep.Hooks);

  return (
    <TileWrapper onClick={actionHandler}>
      <Box align="start" justify="between" fullWidth>
        <Box align="center" grow="1" gap="medium">
          <IconTile icon={Icon} />

          <Box direction="column" gap="small">
            <TileTitle variant="p-t7">{title}</TileTitle>
            <TileDescription>{description}</TileDescription>
          </Box>
        </Box>
        {isDefined && <BadgeNext variant="green" type="strong" text="DEFINED" />}
      </Box>
    </TileWrapper>
  );
};

export default NewStackIntermediateConfirmationStepTile;
