import { useCallback, useState } from "react";

import { ChevronNew } from "components/icons";
import ControlledInfiniteList from "components/InfiniteList/Controlled";
import ButtonNew from "ds/components/Button/New";
import DropdownNew from "ds/components/Dropdown/New";
import DropdownSection from "ds/components/Dropdown/Section";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useAnalytics from "hooks/useAnalytics";

import styles from "./styles.module.css";
import useModuleVersions from "./useModuleVersions";

type VersionDropdownProps = {
  moduleId: string;
  versionNumber: string;
  isLatest: boolean;
};

const VersionDropdown = ({ moduleId, versionNumber, isLatest }: VersionDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { versions, handleLoadMore, hasMore, loading } = useModuleVersions(moduleId);

  const trackSegmentAnalyticsEvent = useAnalytics({
    callbackTrackProviders: { segment: true },
  });

  const handleOpenChange = useCallback(
    (newValue: boolean) => {
      setIsOpen(newValue);

      if (newValue) {
        trackSegmentAnalyticsEvent("Module Registry - Version Switcher Dropdown Opened", {
          count: versions.length,
        });
      }
    },
    [trackSegmentAnalyticsEvent, versions.length]
  );

  const handleVersionClick = useCallback(() => {
    trackSegmentAnalyticsEvent("Module Registry - Version Switcher Dropdown Clicked");
  }, [trackSegmentAnalyticsEvent]);

  return (
    <DropdownNew
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      triggerComponent={
        <ButtonNew
          variant="secondary"
          active={isOpen}
          disabled={loading}
          endIcon={ChevronNew}
          endIconRotate="90"
        >
          {`Version: ${versionNumber}${isLatest && " (latest)"}`}
        </ButtonNew>
      }
    >
      <ControlledInfiniteList onLoadMore={handleLoadMore} hasMore={hasMore} loading={loading}>
        {(onScroll) => (
          <DropdownSection onScroll={onScroll} className={styles.valuesList} scrollable>
            {versions.length === 0 && (
              <DropdownSectionItem disabled>No other version</DropdownSectionItem>
            )}
            {versions.length > 0 &&
              versions.map((item) => (
                <DropdownSectionItem
                  key={item.id}
                  to={`/module/${moduleId}/version/${item.id}`}
                  active={item.number === versionNumber}
                  v5Compat
                  onClick={handleVersionClick}
                  preventDefaultOnClick={false}
                >
                  {item.number}
                </DropdownSectionItem>
              ))}
          </DropdownSection>
        )}
      </ControlledInfiniteList>
    </DropdownNew>
  );
};

export default VersionDropdown;
