import { ReactNode } from "react";

import useTypedContext from "hooks/useTypedContext";
import IconAction from "ds/components/IconAction";
import { Pencil } from "components/icons";
import CollapsibleList from "components/CollapsibleList";

import { ContextFormContext } from "../context";
import { ContextCreationWizardStep } from "../constants";

type NewContextSummaryPanelProps = {
  name: string;
  step: ContextCreationWizardStep;
  children: ReactNode;
  goToStepDisabled?: boolean;
  count?: number;
};

const NewContextSummaryPanel = ({
  name,
  step,
  children,
  count,
  goToStepDisabled,
}: NewContextSummaryPanelProps) => {
  const { setCurrentStep } = useTypedContext(ContextFormContext);

  const goToStep = () => {
    setCurrentStep(step);
  };

  return (
    <CollapsibleList
      ariaLevel={2}
      title={name}
      count={count}
      initialIsCollapsed={false}
      action={
        <IconAction
          disabled={goToStepDisabled}
          icon={Pencil}
          onClick={goToStep}
          tooltip="Edit step"
        />
      }
    >
      {children}
    </CollapsibleList>
  );
};

export default NewContextSummaryPanel;
