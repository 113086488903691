import { AccountType, VcsCheck } from "types/generated";
import { VCS_INTEGRATION_TYPES } from "views/account/VCS/constants";

export enum VCSIntegrationsManageGitHubEnterpriseWizardSteps {
  STEP_INTRO = "STEP_INTRO",
  STEP_INTEGRATION = "STEP_INTEGRATION",
  STEP_OWNER = "STEP_OWNER",
  STEP_CREATE_APP = "STEP_CREATE_APP",
}

export enum ManageGitHubEnterpriseWizardIntegration {
  GITHUB = "GITHUB",
  SELF_HOSTED = "SELF_HOSTED",
}

export type ManageGitHubEnterpriseWizardFormFields = {
  integration: ManageGitHubEnterpriseWizardIntegration;
  host: string;
  apiHost: string;
  ownerName: string;
  ownerType: AccountType;
};

export type ManageGitHubEnterpriseWizardFinalDetailsFormFields = {
  name: string;
  labels: Record<"value", string>[];
  space: string;
  description?: string;
  integrationType: VCS_INTEGRATION_TYPES;
  vcsChecks: VcsCheck;
};
