import { ReactNode } from "react";

import Tooltip from "ds/components/Tooltip";
import { TooltipReferenceProps } from "ds/components/Tooltip/types";
import { BillingTierFeature } from "types/generated";
import useTierFeature from "views/account/hooks/useTierFeature";

import { BlueprintActions } from "./types";

type BlueprintsFeatureGateTooltipProps = {
  on: (props: TooltipReferenceProps & { isDisabled: boolean }) => ReactNode;
  action: BlueprintActions;
};

export const getBlueprintFeatureGatingMessage = (
  action: BlueprintsFeatureGateTooltipProps["action"]
) => {
  switch (action) {
    case BlueprintActions.CreateBlueprint:
      return "Feature is not supported by your plan. Upgrade to the higher plan to be able to create Blueprints.";
    case BlueprintActions.CreateStack:
      return "Blueprints are not supported by your plan. You may still preview or delete the blueprint that you created but you can not use it to create stacks.";
    case BlueprintActions.Edit:
      return "Feature is not supported by your plan. Upgrade to the higher plan to be able to edit the Blueprint.";
    case BlueprintActions.Publish:
      return "Feature is not supported by your plan. Upgrade to the higher plan to be able to publish the Blueprint.";
  }
};

const BlueprintsFeatureGateTooltip = ({ on, action }: BlueprintsFeatureGateTooltipProps) => {
  const isFeatureActive = useTierFeature(BillingTierFeature.Blueprints);

  return (
    <Tooltip
      on={(props) => on({ ...props, isDisabled: !isFeatureActive })}
      active={!isFeatureActive}
      widthMode="maxWidthSm"
    >
      {getBlueprintFeatureGatingMessage(action)}
    </Tooltip>
  );
};

export default BlueprintsFeatureGateTooltip;
