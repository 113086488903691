import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import cx from "classnames";

import Box from "ds/components/Box";
import Button from "ds/components/Button";
import FlashContext from "components/FlashMessages/FlashContext";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import { LayoutContext } from "components/layout/Context";
import Select from "ds/components/Select";
import useTypedContext from "hooks/useTypedContext";
import { IdentityProvider, SpaceAccessLevel } from "types/generated";
import Tooltip from "ds/components/Tooltip";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Typography from "ds/components/Typography";
import Link from "ds/components/Link";
import { validateEmail } from "utils/formValidators";
import { AccountContext } from "views/AccountWrapper";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { getDocsUrl } from "utils/getDocsUrl";

import { UserManagementActivationStatus } from "../../types";
import { showInviteSuccessModal } from "../InviteSuccessModal";
import useInviteUser from "./useInviteUser";
import { InviteUserFields } from "./types";
import styles from "./styles.module.css";
import { SettingsContext } from "../../Context";
import { getManagementStrategy } from "../../helpers";

const spacesAccessRoles = [
  { name: "Admin", id: SpaceAccessLevel.Admin },
  { name: "User", id: SpaceAccessLevel.Read },
];

type UsersInviteFormProps = {
  usersCount: number;
  activeUsersCount: number;
};

const UsersInviteForm = ({ usersCount, activeUsersCount }: UsersInviteFormProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { isExpandedMode } = useTypedContext(LayoutContext);
  const { viewer } = useTypedContext(AccountContext);
  const { onError } = useTypedContext(FlashContext);
  const { onCreate, loading } = useInviteUser();

  const rolesSelectOptions = spacesAccessRoles.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const userInviteForm = useForm<InviteUserFields>({
    defaultValues: {
      userName: "",
      email: "",
      role: SpaceAccessLevel.Read,
    },
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
    getValues,
  } = userInviteForm;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
      role: getValues("role"),
      usersCount,
      activeUsersCount,
    },
  });

  const handleFormSubmit: SubmitHandler<InviteUserFields> = (formData) => {
    trackSegmentAnalyticsEvent("Callout Invite Click");

    const input = {
      // username is either username field value or email field if username field is empty
      username: formData.userName || formData.email,
      email: formData.email,
      invitationEmail: formData.email,
      slackMemberID: null,
      accessRules: [{ space: "root", spaceAccessLevel: formData.role as SpaceAccessLevel }],
    };
    onCreate(input)
      .then(({ data }) => {
        if (data) {
          reset();
          showInviteSuccessModal({ activationStatus });
        }
      })
      .catch(onError);
  };

  const isGithubUser = viewer.identityProvider === IdentityProvider.Github;

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  const hasErrors = errors && !!Object.keys(errors).length;

  return (
    <FormProvider {...userInviteForm}>
      <Box
        align="center"
        justify="center"
        gap="medium"
        className={cx(styles.wrapper, {
          [styles.wrapperExpanded]: isExpandedMode,
          [styles.errors]: hasErrors,
        })}
      >
        {isGithubUser && (
          <FormField
            label="Username"
            error={errors?.userName?.message}
            className={styles.formField}
            tooltipInfoVariant="modal"
            tooltipInfo={
              <TooltipModalBody align="start">
                <Typography tag="p" variant="p-body3">
                  Provide GitHub username
                </Typography>
              </TooltipModalBody>
            }
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter GitHub username"
                className={styles.input}
                error={!!errors?.userName}
                {...register("userName", {
                  required: "Username field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        )}
        <FormField
          label="Email"
          error={errors?.email?.message}
          noMargin
          className={styles.formField}
          tooltipInfoVariant="modal"
          tooltipInfo={
            <TooltipModalBody align="start">
              <Typography tag="p" variant="p-body3">
                The e-mail will be used to send the invite. We won't use it for any other purposes
                and we'll delete it once user management is fully activated.
              </Typography>
            </TooltipModalBody>
          }
        >
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter email"
              className={styles.input}
              error={!!errors?.email}
              {...register("email", {
                required: "Email field is required.",
                validate: validateEmail,
              })}
              {...ariaInputProps}
            />
          )}
        </FormField>
        <Controller
          name="role"
          control={control}
          rules={{ required: "Role field is required." }}
          render={({ field, fieldState }) => (
            <FormField
              label="Role"
              tooltipInfoVariant="modal"
              tooltipInfo={
                <>
                  <TooltipModalTitle>Choose access type</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    <Typography tag="p" variant="p-body3">
                      <Typography tag="span" variant="p-t7">
                        User
                      </Typography>{" "}
                      - has reader role on the root space
                      <br />
                      <Typography tag="span" variant="p-t7">
                        Admin
                      </Typography>{" "}
                      - has admin role on the root space
                      <br />
                      <br />
                      The role will be assigned globally
                    </Typography>
                    <Link href={getDocsUrl("/concepts/user-management/admin#role")} target="_blank">
                      Learn more
                    </Link>
                  </TooltipModalBody>
                </>
              }
              className={styles.roleInput}
              noMargin
              error={fieldState.error?.message}
            >
              {({ ariaInputProps }) => (
                <Select
                  value={field.value}
                  options={rolesSelectOptions}
                  onChange={field.onChange}
                  error={!!fieldState.error?.message}
                  ariaInputProps={ariaInputProps}
                />
              )}
            </FormField>
          )}
        />

        {isUserManagementActive ? (
          <Button
            variant="primary"
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!isValid}
            loading={loading}
            className={styles.button}
          >
            Send invite
          </Button>
        ) : (
          <Tooltip
            on={(props) => (
              <Button
                variant="primary"
                onClick={handleSubmit(handleFormSubmit)}
                disabled={!isValid}
                loading={loading}
                className={styles.button}
                {...props}
              >
                Prepare invite
              </Button>
            )}
          >
            Invites will be sent once User management is set to active
          </Tooltip>
        )}
      </Box>
    </FormProvider>
  );
};

export default UsersInviteForm;
