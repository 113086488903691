import FlashContext from "components/FlashMessages/FlashContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import ModalConfirmation from "components/ModalConfirmation";
import Typography from "ds/components/Typography";
import useTypedContext from "hooks/useTypedContext";
import { ManagedUserGroup } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { getManagementStrategy } from "views/account/settings/helpers";
import { SettingsContext } from "views/account/settings/Context";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";
import useDeleteGroup from "./useDeleteGroup";

type IdpGroupMappingListDropdownProps = {
  handleGroupDetailsClick: (details: ManagedUserGroup) => unknown;
  item: ManagedUserGroup;
};

const IdpGroupMappingListDropdown = ({
  handleGroupDetailsClick,
  item,
}: IdpGroupMappingListDropdownProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);
  const { onDelete } = useDeleteGroup();

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationIdpGroupMapping,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const handleRemoveGroup = () => {
    trackSegmentAnalyticsEvent("Delete confirm");
    onDelete(item.id)
      .then(({ data }) => {
        if (data) {
          reportSuccess({
            message: `Group access was deleted`,
          });
        }
      })
      .catch(onError);
  };

  const getOnDeleteClick = (callback: () => void) => () => {
    trackSegmentAnalyticsEvent("Delete click");
    callback();
  };

  return (
    <div className={styles.wrapper}>
      <DropdownEllipsis dotsSize="small" tooltip="Group actions">
        {({ closeDropdown }) => (
          <DropdownSection>
            <DropdownSectionItem onClick={() => handleGroupDetailsClick(item)}>
              Access details
            </DropdownSectionItem>
            <ModalConfirmation
              title="Delete group access"
              triggerComponent={
                <DropdownSectionItem danger onClick={getOnDeleteClick(closeDropdown)}>
                  Delete
                </DropdownSectionItem>
              }
              confirmCallback={() => handleRemoveGroup()}
              confirmVariant="dangerPrimary"
              confirmText="Delete"
            >
              <Typography variant="p-body2" tag="p">
                Are you sure you want to delete {item.groupName} group access?
              </Typography>
            </ModalConfirmation>
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </div>
  );
};

export default IdpGroupMappingListDropdown;
