import { ErrorColored } from "components/icons";
import Link from "ds/components/Link";
import SystemMessage from "components/SystemMessage";
import Typography from "ds/components/Typography";
import useTitle from "hooks/useTitle";

import styles from "./styles.module.css";

const LoginLimitExceededPage = () => {
  useTitle("Login limit exceeded · Spacelift");

  return (
    <SystemMessage title="Login denied" text="" icon={ErrorColored}>
      <Typography variant="p-body2" tag="p" className={styles.text}>
        Sorry, looks like number of allowed logins has been exceeded. This could well be an error -
        try{" "}
        <Link to="/login" size="medium">
          logging in again
        </Link>{" "}
        to see if that helps.
      </Typography>
    </SystemMessage>
  );
};

export default LoginLimitExceededPage;
