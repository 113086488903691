import { useState } from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import useLocalStorage from "@rehooks/local-storage";

import useTypedFlags from "hooks/useTypedFlags";
import ViewHeader from "components/ViewHeader";
import ViewHeaderTitle from "components/ViewHeader/Title";
import Box from "ds/components/Box";
import Typography from "ds/components/Typography";
import {
  NAVIGATION_CONFIGURATION_STORAGE_KEY,
  NAVIGATION_DEFAULT_VIEW_STORAGE_KEY,
  NAVIGATION_ITEMS_COUNT_LIMIT,
} from "constants/navigation";
import useNavigationConfig from "hooks/useNavigationConfig";
import ViewHeaderWrapper from "components/ViewHeader/Wrapper";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import { SidebarStorageConfig, SideNavigationItemType } from "types/Navigation";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import { AccountContext } from "views/AccountWrapper";
import useTitle from "hooks/useTitle";
import { reorderMultipleDNDLists } from "utils/dnd";

import CustomizeSidebarItems from "./Items";
import styles from "./styles.module.css";
import { DroppableId } from "./types";

const CustomizeSidebar = () => {
  const { dashboardMetrics } = useTypedFlags();
  const [isHiddenDropDisabled, setIsHiddenDropDisabled] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const { viewer, accountName } = useTypedContext(AccountContext);
  const navigationConfig = useNavigationConfig({ isAdmin: viewer.admin });
  const [sidebarStorageConfig, setSidebarStorageConfig] = useLocalStorage<SidebarStorageConfig>(
    NAVIGATION_CONFIGURATION_STORAGE_KEY,
    { visible: Object.keys(navigationConfig) as SideNavigationItemType[], hidden: [] }
  );

  const [, setNavigationDefaultViewStorageConfig] = useLocalStorage<SideNavigationItemType>(
    NAVIGATION_DEFAULT_VIEW_STORAGE_KEY
  );

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPagePersonal.PersonalSidebarCustomization,
    callbackTrackProviders: { segment: true },
  });

  const { reportSuccess } = useTypedContext(FlashContext);

  useTitle(`Personal Settings · Sidebar customization · ${accountName}`);

  const handleDragStart = (start: DragStart) => {
    if (
      start.source.droppableId === DroppableId.Visible &&
      sidebarStorageConfig &&
      sidebarStorageConfig.visible.length <= NAVIGATION_ITEMS_COUNT_LIMIT
    ) {
      setIsHiddenDropDisabled(true);
    }

    setIsDragActive(true);
  };

  const onReorderEnd = (newConfig: Record<string, SideNavigationItemType[]>) => {
    setSidebarStorageConfig(newConfig as SidebarStorageConfig);
    reportSuccess({ message: "Sidebar configuration has been updated" });
    trackSegmentAnalyticsEvent("Configuration updated", {
      visible: newConfig.visible ? newConfig.visible.join(", ") : "",
      hidden: newConfig.hidden ? newConfig.hidden.join(", ") : "",
    });
  };

  const handleDragEnd = (result: DropResult) => {
    setIsDragActive(false);

    if (isHiddenDropDisabled) {
      setIsHiddenDropDisabled(false);
    }

    if (!result.destination || !sidebarStorageConfig) {
      return;
    }

    const newConfig = reorderMultipleDNDLists<SideNavigationItemType>(
      sidebarStorageConfig,
      result.source.droppableId,
      result.destination.droppableId,
      result.source.index,
      result.destination.index
    );

    onReorderEnd(newConfig);
  };

  const onManualChange = (
    from: DroppableId,
    to: DroppableId,
    fromIndex: number,
    toIndex: number
  ) => {
    const newConfig = reorderMultipleDNDLists<SideNavigationItemType>(
      sidebarStorageConfig,
      from,
      to,
      fromIndex,
      toIndex
    );

    onReorderEnd(newConfig);
  };

  const onSetDefaultView = (view: SideNavigationItemType) => {
    setNavigationDefaultViewStorageConfig(view);
    reportSuccess({ message: "Default view has been updated" });
    trackSegmentAnalyticsEvent("Default view updated", { view });
  };

  return (
    <>
      <ViewHeader firstLevel>
        <ViewHeaderWrapper direction="row" align="start" justify="between" fullWidth>
          <Box direction="column" grow="1" gap="small">
            <ViewHeaderTitle tag="h2">Sidebar customization</ViewHeaderTitle>
            <Typography tag="p" variant="p-body2" color="secondary">
              Personalize your sidebar by selecting which features you want to be visible and which
              ones to hide under the 'More' menu.
              {dashboardMetrics ? (
                <>
                  {" "}
                  You can also choose the default view of your account, which will be the first view
                  you see when you open the app.
                </>
              ) : null}
            </Typography>
          </Box>
        </ViewHeaderWrapper>
      </ViewHeader>

      <Box direction="column" grow="1" className={styles.wrapper} fullWidth>
        {sidebarStorageConfig && (
          <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <Box direction="column" padding="large" gap="large" fullWidth>
              <CustomizeSidebarItems
                title={`Visible Features (min. ${NAVIGATION_ITEMS_COUNT_LIMIT})`}
                type={DroppableId.Visible}
                items={sidebarStorageConfig.visible}
                onManualChange={onManualChange}
                onSetDefaultView={onSetDefaultView}
              />

              <CustomizeSidebarItems
                isDropDisabled={isHiddenDropDisabled}
                title="Hidden Features"
                type={DroppableId.Hidden}
                items={sidebarStorageConfig.hidden}
                isDragActive={isDragActive}
                onManualChange={onManualChange}
                onSetDefaultView={onSetDefaultView}
              />
            </Box>
          </DragDropContext>
        )}
      </Box>
    </>
  );
};

export default CustomizeSidebar;
