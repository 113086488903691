import { memo } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import Banner from "ds/components/Banner";
import Box from "ds/components/Box";
import Drawer from "ds/components/Drawer";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";
import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import FormFieldSpace from "components/FormFields/Spaces";
import FormFieldTags from "components/FormFields/Tags";
import { TooltipModalTitle } from "ds/components/TooltipModal/Title";
import TooltipModalBody from "ds/components/TooltipModal/Body";
import Link from "ds/components/Link";
import useAnalytics from "hooks/useAnalytics";
import { getManagementStrategy } from "views/account/settings/helpers";
import { UserManagementActivationStatus } from "views/account/settings/types";
import useTypedContext from "hooks/useTypedContext";
import { AccountContext } from "views/AccountWrapper";
import Select from "ds/components/Select";
import Typography from "ds/components/Typography";
import ReadMoreDocsLink from "components/ReadMoreDocsLink";
import { SubscriptionContext } from "views/account/SubscriptionWrapper";
import { ApiKeyType, BillingTier, BillingTierFeature } from "types/generated";
import { validateRegExp, validateURL } from "utils/formValidators";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import { AnalyticsPagePersonal } from "hooks/useAnalytics/pages/personal";
import { getDocsUrl } from "utils/getDocsUrl";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import createSelectOptionRef from "ds/components/Select/createSelectOptionRef";
import useTierFeature from "views/account/hooks/useTierFeature";
import BadgeNext from "ds/components/BadgeNext";
import { Diamond } from "components/icons";
import Textarea from "ds/components/Textarea";

import { CreateApiKeyFields } from "./types";
import useCreateApiKey from "./useCreateApiKey";
import { API_KEY_CREATE_DRAWER_TEST_ID } from "./constants";

type ApiKeysCreateDrawerProps = {
  isDrawerVisible: boolean;
  setDrawerVisibility: (isVisible: boolean) => void;
  activationStatus: UserManagementActivationStatus;
  refetchQueries?: string[];
  analyticsPage: AnalyticsPageOrganization | AnalyticsPagePersonal;
};

const ApiKeysCreateDrawer = ({
  isDrawerVisible,
  setDrawerVisibility,
  activationStatus,
  refetchQueries,
  analyticsPage,
}: ApiKeysCreateDrawerProps) => {
  const { viewer } = useTypedContext(AccountContext);
  const { tier } = useTypedContext(SubscriptionContext);

  const isOidcApiKeyFeatureActive = useTierFeature(BillingTierFeature.ApiKeysOidc);

  const isCloudTier = tier === BillingTier.Cloud || tier === BillingTier.V3Cloud;

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: { managementStrategy: getManagementStrategy(activationStatus) },
  });

  const groupAddForm = useForm<CreateApiKeyFields>({
    defaultValues: {
      description: "",
      name: "",
      spaces: [{ space: undefined, spaceAccessLevel: undefined }],
      groups: undefined,
      type: ApiKeyType.Secret,
      oidcClientId: "",
      oidcIssuer: "",
      oidcSubjectExpression: ".*",
    },
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid },
  } = groupAddForm;

  const handleCloseDrawer = () => {
    setDrawerVisibility(false);
    reset();
  };

  const { onCreate } = useCreateApiKey({
    refetchQueries,
    callback: handleCloseDrawer,
  });

  const onSubmit: SubmitHandler<CreateApiKeyFields> = (formFields) => {
    trackSegmentAnalyticsEvent("Create Confirm");
    const input = {
      admin: false, // TODO: legacy field
      accessRules: formFields.spaces.filter((space) => !!space.space && !!space.spaceAccessLevel),
      machine: null,
      name: formFields.name,
      teams: formFields.groups?.map((group) => group.value) || [],
      type: formFields.type,
      oidc:
        formFields.type === ApiKeyType.Oidc
          ? {
              clientId: formFields.oidcClientId,
              issuer: formFields.oidcIssuer,
              subjectExpression: formFields.oidcSubjectExpression,
            }
          : null,
      description: formFields.description.trim(),
    };
    onCreate(input);
  };

  const isUserManagementActive = activationStatus === UserManagementActivationStatus.ACTIVE;

  const isNameFieldEmpty = watch("name").length === 0;
  const type = watch("type");

  return (
    <Drawer
      visible={isDrawerVisible}
      onOutsideClick={handleCloseDrawer}
      dataTestId={API_KEY_CREATE_DRAWER_TEST_ID}
    >
      <FormProvider {...groupAddForm}>
        <DrawerHeader justify="between">
          <DrawerHeaderTitle title="Create API key" />
          <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
        </DrawerHeader>
        <DrawerBody fullHeight hasStickyFooter>
          {!isUserManagementActive && viewer.admin && (
            <Banner variant="warning" title="User management is inactive">
              Setting space and role to root and admin will create API key with account admin
              privileges. You can create different space access rules but they won't take effect
              until you switch to user management.
            </Banner>
          )}
          <FormField label="Name" error={errors?.name?.message}>
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter API key name"
                error={!!errors?.name}
                {...register("name", {
                  required: "Name field is required.",
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>

          <Controller
            name="type"
            render={({ field, fieldState }) => (
              <FormField error={fieldState.error?.message} label="Type" fullWidth>
                {({ ariaInputProps }) => (
                  <Box direction="column" gap="x-large">
                    <Select
                      placeholder="Type in or select type from the list"
                      value={field.value}
                      options={[
                        { label: "Secret", value: ApiKeyType.Secret },
                        {
                          label: "OIDC",
                          value: ApiKeyType.Oidc,
                          disabled: !isOidcApiKeyFeatureActive,
                          tooltip: isOidcApiKeyFeatureActive
                            ? undefined
                            : "Upgrade your plan to benefit from ability to use more secure OIDC-based API keys.",
                        },
                      ]}
                      onChange={field.onChange}
                      error={!!fieldState.error?.message}
                      ariaInputProps={ariaInputProps}
                      renderOption={({
                        label,
                        value,
                        disabled,
                        tooltip,
                        onChange,
                        closeSelect,
                        checked,
                      }) => (
                        <DropdownSectionItem
                          onClick={() => (onChange?.(value), closeSelect?.())}
                          key={label}
                          active={checked}
                          innerRef={createSelectOptionRef(checked)}
                          disabled={disabled}
                          tooltip={tooltip}
                          tooltipPlacement="left"
                          tooltipWidthMode="maxWidthSm"
                        >
                          <Box align="center" gap="medium">
                            {label}
                            {value === ApiKeyType.Oidc && !isOidcApiKeyFeatureActive ? (
                              <BadgeNext
                                variant="purple"
                                type="strong"
                                startIcon={Diamond}
                                iconOnly
                                color="red"
                              />
                            ) : null}
                          </Box>
                        </DropdownSectionItem>
                      )}
                    />
                  </Box>
                )}
              </FormField>
            )}
          />

          {type === ApiKeyType.Oidc && (
            <>
              <FormField label="Issuer" error={errors?.oidcIssuer?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="https://"
                    error={!!errors?.oidcIssuer}
                    {...register("oidcIssuer", {
                      required: "Issuer field is required.",
                      validate: validateURL({ https: true }),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
              <FormField label="Client ID (audience)" error={errors?.oidcClientId?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter Client ID (audience)"
                    error={!!errors?.oidcClientId}
                    {...register("oidcClientId", {
                      required: "Client ID (audience) field is required.",
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
              <FormField label="Subject Expression" error={errors?.oidcSubjectExpression?.message}>
                {({ ariaInputProps }) => (
                  <Input
                    placeholder="Enter Subject Expression"
                    error={!!errors?.oidcSubjectExpression}
                    {...register("oidcSubjectExpression", {
                      required: "Subject Expression field is required.",
                      validate: validateRegExp(),
                    })}
                    {...ariaInputProps}
                  />
                )}
              </FormField>
            </>
          )}

          <FormFieldSpace
            analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
            allowEmpty={!isUserManagementActive && viewer.admin}
            isDisabled={!!errors?.name || isNameFieldEmpty}
          />

          {viewer.admin && (
            <FormFieldTags
              label="Groups"
              tagName="group"
              name="groups"
              isOptional
              tooltipDisablePortal
              tooltipInfo={
                <>
                  <TooltipModalTitle>Group name</TooltipModalTitle>
                  <TooltipModalBody align="start">
                    Type in any group name that you have defined in your IdP (e.g. GitHub). It has
                    to be exactly the same.
                    <Link
                      href={getDocsUrl("/concepts/user-management/user.html#groups")}
                      target="_blank"
                    >
                      Learn more
                    </Link>
                  </TooltipModalBody>
                </>
              }
              tooltipInfoVariant="modal"
            />
          )}

          <FormField label="Description" isOptional>
            {({ ariaInputProps }) => (
              <Textarea
                placeholder="Enter API key description here..."
                {...register("description")}
                {...ariaInputProps}
              />
            )}
          </FormField>

          {type === ApiKeyType.Secret && (
            <Box margin="large 0 0 0">
              <Banner variant="info">
                Upon API key creation, a file will be generated with API key secret token details.
                Please save this file somewhere safe as it will never be available again.
              </Banner>
            </Box>
          )}

          {isCloudTier && (
            <Box margin="large 0 0 0">
              <Banner variant="warning">
                <Typography tag="p" variant="p-body3" margin="0 0 medium 0">
                  API keys are virtual users and are billed like regular users, too. Thus, each API
                  key used (exchanged for a token) during any given billing cycle counts against the
                  total number of users.
                </Typography>
                <ReadMoreDocsLink
                  docsUrl={getDocsUrl("/integrations/api#spacelift-api-key-token")}
                />
              </Banner>
            </Box>
          )}

          <DrawerFooter sticky>
            <DrawerFooterActions>
              <Button
                variant="secondary"
                analyticsPage={AnalyticsPageOrganization.OrganizationApiKeys}
                analyticsTitle="Cancel Create Key"
                analyticsProps={{ managementStrategy: getManagementStrategy(activationStatus) }}
                onClick={handleCloseDrawer}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                Create
              </Button>
            </DrawerFooterActions>
          </DrawerFooter>
        </DrawerBody>
      </FormProvider>
    </Drawer>
  );
};

export default memo(ApiKeysCreateDrawer);
