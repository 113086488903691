import { useCallback, useEffect, useRef } from "react";

import useTypedContext from "hooks/useTypedContext";
import useAnalytics, { type AnalyticsPage } from "hooks/useAnalytics";

import { ViewCustomizationContext, ViewItemsVisibility } from "./Context";

const toSegmentValue = (value: boolean) => (value ? "visible" : "hidden");

const useSegmentTracking = (isVisible: boolean, analyticsPage: AnalyticsPage) => {
  const isVisibleRef = useRef(false);
  const initialVisibilityRef = useRef<ViewItemsVisibility | null>(null);
  const { itemsVisibility } = useTypedContext(ViewCustomizationContext);

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: analyticsPage,
    callbackTrackProviders: { segment: true },
  });

  const handleAnalytics = useCallback(() => {
    if (!initialVisibilityRef.current) {
      return;
    }

    let hasChanges = false;

    const properties = Object.entries(itemsVisibility).reduce<{
      hidden: string[];
      visible: string[];
    }>(
      (acc, [key, value]) => {
        const newAcc = { ...acc };

        if (value !== initialVisibilityRef.current?.[key]) {
          hasChanges = true;
        }

        const visibility = toSegmentValue(value);
        newAcc[visibility] = [...acc[visibility], key];
        return newAcc;
      },
      {
        visible: [],
        hidden: [],
      }
    );

    if (!hasChanges) {
      return;
    }

    trackSegmentAnalyticsEvent("List item view changed", properties);
  }, [itemsVisibility, trackSegmentAnalyticsEvent]);

  useEffect(() => {
    if (isVisible) {
      initialVisibilityRef.current = itemsVisibility;
    }

    if (isVisibleRef.current && !isVisible) {
      handleAnalytics();
    }

    isVisibleRef.current = isVisible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
};

export default useSegmentTracking;
