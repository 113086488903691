import { HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY } from "constants/local_storage_keys";
import Button from "ds/components/Button";
import Callout from "ds/components/Callout";
import FeedbackActions from "ds/components/Feedback/Actions";
import { RunIgnoredTrigger, Stack } from "types/generated";
import IgnoredRunPoliciesList from "components/IgnoredRunPoliciesList";

import StackRunsIgnoredRunCalloutPrimaryAction from "./PrimaryAction";
import StackRunsIgnoredRunCalloutTitle from "./Title";
import StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip from "./PermanentCloseConfirmationTooltip";

type StackRunsIgnoredRunCalloutProps = {
  stack: Stack;
  ignoredRun: RunIgnoredTrigger;
};

const StackRunsIgnoredRunCallout = ({ stack, ignoredRun }: StackRunsIgnoredRunCalloutProps) => (
  <Callout
    variant="warning"
    title={<StackRunsIgnoredRunCalloutTitle stack={stack} ignoredRun={ignoredRun} />}
    storageKey={HIDE_IGNORED_RUN_NOTICE_LOCAL_STORAGE_KEY}
    permanentCloseConfirmationTooltipInfo={
      <StackRunsIgnoredRunCalloutPermanentCloseConfirmationTooltip />
    }
    withPermanentCloseConfirmation
  >
    <IgnoredRunPoliciesList ignoredRun={ignoredRun} variant="p-body3" />
    If you want to force the run, sync and trigger it manually.
    <FeedbackActions>
      <StackRunsIgnoredRunCalloutPrimaryAction stack={stack} ignoredRun={ignoredRun} />
      <Button variant="secondary" size="small" to={`/stack/${stack.id}/ignored-runs`}>
        Go to ignored runs
      </Button>
    </FeedbackActions>
  </Callout>
);

export default StackRunsIgnoredRunCallout;
