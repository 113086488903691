import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom"; // eslint-disable-line no-restricted-imports
import { useSearchParams, useLocation } from "react-router-dom-v5-compat";
import cloneDeep from "lodash-es/cloneDeep";

import { SearchQueryOrderDirection } from "types/generated";
import {
  URL_FILTER_KEYS_KEY,
  URL_FILTER_TYPES_KEY,
  URL_FILTER_VALUES_KEY,
  URL_SEARCH_KEY,
  URL_SORT_DIRECTION,
  URL_SORT_KEY,
} from "constants/url_query_keys";

import { useEmptyDefaultView } from "./hooks";
import {
  ActiveFilter,
  ActiveFiltersMap,
  FilterItem,
  FilterItemSettings,
  FiltersDictionary,
  FiltersItemsOptionsMap,
  SortOption,
  SavedFilterView,
} from "./types";
import {
  getInitialFiltersMap,
  recalculateLabelsFilter,
  syncFiltersOrder,
  setUpdatedFiltersUrlParams,
  getSessionStorageKeys,
  removeFiltersFromStorage,
} from "./helpers";
import { useDefaultView } from "./useDefaultView";

type FiltersContextProps = {
  allFilters: FilterItem[];
  filters: FilterItem[];
  filtersItemsOptionsMap: FiltersItemsOptionsMap;
  sortOptions: SortOption[];
  defaultEmptyFilterView: SavedFilterView;
  initialSortOption: string;
  initialSortDirection: SearchQueryOrderDirection;
  activeFilters: ActiveFiltersMap;
  activeFiltersByFilterName: ActiveFiltersMap;
  setActiveFilter: (filter: ActiveFilter) => void;
  deleteActiveFilter: (filter: ActiveFilter) => void;
  resetAllFilters: () => void;
  setGenericFilter: (newFilter: FilterItem) => FilterItem[];
  setActiveFilters: (filters: ActiveFiltersMap) => void;
  setActiveFiltersFromInitialParams: () => void;
  filtersLoading: boolean;
  forceReMountSidebar: () => void;
  forceReMountLabelGroups: () => void;
  reMountSidebar: boolean;
  reMountLabelGroups: boolean;
  toggleNewLabelGroup: (value: boolean) => void;
  isNewLabelGroupOpen: boolean;
  setOpenSections: (filterName: string, value: boolean) => void;
  hasOpenSections: boolean;
  setShouldExpandSections: (value: boolean) => void;
  shouldExpandSections: boolean | undefined;
  filtersDictionary?: FiltersDictionary;
  filtersOrderSettingsKey: string;
  filtersOrder: FilterItemSettings[];
  filterType: string;
  currentView?: SavedFilterView;
  setCurrentView: (view?: SavedFilterView) => void;
  setFiltersOrder: (filters: FilterItemSettings[]) => void;
  filtersRevealedOnHover: boolean;
  setFiltersRevealedOnHover: (revealed: boolean) => void;
  resetOpenSections: () => void;
  disabledFilters?: string[];
};

export const FiltersContext = createContext<FiltersContextProps | undefined>(undefined);
FiltersContext.displayName = "FiltersContext";

export type FiltersProps = {
  filters: FilterItem[];
  filtersItemsOptionsMap: FiltersItemsOptionsMap;
  /**
   * deprecatedFilterNames:
   * filters that will be hidden by default unless used by a view or url param
   */
  deprecatedFilterNames?: string[];
  sortOptions: SortOption[];
  initialSortOption: string;
  initialSortDirection: SearchQueryOrderDirection;
  filtersLoading: boolean;
  filtersDictionary?: FiltersDictionary;
  pollActiveSections: (sections: string[]) => void;
  filtersOrderSettingsKey: string;
  children: React.ReactNode;
  filtersType: string;
  currentSavedView?: SavedFilterView;
  setCurrentSavedView: (view?: SavedFilterView) => void;
  applyFilterCallback?: () => void;
  disabledFilters?: string[];
  resetFiltersCallback?: () => void;
};

const Filters = ({
  children,
  deprecatedFilterNames = [],
  initialSortOption,
  initialSortDirection,
  filters,
  sortOptions,
  filtersLoading,
  filtersItemsOptionsMap,
  filtersDictionary,
  pollActiveSections,
  filtersOrderSettingsKey,
  filtersType,
  currentSavedView,
  setCurrentSavedView,
  applyFilterCallback,
  disabledFilters,
  resetFiltersCallback,
}: FiltersProps) => {
  const storage = sessionStorage;
  const [filtersOrder, setFiltersOrder] = useState<FilterItemSettings[]>([]);

  const defaultEmptyFilterView = useEmptyDefaultView(
    filtersOrder,
    initialSortDirection,
    initialSortOption,
    deprecatedFilterNames
  );

  const {
    storageUrlFilterKeysKey,
    storageUrlFilterTypesKey,
    storageUrlFilterValuesKey,
    storageUrlSearchKey,
    storageUrlSortDirection,
    storageUrlSortKey,
  } = getSessionStorageKeys(filtersOrderSettingsKey);
  const history = useHistory();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [cachedFilters, updateCachedFilters] = useState(() => filters);
  const [activeFilters, setActiveFilters] = useState<ActiveFiltersMap>(new Map());
  const [genericFilters, setGenericFilters] = useState<FilterItem[]>([]);

  const [reMountSidebar, forceReMountSidebar] = useState(false);
  const [reMountLabelGroups, forceReMountLabelGroups] = useState(false);
  const [openSections, setOpenSection] = useState<string[]>([]);
  const [shouldExpandSections, setShouldExpandSections] = useState<boolean | undefined>(undefined);

  const resetOpenSections = useCallback(() => {
    setOpenSection([]);
    isNewLabelGroupOpen.current = false;
  }, []);

  const isNewLabelGroupOpen = useRef(false);
  const defaultView = useDefaultView(filtersOrder, filtersType, defaultEmptyFilterView);

  const handleUpdateUrlParamsForDefaultView = useCallback(
    (
      option: string,
      direction: SearchQueryOrderDirection,
      filters: ActiveFiltersMap,
      search: string | null
    ) => {
      const updatedFiltersParams = setUpdatedFiltersUrlParams(
        filters,
        searchParams,
        storage,
        filtersOrderSettingsKey
      );

      if (option) {
        updatedFiltersParams.set(URL_SORT_KEY, encodeURIComponent(option));
        storage.setItem(storageUrlSortKey, encodeURIComponent(option));
      } else {
        updatedFiltersParams.delete(URL_SORT_KEY);
        storage.removeItem(storageUrlSortKey);
      }

      if (direction) {
        updatedFiltersParams.set(URL_SORT_DIRECTION, encodeURIComponent(direction));
        storage.setItem(storageUrlSortDirection, encodeURIComponent(direction));
      } else {
        updatedFiltersParams.delete(URL_SORT_DIRECTION);
        storage.removeItem(storageUrlSortDirection);
      }

      if (search) {
        updatedFiltersParams.set(URL_SEARCH_KEY, search);
        storage.setItem(storageUrlSearchKey, btoa(encodeURIComponent(JSON.stringify(search))));
      } else {
        updatedFiltersParams.delete(URL_SEARCH_KEY);
        storage.removeItem(storageUrlSearchKey);
      }

      setSearchParams(updatedFiltersParams, { replace: true });
    },
    [
      filtersOrderSettingsKey,
      storage,
      storageUrlSearchKey,
      storageUrlSortDirection,
      storageUrlSortKey,
      searchParams,
      setSearchParams,
    ]
  );

  // Use default view as the initial one
  useEffect(() => {
    // Set default view when no current view or someone moved directly to empty stacks via routing
    if ((!currentSavedView || !location.search) && defaultView.data?.id) {
      setCurrentSavedView(defaultView.data);
      const syncedFilterOrder = syncFiltersOrder(sortedFilters, defaultView.data.order);

      const hasFilterParams =
        searchParams.has(URL_FILTER_KEYS_KEY) &&
        searchParams.has(URL_FILTER_TYPES_KEY) &&
        searchParams.has(URL_FILTER_VALUES_KEY);

      const hasSortParams = searchParams.has(URL_SORT_DIRECTION) && searchParams.has(URL_SORT_KEY);

      const hasQueryParam = searchParams.has(URL_SEARCH_KEY);

      if (hasFilterParams || hasSortParams || hasQueryParam) {
        const { initialGenericFilters } = setActiveFiltersFromInitialParams();

        // Enable filters when we activate them
        setFiltersOrder(
          syncedFilterOrder.map((item) => ({
            ...item,
            visible:
              initialGenericFilters.some(({ filterName }) => filterName == item.name) ||
              item.visible,
          }))
        );
      } else {
        setFiltersOrder(syncedFilterOrder);
        handleSetActiveFilters(defaultView.data.filters);
        handleUpdateUrlParamsForDefaultView(
          defaultView.data.sortOption,
          defaultView.data.sortDirection,
          defaultView.data.filters,
          defaultView.data.search
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultView?.data?.id, location.search]);

  const toggleNewLabelGroup = useCallback((value: boolean) => {
    isNewLabelGroupOpen.current = value;
  }, []);

  const hasOpenSections = useMemo(() => {
    setShouldExpandSections(undefined);

    return openSections.length > 0;
  }, [openSections]);

  const setOpenSections = useCallback(
    (filterName: string, value: boolean) => {
      if (value) {
        setOpenSection([...openSections, filterName]);
      } else {
        const newOpenSections = [...openSections];

        const indexToRemove = newOpenSections.findIndex(
          (openSection) => filterName === openSection
        );

        if (indexToRemove > -1) {
          newOpenSections.splice(indexToRemove, 1);
          setOpenSection(newOpenSections);
        }
      }
    },
    [openSections]
  );

  const sortedFilters = useMemo(() => {
    // skip duplicates
    const arrayUniqueByKey = [
      ...new Map([...cachedFilters, ...genericFilters].map((item) => [item.key, item])).values(),
    ];

    return arrayUniqueByKey.sort((a, b) => {
      if (a.key.startsWith("label") && b.key.startsWith("label")) {
        const aIndex = Number(a.key.replace("label", ""));
        const bIndex = Number(b.key.replace("label", ""));

        if (aIndex === bIndex) {
          return 0;
        }

        return aIndex > bIndex ? 1 : -1;
      }
      return a.key.localeCompare(b.key);
    });
  }, [cachedFilters, genericFilters]);

  const orderedFilters = useMemo(() => {
    const currentFiltersOrder = filtersOrder;
    if (currentFiltersOrder) {
      return currentFiltersOrder.reduce<FilterItem[]>((acc, filter) => {
        const item = sortedFilters.filter(
          (item) => item.filterName === filter.name && filter.visible
        );
        if (item) {
          acc.push(...item);
        }

        return acc;
      }, []);
    }

    return sortedFilters;
  }, [filtersOrder, sortedFilters]);

  const activeFiltersByFilterName = useMemo(() => {
    return new Map([...activeFilters.values()].map((filter) => [filter.filterName, filter]));
  }, [activeFilters]);

  const handleForceReMountLabelGroups = useCallback(
    () => forceReMountLabelGroups(!reMountLabelGroups),
    [reMountLabelGroups]
  );

  const handleUpdateUrlParams = useCallback(
    (activeFilters: ActiveFiltersMap, replaceHistory = false) => {
      const updatedParams = setUpdatedFiltersUrlParams(
        activeFilters,
        searchParams,
        storage,
        filtersOrderSettingsKey
      );

      setSearchParams(updatedParams, { replace: replaceHistory });
    },
    [filtersOrderSettingsKey, storage, searchParams, setSearchParams]
  );

  const handleSetActiveFilters = useCallback((filters: ActiveFiltersMap) => {
    const [newActiveFilters, newGenericFilters] = recalculateLabelsFilter(filters);

    setActiveFilters(newActiveFilters);
    setGenericFilters(newGenericFilters);
  }, []);

  const handleSetActiveFilter = useCallback(
    (filter: ActiveFilter) => {
      const copiedActiveFilters = cloneDeep(activeFilters);
      if (filter.values.length === 0 && copiedActiveFilters.has(filter.key)) {
        copiedActiveFilters.delete(filter.key);
      } else if (filter.values.length > 0) {
        copiedActiveFilters.set(filter.key, filter);
      } else {
        // no need to do anything since the filter has no values
        return;
      }

      // on generic filters being empty, we need to recalculate labels
      if (filter.filterName === "label" && filter.values.length === 0) {
        const [newActiveFilters, newGenericFilters] = recalculateLabelsFilter(copiedActiveFilters);
        setActiveFilters(newActiveFilters);
        setGenericFilters(newGenericFilters);
        handleUpdateUrlParams(newActiveFilters);
        handleForceReMountLabelGroups();
      }
      // default behavior
      else {
        setActiveFilters(copiedActiveFilters);
        handleUpdateUrlParams(copiedActiveFilters);
      }

      applyFilterCallback?.();
    },
    [activeFilters, applyFilterCallback, handleForceReMountLabelGroups, handleUpdateUrlParams]
  );

  const handleDeleteActiveFilter = useCallback(
    (filter: ActiveFilter) => {
      const copiedActiveFilters = cloneDeep(activeFilters);
      copiedActiveFilters.delete(filter.key);

      const [newActiveFilters, newGenericFilters] = recalculateLabelsFilter(copiedActiveFilters);

      setActiveFilters(newActiveFilters);
      handleUpdateUrlParams(newActiveFilters);
      setGenericFilters(newGenericFilters);
      applyFilterCallback?.();
    },
    [activeFilters, handleUpdateUrlParams, applyFilterCallback]
  );

  const handleAddGenericFilter = useCallback(
    (newFilter: FilterItem) => {
      const updatedFilters = [...genericFilters, newFilter];
      setGenericFilters(updatedFilters);

      return updatedFilters;
    },
    [genericFilters]
  );

  const clearStorage = useCallback(() => {
    removeFiltersFromStorage(storage, filtersOrderSettingsKey);
    storage.removeItem(storageUrlSortKey);
    storage.removeItem(storageUrlSortDirection);
    storage.removeItem(storageUrlSearchKey);
  }, [
    filtersOrderSettingsKey,
    storage,
    storageUrlSearchKey,
    storageUrlSortDirection,
    storageUrlSortKey,
  ]);

  const handleForceReMountSidebar = useCallback(() => {
    forceReMountSidebar(!reMountSidebar);
    setOpenSection([]);
    setShouldExpandSections(false);
  }, [reMountSidebar]);

  const handleResetAllFilters = useCallback(() => {
    setActiveFilters(new Map());
    handleUpdateUrlParams(new Map());
    setGenericFilters([]);
    handleForceReMountSidebar();
    resetFiltersCallback?.();
  }, [handleForceReMountSidebar, handleUpdateUrlParams, resetFiltersCallback]);

  const setActiveFiltersFromInitialParams = useCallback(() => {
    const [initialActiveFilters, initialGenericFilters] = getInitialFiltersMap(
      searchParams,
      storage,
      filtersOrderSettingsKey,
      filtersDictionary
    );

    setActiveFilters(initialActiveFilters);
    setGenericFilters(initialGenericFilters);

    if (searchParams.has(URL_FILTER_KEYS_KEY)) {
      storage.setItem(storageUrlFilterKeysKey, searchParams.get(URL_FILTER_KEYS_KEY) as string);
    }

    if (searchParams.has(URL_FILTER_TYPES_KEY)) {
      storage.setItem(storageUrlFilterTypesKey, searchParams.get(URL_FILTER_TYPES_KEY) as string);
    }

    if (searchParams.has(URL_FILTER_VALUES_KEY)) {
      storage.setItem(storageUrlFilterValuesKey, searchParams.get(URL_FILTER_VALUES_KEY) as string);
    }

    return {
      initialGenericFilters,
    };
  }, [
    filtersDictionary,
    filtersOrderSettingsKey,
    storage,
    storageUrlFilterKeysKey,
    storageUrlFilterTypesKey,
    storageUrlFilterValuesKey,
    searchParams,
  ]);

  useEffect(() => {
    /**
     * TODO: react-router migration - this should useNavigationType, however for some reason it's not updating from POP, let's come back to this on the migration cleanup
     * One seemingly related issue is here: https://github.com/remix-run/react-router/discussions/10053, but we don't pass the location as prop
     * If you use useNavigationType make sure that changing views in the filters works properly
     */
    if (history.action === "POP") {
      setCurrentSavedView(undefined);
    }

    // sync active filters with url params on history back/forward events!
    if (history.action === "POP" && activeFilters.size > 0) {
      setActiveFiltersFromInitialParams();
      handleForceReMountSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // remove storage data on Browser "reload" event
  useEffect(() => {
    //on browser page reload event
    window.addEventListener("beforeunload", clearStorage);

    return () => {
      window.removeEventListener("beforeunload", clearStorage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sync Filters settings
  useEffect(() => {
    setFiltersOrder(syncFiltersOrder(sortedFilters, filtersOrder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedFilters]);

  useEffect(() => {
    if (!filtersLoading) {
      updateCachedFilters(filters);
    }
  }, [filters, filtersLoading]);

  // polling with expanded filters for performance optimization
  useEffect(() => {
    const uniqFilterKeys = [...new Set(openSections)].filter((sectionKey) =>
      orderedFilters.find(({ filterName }) => sectionKey === filterName)
    );

    pollActiveSections(uniqFilterKeys);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSections]);

  // TODO: create separate context or hook for collapsed filters during next step or refactor
  const [filtersRevealedOnHover, setFiltersRevealedOnHover] = useState(false);

  const savedViewsLoading = filtersLoading || defaultView.loading;

  const value = useMemo(
    () => ({
      allFilters: sortedFilters,
      filters: orderedFilters,
      filtersOrder: filtersOrder,
      filtersItemsOptionsMap,
      sortOptions,
      defaultEmptyFilterView,
      initialSortOption,
      initialSortDirection,
      activeFilters,
      activeFiltersByFilterName,
      setActiveFilter: handleSetActiveFilter,
      deleteActiveFilter: handleDeleteActiveFilter,
      resetAllFilters: handleResetAllFilters,
      setGenericFilter: handleAddGenericFilter,
      setActiveFilters: handleSetActiveFilters,
      setFiltersOrder,
      filtersLoading: savedViewsLoading,
      forceReMountSidebar: handleForceReMountSidebar,
      forceReMountLabelGroups: handleForceReMountLabelGroups,
      reMountSidebar,
      reMountLabelGroups,
      toggleNewLabelGroup,
      isNewLabelGroupOpen: isNewLabelGroupOpen.current,
      hasOpenSections,
      setOpenSections,
      shouldExpandSections,
      setShouldExpandSections,
      setActiveFiltersFromInitialParams,
      filtersDictionary,
      filtersOrderSettingsKey,
      filterType: filtersType,
      currentView: currentSavedView,
      resetOpenSections,
      setCurrentView: (view?: SavedFilterView) => {
        setCurrentSavedView(view);

        if (view) {
          setFiltersOrder(view.order);
        }
      },
      filtersRevealedOnHover,
      setFiltersRevealedOnHover,
      disabledFilters,
    }),
    [
      activeFilters,
      activeFiltersByFilterName,
      currentSavedView,
      defaultEmptyFilterView,
      filtersDictionary,
      filtersItemsOptionsMap,
      filtersOrder,
      filtersOrderSettingsKey,
      filtersRevealedOnHover,
      setActiveFiltersFromInitialParams,
      filtersType,
      handleAddGenericFilter,
      handleDeleteActiveFilter,
      handleForceReMountLabelGroups,
      handleForceReMountSidebar,
      handleResetAllFilters,
      handleSetActiveFilter,
      handleSetActiveFilters,
      hasOpenSections,
      initialSortDirection,
      initialSortOption,
      orderedFilters,
      reMountLabelGroups,
      reMountSidebar,
      resetOpenSections,
      savedViewsLoading,
      setCurrentSavedView,
      setOpenSections,
      shouldExpandSections,
      sortOptions,
      sortedFilters,
      toggleNewLabelGroup,
      disabledFilters,
    ]
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export default Filters;
