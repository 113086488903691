import useLocalStorage from "@rehooks/local-storage";

import useTypedFlags from "hooks/useTypedFlags";
import { SidebarNavigationItem, SideNavigationItemType } from "types/Navigation";
import { NAVIGATION_DEFAULT_VIEW_STORAGE_KEY } from "constants/navigation";

import useNavigationConfig from "./useNavigationConfig";

const useNavigationDefaultView = (
  isAdmin = false
): {
  id: SideNavigationItemType;
  config: SidebarNavigationItem;
} => {
  const { dashboardMetrics } = useTypedFlags();
  const navigationConfig = useNavigationConfig({ isAdmin });
  const [viewFromStorage] = useLocalStorage<SideNavigationItemType>(
    NAVIGATION_DEFAULT_VIEW_STORAGE_KEY
  );

  if (!dashboardMetrics) {
    return {
      id: SideNavigationItemType.Stacks,
      config: navigationConfig[SideNavigationItemType.Stacks],
    };
  }

  if (viewFromStorage && navigationConfig[viewFromStorage]) {
    return {
      id: viewFromStorage,
      config: navigationConfig[viewFromStorage],
    };
  }

  if (navigationConfig[SideNavigationItemType.Dashboard]) {
    return {
      id: SideNavigationItemType.Dashboard,
      config: navigationConfig[SideNavigationItemType.Dashboard],
    };
  }

  return {
    id: SideNavigationItemType.Stacks,
    config: navigationConfig[SideNavigationItemType.Stacks],
  };
};

export default useNavigationDefaultView;
