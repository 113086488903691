import { ReactNode } from "react";
import { PopoverProps } from "react-aria-components";

import { Dots } from "components/icons";

import ButtonIconNew, { ButtonIconNewProps } from "../ButtonIcon/New";
import DropdownMenu from ".";

type DropdownMenuEllipsisProps = {
  children: ReactNode;
  dotsSize?: ButtonIconNewProps["size"];
  buttonVariant?: ButtonIconNewProps["variant"];
  placement?: PopoverProps["placement"];
  disabled?: boolean;
  tooltip: string;
  onPress?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  className?: string;
};

const DropdownMenuEllipsis = ({
  children,
  dotsSize = "small",
  buttonVariant = "secondary",
  placement = "bottom right",
  disabled,
  tooltip,
  onPress,
  onOpenChange,
  className,
}: DropdownMenuEllipsisProps) => {
  return (
    <DropdownMenu
      onOpenChange={onOpenChange}
      placement={placement}
      triggerComponent={
        <ButtonIconNew
          disabled={disabled}
          onPress={onPress}
          variant={buttonVariant}
          icon={Dots}
          size={dotsSize}
          className={className}
        >
          {tooltip}
        </ButtonIconNew>
      }
    >
      {children}
    </DropdownMenu>
  );
};

export default DropdownMenuEllipsis;
