import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import DrawerHeader from "ds/components/Drawer/Header";
import DrawerBody from "ds/components/Drawer/Body";
import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import Textarea from "ds/components/Textarea";
import DrawerFooter from "ds/components/Drawer/Footer";
import DrawerFooterActions from "ds/components/Drawer/FooterActions";
import Button from "ds/components/Button";
import useTypedContext from "hooks/useTypedContext";
import FlashContext from "components/FlashMessages/FlashContext";
import DrawerHeaderTitle from "ds/components/Drawer/HeaderTitle";
import DrawerCloseIcon from "ds/components/Drawer/CloseIcon";

import { CREATE_GPG_KEY } from "../gql";
import { CreateGpgKeyDrawerProps, CreateGpgKeyFormFields, CreateGpgKeyGql } from "./types";

const CreateGpgKeyDrawer = ({ handleCloseDrawer }: CreateGpgKeyDrawerProps) => {
  const builderForm = useForm<CreateGpgKeyFormFields>({
    defaultValues: {
      name: "",
      asciiArmor: "",
      description: "",
    },
    mode: "onChange",
  });

  const { onError, reportError, reportSuccess } = useTypedContext(FlashContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = builderForm;

  const [createGpgKey] = useMutation<CreateGpgKeyGql>(CREATE_GPG_KEY, {
    refetchQueries: ["GetGpgKeys"],
  });

  const onCreateSubmit = (formData: CreateGpgKeyFormFields) => {
    createGpgKey({
      variables: {
        name: formData.name,
        description: formData.description,
        asciiArmor: formData.asciiArmor,
      },
    })
      .then(({ data }) => {
        if (data?.gpgKeyCreate?.name) {
          reportSuccess({
            message: `GPG key "${data.gpgKeyCreate.name}" was successfully registered`,
          });
        } else {
          reportError({
            message: "Something went wrong while registering GPG key, please try again.",
          });
        }

        reset();
        handleCloseDrawer();
      })
      .catch(onError);
  };

  const handleCancel = () => {
    reset();
    handleCloseDrawer();
  };

  return (
    <FormProvider {...builderForm}>
      <DrawerHeader justify="between">
        <DrawerHeaderTitle title="Register GPG key" />
        <DrawerCloseIcon handleCloseDrawer={handleCloseDrawer} />
      </DrawerHeader>
      <DrawerBody fullHeight>
        <FormField label="Name" error={errors?.name?.message}>
          {({ ariaInputProps }) => (
            <Input
              placeholder="Enter a unique name..."
              error={!!errors?.name}
              {...register("name", { required: "Name field is required." })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="ASCII armor">
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter ASCII armor here..."
              maxRows={15}
              {...register("asciiArmor", { required: "ASCII armor field is required." })}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <FormField label="Description" isOptional>
          {({ ariaInputProps }) => (
            <Textarea
              placeholder="Enter description here..."
              maxRows={15}
              {...register("description")}
              {...ariaInputProps}
            />
          )}
        </FormField>

        <DrawerFooter>
          <DrawerFooterActions>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit(onCreateSubmit)}
              disabled={!isValid || !isDirty}
            >
              Register
            </Button>
          </DrawerFooterActions>
        </DrawerFooter>
      </DrawerBody>
    </FormProvider>
  );
};

export default CreateGpgKeyDrawer;
