import { useFormContext } from "react-hook-form";

import FormField from "ds/components/Form/Field";
import Input from "ds/components/Input";
import FormArrayField from "ds/components/Form/ArrayField";

const EMPTY_VALUE = { value: "" };

type FormValues = {
  scopes: Array<{ value: string }>;
};

const StackSettingsIntegrationsCloudAttachFormGCPScopesField = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormValues>();

  return (
    <FormArrayField<FormValues, "scopes">
      name="scopes"
      addButtonLabel="Add another scope"
      emptyValue={EMPTY_VALUE}
    >
      {({ field, index }) => {
        return (
          <FormField
            key={field.id}
            error={errors?.scopes?.[index]?.value?.message}
            label={index === 0 ? "Scopes" : undefined}
            fullWidth
            noMargin
          >
            {({ ariaInputProps }) => (
              <Input
                placeholder="Enter scope"
                error={!!errors?.scopes?.[index]?.value?.message}
                {...register(`scopes.${index}.value`, {
                  required: true,
                  minLength: 1,
                  maxLength: 255,
                })}
                {...ariaInputProps}
              />
            )}
          </FormField>
        );
      }}
    </FormArrayField>
  );
};

export default StackSettingsIntegrationsCloudAttachFormGCPScopesField;
