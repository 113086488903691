import Typography from "ds/components/Typography";
import TextEllipsis from "ds/components/TextEllipsis";
import { RunExternalDependency } from "types/generated";
import Box from "ds/components/Box";
import { TreeBranch, TreeBranchLink } from "ds/components/Tree";

import HistoryEntryWrapperSeparator from "../../components/HistoryEntryWrapper/Separator";
import HistoryEntryDate from "../../components/HistoryEntryDate";
import ExternalDependencyBadge from "./ExternalDependencyBadge";

type DependenciesEntryExternalDependencyProps = {
  item: RunExternalDependency;
};

const DependenciesEntryExternalDependency = ({
  item,
}: DependenciesEntryExternalDependencyProps) => {
  return (
    <TreeBranch>
      <TreeBranchLink
        gap="medium"
        padding="small small small 0"
        grid
        gridTemplate="max-content auto max-content"
      >
        <Typography variant="p-body3" tag="span" color="secondary">
          External dependency
        </Typography>
        <TextEllipsis tooltip={item.id} delay={400} tooltipWidthMode="maxWidthXl">
          {(props) => (
            <Typography {...props} tag="span" variant="p-body3">
              {item.id}
            </Typography>
          )}
        </TextEllipsis>
        <Box gap="medium" align="center">
          <ExternalDependencyBadge state={item.status} />
          <HistoryEntryWrapperSeparator margin="0" />
          <HistoryEntryDate timestamp={item.completedAt || item.createdAt} />
        </Box>
      </TreeBranchLink>
    </TreeBranch>
  );
};

export default DependenciesEntryExternalDependency;
