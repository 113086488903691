import { useMutation } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import DropdownEllipsis from "ds/components/DropdownEllipsis";
import DropdownSectionItem from "ds/components/Dropdown/SectionItem";
import useTypedContext from "hooks/useTypedContext";
import { getManagementStrategy } from "views/account/settings/helpers";
import { SettingsContext } from "views/account/settings/Context";
import useAnalytics from "hooks/useAnalytics";
import { ManagedUser } from "types/generated";
import { isSaasDistribution } from "utils/distribution";
import { AnalyticsPageOrganization } from "hooks/useAnalytics/pages/organization";
import DropdownSection from "ds/components/Dropdown/Section";

import styles from "./styles.module.css";
import { RESEND_MANAGED_USER_INVITE, DELETE_MANAGED_USER } from "../../gql";

const isSaas = isSaasDistribution();

type UsersListInviteDropdownProps = {
  handleUserDetailsClick: (details: ManagedUser) => unknown;
  item: ManagedUser;
};

const UsersListInviteDropdown = ({
  handleUserDetailsClick,
  item,
}: UsersListInviteDropdownProps) => {
  const { activationStatus } = useTypedContext(SettingsContext);
  const { onError, reportSuccess } = useTypedContext(FlashContext);

  const [resendInvite] = useMutation(RESEND_MANAGED_USER_INVITE, {
    refetchQueries: ["GetManagedUsers"],
  });

  const [revokeInvite] = useMutation(DELETE_MANAGED_USER, {
    refetchQueries: ["GetManagedUsers"],
  });

  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageOrganization.OrganizationUsers,
    callbackTrackProviders: { segment: true },
    defaultCallbackTrackProperties: {
      managementStrategy: getManagementStrategy(activationStatus),
    },
  });

  const handleRevokeInvite = (callback: () => void) => {
    trackSegmentAnalyticsEvent("Revoke Invite");
    revokeInvite({ variables: { id: item.id } })
      .then(() => reportSuccess({ message: "Invitation successfully revoked" }))
      .catch(onError);

    callback();
  };

  const handleResendInvite = (callback: () => void) => {
    trackSegmentAnalyticsEvent("Resend Invite");
    resendInvite({ variables: { id: item.id } })
      .then(() => reportSuccess({ message: "Invitation successfully resent" }))
      .catch(onError);

    callback();
  };

  return (
    <div className={styles.wrapper}>
      <DropdownEllipsis tooltip="User actions" dotsSize="small">
        {({ closeDropdown }) => (
          <DropdownSection>
            <DropdownSectionItem onClick={() => handleUserDetailsClick(item)}>
              Access details
            </DropdownSectionItem>
            {isSaas && (
              <DropdownSectionItem onClick={() => handleResendInvite(closeDropdown)}>
                Resend invite
              </DropdownSectionItem>
            )}
            <DropdownSectionItem onClick={() => handleRevokeInvite(closeDropdown)} danger>
              Revoke invite
            </DropdownSectionItem>
          </DropdownSection>
        )}
      </DropdownEllipsis>
    </div>
  );
};

export default UsersListInviteDropdown;
