import { SortableTableColumn } from "components/SortableTable";
import { dropdownEllipsisWidth } from "ds/components/DropdownEllipsis/constants";

export const SORTABLE_TABLE_COLUMNS: SortableTableColumn[] = [
  {
    value: "endpoint",
    label: "Endpoint",
  },
  {
    value: "enabled",
    label: "Enabled",
  },
  {
    value: "createdAt",
    label: "Created at",
  },
];

export const SORTABLE_TABLE_COLUMNS_ORDER = `minmax(12rem, 4fr) 1fr 1.5fr ${dropdownEllipsisWidth}`;
