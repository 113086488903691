import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { ListEntitiesBaseItemProps } from "components/ListEntitiesNew";
import { Context } from "types/generated";

import ContextListItem from ".";

type ContextVirtualizedListItemProps = {
  items: Context[];
  onShowFullDescription: (context: Context) => void;
  onEdit: (context: Context) => void;
  focusedContextId?: string;
  canManageContexts: boolean;
};

const ContextVirtualizedListItem = (
  props: ListChildComponentProps<ContextVirtualizedListItemProps & ListEntitiesBaseItemProps>
) => {
  const { index, style, data } = props;

  const handleSetRowHeight = (size: number) => data.setRowHeight(index, size);

  return (
    <div style={style}>
      <ContextListItem
        key={data.items[index].id}
        item={data.items[index]}
        setRowHeight={handleSetRowHeight}
        onShowFullDescription={data.onShowFullDescription}
        onEdit={data.onEdit}
        isActive={data.focusedContextId === data.items[index].id}
        canManageContexts={data.canManageContexts}
      />
    </div>
  );
};

export default memo(ContextVirtualizedListItem, areEqual);
