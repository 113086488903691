import { useLocation } from "react-router-dom-v5-compat";
import { useMemo, useRef } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";

import FlashContext from "components/FlashMessages/FlashContext";
import useTypedContext from "hooks/useTypedContext";
import { RunWithPosition, WorkerPool } from "types/generated";
import useURLParams from "hooks/useURLParams";
import { getSearchQuery } from "components/SearchInput/helpers";
import { getFiltersPredicationFromURI, getSortOptionFromURI } from "components/Filters/helpers";
import {
  INITIAL_SORT_DIRECTION,
  INITIAL_SORT_OPTION,
  POLL_INTERVAL,
} from "views/account/WorkerPool/QueuedRunsView/constants";

import { SEARCH_PRIVATE_WORKER_POOL_SCHEDULABLE_RUNS } from "./gql";

const useSearchQueuedRuns = (workerPoolId?: string) => {
  const { state: sharedPrivateWorkerPoolState }: { state: WorkerPool | undefined } =
    useLocation(); /*<WorkerPool | undefined> TODO: use generic once react-router-dom is updated to v6 */

  const { onError } = useTypedContext(FlashContext);
  const cachedEdges = useRef<RunWithPosition[]>([]);

  const urlParams = useURLParams();
  const searchInput = getSearchQuery(urlParams);

  const sortOptionFields = useMemo(
    () => getSortOptionFromURI(urlParams, INITIAL_SORT_OPTION, INITIAL_SORT_DIRECTION),
    [urlParams]
  );

  const predicates = useMemo(() => {
    const predicatesMap = getFiltersPredicationFromURI(urlParams, true);

    return [...(predicatesMap?.values() || [])];
  }, [urlParams]);

  const { error, loading, data, stopPolling, refetch, previousData, networkStatus } = useQuery<{
    workerPool: WorkerPool;
  }>(SEARCH_PRIVATE_WORKER_POOL_SCHEDULABLE_RUNS, {
    variables: {
      workerPoolId,
      input: {
        predicates,
        fullTextSearch: searchInput,
        // "first" & "after" arguments are ignored by the server, no pagination is needed here
        first: null,
        after: null,
        ...(sortOptionFields && { orderBy: sortOptionFields }),
      },
    },
    onError,
    pollInterval: POLL_INTERVAL,
    // avoid request executing twice while fetchMore
    nextFetchPolicy: "cache-first",
    // APOLLO CLIENT UPDATE
  });

  const [workerPool, rawSearchRuns] = useMemo(() => {
    const dataWorkerPool = data?.workerPool || previousData?.workerPool;
    if (dataWorkerPool) {
      const { searchSchedulableRuns, ...workerPool } = dataWorkerPool;

      // FYI: data.workerPool could contain the huge qty of searchQueuedRuns fields
      // so we need to separate them from the workerPool object that stays to has WorkerPool type
      // TODO: implement dynamic types base on a query
      return [workerPool as WorkerPool, searchSchedulableRuns];
    }

    return [undefined, undefined];
  }, [data?.workerPool, previousData?.workerPool]);

  const memoizedEntities = useMemo(() => {
    const sourceEdges = rawSearchRuns?.edges.map((edge) => edge.node) || [];
    const edges = loading && !sourceEdges.length ? cachedEdges.current : sourceEdges;

    if (!loading) {
      cachedEdges.current = sourceEdges;
    }

    return edges;
  }, [rawSearchRuns?.edges, loading]);

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) {
      onError(error);
    }
  };

  const workerPoolResult = workerPool || sharedPrivateWorkerPoolState;

  return {
    workerPool: workerPoolResult,
    entities: memoizedEntities,
    error,
    stopPolling,
    loading,
    isPageEmpty: !!(data && !memoizedEntities.length && !searchInput && predicates.length === 0),
    hasNoFilteringResults:
      !!data && !memoizedEntities.length && (!!searchInput || predicates.length > 0),
    refetch: handleRefetch,
    isPageLoading: loading && !workerPoolResult && networkStatus === NetworkStatus.loading,
  };
};

export default useSearchQueuedRuns;
