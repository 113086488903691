import { ReactNode, useCallback } from "react";

import FullScreenModalBody from "ds/components/FullScreenModal/Body";
import FullScreenModalFooter from "ds/components/FullScreenModal/Footer";
import { VcsProvider } from "types/generated";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsPageVCS } from "hooks/useAnalytics/pages/vcs";

import VCSIntegrationsDocumentationButton from "../../components/DocumentationButton";
import VCSIntegrationsContinueButton from "../../components/ContinueButton";

type VCSIntegrationsManageGitHubEnterpriseWizardStepIntroProps = {
  children: ReactNode;
  goToNextStep: () => void;
};

const VCSIntegrationsManageGitHubEnterpriseWizardStepIntro = ({
  children,
  goToNextStep,
}: VCSIntegrationsManageGitHubEnterpriseWizardStepIntroProps) => {
  const trackSegmentAnalyticsEvent = useAnalytics({
    page: AnalyticsPageVCS.VCSIntegrations,
    callbackTrackProviders: { segment: true },
  });

  const handleGoToNextStep = useCallback(() => {
    goToNextStep();
    trackSegmentAnalyticsEvent("GitHub - Wizard Continue Click");
  }, [goToNextStep, trackSegmentAnalyticsEvent]);

  return (
    <>
      <FullScreenModalBody>{children}</FullScreenModalBody>
      <FullScreenModalFooter justify="between">
        <VCSIntegrationsDocumentationButton provider={VcsProvider.GithubEnterprise} />

        <VCSIntegrationsContinueButton onClick={handleGoToNextStep} disabled={false} />
      </FullScreenModalFooter>
    </>
  );
};

export default VCSIntegrationsManageGitHubEnterpriseWizardStepIntro;
